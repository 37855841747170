.main_div {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 0.8rem 1.8rem;
}
.date-div {
  color: #ef5934;
  background-color: #ffd8ce;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 15px;
  letter-spacing: 0.56px;
  border-radius: 5px;
}
.banner_div h5 {
  color: #766c8e;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.72px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.banner_div h3 {
  color: #051b26;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.72px;
}
.banner_div .time {
  width: 15px;
  height: 15px;
}
.b4_photo {
  width: 100px;
  height: 100px;
  border-radius: 15px;
}
.first_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.first_box .name {
  color: #772569;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.66px;
  text-transform: uppercase;
}
.image_section .standard {
  font-size: 12px;
  font-weight: 400;
  color: #772569;
}
.logout_button {
  border-color: none;
  background-color: rgba(36, 105, 92, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 100px;
}
.timerow {
  justify-content: space-between;
  display: flex;
  margin-bottom: 10px;
}
.timetablebutton {
  background-color: #9eb91c !important;
  border-color: #78266a00 !important;
  color: #ffffff;
  transition: background-color 1s ease;
}
.timetablebutton:hover {
  background-color: #fff !important;
  border-color: #9eb91c !important;
  color: #9eb91c;
}
.view {
  width: 28px;
  height: 28px;
}
.image {
  color: #ffff 1px solid;
}
.tablebuttonview {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 10px;
}
.showingnumber {
  display: flex;
  justify-content: space-between;
}
.modal_cancel {
  min-width: 91px;
  background-color: #fff;
  border: 2px solid #772569;
  color: #772569;
  padding: 10px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
}
.checking {
  display: flex;
  justify-content: space-around;
}
.schedulebutton {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.downimage {
  width: 18px;
  height: 18px;
}
.subject_table thead tr {
  background-color: #73276b14;
}
.subject_table {
  padding: 20px;
}
.subject_image {
  width: 25px;
  height: 25px;
  border-radius: 10px;
}
.subject_image_div {
  align-items: center;
  display: flex;
  gap: 5px;
}

.Instruction_list li {
  list-style-type: circle;
  color: #78266a;
  font-size: 14px;
  font-family: "poppins";
}
.Instruction_label {
  font-size: 18px;
  color: #78266a;
  font-family: "poppins";
}
.div_Succesfull {
  background-color: rgba(174, 140, 132, 0.2);
  border-radius: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.classes_text {
  display: flex;
  font-size: 16px;
  justify-content: center;
}
.classes_subtext {
  font-size: 12px;
  display: flex;
  justify-content: center;
}
.count_div {
  background-color: rgba(174, 140, 132, 0.2);
  padding: 2px 5px;
  border-radius: 5px;
}
.custom-modal {
  max-width: 800px;
  width: 100%;
}
.datasize {
  font-size: 14px;
}
.button_div {
  display: flex;
  justify-content: right;
}
.teacherimportbutton {
  display: flex;
  gap: 20px;
}
.flag {
  margin-bottom: 10px;
}
.flag {
  cursor: pointer;
  margin: 0px;
}
.tablehead {
  color: #6c757d;
  font-family: "poppins";
}
.timetable-cell td {
  text-align: center;
}
.timetable-cell th {
  text-align: center;
}
.card-div {
  display: flex;
  gap: 50px;
  justify-content: center;
}
.teacher_profile {
  width: 100px;
  height: 100px;
}
.teacher_profile_div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.labelcolor {
  color: #6c757d;
  width: 30%;
}
.label2 {
  width: 70%;
}
.periodbox {
  padding: 5px;
}
.subletter {
  color: black;
  font-size: 6px;
}
.subjectbox {
  padding: 11px 7px;
}
.thcolor {
  color: #6c757d;
  font-size: 12px;
}
.trstyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subject_name {
  font-size: 10px;
  font-weight: 400;
  text-align: center;
}
.timetable-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  scrollbar-width: none; /* Hide scrollbar */
}

.fixed-size {
  width: 24px; /* Adjust width as needed */
  height: 24px; /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px; /* Optional: for rounded corners */
  color: #000; /* Text color */
}

.icon_size {
  width: 24px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #000;
  box-shadow: 100px;
}
.profilepic {
  width: 20px;
  height: 20px;
  color: skyblue;
}
.fixed-div {
  position: fixed; /* Fixed positioning */
  bottom: 55px; /* Distance from the top */
  left: 20px; /* Distance from the right */
}
.rightfixed-div {
  position: fixed; /* Fixed positioning */
  bottom: 53px; /* Distance from the top */
  right: 12px; /* Distance from the right */
}
.radiodiv {
  display: flex;
  gap: 8px;
  align-items: center;
}
.radiolabel {
  font-size: 11px;
  color: #6c757d;
}
.samplediv {
  width: 10px;
  height: 10px;
  background-color: wheat;
}
.mainsamplediv {
  display: flex;
  align-items: center;
  gap: 2px;
}
.advancelabel {
  font-size: 15px;
  color: #6c757d;
}
