@charset "UTF-8";
@import url(dark.css);
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.1/font/bootstrap-icons.css");
/**========== 01. General CSS Start =========================**/

html {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  font-size: 14px;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
  color: #242934;
  background-color: #f5f7fb;
  scrollbar-width: 5px solid red;
  scrollbar-color: red;
}

.header-icon-svg {
  height: 16px;
  width: 16px;
  color: #592160;
  margin: 0px 3px;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: #ffffff;
}

body::-webkit-scrollbar-thumb {
  background-color: #adc927;
  border-radius: 6px;
  border: 3px solid #fff;
}

::selection {
  color: #5e6b1e;
  background: #adc927;
}

ul {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0;
  padding-right: 0px;
}

a {
  color: #78266a;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.btn-link {
  text-decoration: none;
}

*a {
  color: #78266a;
  text-decoration: none;
}

*a:hover {
  text-decoration: none;
}

*.btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

h6 {
  font-weight: 600;
}

p {
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: inherit;
}

span {
  font-weight: 500;
}

.dropdown-item:active,
.dropdown-item.active {
  background-color: #78266a;
}

hr {
  background-color: #e6edef;
  opacity: 1;
}

.theme-form .col-form-label {
  color: #59667a;
}

.form-label {
  color: #59667a;
  font-weight: 500;
}

code {
  color: #78266a !important;
  background-color: rgba(36, 105, 92, 0.03);
  padding: 3px;
  margin: 0 3px;
  border-radius: 2px;
}

blockquote {
  border-left: 4px solid #e6edef;
  padding: 15px;
}

blockquote.text-center {
  border: none;
  padding: 15px;
}

blockquote.text-end {
  border-left: none;
  border-right: 4px solid #e6edef;
  padding: 15px;
}

:focus {
  outline: none;
}

.accordion-button:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

svg {
  vertical-align: baseline;
}

input:focus {
  outline-color: transparent;
}

.font-nunito {
  font-family: Nunito, sans-serif;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.font-rubik {
  font-family: "Rubik", sans-serif, sans-serif;
}

.media-widgets .media-body {
  margin-bottom: 30px;
}

.rating-star {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rating-star li i {
  color: #f4c705;
}

@-webkit-keyframes rotateInSetting {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes rotateInSetting {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
  }
}

.setting-primary,
.setting-secondary,
.setting-white {
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50px;
}

.setting-primary svg,
.setting-secondary svg,
.setting-white svg {
  width: 15px;
  stroke-width: 2.5px;
  -webkit-animation: rotateInSetting 1.5s ease infinite;
  animation: rotateInSetting 1.5s ease infinite;
}

.lang_select {
  border: none;
  font-weight: 600;
  background: #e9f0ee;
  color: #592160;
  padding: 8px 10px;
  width: 120px;
  border-radius: 4px;
}

.lang_select:hover {
  cursor: pointer;
}

.lang-list:hover {
  background-color: transparent !important;
  cursor: pointer !important;
}

.setting-primary {
  background-color: rgb(255 255 255 / 17%);
}

.setting-primary svg {
  color: #ffffff;
}

.setting-primary i {
  color: #78266a;
}

.setting-primary:hover {
  background-color: #adc926;
}

.setting-primary:hover svg {
  color: #fff;
}

.setting-primary:hover i {
  color: #fff;
}

.setting-secondary {
  background-color: rgba(186, 137, 93, 0.1);
}

.setting-secondary svg {
  color: #ba895d;
}

.setting-secondary i {
  color: #ba895d;
}

.setting-secondary:hover {
  background-color: #ba895d;
}

.setting-secondary:hover svg {
  color: #fff;
}

.setting-secondary:hover i {
  color: #fff;
}

.setting-white {
  background-color: rgba(255, 255, 255, 0.1);
}

.setting-white svg {
  color: #fff;
}

.setting-white i {
  color: #fff;
}

.setting-white:hover {
  background-color: #fff;
}

.setting-white:hover svg {
  color: #78266a;
}

.setting-white:hover i {
  color: #78266a;
}

.setting-bg-primary {
  background-color: rgba(36, 105, 92, 0.1);
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50px;
}

.setting-bg-primary svg {
  width: 15px;
  color: #78266a;
  stroke-width: 2.5px;
  -webkit-animation: rotateInSetting 1.5s ease infinite;
  animation: rotateInSetting 1.5s ease infinite;
}

.typography h1,
.typography .h1,
.typography h2,
.typography .h2,
.typography h3,
.typography .h3,
.typography h4,
.typography .h4,
.typography h5,
.typography .h5,
.typography h6,
.typography .h6 {
  margin: 20px 0;
  margin-top: 0;
}

.typography h1:last-child,
.typography .h1:last-child,
.typography h2:last-child,
.typography .h2:last-child,
.typography h3:last-child,
.typography .h3:last-child,
.typography h4:last-child,
.typography .h4:last-child,
.typography h5:last-child,
.typography .h5:last-child,
.typography h6:last-child,
.typography .h6:last-child {
  margin-bottom: 0;
}

.typography small {
  padding-left: 10px;
  color: #2c323f;
}

pre {
  background-color: rgba(36, 105, 92, 0.03);
  padding: 20px 0;
}

a:hover {
  text-decoration: none;
  color: #78266a;
}

.typography .blockquote-footer {
  background-color: #f5f7fb;
}

.owl-theme .owl-dots .owl-dot span {
  background: #3eb59f;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #78266a;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-bottom: -10px;
  margin-top: 25px;
}

.click2edit ~ .note-editor.note-frame {
  margin-bottom: 30px;
}

.note-editor p {
  color: #999;
}

.note-editor.note-frame {
  border-color: #e6edef;
}

.note-editor i {
  color: #78266a;
}

.note-editor span {
  color: #78266a;
}

.note-editor.note-frame {
  border-color: #e6edef;
}

.modal .modal-body .card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.modal-dialog .modal-content .modal-body p a {
  margin-right: 0;
}

.clipboaard-container p {
  font-weight: 600;
}

.clipboaard-container h6 {
  line-height: 1.8;
}

.alert-theme button {
  top: 24px !important;
  right: 30px !important;
  color: #78266a;
}

.alert-theme span + span + span {
  border-left: 5px solid #78266a;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 14px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 14px -3px rgba(0, 0, 0, 0.2);
}

.alert-theme i {
  padding: 20px;
  padding-right: 0 !important;
  margin-right: 10px !important;
}

.alert-theme .progress {
  margin-top: -2px !important;
  height: 2px;
}

.alert-theme .progress .progress-bar {
  background-color: #78266a;
}

.alert-copy {
  border: 1px solid #78266a;
  line-height: 1;
  display: inline-block;
  width: 300px;
  color: #fff;
  background-color: #78266a;
  -webkit-box-shadow: 3px 3px 5px 0 #9b9b9b;
  box-shadow: 3px 3px 5px 0 #9b9b9b;
}

.alert-copy .close {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  line-height: 1.4;
  font-weight: 100;
}

.ace-editor {
  height: 400px;
  width: 100%;
}

.editor-toolbar {
  width: 100% !important;
}

.CodeMirror {
  top: 0 !important;
}

.border {
  border: 1px solid #e6edef !important;
}

.irs-from,
.irs-to,
.irs-single {
  background: #78266a;
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.row > div {
  position: relative;
}

[class*="col-"] {
  position: relative;
}

.cke_top {
  background-color: #f5f7fb;
}

.cke_wysiwyg_frame,
.cke_wysiwyg_div {
  background-color: #f5f7fb;
}

.modal-header .btn-close,
.modal-footer .btn-close,
.modal-content .btn-close {
  position: absolute;
  top: 30px;
  right: 25px;
}

.btn-close:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.my-gallery.gallery-with-description img {
  border-color: #e6edef !important;
}

/**=====================
      01. General CSS Ends
==========================**/

/**=====================
     02. Generic CSS Start
==========================**/

/*====== Padding css starts ======*/

.p-0 {
  padding: 0px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

/*====== Padding css ends ======*/

/*====== Padding-left css starts ======*/

.p-l-0 {
  padding-left: 0px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-l-35 {
  padding-left: 35px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-l-50 {
  padding-left: 50px;
}

/*====== Padding-left css ends ======*/

/*====== Padding-top css starts ======*/

.p-t-0 {
  padding-top: 0px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

/*====== Padding-top css ends ======*/

/*====== Padding-bottom css starts ======*/

.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

/*====== Padding-bottom css ends ======*/

/*====== Padding-right css starts ======*/

.p-r-0 {
  padding-right: 0px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-25 {
  padding-right: 25px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-r-35 {
  padding-right: 35px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-r-45 {
  padding-right: 45px;
}

.p-r-50 {
  padding-right: 50px;
}

/*====== Padding-right css ends ======*/

/*====== Margin css starts ======*/

.m-0 {
  margin: 0px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

/*====== Margin css ends ======*/

/*====== Margin-top css starts ======*/

.m-t-0 {
  margin-top: 0px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

/*====== Margin-top css ends ======*/

/*====== Margin-Bottom css starts ======*/

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

/*====== Margin-Bottom css ends ======*/

/*====== Margin-left css starts ======*/

.m-l-0 {
  margin-left: 0px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

/*====== Margin-left css ends ======*/

/*====== Margin-right css starts ======*/

.m-r-0 {
  margin-right: 0px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-r-50 {
  margin-right: 50px;
}

/*====== Margin-right css ends ======*/

/*====== Border-radius css starts ======*/

.b-r-0 {
  border-radius: 0px !important;
}

.b-r-1 {
  border-radius: 1px !important;
}

.b-r-2 {
  border-radius: 2px !important;
}

.b-r-3 {
  border-radius: 3px !important;
}

.b-r-4 {
  border-radius: 4px !important;
}

.b-r-5 {
  border-radius: 5px !important;
}

.b-r-6 {
  border-radius: 6px !important;
}

.b-r-7 {
  border-radius: 7px !important;
}

.b-r-8 {
  border-radius: 8px !important;
}

.b-r-9 {
  border-radius: 9px !important;
}

.b-r-10 {
  border-radius: 10px !important;
}

/*====== Border-radius css ends ======*/

/*====== Font-size css starts ======*/

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-32 {
  font-size: 32px;
}

.f-34 {
  font-size: 34px;
}

.f-36 {
  font-size: 36px;
}

.f-38 {
  font-size: 38px;
}

.f-40 {
  font-size: 40px;
}

.f-42 {
  font-size: 42px;
}

.f-44 {
  font-size: 44px;
}

.f-46 {
  font-size: 46px;
}

.f-48 {
  font-size: 48px;
}

.f-50 {
  font-size: 50px;
}

.f-52 {
  font-size: 52px;
}

.f-54 {
  font-size: 54px;
}

.f-56 {
  font-size: 56px;
}

.f-58 {
  font-size: 58px;
}

.f-60 {
  font-size: 60px;
}

.f-62 {
  font-size: 62px;
}

.f-64 {
  font-size: 64px;
}

.f-66 {
  font-size: 66px;
}

.f-68 {
  font-size: 68px;
}

.f-70 {
  font-size: 70px;
}

.f-72 {
  font-size: 72px;
}

.f-74 {
  font-size: 74px;
}

.f-76 {
  font-size: 76px;
}

.f-78 {
  font-size: 78px;
}

.f-80 {
  font-size: 80px;
}

.f-82 {
  font-size: 82px;
}

.f-84 {
  font-size: 84px;
}

.f-86 {
  font-size: 86px;
}

.f-88 {
  font-size: 88px;
}

.f-90 {
  font-size: 90px;
}

.f-92 {
  font-size: 92px;
}

.f-94 {
  font-size: 94px;
}

.f-96 {
  font-size: 96px;
}

.f-98 {
  font-size: 98px;
}

.f-100 {
  font-size: 100px;
}

/*====== Font-size css ends ======*/

/*====== Font-weight css starts ======*/

.f-w-100 {
  font-weight: 100;
}

.f-w-200 {
  font-weight: 200;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-500 {
  font-weight: 500;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-800 {
  font-weight: 800;
}

.f-w-900 {
  font-weight: 900;
}

/*====== Font-weight css ends ======*/

/*====== Font-style css starts ======*/

.f-s-normal {
  font-style: normal;
}

.f-s-italic {
  font-style: italic;
}

.f-s-oblique {
  font-style: oblique;
}

.f-s-initial {
  font-style: initial;
}

.f-s-inherit {
  font-style: inherit;
}

/*====== Font-style css ends ======*/

/*====== Text-Decoration css starts ======*/

.text-overline {
  text-decoration: overline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-dashed {
  -webkit-text-decoration: dashed;
  text-decoration: dashed;
}

.text-blink {
  text-decoration: blink;
}

.text-dotted {
  -webkit-text-decoration: dotted;
  text-decoration: dotted;
}

.text-initial {
  text-decoration: initial;
}

.text-none {
  text-decoration: none;
}

.text-solid {
  -webkit-text-decoration: solid;
  text-decoration: solid;
}

.text-wavy {
  -webkit-text-decoration: wavy;
  text-decoration: wavy;
}

.text-inherit {
  text-decoration: inherit;
}

.text-double {
  -webkit-text-decoration: double;
  text-decoration: double;
}

/*====== Text-Decoration css ends ======*/

/*====== Vertical-Align css starts ======*/

.baseline {
  vertical-align: baseline;
}

.sub {
  vertical-align: sub;
}

.super {
  vertical-align: super;
}

.top {
  vertical-align: top;
}

.text-top {
  vertical-align: text-top;
}

.middle {
  vertical-align: middle;
}

.bottom {
  vertical-align: bottom;
}

.text-bottom {
  vertical-align: text-bottom;
}

.initial {
  vertical-align: initial;
}

.inherit {
  vertical-align: inherit;
}

/*====== Vertical-Align css ends ======*/

/*====== Position css starts ======*/

.p-static {
  position: static;
}

.p-absolute {
  position: absolute;
}

.p-fixed {
  position: fixed;
}

.p-relative {
  position: relative;
}

.p-initial {
  position: initial;
}

.p-inherit {
  position: inherit;
}

/*====== Position css ends ======*/

/*====== Float css starts ======*/

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.f-none {
  float: none;
}

/*====== Float css ends ======*/

/*====== Overflow css starts ======*/

.o-hidden {
  overflow: hidden;
}

.o-visible {
  overflow: visible;
}

.o-auto {
  overflow: auto;
}

/*====== Overflow css ends ======*/

/*====== Image-sizes css starts ======*/

.img-10 {
  width: 10px !important;
}

.img-20 {
  width: 20px !important;
}

.img-30 {
  width: 30px !important;
}

.img-40 {
  width: 40px !important;
}

.img-50 {
  width: 50px !important;
}

.img-60 {
  width: 60px !important;
}

.img-70 {
  width: 70px !important;
}

.img-80 {
  width: 80px !important;
}

.img-90 {
  width: 90px !important;
}

.img-100 {
  width: 100px !important;
}

/*====== Image-sizes css ends ======*/

/*======= Text css starts ===========*/

.font-primary {
  color: #78266a !important;
}

.font-secondary {
  color: #ba895d !important;
}

.font-success {
  color: #1b4c43 !important;
}

.font-danger {
  color: #d22d3d !important;
}

.font-info {
  color: #717171 !important;
}

.font-light {
  color: #e6edef !important;
}

.font-dark {
  color: #2c323f !important;
}

.font-warning {
  color: #e2c636 !important;
}

/*======= Text css ends ===========*/

/*======= Font bold start ===========*/

.fontbold-100 {
  font-weight: 100;
}

.fontbold-200 {
  font-weight: 200;
}

.fontbold-300 {
  font-weight: 300;
}

.fontbold-400 {
  font-weight: 400;
}

.fontbold-500 {
  font-weight: 500;
}

.fontbold-600 {
  font-weight: 600;
}

.fontbold-700 {
  font-weight: 700;
}

.fontbold-800 {
  font-weight: 800;
}

.fontbold-900 {
  font-weight: 900;
}

/*======= Font bold end ===========*/

/*======= Label-color css starts  ======= */

.label {
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

.label-theme {
  background-color: #78266a;
}

.label-primary {
  background-color: #78266a;
}

.label-secondary {
  background-color: #ba895d;
}

.label-success {
  background-color: #1b4c43;
}

.label-danger {
  background-color: #d22d3d;
}

.label-info {
  background-color: #717171;
}

.label-light {
  background-color: #e6edef;
}

.label-dark {
  background-color: #2c323f;
}

.label-warning {
  background-color: #e2c636;
}

/*======= Label-color css ends  ======= */

/*======= Badge-color css starts  ======= */

.badge-primary {
  background-color: #78266a;
}

.badge-secondary {
  background-color: #ba895d;
}

.badge-success {
  background-color: #1b4c43;
}

.badge-danger {
  background-color: #d22d3d;
}

.badge-info {
  background-color: #717171;
}

.badge-light {
  background-color: #e6edef;
}

.badge-dark {
  background-color: #2c323f;
}

.badge-warning {
  background-color: #e2c636;
}

/*======= Badge-color css end  ======= */

/*======= Background-color css starts  ======= */

.bg-primary {
  background-color: #78266a !important;
  color: #fff;
}

.bg-secondary {
  background-color: #ba895d !important;
  color: #fff;
}

.bg-success {
  background-color: #1b4c43 !important;
  color: #fff;
}

.bg-danger {
  background-color: #d22d3d !important;
  color: #fff;
}

.bg-info {
  background-color: #717171 !important;
  color: #fff;
}

.bg-light {
  background-color: #e6edef !important;
  color: #fff;
}

.bg-dark {
  background-color: #2c323f !important;
  color: #fff;
}

.bg-warning {
  background-color: #e2c636 !important;
  color: #fff;
}

/*======= Background-color css end  ======= */

/*======= Font-color css starts  ======= */

.txt-primary {
  color: #78266a !important;
}

.txt-secondary {
  color: #ba895d !important;
}

.txt-success {
  color: #1b4c43 !important;
}

.txt-danger {
  color: #d22d3d !important;
}

.txt-info {
  color: #717171 !important;
}

.txt-light {
  color: #e6edef !important;
}

.txt-dark {
  color: #2c323f !important;
}

.txt-warning {
  color: #e2c636 !important;
}

.txt-google-plus {
  color: #c64e40 !important;
}

.txt-twitter {
  color: #6fa2d8 !important;
}

.txt-linkedin {
  color: #0077b5 !important;
}

.txt-fb {
  color: #50598e !important;
}

.txt-white {
  color: #fff !important;
}

/*======= Font-color css end  ======= */

/*======= Button-color css starts  ======= */

.btn-primary {
  background-color: #78266a !important;
  border-color: #78266a !important;
}

.btn-primary:hover {
  background-color: #78266a00 !important;
  color: #78266a;
  border-color: #78266a !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #78266a !important;
  border-color: #78266a !important;
}

.btn-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #5ec8b4;
  box-shadow: 0 0 0 0.2rem #5ec8b4;
}

.btn-secondary {
  background-color: #ffffff00 !important;
  color: #9ab322;
  border-color: #9ab322 !important;
}

.btn-secondary:hover {
  background-color: #9ab322 !important;
  color: #fff;
  border-color: #9ab322 !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #ba895d !important;
  border-color: #ba895d !important;
}

.btn-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #e8d7c8;
  box-shadow: 0 0 0 0.2rem #e8d7c8;
}

.btn-success {
  background-color: #1b4c43 !important;
  border-color: #1b4c43 !important;
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #1b4c43 !important;
  border-color: #1b4c43 !important;
}

.btn-success:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #44bca6;
  box-shadow: 0 0 0 0.2rem #44bca6;
}

.btn-danger {
  background-color: #d22d3d !important;
  border-color: #d22d3d !important;
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #d22d3d !important;
  border-color: #d22d3d !important;
}

.btn-danger:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #edabb1;
  box-shadow: 0 0 0 0.2rem #edabb1;
}

.btn-info {
  background-color: #717171 !important;
  border-color: #717171 !important;
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #717171 !important;
  border-color: #717171 !important;
}

.btn-info:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #bebebe;
  box-shadow: 0 0 0 0.2rem #bebebe;
}

.btn-light {
  background-color: #e6edef !important;
  border-color: #e6edef !important;
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: #e6edef !important;
  border-color: #e6edef !important;
}

.btn-light:focus {
  -webkit-box-shadow: 0 0 0 0.2rem white;
  box-shadow: 0 0 0 0.2rem white;
}

.btn-dark {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
}

.btn-dark:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #6c7a98;
  box-shadow: 0 0 0 0.2rem #6c7a98;
}

.btn-warning {
  background-color: #e2c636 !important;
  border-color: #e2c636 !important;
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #e2c636 !important;
  border-color: #e2c636 !important;
}

.btn-warning:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #f5ecbc;
  box-shadow: 0 0 0 0.2rem #f5ecbc;
}

.btn-primary-light {
  background-color: rgba(36, 105, 92, 0.1) !important;
  border: none !important;
  color: #78266a;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.btn-primary-light:focus,
.btn-primary-light:hover {
  background-color: rgba(36, 105, 92, 0.5) !important;
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/*======= Button-color css ends  ======= */

.btn-outline-primary-2x {
  border-width: 2px;
  border-color: #78266a;
  color: #78266a;
  background-color: transparent;
}

.btn-outline-primary-2x:hover,
.btn-outline-primary-2x:focus,
.btn-outline-primary-2x:active,
.btn-outline-primary-2x.active {
  color: white;
  background-color: #17433b !important;
  border-color: #17433b !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-secondary-2x {
  border-width: 2px;
  border-color: #ba895d;
  color: #ba895d;
  background-color: transparent;
}

.btn-outline-secondary-2x:hover,
.btn-outline-secondary-2x:focus,
.btn-outline-secondary-2x:active,
.btn-outline-secondary-2x.active {
  color: white;
  background-color: #a07044 !important;
  border-color: #a07044 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-success-2x {
  border-width: 2px;
  border-color: #1b4c43;
  color: #1b4c43;
  background-color: transparent;
}

.btn-outline-success-2x:hover,
.btn-outline-success-2x:focus,
.btn-outline-success-2x:active,
.btn-outline-success-2x.active {
  color: white;
  background-color: #0e2622 !important;
  border-color: #0e2622 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-danger-2x {
  border-width: 2px;
  border-color: #d22d3d;
  color: #d22d3d;
  background-color: transparent;
}

.btn-outline-danger-2x:hover,
.btn-outline-danger-2x:focus,
.btn-outline-danger-2x:active,
.btn-outline-danger-2x.active {
  color: white;
  background-color: #a82431 !important;
  border-color: #a82431 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-info-2x {
  border-width: 2px;
  border-color: #717171;
  color: #717171;
  background-color: transparent;
}

.btn-outline-info-2x:hover,
.btn-outline-info-2x:focus,
.btn-outline-info-2x:active,
.btn-outline-info-2x.active {
  color: white;
  background-color: #585858 !important;
  border-color: #585858 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-light-2x {
  border-width: 2px;
  border-color: #e6edef;
  color: #e6edef;
  background-color: transparent;
}

.btn-outline-light-2x:hover,
.btn-outline-light-2x:focus,
.btn-outline-light-2x:active,
.btn-outline-light-2x.active {
  color: white;
  background-color: #c7d7db !important;
  border-color: #c7d7db !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-dark-2x {
  border-width: 2px;
  border-color: #2c323f;
  color: #2c323f;
  background-color: transparent;
}

.btn-outline-dark-2x:hover,
.btn-outline-dark-2x:focus,
.btn-outline-dark-2x:active,
.btn-outline-dark-2x.active {
  color: white;
  background-color: #171a21 !important;
  border-color: #171a21 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-warning-2x {
  border-width: 2px;
  border-color: #e2c636;
  color: #e2c636;
  background-color: transparent;
}

.btn-outline-warning-2x:hover,
.btn-outline-warning-2x:focus,
.btn-outline-warning-2x:active,
.btn-outline-warning-2x.active {
  color: white;
  background-color: #c8ac1d !important;
  border-color: #c8ac1d !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-primary {
  border-color: #78266a;
  color: #78266a;
  background-color: transparent;
}

.btn-outline-primary.disabled {
  color: #78266a;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active {
  color: white;
  background-color: #17433b !important;
  border-color: #17433b !important;
}

.btn-outline-secondary {
  border-color: #ba895d;
  color: #ba895d;
  background-color: transparent;
}

.btn-outline-secondary.disabled {
  color: #ba895d;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary.active {
  color: white;
  background-color: #a07044 !important;
  border-color: #a07044 !important;
}

.btn-outline-success {
  border-color: #1b4c43;
  color: #1b4c43;
  background-color: transparent;
}

.btn-outline-success.disabled {
  color: #1b4c43;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success.active {
  color: white;
  background-color: #0e2622 !important;
  border-color: #0e2622 !important;
}

.btn-outline-danger {
  border-color: #d22d3d;
  color: #d22d3d;
  background-color: transparent;
}

.btn-outline-danger.disabled {
  color: #d22d3d;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.active {
  color: white;
  background-color: #a82431 !important;
  border-color: #a82431 !important;
}

.btn-outline-info {
  border-color: #717171;
  color: #717171;
  background-color: transparent;
}

.btn-outline-info.disabled {
  color: #717171;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active,
.btn-outline-info.active {
  color: white;
  background-color: #585858 !important;
  border-color: #585858 !important;
}

.btn-outline-light {
  border-color: #e6edef;
  color: #e6edef;
  background-color: transparent;
  color: #242934;
}

.btn-outline-light.disabled {
  color: #e6edef;
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active {
  color: white;
  background-color: #c7d7db !important;
  border-color: #c7d7db !important;
}

.btn-outline-dark {
  border-color: #2c323f;
  color: #2c323f;
  background-color: transparent;
}

.btn-outline-dark.disabled {
  color: #2c323f;
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active,
.btn-outline-dark.active {
  color: white;
  background-color: #171a21 !important;
  border-color: #171a21 !important;
}

.btn-outline-warning {
  border-color: #e2c636;
  color: #e2c636;
  background-color: transparent;
}

.btn-outline-warning.disabled {
  color: #e2c636;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning.active {
  color: white;
  background-color: #c8ac1d !important;
  border-color: #c8ac1d !important;
}

.btn-primary:not([disabled]):not(.disabled).active {
  background-color: #17433b;
  border-color: #17433b;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-primary:not([disabled]):not(.disabled).active:hover,
.btn-primary:not([disabled]):not(.disabled).active:focus,
.btn-primary:not([disabled]):not(.disabled).active:active,
.btn-primary:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #17433b;
  border-color: #17433b;
}

.btn-secondary:not([disabled]):not(.disabled).active {
  background-color: #a07044;
  border-color: #a07044;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-secondary:not([disabled]):not(.disabled).active:hover,
.btn-secondary:not([disabled]):not(.disabled).active:focus,
.btn-secondary:not([disabled]):not(.disabled).active:active,
.btn-secondary:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #a07044;
  border-color: #a07044;
}

.btn-success:not([disabled]):not(.disabled).active {
  background-color: #0e2622;
  border-color: #0e2622;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-success:not([disabled]):not(.disabled).active:hover,
.btn-success:not([disabled]):not(.disabled).active:focus,
.btn-success:not([disabled]):not(.disabled).active:active,
.btn-success:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #0e2622;
  border-color: #0e2622;
}

.btn-danger:not([disabled]):not(.disabled).active {
  background-color: #a82431;
  border-color: #a82431;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-danger:not([disabled]):not(.disabled).active:hover,
.btn-danger:not([disabled]):not(.disabled).active:focus,
.btn-danger:not([disabled]):not(.disabled).active:active,
.btn-danger:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #a82431;
  border-color: #a82431;
}

.btn-info:not([disabled]):not(.disabled).active {
  background-color: #585858;
  border-color: #585858;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-info:not([disabled]):not(.disabled).active:hover,
.btn-info:not([disabled]):not(.disabled).active:focus,
.btn-info:not([disabled]):not(.disabled).active:active,
.btn-info:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #585858;
  border-color: #585858;
}

.btn-light:not([disabled]):not(.disabled).active {
  background-color: #c7d7db;
  border-color: #c7d7db;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-light:not([disabled]):not(.disabled).active:hover,
.btn-light:not([disabled]):not(.disabled).active:focus,
.btn-light:not([disabled]):not(.disabled).active:active,
.btn-light:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #c7d7db;
  border-color: #c7d7db;
}

.btn-dark:not([disabled]):not(.disabled).active {
  background-color: #171a21;
  border-color: #171a21;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-dark:not([disabled]):not(.disabled).active:hover,
.btn-dark:not([disabled]):not(.disabled).active:focus,
.btn-dark:not([disabled]):not(.disabled).active:active,
.btn-dark:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #171a21;
  border-color: #171a21;
}

.btn-warning:not([disabled]):not(.disabled).active {
  background-color: #c8ac1d;
  border-color: #c8ac1d;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-warning:not([disabled]):not(.disabled).active:hover,
.btn-warning:not([disabled]):not(.disabled).active:focus,
.btn-warning:not([disabled]):not(.disabled).active:active,
.btn-warning:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #c8ac1d;
  border-color: #c8ac1d;
}

.btn-outline-primary-2x:not([disabled]):not(.disabled).active {
  background-color: #78266a;
  border-color: #78266a;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-primary-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-primary-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-primary-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-primary-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #17433b !important;
  border-color: #17433b !important;
}

.btn-outline-secondary-2x:not([disabled]):not(.disabled).active {
  background-color: #ba895d;
  border-color: #ba895d;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-secondary-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #a07044 !important;
  border-color: #a07044 !important;
}

.btn-outline-success-2x:not([disabled]):not(.disabled).active {
  background-color: #1b4c43;
  border-color: #1b4c43;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-success-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-success-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-success-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-success-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #0e2622 !important;
  border-color: #0e2622 !important;
}

.btn-outline-danger-2x:not([disabled]):not(.disabled).active {
  background-color: #d22d3d;
  border-color: #d22d3d;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-danger-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-danger-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-danger-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-danger-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #a82431 !important;
  border-color: #a82431 !important;
}

.btn-outline-info-2x:not([disabled]):not(.disabled).active {
  background-color: #717171;
  border-color: #717171;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-info-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-info-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-info-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-info-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #585858 !important;
  border-color: #585858 !important;
}

.btn-outline-light-2x:not([disabled]):not(.disabled).active {
  background-color: #e6edef;
  border-color: #e6edef;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-light-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-light-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-light-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-light-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #c7d7db !important;
  border-color: #c7d7db !important;
}

.btn-outline-dark-2x:not([disabled]):not(.disabled).active {
  background-color: #2c323f;
  border-color: #2c323f;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-dark-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-dark-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-dark-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-dark-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #171a21 !important;
  border-color: #171a21 !important;
}

.btn-outline-warning-2x:not([disabled]):not(.disabled).active {
  background-color: #e2c636;
  border-color: #e2c636;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

.btn-outline-warning-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-warning-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-warning-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-warning-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #c8ac1d !important;
  border-color: #c8ac1d !important;
}

/*======= Table-Border-Bottom-color css starts  ======= */

table thead .border-bottom-primary th,
table tbody .border-bottom-primary th,
table tbody .border-bottom-primary td {
  border-bottom: 1px solid #78266a;
}

table thead .border-bottom-secondary th,
table tbody .border-bottom-secondary th,
table tbody .border-bottom-secondary td {
  border-bottom: 1px solid #ba895d;
}

table thead .border-bottom-success th,
table tbody .border-bottom-success th,
table tbody .border-bottom-success td {
  border-bottom: 1px solid #1b4c43;
}

table thead .border-bottom-danger th,
table tbody .border-bottom-danger th,
table tbody .border-bottom-danger td {
  border-bottom: 1px solid #d22d3d;
}

table thead .border-bottom-info th,
table tbody .border-bottom-info th,
table tbody .border-bottom-info td {
  border-bottom: 1px solid #717171;
}

table thead .border-bottom-light th,
table tbody .border-bottom-light th,
table tbody .border-bottom-light td {
  border-bottom: 1px solid #e6edef;
}

table thead .border-bottom-dark th,
table tbody .border-bottom-dark th,
table tbody .border-bottom-dark td {
  border-bottom: 1px solid #2c323f;
}

table thead .border-bottom-warning th,
table tbody .border-bottom-warning th,
table tbody .border-bottom-warning td {
  border-bottom: 1px solid #e2c636;
}

/*======= Table-Border-Bottom-color css ends  ======= */

/*======= Table styling css starts  ======= */

.table-styling .table-primary,
.table-styling.table-primary {
  background-color: #78266a;
  color: #fff !important;
  border: 3px solid #78266a;
}

.table-styling .table-primary thead,
.table-styling.table-primary thead {
  background-color: #17433b;
  border: 3px solid #17433b;
}

.table-styling .table-primary tr th,
.table-styling .table-primary tr td,
.table-styling.table-primary tr th,
.table-styling.table-primary tr td {
  color: #fff;
}

.table-styling .table-primary th,
.table-styling .table-primary td,
.table-styling.table-primary th,
.table-styling.table-primary td {
  background-color: transparent;
}

.table-styling .table-secondary,
.table-styling.table-secondary {
  background-color: #ba895d;
  color: #fff !important;
  border: 3px solid #ba895d;
}

.table-styling .table-secondary thead,
.table-styling.table-secondary thead {
  background-color: #a07044;
  border: 3px solid #a07044;
}

.table-styling .table-secondary tr th,
.table-styling .table-secondary tr td,
.table-styling.table-secondary tr th,
.table-styling.table-secondary tr td {
  color: #fff;
}

.table-styling .table-secondary th,
.table-styling .table-secondary td,
.table-styling.table-secondary th,
.table-styling.table-secondary td {
  background-color: transparent;
}

.table-styling .table-success,
.table-styling.table-success {
  background-color: #1b4c43;
  color: #fff !important;
  border: 3px solid #1b4c43;
}

.table-styling .table-success thead,
.table-styling.table-success thead {
  background-color: #0e2622;
  border: 3px solid #0e2622;
}

.table-styling .table-success tr th,
.table-styling .table-success tr td,
.table-styling.table-success tr th,
.table-styling.table-success tr td {
  color: #fff;
}

.table-styling .table-success th,
.table-styling .table-success td,
.table-styling.table-success th,
.table-styling.table-success td {
  background-color: transparent;
}

.table-styling .table-danger,
.table-styling.table-danger {
  background-color: #d22d3d;
  color: #fff !important;
  border: 3px solid #d22d3d;
}

.table-styling .table-danger thead,
.table-styling.table-danger thead {
  background-color: #a82431;
  border: 3px solid #a82431;
}

.table-styling .table-danger tr th,
.table-styling .table-danger tr td,
.table-styling.table-danger tr th,
.table-styling.table-danger tr td {
  color: #fff;
}

.table-styling .table-danger th,
.table-styling .table-danger td,
.table-styling.table-danger th,
.table-styling.table-danger td {
  background-color: transparent;
}

.table-styling .table-info,
.table-styling.table-info {
  background-color: #717171;
  color: #fff !important;
  border: 3px solid #717171;
}

.table-styling .table-info thead,
.table-styling.table-info thead {
  background-color: #585858;
  border: 3px solid #585858;
}

.table-styling .table-info tr th,
.table-styling .table-info tr td,
.table-styling.table-info tr th,
.table-styling.table-info tr td {
  color: #fff;
}

.table-styling .table-info th,
.table-styling .table-info td,
.table-styling.table-info th,
.table-styling.table-info td {
  background-color: transparent;
}

.table-styling .table-light,
.table-styling.table-light {
  background-color: #e6edef;
  color: #fff !important;
  border: 3px solid #e6edef;
}

.table-styling .table-light thead,
.table-styling.table-light thead {
  background-color: #c7d7db;
  border: 3px solid #c7d7db;
}

.table-styling .table-light tr th,
.table-styling .table-light tr td,
.table-styling.table-light tr th,
.table-styling.table-light tr td {
  color: #fff;
}

.table-styling .table-light th,
.table-styling .table-light td,
.table-styling.table-light th,
.table-styling.table-light td {
  background-color: transparent;
}

.table-styling .table-dark,
.table-styling.table-dark {
  background-color: #2c323f;
  color: #fff !important;
  border: 3px solid #2c323f;
}

.table-styling .table-dark thead,
.table-styling.table-dark thead {
  background-color: #171a21;
  border: 3px solid #171a21;
}

.table-styling .table-dark tr th,
.table-styling .table-dark tr td,
.table-styling.table-dark tr th,
.table-styling.table-dark tr td {
  color: #fff;
}

.table-styling .table-dark th,
.table-styling .table-dark td,
.table-styling.table-dark th,
.table-styling.table-dark td {
  background-color: transparent;
}

.table-styling .table-warning,
.table-styling.table-warning {
  background-color: #e2c636;
  color: #fff !important;
  border: 3px solid #e2c636;
}

.table-styling .table-warning thead,
.table-styling.table-warning thead {
  background-color: #c8ac1d;
  border: 3px solid #c8ac1d;
}

.table-styling .table-warning tr th,
.table-styling .table-warning tr td,
.table-styling.table-warning tr th,
.table-styling.table-warning tr td {
  color: #fff;
}

.table-styling .table-warning th,
.table-styling .table-warning td,
.table-styling.table-warning th,
.table-styling.table-warning td {
  background-color: transparent;
}

/*======= Table styling css ends  ======= */

/*======= All-Borders-color css starts  ======= */

.b-primary {
  border: 1px solid #78266a !important;
}

.b-t-primary {
  border-top: 1px solid #78266a !important;
}

.b-b-primary {
  border-bottom: 1px solid #78266a !important;
}

.b-l-primary {
  border-left: 1px solid #78266a !important;
}

.b-r-primary {
  border-right: 1px solid #78266a !important;
}

.b-secondary {
  border: 1px solid #ba895d !important;
}

.b-t-secondary {
  border-top: 1px solid #ba895d !important;
}

.b-b-secondary {
  border-bottom: 1px solid #ba895d !important;
}

.b-l-secondary {
  border-left: 1px solid #ba895d !important;
}

.b-r-secondary {
  border-right: 1px solid #ba895d !important;
}

.b-success {
  border: 1px solid #1b4c43 !important;
}

.b-t-success {
  border-top: 1px solid #1b4c43 !important;
}

.b-b-success {
  border-bottom: 1px solid #1b4c43 !important;
}

.b-l-success {
  border-left: 1px solid #1b4c43 !important;
}

.b-r-success {
  border-right: 1px solid #1b4c43 !important;
}

.b-danger {
  border: 1px solid #d22d3d !important;
}

.b-t-danger {
  border-top: 1px solid #d22d3d !important;
}

.b-b-danger {
  border-bottom: 1px solid #d22d3d !important;
}

.b-l-danger {
  border-left: 1px solid #d22d3d !important;
}

.b-r-danger {
  border-right: 1px solid #d22d3d !important;
}

.b-info {
  border: 1px solid #717171 !important;
}

.b-t-info {
  border-top: 1px solid #717171 !important;
}

.b-b-info {
  border-bottom: 1px solid #717171 !important;
}

.b-l-info {
  border-left: 1px solid #717171 !important;
}

.b-r-info {
  border-right: 1px solid #717171 !important;
}

.b-light {
  border: 1px solid #e6edef !important;
}

.b-t-light {
  border-top: 1px solid #e6edef !important;
}

.b-b-light {
  border-bottom: 1px solid #e6edef !important;
}

.b-l-light {
  border-left: 1px solid #e6edef !important;
}

.b-r-light {
  border-right: 1px solid #e6edef !important;
}

.b-dark {
  border: 1px solid #2c323f !important;
}

.b-t-dark {
  border-top: 1px solid #2c323f !important;
}

.b-b-dark {
  border-bottom: 1px solid #2c323f !important;
}

.b-l-dark {
  border-left: 1px solid #2c323f !important;
}

.b-r-dark {
  border-right: 1px solid #2c323f !important;
}

.b-warning {
  border: 1px solid #e2c636 !important;
}

.b-t-warning {
  border-top: 1px solid #e2c636 !important;
}

.b-b-warning {
  border-bottom: 1px solid #e2c636 !important;
}

.b-l-warning {
  border-left: 1px solid #e2c636 !important;
}

.b-r-warning {
  border-right: 1px solid #e2c636 !important;
}

/*======= All-Borders-color css ends  ======= */

/*====== Border width css starts ======*/

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-6 {
  border-width: 6px !important;
}

.border-7 {
  border-width: 7px !important;
}

.border-8 {
  border-width: 8px !important;
}

.border-9 {
  border-width: 9px !important;
}

.border-10 {
  border-width: 10px !important;
}

/*====== Border width css ends ======*/

.opacity-0 {
  opacity: 0;
}

.shadow-0 {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.map-block {
  height: 350px;
  width: 100%;
}

.map-js-height {
  height: 500px;
}

/**====== custom scrollbar css start ======**/

.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #e6edef;
}

.custom-scrollbar::-webkit-scrollbar {
  /* width: 6px; */
  display: none;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(68, 103, 242, 0);
}

/**====== Custom scrollbar css end ======**/

/**====== Animation css Start ======**/

.comment {
  color: #9f9ba5;
  font-style: italic;
}

.line {
  color: #2c323f;
}

.line pre {
  font-size: 100%;
}

/**====== Animation css end ======**/

/**====== list style css Start ======**/

.list-circle {
  list-style: circle;
}

/**====== list style css end ======**/

/**====== Modal style css Start ======**/

.theme-close {
  opacity: 1;
  height: 40px;
  width: 40px;
  position: absolute;
  z-index: 1;
  right: 0;
  background-color: #fff !important;
  border-radius: 5px;
}

/**====== Modal style css end ======**/

/**====== Animation css start ======**/

.options > div {
  color: #cccccc;
  display: inline-block;
  padding: 2px 10px;
  border: 1px solid;
  margin: 0 8px 8px 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.options > div:hover {
  background-color: #78266a;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/**====== Animation css Ends ======**/

.modal-footer {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.img-cropper #putData {
  margin-bottom: 0;
}

.img-cropper .img-container {
  min-height: auto;
  margin-bottom: 0;
}

.img-cropper .docs-data > .input-group:last-child {
  margin-bottom: 0;
}

.img-cropper .docs-preview {
  margin-top: 20px;
  margin-bottom: 10px;
}

.dropzone .dz-preview .dz-error-message {
  background: #fff !important;
  color: #78266a !important;
  border: 1px solid #78266a;
}

.dropzone .dz-preview .dz-error-message:after {
  border-bottom: 6px solid #78266a !important;
}

.typeahead .theme-form .form-group {
  margin-bottom: 0;
}

.editor-statusbar {
  border-bottom: 1px solid #e6edef;
}

.page-builder .ge-canvas.ge-editing .row {
  padding: 30px;
  margin-bottom: 0;
}

.page-builder .ge-canvas.ge-layout-desktop [class*="col-"] {
  width: inherit !important;
}

.page-builder .btn-screen {
  padding: 0 18px 0 0;
}

.datepicker {
  z-index: 99;
}

/**=====================
     02. Generic CSS Ends
==========================**/

/**=====================
    03.  Card CSS Start
==========================**/

.card {
  margin-bottom: 10px;
  border: 1px solid #e6edef;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 0;
  background-color: #fff;
}

.card .card-header {
  padding: 30px;
  border-bottom: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  background-color: #fff;
}

.card .card-header.card-no-border {
  border-bottom: none !important;
}

.card .card-header h4 {
  font-size: 24px;
  font-weight: 500;
}

.card .card-header h5 {
  font-size: 22px;
  font-weight: 500;
}

.card .card-header h5:not(.mb-0) {
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3;
  color: #2c323f;
}

.card .card-header > span {
  font-size: 12px;
  color: rgba(36, 41, 52, 0.7);
  margin-top: 5px;
  display: block;
  letter-spacing: 1px;
}

.card .card-header.bg-primary h4,
.card .card-header.bg-secondary h4,
.card .card-header.bg-success h4,
.card .card-header.bg-danger h4,
.card .card-header.bg-warning h4 {
  color: #fff;
}

.card .card-header.bg-primary h5,
.card .card-header.bg-secondary h5,
.card .card-header.bg-success h5,
.card .card-header.bg-danger h5,
.card .card-header.bg-warning h5 {
  color: #fff;
}

.card .card-header.bg-primary > span,
.card .card-header.bg-secondary > span,
.card .card-header.bg-success > span,
.card .card-header.bg-danger > span,
.card .card-header.bg-warning > span {
  color: #fff;
}

.card .card-body {
  padding: 20px;
  background-color: transparent;
}

.card .card-body p:last-child {
  margin-bottom: 0;
}

.card .card-body.bg-primary p,
.card .card-body.bg-secondary p,
.card .card-body.bg-success p,
.card .card-body.bg-danger p,
.card .card-body.bg-warning p {
  color: #fff;
}

.card .sub-title {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
  padding-bottom: 5px;
  margin-bottom: 8px;
  font-size: 18px;
}

.card .card-footer {
  background-color: transparent;
  border-top: 1px solid #e6edef;
  padding: 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.card .card-footer.bg-primary,
.card .card-footer.bg-secondary,
.card .card-footer.bg-success,
.card .card-footer.bg-danger,
.card .card-footer.bg-warning {
  color: #fff;
}

.setting-list {
  border-radius: 0 0 0 7px;
  position: absolute;
  right: 20px;
  top: 22px;
  display: inline-block;
  float: right;
  z-index: 1;
  background-color: #fff;
}

.setting-list .setting-option {
  text-align: right;
  width: 35px;
  height: 35px;
  overflow: hidden;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.setting-list .setting-option li {
  display: inline-block;
}

.setting-list .setting-option li:first-child i.icon-angle-double-right {
  font-size: 14px;
}

.setting-list .setting-option.open-setting {
  width: 190px;
}

.setting-list .setting-option.setting-vertical {
  text-align: center;
  height: 40px;
}

.setting-list .setting-option.setting-vertical li {
  display: block;
  margin: 5px 0;
}

.setting-list
  .setting-option.setting-vertical
  li:first-child
  i.icon-angle-double-right {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.setting-list .setting-option.setting-vertical.open-setting {
  height: 170px;
  width: 35px;
}

.setting-list i {
  margin: 0 5px;
  cursor: pointer;
  line-height: 20px;
}

.setting-list i.icofont-refresh {
  font-size: 13px;
}

.card.card-load .card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 8;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.card.card-load .card-loader i {
  margin: 0 auto;
  color: #78266a;
  font-size: 20px;
}

.card.full-card {
  position: fixed;
  top: 0;
  z-index: 99999;
  -webkit-box-shadow: none;
  box-shadow: none;
  right: 0;
  border-radius: 0;
  border: 1px solid #efefef;
  width: calc(100vw - 12px);
  height: calc(100vh);
}

.card.full-card .card-body {
  overflow: auto;
}

.card-absolute {
  margin-top: 20px;
}

.card-absolute .card-header {
  position: absolute;
  top: -20px;
  margin-bottom: 30px;
  left: 15px;
  border-radius: 0.25rem;
  padding: 10px 15px;
}

.card-absolute .card-header h5 {
  font-size: 17px;
}

.card-absolute .card-header h5.text-white {
  -webkit-text-fill-color: #fff;
}

.card-absolute .card-body {
  margin-top: 10px;
}

.card-header .border-tab {
  margin-bottom: -13px;
}

.custom-card {
  overflow: hidden;
  padding: 30px;
}

.custom-card .card-header {
  padding: 0;
}

.custom-card .card-header img {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.custom-card .card-profile {
  text-align: center;
}

.custom-card .card-profile img {
  height: 110px;
  padding: 7px;
  background-color: #f5f7fb;
  z-index: 1;
  position: relative;
}

.custom-card .card-social {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.custom-card .card-social li {
  display: inline-block;
}

.custom-card .card-social li:nth-child(n + 2) {
  margin-left: 10px;
}

.custom-card .card-social li a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: rgba(36, 105, 92, 0.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #78266a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.custom-card .card-social li:hover a {
  background-color: #78266a;
  color: #fff;
}

.custom-card .profile-details h4 {
  font-weight: 600;
  color: #242934;
}

.custom-card .profile-details h6 {
  margin-bottom: 30px;
  margin-top: 10px;
  color: #999;
  font-size: 14px;
}

.custom-card .card-footer {
  padding: 0;
}

.custom-card .card-footer > div {
  padding: 15px;
  text-align: center;
}

.custom-card .card-footer > div + div {
  border-left: 1px solid #efefef;
}

.custom-card .card-footer > div h3 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}

.custom-card .card-footer > div h6 {
  font-size: 13px;
  color: #999;
}

.custom-card .card-footer > div i {
  font-size: 24px;
  display: inline-block;
  margin-bottom: 15px;
}

.custom-card .card-footer > div .m-b-card {
  margin-bottom: 10px;
}

/**=====================
    03. Card CSS End
==========================**/

/**=====================
     04. Loader CSS Start
==========================**/

.loader-wrapper {
  position: fixed;
  z-index: 999999;
  background: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.loader-wrapper .theme-loader {
  height: 100px;
  width: 100px;
  position: relative;
}

.loader-wrapper .theme-loader .loader-p {
  border: 0 solid transparent;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  position: absolute;
  top: calc(50vh - 75px);
  left: calc(50vw - 75px);
}

.loader-wrapper .theme-loader .loader-p:before {
  content: "";
  border: 1em solid #78266a;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
  opacity: 0;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.loader-wrapper .theme-loader .loader-p:after {
  content: "";
  border: 1em solid #78266a;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
  opacity: 0;
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes loader {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.loader-box {
  height: 150px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
  transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;
}

.loader-box [class*="loader-"] {
  display: inline-block;
  width: 50px;
  height: 50px;
  color: inherit;
  vertical-align: middle;
}

.loader-box .loader-1 {
  border: 0.2em dotted #78266a;
  border-radius: 50%;
  -webkit-animation: 1s loader-01 linear infinite;
  animation: 1s loader-01 linear infinite;
}

@-webkit-keyframes loader-01 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-01 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-2 {
  border: 0.2em solid transparent;
  border-left-color: #ba895d;
  border-right-color: #ba895d;
  border-radius: 50%;
  -webkit-animation: 1s loader-02 linear infinite;
  animation: 1s loader-02 linear infinite;
}

@-webkit-keyframes loader-02 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-02 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-3 {
  border: 0.2em solid #1b4c43;
  border-bottom-color: transparent;
  border-radius: 50%;
  -webkit-animation: 1s loader-03 linear infinite;
  animation: 1s loader-03 linear infinite;
  position: relative;
}

@-webkit-keyframes loader-03 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-03 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-4 {
  border: 1px solid #717171;
  border-radius: 50%;
  -webkit-animation: 1s loader-04 linear infinite;
  animation: 1s loader-04 linear infinite;
  position: relative;
}

.loader-box .loader-4:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: -0.2em;
  left: 50%;
  border: 0.2em solid #717171;
  border-radius: 50%;
}

@-webkit-keyframes loader-04 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-04 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-5 {
  border: 0.2em solid transparent;
  border-top-color: #e2c636;
  border-radius: 50%;
  -webkit-animation: 1s loader-05 linear infinite;
  animation: 1s loader-05 linear infinite;
  position: relative;
}

.loader-box .loader-5:before {
  content: "";
  display: block;
  width: inherit;
  height: inherit;
  position: absolute;
  top: -0.2em;
  left: -0.2em;
  border: 0.2em solid #e2c636;
  border-radius: 50%;
  opacity: 0.5;
}

@-webkit-keyframes loader-05 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-05 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-6 {
  border: 0.2em solid #d22d3d;
  border-radius: 50%;
  -webkit-animation: loader-06 1s ease-out infinite;
  animation: loader-06 1s ease-out infinite;
}

@-webkit-keyframes loader-06 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes loader-06 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.loader-box .loader-7 {
  border: 0 solid transparent;
  border-radius: 50%;
  position: relative;
}

.loader-box .loader-7:before,
.loader-box .loader-7:after {
  content: "";
  border: 0.2em solid #78266a;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: loader-07 1s linear infinite;
  animation: loader-07 1s linear infinite;
  opacity: 0;
}

.loader-box .loader-7:before {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.loader-box .loader-7:after {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes loader-07 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes loader-07 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.loader-box .loader-8 {
  position: relative;
}

.loader-box .loader-8:before,
.loader-box .loader-8:after {
  content: "";
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-color: #ba895d;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: loader-08 2s infinite ease-in-out;
  animation: loader-08 2s infinite ease-in-out;
}

.loader-box .loader-8:after {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes loader-08 {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes loader-08 {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.loader-box .loader-9 {
  background-color: #1b4c43;
  border-radius: 50%;
  -webkit-animation: loader-09 1s infinite ease-in-out;
  animation: loader-09 1s infinite ease-in-out;
}

@-webkit-keyframes loader-09 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes loader-09 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.loader-box .loader-10 {
  position: relative;
  -webkit-animation: loader-10-1 2s infinite linear;
  animation: loader-10-1 2s infinite linear;
}

.loader-box .loader-10:before,
.loader-box .loader-10:after {
  content: "";
  width: 0;
  height: 0;
  border: 0.5em solid #717171;
  display: block;
  position: absolute;
  border-radius: 100%;
  -webkit-animation: loader-10-2 2s infinite ease-in-out;
  animation: loader-10-2 2s infinite ease-in-out;
}

.loader-box .loader-10:before {
  top: 0;
  left: 50%;
}

.loader-box .loader-10:after {
  bottom: 0;
  right: 50%;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes loader-10-1 {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-10-1 {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-10-2 {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes loader-10-2 {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.loader-box .loader-11 {
  background-color: #e2c636;
  -webkit-animation: loader-11 1.2s infinite ease-in-out;
  animation: loader-11 1.2s infinite ease-in-out;
}

@-webkit-keyframes loader-11 {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes loader-11 {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.loader-box .loader-12 {
  position: relative;
}

.loader-box .loader-12:before,
.loader-box .loader-12:after {
  content: "";
  display: block;
  position: absolute;
  background-color: #d22d3d;
  left: 50%;
  right: 0;
  top: 0;
  bottom: 50%;
  -webkit-box-shadow: -0.5em 0 0 #d22d3d;
  box-shadow: -0.5em 0 0 #d22d3d;
  -webkit-animation: loader-12 1s linear infinite;
  animation: loader-12 1s linear infinite;
}

.loader-box .loader-12:after {
  top: 50%;
  bottom: 0;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

@-webkit-keyframes loader-12 {
  0%,
  100% {
    -webkit-box-shadow: -0.5em 0 0 transparent;
    box-shadow: -0.5em 0 0 transparent;
    background-color: #d22d3d;
  }

  50% {
    -webkit-box-shadow: -0.5em 0 0 #d22d3d;
    box-shadow: -0.5em 0 0 #d22d3d;
    background-color: transparent;
  }
}

@keyframes loader-12 {
  0%,
  100% {
    -webkit-box-shadow: -0.5em 0 0 transparent;
    box-shadow: -0.5em 0 0 transparent;
    background-color: #d22d3d;
  }

  50% {
    -webkit-box-shadow: -0.5em 0 0 #d22d3d;
    box-shadow: -0.5em 0 0 #d22d3d;
    background-color: transparent;
  }
}

.loader-box .loader-13:before,
.loader-box .loader-13:after,
.loader-box .loader-13 {
  border-radius: 50%;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: loader-13 1.8s infinite ease-in-out;
  animation: loader-13 1.8s infinite ease-in-out;
}

.loader-box .loader-13 {
  color: #78266a;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  top: -1em;
}

.loader-box .loader-13:before {
  right: 100%;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader-box .loader-13:after {
  left: 100%;
}

.loader-box .loader-13:before,
.loader-box .loader-13:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: inherit;
  height: inherit;
}

@-webkit-keyframes loader-13 {
  0%,
  80%,
  100% {
    -webkit-box-shadow: 0 1em 0 -1em;
    box-shadow: 0 1em 0 -1em;
  }

  40% {
    -webkit-box-shadow: 0 1em 0 -0.2em;
    box-shadow: 0 1em 0 -0.2em;
  }
}

@keyframes loader-13 {
  0%,
  80%,
  100% {
    -webkit-box-shadow: 0 1em 0 -1em;
    box-shadow: 0 1em 0 -1em;
  }

  40% {
    -webkit-box-shadow: 0 1em 0 -0.2em;
    box-shadow: 0 1em 0 -0.2em;
  }
}

.loader-box .loader-14 {
  border-radius: 50%;
  -webkit-box-shadow: 0 1em 0 -0.2em #ba895d;
  box-shadow: 0 1em 0 -0.2em #ba895d;
  position: relative;
  -webkit-animation: loader-14 0.8s ease-in-out alternate infinite;
  animation: loader-14 0.8s ease-in-out alternate infinite;
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
  top: -1em;
}

.loader-box .loader-14:after,
.loader-box .loader-14:before {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  -webkit-animation: inherit;
  animation: inherit;
}

.loader-box .loader-14:before {
  left: -1em;
  -webkit-animation-delay: 0.48s;
  animation-delay: 0.48s;
}

.loader-box .loader-14:after {
  right: -1em;
  -webkit-animation-delay: 0.16s;
  animation-delay: 0.16s;
}

@-webkit-keyframes loader-14 {
  0% {
    -webkit-box-shadow: 0 2em 0 -0.2em #ba895d;
    box-shadow: 0 2em 0 -0.2em #ba895d;
  }

  100% {
    -webkit-box-shadow: 0 1em 0 -0.2em #ba895d;
    box-shadow: 0 1em 0 -0.2em #ba895d;
  }
}

@keyframes loader-14 {
  0% {
    -webkit-box-shadow: 0 2em 0 -0.2em #ba895d;
    box-shadow: 0 2em 0 -0.2em #ba895d;
  }

  100% {
    -webkit-box-shadow: 0 1em 0 -0.2em #ba895d;
    box-shadow: 0 1em 0 -0.2em #ba895d;
  }
}

.loader-box .loader-15 {
  background: #1b4c43;
  position: relative;
  -webkit-animation: loader-15 1s ease-in-out infinite;
  animation: loader-15 1s ease-in-out infinite;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  width: 0.25em;
  height: 0.5em;
  margin: 0 0.5em;
}

.loader-box .loader-15:after,
.loader-box .loader-15:before {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  background: inherit;
  -webkit-animation: inherit;
  animation: inherit;
}

.loader-box .loader-15:before {
  right: 0.5em;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.loader-box .loader-15:after {
  left: 0.5em;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes loader-15 {
  0%,
  100% {
    -webkit-box-shadow: 0 0 0 #1b4c43, 0 0 0 #1b4c43;
    box-shadow: 0 0 0 #1b4c43, 0 0 0 #1b4c43;
  }

  50% {
    -webkit-box-shadow: 0 -0.25em 0 #1b4c43, 0 0.25em 0 #1b4c43;
    box-shadow: 0 -0.25em 0 #1b4c43, 0 0.25em 0 #1b4c43;
  }
}

@keyframes loader-15 {
  0%,
  100% {
    -webkit-box-shadow: 0 0 0 #1b4c43, 0 0 0 #1b4c43;
    box-shadow: 0 0 0 #1b4c43, 0 0 0 #1b4c43;
  }

  50% {
    -webkit-box-shadow: 0 -0.25em 0 #1b4c43, 0 0.25em 0 #1b4c43;
    box-shadow: 0 -0.25em 0 #1b4c43, 0 0.25em 0 #1b4c43;
  }
}

.loader-box .loader-16 {
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  -webkit-perspective: 1000px;
  perspective: 1000px;
  border-radius: 50%;
}

.loader-box .loader-16:before,
.loader-box .loader-16:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

.loader-box .loader-16:before {
  -webkit-transform: rotateX(70deg);
  transform: rotateX(70deg);
}

.loader-box .loader-16:after {
  -webkit-transform: rotateY(70deg);
  transform: rotateY(70deg);
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@-webkit-keyframes rotateccw {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes rotateccw {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-webkit-keyframes spin {
  0%,
  100% {
    -webkit-box-shadow: 0.2em 0px 0 0px #717171;
    box-shadow: 0.2em 0px 0 0px #717171;
  }

  12% {
    -webkit-box-shadow: 0.2em 0.2em 0 0 #717171;
    box-shadow: 0.2em 0.2em 0 0 #717171;
  }

  25% {
    -webkit-box-shadow: 0 0.2em 0 0px #717171;
    box-shadow: 0 0.2em 0 0px #717171;
  }

  37% {
    -webkit-box-shadow: -0.2em 0.2em 0 0 #717171;
    box-shadow: -0.2em 0.2em 0 0 #717171;
  }

  50% {
    -webkit-box-shadow: -0.2em 0 0 0 #717171;
    box-shadow: -0.2em 0 0 0 #717171;
  }

  62% {
    -webkit-box-shadow: -0.2em -0.2em 0 0 #717171;
    box-shadow: -0.2em -0.2em 0 0 #717171;
  }

  75% {
    -webkit-box-shadow: 0px -0.2em 0 0 #717171;
    box-shadow: 0px -0.2em 0 0 #717171;
  }

  87% {
    -webkit-box-shadow: 0.2em -0.2em 0 0 #717171;
    box-shadow: 0.2em -0.2em 0 0 #717171;
  }
}

@keyframes spin {
  0%,
  100% {
    -webkit-box-shadow: 0.2em 0px 0 0px #717171;
    box-shadow: 0.2em 0px 0 0px #717171;
  }

  12% {
    -webkit-box-shadow: 0.2em 0.2em 0 0 #717171;
    box-shadow: 0.2em 0.2em 0 0 #717171;
  }

  25% {
    -webkit-box-shadow: 0 0.2em 0 0px #717171;
    box-shadow: 0 0.2em 0 0px #717171;
  }

  37% {
    -webkit-box-shadow: -0.2em 0.2em 0 0 #717171;
    box-shadow: -0.2em 0.2em 0 0 #717171;
  }

  50% {
    -webkit-box-shadow: -0.2em 0 0 0 #717171;
    box-shadow: -0.2em 0 0 0 #717171;
  }

  62% {
    -webkit-box-shadow: -0.2em -0.2em 0 0 #717171;
    box-shadow: -0.2em -0.2em 0 0 #717171;
  }

  75% {
    -webkit-box-shadow: 0px -0.2em 0 0 #717171;
    box-shadow: 0px -0.2em 0 0 #717171;
  }

  87% {
    -webkit-box-shadow: 0.2em -0.2em 0 0 #717171;
    box-shadow: 0.2em -0.2em 0 0 #717171;
  }
}

.loader-box .loader-17 {
  position: relative;
  background-color: #e2c636;
  border-radius: 50%;
}

.loader-box .loader-17:after,
.loader-box .loader-17:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  opacity: 0.8;
}

.loader-box .loader-17:after {
  left: -0.5em;
  top: -0.25em;
  background-color: #e2c636;
  -webkit-transform-origin: 30px 35px;
  transform-origin: 30px 35px;
  -webkit-animation: loader-17 1s linear infinite;
  animation: loader-17 1s linear infinite;
  opacity: 0.6;
}

.loader-box .loader-17:before {
  left: -1.25em;
  top: -0.75em;
  background-color: #e2c636;
  -webkit-transform-origin: 40px 40px;
  transform-origin: 40px 40px;
  -webkit-animation: loader-17 2s linear infinite;
  animation: loader-17 2s linear infinite;
}

@-webkit-keyframes loader-17 {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0);
  }
}

@keyframes loader-17 {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0);
  }
}

.loader-box .loader-18 {
  position: relative;
}

.loader-box .loader-18:before,
.loader-box .loader-18:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
  border: 0.1em solid transparent;
  border-bottom-color: #d22d3d;
  top: 0;
  left: 0;
  -webkit-animation: 1s loader-18 linear infinite;
  animation: 1s loader-18 linear infinite;
}

.loader-box .loader-18:before {
  width: 40px;
  height: 40px;
}

.loader-box .loader-18:after {
  width: 30px;
  height: 30px;
  top: 0.1em;
  left: 0.1em;
  animation-direction: reverse;
}

@-webkit-keyframes loader-18 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-18 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-19 {
  border-top: 0.2em solid #78266a;
  border-right: 0.2em solid transparent;
  -webkit-animation: loader-19 1s linear infinite;
  animation: loader-19 1s linear infinite;
  border-radius: 100%;
  position: relative;
}

@-webkit-keyframes loader-19 {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-19 {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-20 {
  background-color: transparent;
  -webkit-box-shadow: inset 0px 0px 0px 0.1em #ba895d;
  box-shadow: inset 0px 0px 0px 0.1em #ba895d;
  border-radius: 50%;
  position: relative;
}

.loader-box .loader-20:after,
.loader-box .loader-20:before {
  position: absolute;
  content: "";
  background-color: #ba895d;
  top: 24px;
  left: 24px;
  height: 0.1em;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.loader-box .loader-20:after {
  width: 0.4em;
  -webkit-animation: loader-20 2s linear infinite;
  animation: loader-20 2s linear infinite;
}

.loader-box .loader-20:before {
  width: 20px;
  -webkit-animation: loader-20 8s linear infinite;
  animation: loader-20 8s linear infinite;
}

@-webkit-keyframes loader-20 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-20 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-21 {
  position: relative;
}

.loader-box .loader-21:before,
.loader-box .loader-21:after {
  position: absolute;
  content: "";
}

.loader-box .loader-21:before {
  width: 80%;
  height: 80%;
  left: 10%;
  bottom: 10%;
  border-radius: 100% 100% 100% 0;
  -webkit-box-shadow: 0px 0px 0px 0.1em #1b4c43;
  box-shadow: 0px 0px 0px 0.1em #1b4c43;
  -webkit-animation: loader-21 1s linear infinite;
  animation: loader-21 1s linear infinite;
  -webkit-transform: rotate(-46deg);
  transform: rotate(-46deg);
}

.loader-box .loader-21:after {
  width: 1em;
  height: 0.3em;
  border-radius: 100%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.2);
  bottom: -0.2em;
  z-index: -1;
}

@-webkit-keyframes loader-21 {
  0% {
    top: 0;
  }

  50% {
    top: -5px;
  }

  100% {
    top: 0;
  }
}

@keyframes loader-21 {
  0% {
    top: 0;
  }

  50% {
    top: -5px;
  }

  100% {
    top: 0;
  }
}

.loader-box .loader-22 {
  border: 0.1em #717171 solid;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.loader-box .loader-22:after,
.loader-box .loader-22:before {
  position: absolute;
  content: "";
  background-color: #717171;
}

.loader-box .loader-22:after {
  width: 50%;
  height: 0.1em;
  left: 50%;
  top: 50%;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-animation: loader-22 2s linear infinite alternate;
  animation: loader-22 2s linear infinite alternate;
}

.loader-box .loader-22:before {
  width: 100%;
  height: 40%;
  left: 0;
  bottom: 0;
}

@-webkit-keyframes loader-22 {
  0% {
    -webkit-transform: rotate(-160deg);
    transform: rotate(-160deg);
  }

  100% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

@keyframes loader-22 {
  0% {
    -webkit-transform: rotate(-160deg);
    transform: rotate(-160deg);
  }

  100% {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

.loader-box .loader-23 {
  height: 0.5em;
  border: 0.1em #e2c636 solid;
  border-radius: 0.1em;
  position: relative;
  -webkit-animation: loader-23 5s linear infinite;
  animation: loader-23 5s linear infinite;
}

.loader-box .loader-23:after {
  width: 0.07em;
  height: 100%;
  background-color: #e2c636;
  border-radius: 0px 0.5em 0.5em 0px;
  position: absolute;
  content: "";
  top: 0;
  left: calc(100% + 0.1em);
}

@-webkit-keyframes loader-23 {
  0% {
    -webkit-box-shadow: inset 0px 0px 0px #e2c636;
    box-shadow: inset 0px 0px 0px #e2c636;
  }

  100% {
    -webkit-box-shadow: inset 1em 0px 0px #e2c636;
    box-shadow: inset 1em 0px 0px #e2c636;
  }
}

@keyframes loader-23 {
  0% {
    -webkit-box-shadow: inset 0px 0px 0px #e2c636;
    box-shadow: inset 0px 0px 0px #e2c636;
  }

  100% {
    -webkit-box-shadow: inset 1em 0px 0px #e2c636;
    box-shadow: inset 1em 0px 0px #e2c636;
  }
}

.loader-box .loader-24 {
  width: 0.8em;
  height: 1em;
  border: 0.1em #d22d3d solid;
  border-radius: 0px 0px 0.2em 0.2em;
  position: relative;
}

.loader-box .loader-24:after,
.loader-box .loader-24:before {
  position: absolute;
  content: "";
}

.loader-box .loader-24:after {
  width: 0.2em;
  height: 50%;
  border: 0.1em #d22d3d solid;
  border-left: none;
  border-radius: 0px 0.5em 0.5em 0px;
  left: calc(100% + 0.1em);
  top: 0.1em;
}

.loader-box .loader-24:before {
  width: 0.1em;
  height: 0.3em;
  background-color: #d22d3d;
  top: -0.3em;
  left: 0.05em;
  -webkit-box-shadow: 0.2em 0px 0px 0px #d22d3d, 0.2em -0.2em 0px 0px #d22d3d,
    0.4em 0px 0px 0px #d22d3d;
  box-shadow: 0.2em 0px 0px 0px #d22d3d, 0.2em -0.2em 0px 0px #d22d3d,
    0.4em 0px 0px 0px #d22d3d;
  -webkit-animation: loader-24 1s linear infinite alternate;
  animation: loader-24 1s linear infinite alternate;
}

@-webkit-keyframes loader-24 {
  0% {
    height: 0px;
  }

  100% {
    height: 6px;
  }
}

@keyframes loader-24 {
  0% {
    height: 0px;
  }

  100% {
    height: 6px;
  }
}

.loader-box .loader-25 {
  border: 0.1em #78266a solid;
  position: relative;
  -webkit-animation: loader-25-1 5s linear infinite;
  animation: loader-25-1 5s linear infinite;
}

.loader-box .loader-25:after {
  width: 0.2em;
  height: 0.2em;
  position: absolute;
  content: "";
  background-color: #78266a;
  bottom: calc(100% + 0.2em);
  left: -0.4em;
  -webkit-animation: loader-25-2 1s ease-in-out infinite;
  animation: loader-25-2 1s ease-in-out infinite;
}

@-webkit-keyframes loader-25-1 {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 #78266a;
    box-shadow: inset 0 0 0 0 #78266a;
  }

  100% {
    -webkit-box-shadow: inset 0 -1em 0 0 #78266a;
    box-shadow: inset 0 -1em 0 0 #78266a;
  }
}

@keyframes loader-25-1 {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 #78266a;
    box-shadow: inset 0 0 0 0 #78266a;
  }

  100% {
    -webkit-box-shadow: inset 0 -1em 0 0 #78266a;
    box-shadow: inset 0 -1em 0 0 #78266a;
  }
}

@-webkit-keyframes loader-25-2 {
  25% {
    left: calc(100% + 0.2em);
    bottom: calc(100% + 0.2em);
  }

  50% {
    left: calc(100% + 0.2em);
    bottom: -0.4em;
  }

  75% {
    left: -0.4em;
    bottom: -0.4em;
  }

  100% {
    left: -0.4em;
    bottom: calc(100% + 0.2em);
  }
}

@keyframes loader-25-2 {
  25% {
    left: calc(100% + 0.2em);
    bottom: calc(100% + 0.2em);
  }

  50% {
    left: calc(100% + 0.2em);
    bottom: -0.4em;
  }

  75% {
    left: -0.4em;
    bottom: -0.4em;
  }

  100% {
    left: -0.4em;
    bottom: calc(100% + 0.2em);
  }
}

.loader-box .loader-26 {
  width: 0.5em;
  height: 0.5em;
  background-color: #ba895d;
  -webkit-box-shadow: 1em 0px 0px #ba895d;
  box-shadow: 1em 0px 0px #ba895d;
  border-radius: 50%;
  -webkit-animation: loader-26 1s ease-in-out infinite alternate;
  animation: loader-26 1s ease-in-out infinite alternate;
}

@-webkit-keyframes loader-26 {
  0% {
    opacity: 0.1;
    -webkit-transform: rotate(0deg) scale(0.5);
    transform: rotate(0deg) scale(0.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotate(360deg) scale(1.2);
    transform: rotate(360deg) scale(1.2);
  }
}

@keyframes loader-26 {
  0% {
    opacity: 0.1;
    -webkit-transform: rotate(0deg) scale(0.5);
    transform: rotate(0deg) scale(0.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: rotate(360deg) scale(1.2);
    transform: rotate(360deg) scale(1.2);
  }
}

.loader-box .loader-29 {
  border-radius: 50%;
  -webkit-box-shadow: inset 0 0 0 0.1em #1b4c43, -0.5em -0.5em 0 -0.4em #1b4c43,
    0 -0.7em 0 -0.4em #1b4c43, 0.5em -0.5em 0 -0.4em #1b4c43,
    -0.5em 0.5em 0 -0.4em #1b4c43, 0 0.7em 0 -0.4em #1b4c43,
    0.5em 0.5em 0 -0.4em #1b4c43, -0.7em 0 0 -0.4em #1b4c43,
    0.7em 0 0 -0.4em #1b4c43;
  box-shadow: inset 0 0 0 0.1em #1b4c43, -0.5em -0.5em 0 -0.4em #1b4c43,
    0 -0.7em 0 -0.4em #1b4c43, 0.5em -0.5em 0 -0.4em #1b4c43,
    -0.5em 0.5em 0 -0.4em #1b4c43, 0 0.7em 0 -0.4em #1b4c43,
    0.5em 0.5em 0 -0.4em #1b4c43, -0.7em 0 0 -0.4em #1b4c43,
    0.7em 0 0 -0.4em #1b4c43;
  -webkit-animation: 5s loader-29 linear infinite;
  animation: 5s loader-29 linear infinite;
}

@-webkit-keyframes loader-29 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-29 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-30 {
  border: 0.2em solid transparent;
  border-top-color: #717171;
  border-bottom-color: #717171;
  border-radius: 50%;
  position: relative;
  -webkit-animation: 1s loader-30 linear infinite;
  animation: 1s loader-30 linear infinite;
}

.loader-box .loader-30:before,
.loader-box .loader-30:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border: 0.2em solid transparent;
  border-bottom-color: #717171;
}

.loader-box .loader-30:before {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  right: -0.3em;
  top: -0.05em;
}

.loader-box .loader-30:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: -0.3em;
  bottom: -0.05em;
}

@-webkit-keyframes loader-30 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-30 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-box .loader-31 {
  -webkit-box-shadow: 0 0 2em #e2c636;
  box-shadow: 0 0 2em #e2c636;
  background-color: #e2c636;
  position: relative;
  border-radius: 50%;
  -webkit-transform: rotateX(-60deg) perspective(1000px);
  transform: rotateX(-60deg) perspective(1000px);
}

.loader-box .loader-31:before,
.loader-box .loader-31:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  -webkit-animation: 1s loader-31 ease-out infinite;
  animation: 1s loader-31 ease-out infinite;
}

.loader-box .loader-31:after {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@-webkit-keyframes loader-31 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-box-shadow: 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636;
    box-shadow: 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636;
  }

  100% {
    opacity: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-box-shadow: -1em -1em 0 -0.35em #e2c636, 0 -1.5em 0 -0.35em #e2c636,
      1em -1em 0 -0.35em #e2c636, -1.5em 0 0 -0.35em #e2c636,
      1.5em 0 0 -0.35em #e2c636, -1em 1em 0 -0.35em #e2c636,
      0 1.5em 0 -0.35em #e2c636, 1em 1em 0 -0.35em #e2c636;
    box-shadow: -1em -1em 0 -0.35em #e2c636, 0 -1.5em 0 -0.35em #e2c636,
      1em -1em 0 -0.35em #e2c636, -1.5em 0 0 -0.35em #e2c636,
      1.5em 0 0 -0.35em #e2c636, -1em 1em 0 -0.35em #e2c636,
      0 1.5em 0 -0.35em #e2c636, 1em 1em 0 -0.35em #e2c636;
  }
}

@keyframes loader-31 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-box-shadow: 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636;
    box-shadow: 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636,
      0 0 0 -0.5em #e2c636, 0 0 0 -0.5em #e2c636;
  }

  100% {
    opacity: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-box-shadow: -1em -1em 0 -0.35em #e2c636, 0 -1.5em 0 -0.35em #e2c636,
      1em -1em 0 -0.35em #e2c636, -1.5em 0 0 -0.35em #e2c636,
      1.5em 0 0 -0.35em #e2c636, -1em 1em 0 -0.35em #e2c636,
      0 1.5em 0 -0.35em #e2c636, 1em 1em 0 -0.35em #e2c636;
    box-shadow: -1em -1em 0 -0.35em #e2c636, 0 -1.5em 0 -0.35em #e2c636,
      1em -1em 0 -0.35em #e2c636, -1.5em 0 0 -0.35em #e2c636,
      1.5em 0 0 -0.35em #e2c636, -1em 1em 0 -0.35em #e2c636,
      0 1.5em 0 -0.35em #e2c636, 1em 1em 0 -0.35em #e2c636;
  }
}

.loader-box .loader-32 {
  position: relative;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 1em 0 #d22d3d, inset 0 0 1em 0 #d22d3d;
  box-shadow: 0 0 1em 0 #d22d3d, inset 0 0 1em 0 #d22d3d;
  -webkit-animation: 1s loader-32 linear infinite;
  animation: 1s loader-32 linear infinite;
}

.loader-box .loader-32:before,
.loader-box .loader-32:after {
  content: "";
  display: block;
  width: inherit;
  height: inherit;
  position: absolute;
  border-radius: 50%;
}

.loader-box .loader-32:before {
  border-top: 0.2em solid #d22d3d;
  border-right: 0.2em solid transparent;
  top: 0.28em;
  right: calc(50% - 0.22em);
}

.loader-box .loader-32:after {
  border-bottom: 0.2em solid #d22d3d;
  border-left: 0.2em solid transparent;
  bottom: 0.28em;
  left: calc(50% - 0.22em);
}

@-webkit-keyframes loader-32 {
  0% {
    -webkit-transform: rotateX(-60deg) rotateZ(0deg);
    transform: rotateX(-60deg) rotateZ(0deg);
  }

  100% {
    -webkit-transform: rotateX(-60deg) rotateZ(360deg);
    transform: rotateX(-60deg) rotateZ(360deg);
  }
}

@keyframes loader-32 {
  0% {
    -webkit-transform: rotateX(-60deg) rotateZ(0deg);
    transform: rotateX(-60deg) rotateZ(0deg);
  }

  100% {
    -webkit-transform: rotateX(-60deg) rotateZ(360deg);
    transform: rotateX(-60deg) rotateZ(360deg);
  }
}

.loader-box .loader-34 {
  position: relative;
  width: 1em;
  height: 0.5em;
}

.loader-box .loader-34:after,
.loader-box .loader-34:before {
  position: absolute;
  content: "";
  height: 0.4em;
  width: 0.4em;
  top: 0;
  background-color: #78266a;
  border-radius: 50%;
}

.loader-box .loader-34:after {
  right: 0;
  -webkit-animation: loader-34-2 0.5s ease-in-out infinite;
  animation: loader-34-2 0.5s ease-in-out infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.loader-box .loader-34:before {
  left: 0;
  -webkit-animation: loader-34-1 0.5s ease-in-out infinite;
  animation: loader-34-1 0.5s ease-in-out infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

@-webkit-keyframes loader-34-1 {
  0% {
    -webkit-transform: translatex(0px);
    transform: translatex(0px);
  }

  65% {
    height: 0.4em;
    width: 0.4em;
  }

  100% {
    height: 0.5em;
    width: 0.3em;
    -webkit-transform: translatex(0.2em);
    transform: translatex(0.2em);
  }
}

@keyframes loader-34-1 {
  0% {
    -webkit-transform: translatex(0px);
    transform: translatex(0px);
  }

  65% {
    height: 0.4em;
    width: 0.4em;
  }

  100% {
    height: 0.5em;
    width: 0.3em;
    -webkit-transform: translatex(0.2em);
    transform: translatex(0.2em);
  }
}

@-webkit-keyframes loader-34-2 {
  0% {
    -webkit-transform: translatex(0px);
    transform: translatex(0px);
  }

  65% {
    height: 0.4em;
    width: 0.4em;
  }

  100% {
    height: 0.5em;
    width: 0.3em;
    -webkit-transform: translatex(-0.2em);
    transform: translatex(-0.2em);
  }
}

@keyframes loader-34-2 {
  0% {
    -webkit-transform: translatex(0px);
    transform: translatex(0px);
  }

  65% {
    height: 0.4em;
    width: 0.4em;
  }

  100% {
    height: 0.5em;
    width: 0.3em;
    -webkit-transform: translatex(-0.2em);
    transform: translatex(-0.2em);
  }
}

.loader-box .loader-35 {
  margin: 0 0.5em;
  position: relative;
}

.loader-box .loader-35:before {
  border-radius: 50%;
  background-color: #ba895d;
  -webkit-animation: loader-35 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-35 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  content: "";
  width: inherit;
  height: inherit;
  top: 0;
  left: 0;
  position: absolute;
}

@-webkit-keyframes loader-35 {
  0% {
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }

  25% {
    -webkit-transform: translateX(-100%) scale(0.3);
    transform: translateX(-100%) scale(0.3);
  }

  50% {
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }

  75% {
    -webkit-transform: translateX(100%) scale(0.3);
    transform: translateX(100%) scale(0.3);
  }

  100% {
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }
}

@keyframes loader-35 {
  0% {
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }

  25% {
    -webkit-transform: translateX(-100%) scale(0.3);
    transform: translateX(-100%) scale(0.3);
  }

  50% {
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }

  75% {
    -webkit-transform: translateX(100%) scale(0.3);
    transform: translateX(100%) scale(0.3);
  }

  100% {
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }
}

.loader-box .loader-37 {
  border-right: 0.1em solid #1b4c43;
  border-radius: 100%;
  -webkit-animation: loader-37 800ms linear infinite;
  animation: loader-37 800ms linear infinite;
}

.loader-box .loader-37:before,
.loader-box .loader-37:after {
  content: "";
  width: 0.8em;
  height: 0.8em;
  display: block;
  position: absolute;
  top: calc(50% - 0.4em);
  left: calc(50% - 0.4em);
  border-left: 0.08em solid #1b4c43;
  border-radius: 100%;
  animation: loader-37 400ms linear infinite reverse;
}

.loader-box .loader-37:after {
  width: 0.6em;
  height: 0.6em;
  top: calc(50% - 0.3em);
  left: calc(50% - 0.3em);
  border: 0;
  border-right: 0.05em solid #1b4c43;
  -webkit-animation: none;
  animation: none;
}

@-webkit-keyframes loader-37 {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes loader-37 {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.loader-box .loader-38 {
  height: 0.1em;
  width: 0.1em;
  -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
    -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
    -0.2em -0.2em 0 0.1em #717171;
  box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
    -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171;
  -webkit-animation: loader-38 6s infinite;
  animation: loader-38 6s infinite;
}

@-webkit-keyframes loader-38 {
  0% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171;
  }

  8.33% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171;
  }

  16.66% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171;
  }

  24.99% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }

  33.32% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171;
  }

  41.65% {
    -webkit-box-shadow: 0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171;
    box-shadow: 0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171;
  }

  49.98% {
    -webkit-box-shadow: 0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171;
    box-shadow: 0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171;
  }

  58.31% {
    -webkit-box-shadow: -0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }

  66.64% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }

  74.97% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }

  83.3% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }

  91.63% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }

  100% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171;
  }
}

@keyframes loader-38 {
  0% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171;
  }

  8.33% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171;
  }

  16.66% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171;
  }

  24.99% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }

  33.32% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171;
  }

  41.65% {
    -webkit-box-shadow: 0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171;
    box-shadow: 0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171;
  }

  49.98% {
    -webkit-box-shadow: 0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171;
    box-shadow: 0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171;
  }

  58.31% {
    -webkit-box-shadow: -0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }

  66.64% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }

  74.97% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }

  83.3% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, 0.2em 0.2em 0 0.1em #717171,
      0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }

  91.63% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171,
      -0.2em 0.2em 0 0.1em #717171, -0.2em 0.2em 0 0.1em #717171;
  }

  100% {
    -webkit-box-shadow: -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171;
    box-shadow: -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171,
      -0.2em -0.2em 0 0.1em #717171, -0.2em -0.2em 0 0.1em #717171;
  }
}

.loader-box .loader-39 {
  position: relative;
  width: 0.15em;
  height: 0.15em;
  background-color: #e2c636;
  border-radius: 100%;
  -webkit-animation: loader-39-1 30s infinite linear;
  animation: loader-39-1 30s infinite linear;
}

.loader-box .loader-39:before,
.loader-box .loader-39:after {
  content: "";
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader-box .loader-39:before {
  width: 20px;
  height: 60px;
  -webkit-animation: loader-39-2 0.8s linear infinite;
  animation: loader-39-2 0.8s linear infinite;
}

.loader-box .loader-39:after {
  width: 60px;
  height: 20px;
  -webkit-animation: loader-39-2 1.2s linear infinite;
  animation: loader-39-2 1.2s linear infinite;
}

@-webkit-keyframes loader-39-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-39-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-39-2 {
  0% {
    -webkit-box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
    box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
  }

  25% {
    -webkit-box-shadow: 0.04em 0.04em 0 0.02em #e2c636;
    box-shadow: 0.04em 0.04em 0 0.02em #e2c636;
  }

  50% {
    -webkit-box-shadow: -0.04em 0.04em 0 0.02em #e2c636;
    box-shadow: -0.04em 0.04em 0 0.02em #e2c636;
  }

  75% {
    -webkit-box-shadow: -0.04em -0.04em 0 0.02em #e2c636;
    box-shadow: -0.04em -0.04em 0 0.02em #e2c636;
  }

  100% {
    -webkit-box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
    box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
  }
}

@keyframes loader-39-2 {
  0% {
    -webkit-box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
    box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
  }

  25% {
    -webkit-box-shadow: 0.04em 0.04em 0 0.02em #e2c636;
    box-shadow: 0.04em 0.04em 0 0.02em #e2c636;
  }

  50% {
    -webkit-box-shadow: -0.04em 0.04em 0 0.02em #e2c636;
    box-shadow: -0.04em 0.04em 0 0.02em #e2c636;
  }

  75% {
    -webkit-box-shadow: -0.04em -0.04em 0 0.02em #e2c636;
    box-shadow: -0.04em -0.04em 0 0.02em #e2c636;
  }

  100% {
    -webkit-box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
    box-shadow: 0.04em -0.04em 0 0.02em #e2c636;
  }
}

.loader-box .loader-40 {
  border: 0.05em #d22d3d solid;
  border-radius: 0.2em;
  overflow: hidden;
  position: relative;
}

.loader-box .loader-40:after,
.loader-box .loader-40:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  width: inherit;
  height: inherit;
  -webkit-animation: loader-40 2s infinite linear;
  animation: loader-40 2s infinite linear;
}

.loader-box .loader-40:before {
  border-top: 0.2em #d22d3d solid;
  top: -0.15em;
  left: calc(-50% - 0.15em);
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

.loader-box .loader-40:after {
  border-bottom: 0.2em #d22d3d solid;
  top: 0.15em;
  right: calc(-50% - 0.15em);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

@-webkit-keyframes loader-40 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes loader-40 {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.loader-box .loader-41 {
  border: 0.05em #78266a solid;
  border-radius: 0.2em;
  position: relative;
  background: linear-gradient(
      45deg,
      transparent 48%,
      #78266a 50%,
      #78266a 50%,
      transparent 52%,
      transparent
    ),
    linear-gradient(
      -45deg,
      transparent 48%,
      #78266a 50%,
      #78266a 50%,
      transparent 52%,
      transparent
    );
  background-size: 0.5em 0.5em;
  background-position: 0% 0%;
  -webkit-animation: loader-41 1s infinite linear;
  animation: loader-41 1s infinite linear;
}

@-webkit-keyframes loader-41 {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -1em 0;
  }
}

@keyframes loader-41 {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -1em 0;
  }
}

.loader-box .loader-42 {
  width: 2em;
  height: 0.66em;
  border: 0.05em #ba895d solid;
  border-radius: 0.1em;
  background: linear-gradient(
    -60deg,
    transparent 0%,
    transparent 50%,
    #ba895d 50%,
    #ba895d 75%,
    transparent 75%,
    transparent
  );
  background-size: 1em 2em;
  background-position: 0 0;
  -webkit-animation: loader-42 0.8s infinite linear;
  animation: loader-42 0.8s infinite linear;
}

@-webkit-keyframes loader-42 {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -2em 0;
  }
}

@keyframes loader-42 {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -2em 0;
  }
}

/**=====================
     04. Loader CSS Ends
==========================**/

/**=====================
    05. Header CSS Start
==========================**/

.onhover-show-div {
  -webkit-box-shadow: 0 0 12px 3px rgba(25, 124, 207, 0.05);
  box-shadow: 0 0 12px 3px rgba(25, 124, 207, 0.05);
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}

.onhover-dropdown:hover .onhover-show-div {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  visibility: visible;
}

.onhover-dropdown:hover .onhover-show-div:before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  content: "";
  top: -7px;
  position: absolute;
  left: 10px;
  z-index: 2;
}

.onhover-dropdown:hover .onhover-show-div:after {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #f5f7fb;
  content: "";
  top: -7px;
  position: absolute;
  left: 10px;
  z-index: 1;
}

/*======= Page Header css Start ======= */

.page-wrapper .page-main-header {
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #fff;
  -webkit-box-shadow: 0 0 20px rgba(25, 124, 207, 0.08);
  box-shadow: 0 0 20px rgba(25, 124, 207, 0.08);
}

.page-wrapper .page-main-header.open {
  margin-left: 0;
  width: 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.page-wrapper .page-body-wrapper {
  background-color: #f5f7fb;
}

.page-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 76px);
  margin-top: 76px;
  padding: 0 15px 0 15px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #eef2ff;
}

.page-wrapper .page-body-wrapper .page-header {
  padding-bottom: 30px;
}

.page-wrapper .page-body-wrapper .page-header .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-wrapper .page-body-wrapper .page-header .row h3 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  text-transform: capitalize;
}

.page-wrapper .page-body-wrapper .page-header .row h3 small {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: rgba(36, 41, 52, 0.7);
}

.page-wrapper .page-body-wrapper .page-header .breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-top: 5px;
  margin-bottom: 0;
}

.page-wrapper .page-body-wrapper .page-header .breadcrumb li {
  color: #717171;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 500;
}

.page-wrapper
  .page-body-wrapper
  .page-header
  .breadcrumb
  .breadcrumb-item
  + .breadcrumb-item:before {
  content: "/";
}

.page-wrapper .page-body-wrapper .page-header .breadcrumb .breadcrumb-item a {
  color: #78266a;
}

/*======= Page Header css ends  ======= */

/**======Main Header css Start ======**/

.page-main-header {
  max-width: 100vw;
}

.page-main-header .main-header-right {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.page-main-header .main-header-right .main-header-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 290px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 27px 40px;
  border-right: 1px solid #e6edef;
  border-bottom: 1px solid #e6edef2e;
  background-color: #73276b;
}

.page-main-header .main-header-right .main-header-left .dark-logo-wrapper {
  display: none;
}

.page-main-header .main-header-right .left-menu-header {
  padding: 24px 40px;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  .search-bg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  .search-bg
  i {
  text-align: center;
}

.page-main-header .main-header-right .nav-right .nav-menus {
  padding: 20px 40px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown {
  width: 160px;
  top: 57px;
  right: -12px;
  padding: 10px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li {
  padding: 10px !important;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li svg {
  width: 16px;
  vertical-align: bottom;
  margin-right: 10px;
}

.page-main-header .main-header-right .toggle-sidebar {
  cursor: pointer;
  padding: 5px 8px;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-main-header .main-header-right .toggle-sidebar svg {
  width: 20px;
  color: #eef2ff;
}

.page-main-header .main-header-right .toggle-sidebar:hover {
  background-color: rgb(255 255 255 / 12%);
}

.page-main-header .main-header-right .vertical-mobile-sidebar {
  display: none;
}

.page-main-header .main-header-right .nav-left i {
  margin-right: 20px;
}

.page-main-header .main-header-right .nav-left input:focus {
  outline: 0 !important;
}

.page-main-header .main-header-right .left-menu-header ul li i {
  color: #78266a;
}

.page-main-header .main-header-right .left-menu-header ul li .search-form {
  font-family: "Roboto", sans-serif;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input {
  padding: 0 20px;
  border: none;
  background: transparent;
  color: rgba(36, 41, 52, 0.7);
  font-weight: 500;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::-webkit-input-placeholder {
  color: rgba(36, 41, 52, 0.7);
  font-weight: 500;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::-moz-placeholder {
  color: rgba(36, 41, 52, 0.7);
  font-weight: 500;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input:-ms-input-placeholder {
  color: rgba(36, 41, 52, 0.7);
  font-weight: 500;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::-ms-input-placeholder {
  color: rgba(36, 41, 52, 0.7);
  font-weight: 500;
}

.page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::placeholder {
  color: rgba(36, 41, 52, 0.7);
  font-weight: 500;
}

.page-main-header .main-header-right .nav-right {
  text-align: left;
}

.page-main-header .main-header-right .nav-right ul li svg {
  vertical-align: middle;
  width: 18px;
  height: 18px;
}

.page-main-header .main-header-right .nav-right ul li svg path {
  color: #2b2b2b;
}

.page-main-header .main-header-right .nav-right ul li:last-child {
  margin-right: 0;
}

.page-main-header .main-header-right .nav-right ul li .mode .mode-moon,
.page-main-header .main-header-right .nav-right ul li .mode .mode-sun {
  position: absolute;
  top: 0;
  left: 0;
}

.page-main-header .main-header-right .nav-right ul li .mode .mode-sun {
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.page-main-header .main-header-right .nav-right ul li .mode .mode-sun.show {
  opacity: 1;
  visibility: visible;
}

.page-main-header .main-header-right .nav-right ul li .mode i {
  vertical-align: middle;
  font-size: 18px;
}

.page-main-header .main-header-right .nav-right .onhover-show-div li {
  display: block;
}

.page-main-header .main-header-right .nav-right > ul {
  padding: 0 0;
}

.page-main-header .main-header-right .nav-right .btn-primary-light {
  padding: 6px 6px;
  font-weight: 600;
}

.page-main-header .main-header-right .nav-right .btn-primary-light svg {
  margin-right: 10px;
}

.page-main-header .main-header-right .nav-right .btn-primary-light svg path {
  color: #78266a;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.page-main-header .main-header-right .nav-right .btn-primary-light:focus,
.page-main-header .main-header-right .nav-right .btn-primary-light:hover {
  background-color: #78266a !important;
  color: #fff;
}

.page-main-header .main-header-right .nav-right .btn-primary-light:focus a,
.page-main-header .main-header-right .nav-right .btn-primary-light:hover a {
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .btn-primary-light:focus
  svg
  path,
.page-main-header
  .main-header-right
  .nav-right
  .btn-primary-light:hover
  svg
  path {
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.page-main-header .main-header-right .nav-right.right-menu ul {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.page-main-header .main-header-right .nav-right.right-menu ul li {
  position: relative;
}

.page-main-header .main-header-right .nav-right.right-menu ul li .notification {
  position: absolute;
  top: -2px;
  right: -9px;
  padding: 5px 7px;
}

.page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li
  .profile-media
  .media-body {
  margin-left: 15px;
}

.page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li
  .profile-media
  .media-body
  span {
  font-weight: 500;
}

.page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li
  .profile-media
  .media-body
  p {
  font-size: 12px;
  line-height: 1;
  color: rgba(36, 41, 52, 0.7);
  font-weight: 500;
}

.page-main-header .main-header-right .nav-right.right-menu ul li:hover {
  background-color: rgba(36, 105, 92, 0.1);
}

.page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li:hover
  .media
  .media-body
  > span {
  color: #323846;
}

.page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li:hover
  .media
  .media-body
  p {
  color: #78266a;
}

.page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li:hover
  .media
  p {
  color: #78266a;
}

.page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li:hover
  .btn
  svg {
  stroke: #fff;
}

.page-main-header .main-header-right .nav-right .icon-user {
  font-size: 16px;
}

.page-main-header .main-header-right .nav-right > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-main-header .main-header-right .nav-right > ul > li {
  position: relative;
  padding: 6px 10px;
  border-radius: 4px;
  margin-right: 20px;
}

.page-main-header .main-header-right .nav-right > ul > li .badge {
  padding: 4px 8px;
}

.page-main-header .main-header-right .nav-right > ul > li span {
  letter-spacing: 0.9px;
}

.page-main-header .main-header-right .nav-right > ul > li span .fa {
  color: #cccccc;
}

.page-main-header .main-header-right .nav-right > ul > li span ul {
  left: inherit;
  right: -10px;
  width: 130px;
}

.page-main-header .main-header-right .nav-right > ul > li span ul:before,
.page-main-header .main-header-right .nav-right > ul > li span ul:after {
  left: inherit;
  right: 10px;
}

.page-main-header .main-header-right .nav-right > ul > li span ul li {
  display: block;
}

.page-main-header .main-header-right .nav-right > ul > li span ul li a {
  font-size: 14px;
  color: #2c323f;
}

.page-main-header .main-header-right .nav-right > ul > li span ul li a i {
  margin-left: 10px;
  font-size: 13px;
  color: #2c323f;
}

.page-main-header .main-header-right .nav-right > ul .flag-icon {
  font-size: 16px;
}

.page-main-header .main-header-right .nav-right .chat-dropdown {
  width: 330px;
  top: 49px;
  right: 0;
  left: unset;
}

.page-main-header .main-header-right .nav-right .chat-dropdown li {
  padding: 15px;
}

.page-main-header .main-header-right .nav-right .chat-dropdown li + li {
  border-top: 1px solid #e6edef;
}

.page-main-header .main-header-right .nav-right .chat-dropdown li .media img {
  width: 40px;
  position: relative;
}

.page-main-header
  .main-header-right
  .nav-right
  .chat-dropdown
  li
  .media
  .media-body
  > span {
  font-weight: 600;
  color: #78266a !important;
}

.page-main-header
  .main-header-right
  .nav-right
  .chat-dropdown
  li
  .media
  .media-body
  p {
  margin-bottom: 0;
  color: #2c323f !important;
}

.page-main-header .main-header-right .nav-right .chat-dropdown li .media > p {
  color: #cccccc;
}

.page-main-header .main-header-right .nav-right .chat-dropdown:before,
.page-main-header .main-header-right .nav-right .chat-dropdown:after {
  right: 10px !important;
  left: unset !important;
}

.page-main-header .main-header-right .nav-right .bookmark-dropdown {
  width: 300px;
  top: 50px;
  left: unset;
  right: 0;
  border-radius: 8px;
  padding: 15px;
}

.page-main-header .main-header-right .nav-right .bookmark-dropdown::before,
.page-main-header .main-header-right .nav-right .bookmark-dropdown::after {
  display: none;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  .form-group
  .input-group
  .input-group-prepend
  .input-group-text {
  background-color: transparent;
  height: 100%;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  .form-group
  .input-group
  .form-control {
  border-left: none;
  font-size: 13px;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  .form-group
  .input-group
  .form-control::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 13px;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  .form-group
  .input-group
  .form-control::-moz-placeholder {
  color: #cccccc;
  font-size: 13px;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  .form-group
  .input-group
  .form-control:-ms-input-placeholder {
  color: #cccccc;
  font-size: 13px;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  .form-group
  .input-group
  .form-control::-ms-input-placeholder {
  color: #cccccc;
  font-size: 13px;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  .form-group
  .input-group
  .form-control::placeholder {
  color: #cccccc;
  font-size: 13px;
}

.page-main-header .main-header-right .nav-right .bookmark-dropdown ul li {
  padding-top: 10px;
  padding-bottom: 10px;
}

.page-main-header .main-header-right .nav-right .bookmark-dropdown ul li:hover {
  background-color: #f5f7fb;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li:hover
  .bookmark-icon {
  color: #78266a;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li
  .bookmark-icon {
  margin-right: 15px;
  vertical-align: bottom;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li.add-to-bookmark {
  font-weight: 500;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li.add-to-bookmark
  .pull-right
  svg {
  color: #fa9f44;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li.add-to-bookmark:hover {
  color: #78266a;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li.add-to-bookmark:hover
  .bookmark-icon {
  stroke: #78266a;
}

.page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li.add-to-bookmark:hover
  .pull-right
  svg {
  color: #fa9f44;
  fill: #fa9f44;
}

.page-main-header .main-header-right .nav-right .notification-box {
  position: relative;
}

.page-main-header .main-header-right .nav-right .notification-box svg {
  -webkit-animation: tada 1.5s ease infinite;
  animation: tada 1.5s ease infinite;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-box
  .dot-animated {
  position: absolute;
  right: -5px;
  top: -6px;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  content: "";
  background-color: #d22d3d;
  -webkit-animation: fadeIn 2s ease infinite;
  animation: fadeIn 2s ease infinite;
}

.page-main-header .main-header-right .nav-right .notification-dropdown {
  top: 52px;
  width: 300px;
  right: -20px !important;
  left: unset;
}

.page-main-header .main-header-right .nav-right .notification-dropdown li {
  padding: 15px !important;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .badge-primary {
  background-color: rgba(36, 105, 92, 0.1);
  color: #78266a;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg.bg-light-primary {
  background-color: rgba(36, 105, 92, 0.1);
  color: #78266a;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg.bg-light-secondary {
  background-color: rgba(186, 137, 93, 0.1);
  color: #ba895d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg.bg-light-secondary
  svg
  path {
  color: #ba895d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg.bg-light-success {
  background-color: rgba(27, 76, 67, 0.1);
  color: #1b4c43;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg.bg-light-danger {
  background-color: rgba(210, 45, 61, 0.1);
  color: #d22d3d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg.bg-light-danger
  path {
  color: #d22d3d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .media-body
  p {
  font-weight: 700;
  margin-bottom: 0;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .media-body
  span {
  font-size: 12px;
  color: #cccccc;
  font-weight: 500;
}

.page-main-header .main-header-right .nav-right .notification-dropdown li + li {
  border-top: 1px solid #e6edef;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-primary:hover {
  background-color: rgba(36, 105, 92, 0.1);
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-secondary:hover {
  background-color: rgba(186, 137, 93, 0.1);
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-secondary:hover
  .media
  .media-body
  p {
  color: #ba895d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-secondary:hover
  .media
  .media-body
  span {
  color: #ba895d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-success:hover {
  background-color: rgba(27, 76, 67, 0.1);
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-success:hover
  .media
  .media-body
  p {
  color: #1b4c43;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-success:hover
  .media
  .media-body
  span {
  color: #1b4c43;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-danger:hover {
  background-color: rgba(210, 45, 61, 0.1);
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-danger:hover
  .media
  .media-body
  p {
  color: #d22d3d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li.noti-danger:hover
  .media
  .media-body
  span {
  color: #d22d3d;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-primary {
  background-color: #78266a;
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-secondary {
  background-color: #ba895d;
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-secondary
  svg
  path {
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-success {
  background-color: #1b4c43;
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-success
  svg {
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-success
  svg
  path {
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-danger {
  background-color: #d22d3d;
  color: #fff;
}

.page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li:hover
  .notification-bg.bg-light-danger
  path {
  color: #fff;
}

.page-main-header .main-header-right .nav-right .notification-dropdown:before,
.page-main-header .main-header-right .nav-right .notification-dropdown:after {
  right: 28px !important;
  left: unset !important;
}

.page-main-header .main-header-right li {
  /* display: inline-block; */
  position: relative;
}

.onhover-dropdown {
  cursor: pointer;
  position: relative;
}

.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 8;
  background-color: #fff;
  border: 1px solid #f5f7fb;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.onhover-show-div li a svg {
  margin-top: 0 !important;
}

.onhover-show-div li a svg path,
.onhover-show-div li a svg line {
  color: #242934 !important;
}

/**======Main Header css Ends ======**/

/** ===== Vertical Menu css Start=====**/

.vertical-menu-main {
  background: white;
  position: fixed;
  top: 88px;
  z-index: 1;
  width: 100%;
}

.vertical-menu-mt {
  margin-top: 148px !important;
  margin-left: 0 !important;
}

.vertical-menu-mt .custom-card .card-header img {
  margin-top: -146px !important;
}

.vertical-menu-main #main-nav #main-menu li a svg {
  width: 15px;
  height: 15px;
  margin-right: 8px;
  stroke-width: 2.5;
}

.vertical-menu-main .mega-menu {
  width: 1500px !important;
  max-width: 1500px !important;
  padding: 0;
  left: -120px !important;
  top: 65px !important;
}

/** ===== Vertical Menu css Ends=====**/

/*=======Mega menu css start=======*/

.mega-menu {
  -webkit-perspective: inherit;
  perspective: inherit;
  position: static !important;
  padding-top: 5px;
}

.mega-menu .default-according .card .btn-link {
  font-weight: 500;
  color: gray;
}

.mega-menu .default-according .card .card-body {
  font-size: 12px;
}

.mega-menu .onhover-show-div {
  width: 1500px;
}

.mega-menu .card-body {
  padding: 20px;
}

.mega-menu .title {
  color: #242934;
  font-weight: 600;
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.mega-menu div > div a {
  margin-bottom: 0px;
  display: inline-block;
  color: rgba(36, 41, 52, 0.7);
}

.mega-menu .custom-nav-img {
  position: static;
}

.mega-menu .custom-nav-img .desk {
  line-height: 25px;
  font-size: 14px;
  color: rgba(36, 41, 52, 0.7);
}

.mega-menu .list-unstyled div a {
  padding: 8px 35px 8px 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.mega-menu .list-unstyled div a:hover {
  padding: 8px 35px 8px 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.mega-menu .galleria div {
  margin-bottom: 5px;
}

.mega-menu .galleria div:hover {
  -webkit-transform: translateY(4px) scale(1.04);
  transform: translateY(4px) scale(1.04);
}

.mega-menu .galleria-list .galleria > div {
  width: 25%;
  font-size: 12px;
  float: left;
  color: #000;
  -webkit-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}

.mega-menu .galleria-list .galleria > div > a {
  display: block;
  margin: 5px 0 10px;
  padding: 0 0 !important;
}

.mega-menu .galleria-list .galleria > div img {
  border-radius: 3px;
  max-width: 100%;
}

.mega-menu .galleria-list .galleria > div .username {
  font-size: 12px;
  color: #242934;
  margin-bottom: 5px;
}

.mega-menu .galleria-list .galleria > div .username small {
  display: block;
  font-size: 11px;
  color: rgba(36, 41, 52, 0.7);
  margin-top: 3px;
}

.mega-menu .drilldown {
  overflow: hidden;
  width: 100%;
  padding: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mega-menu .drilldown a {
  width: 100%;
}

.mega-menu .drilldown a:hover {
  background-color: transparent;
  color: #78266a;
}

.mega-menu .drilldown-sub {
  display: none;
}

.mega-menu .drilldown-back {
  font-weight: bold;
}

/*=======Mega menu css end=======*/

@media only screen and (max-width: 1366px) {
  .profile-dropdown.onhover-show-div {
    left: 0;
    right: unset !important;
  }

  .profile-dropdown.onhover-show-div:before,
  .profile-dropdown.onhover-show-div:after {
    right: 10px !important;
    left: unset !important;
  }
}

@media only screen and (max-width: 1199px) {
  .page-main-header .main-header-right .nav-right > ul > li {
    padding: 6px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .page-main-header .main-header-right .nav-right .notification-dropdown {
    top: 46px;
  }
}

/**=====================
    05. Header CSS Ends
==========================**/

/**=====================
    78. Sidebar CSS Start
==========================**/

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  a:after,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5:after {
  position: absolute;
  content: "";
  top: 30%;
  font-family: FontAwesome;
  font-size: 11px;
  color: #cccccc;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu.sub-level_toggle {
  display: block;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  a:after {
  position: absolute;
  content: "";
  top: 49%;
  width: 5px;
  height: 1px;
  border-radius: 100%;
  background-color: #59667a;
}

.page-wrapper {
  position: relative;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav {
  z-index: 3;
  height: 54px;
  line-height: inherit;
  position: fixed;
  width: 100%;
  top: 77px;
  background: #fff;
  border-top: 1px solid #e6edef;
  border-bottom: 1px solid #e6edef;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .sidebar-user {
  display: none;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .logo-wrapper,
.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .logo-icon-wrapper {
  display: none;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar {
  text-align: left;
  position: relative;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  #mainnav {
  width: 1920px;
  position: fixed;
  left: 0;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .left-arrow,
.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .right-arrow {
  position: absolute;
  top: 0px;
  padding: 14px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .left-arrow.disabled,
.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .right-arrow.disabled {
  display: none;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .left-arrow {
  left: 0;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .right-arrow {
  right: 0;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
  display: -webkit-box !important;
  padding: 0 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  white-space: nowrap;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  li.dropdown
  a.active
  .according-menu
  i:before {
  content: "-";
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  li.dropdown
  a.link-nav
  .according-menu {
  display: none;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  li.dropdown
  ul.nav-submenu
  li
  a.active {
  color: #78266a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  li.dropdown
  ul.nav-submenu
  li
  a.active:after {
  background-color: #78266a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  .sidebar-main-title {
  display: none;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li {
  position: relative;
  float: left;
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  + li {
  border-left: 1px solid #e6edef;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li:first-child {
  display: none !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  > a {
  display: block;
  color: #242934;
  line-height: 23px;
  text-decoration: none;
  text-transform: capitalize;
  padding: 0 18px;
  letter-spacing: 0.04em;
  position: relative;
  border-radius: 4px;
  font-weight: 500;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  > a
  svg {
  width: 16px;
  vertical-align: bottom;
  margin-right: 15px;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  > a
  .according-menu {
  display: none;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu {
  position: absolute;
  left: 0;
  z-index: 4;
  -webkit-box-shadow: 0 0 1px 0 #898989;
  box-shadow: 0 0 1px 0 #898989;
  padding: 15px 0px 15px 0px;
  background: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  a {
  border: 0 !important;
  background: transparent;
  color: #717171;
  border-radius: 0 !important;
  text-transform: capitalize;
  font-size: 13px;
  padding: 6px 25px;
  display: block;
  letter-spacing: 0.07em;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  a:after {
  left: 12px;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  .nav-sub-childmenu {
  position: absolute;
  width: 200px;
  right: -200px;
  top: 0;
  background-color: #fff;
  border: none;
  padding: 15px 0px 15px 0px;
  -webkit-box-shadow: 0 0 1px 0 #898989;
  box-shadow: 0 0 1px 0 #898989;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container {
  position: fixed;
  right: 30px;
  z-index: 4;
  -webkit-box-shadow: 0 0 1px 0 #898989;
  box-shadow: 0 0 1px 0 #898989;
  padding: 30px;
  background-color: #fff;
  width: 95%;
  border-radius: 4px;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box {
  width: 20%;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  text-transform: uppercase;
  font-size: 14px;
  margin-left: -13px;
  font-weight: 600;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  position: relative;
  display: block;
  padding: 0;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  ul
  li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  ul
  li
  a {
  border: 0 !important;
  background: transparent;
  color: #717171;
  border-radius: 0 !important;
  text-transform: capitalize;
  padding: 3px 0;
  font-size: 13px;
  display: block;
  letter-spacing: 0.07em;
  line-height: 1.9;
  position: relative;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  ul
  li
  a:after {
  position: absolute;
  content: "";
  left: -12px;
  top: 50%;
  width: 5px;
  height: 1px;
  background-color: #59667a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li:hover
  > a:after {
  left: 0;
  right: 0;
  opacity: 1;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li.mega-menu {
  position: unset;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav.white-active
  .main-navbar
  .nav-menu
  > li
  .nav-link.active {
  background-color: white;
  color: #ba895d;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav.overlay-white {
  background-color: rgba(255, 255, 255, 0.08);
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  .toggle-nav {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  .toggle-nav
  i {
  font-size: 24px;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  .mega-box
  .link-section
  .demo
  ul
  li
  a {
  position: relative;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  .mega-box
  .link-section
  .demo
  ul
  li
  a:hover,
.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  .mega-box
  .link-section
  .demo
  ul
  li
  a:focus {
  padding-top: 3px;
  padding-bottom: 3px;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  .mega-box
  .link-section
  .demo
  .new {
  position: absolute;
  top: -8px;
  right: 0px;
  color: white;
  background-color: #78266a;
  padding: 2px 7px;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 1.3;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  .mega-box
  .link-section
  .demo
  .title {
  border-bottom: 2px solid red !important;
}

.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 130px);
  margin-top: 130px;
  padding-top: 30px;
}

.page-wrapper.horizontal-wrapper .page-main-header {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .form-control-plaintext {
  width: auto !important;
}

.page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .toggle-sidebar {
  display: none;
}

.page-wrapper.compact-wrapper .page-main-header.close_icon {
  margin-left: 0;
  width: calc(100% - 0px);
}

.page-wrapper.compact-wrapper .page-body-wrapper header.main-nav {
  position: fixed;
  border-bottom: none;
  top: 78px;
  z-index: 8;
  height: 100%;
  line-height: inherit;
  background-image: linear-gradient(180deg, #772569 0%, #0e184a 100%);
  border-right: 1px solid #e6edef;
  width: 290px;
  text-align: left;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.newModal {
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e6edef;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div
  h6 {
  color: #78266a;
  font-size: 18px;
  margin-bottom: 0;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div
  p {
  color: #242934;
  margin-bottom: 0;
}

.page-wrapper.compact-wrapper .page-body-wrapper header.main-nav .sidebar-user {
  padding: 30px;
  position: relative;
  border-bottom: 1px solid #e6edef2e;
  margin-bottom: 20px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  img {
  border: 10px solid rgb(255 255 255 / 18%);
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  .setting-primary {
  position: absolute;
  top: 30px;
  right: 35px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  h6 {
  margin-bottom: 0;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  p {
  color: #999;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  .badge-bottom {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 95px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  .badge-bottom
  .badge {
  padding: 4px 6px 3px;
  -webkit-box-shadow: 0px 7px 10px rgba(36, 105, 92, 0.3);
  box-shadow: 0px 7px 10px rgba(36, 105, 92, 0.3);
  font-family: "Roboto", sans-serif;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-top: 20px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li {
  position: relative;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li
  + li::before {
  position: absolute;
  content: "";
  left: -27px;
  top: 12px;
  width: 2px;
  height: 15px;
  background-color: #e6edef;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li
  span {
  color: #fff;
  font-weight: 600;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li
  p {
  font-size: 12px;
  margin-bottom: -5px;
}

.page-wrapper.compact-wrapper .page-body-wrapper header.main-nav.close_icon {
  margin-left: 0;
  opacity: 0;
  visibility: hidden;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  ~ .page-body {
  margin-left: 0px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  ~ footer {
  margin-left: 78px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .left-arrow,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .right-arrow {
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu {
  border-radius: 0;

  left: -300px;
  z-index: 99;
  -webkit-transition: color 1s ease;
  transition: color 1s ease;
  overflow: hidden;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0);
  padding-bottom: 15px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu.custom-scrollbar::-webkit-scrollbar-thumb,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  border-radius: 13px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu.custom-scrollbar::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 10px;
  box-shadow: inset 0 0 0 10px;
  background-color: #f5f7fb;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu:hover.custom-scrollbar {
  color: rgba(36, 105, 92, 0.2);
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .dropdown
  ul.nav-submenu
  li
  a.active {
  color: #adc926;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .dropdown
  ul.nav-submenu
  li
  a.active:after {
  color: #78266a;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .dropdown
  ul.nav-submenu
  li:hover
  a {
  color: #78266a;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  li
  a {
  font-weight: 600;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  li
  a.link-nav
  .according-menu {
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  li:last-child {
  border-bottom: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu.opennav {
  left: 0;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .back-btn {
  display: block;
  width: 100%;
  padding: 0;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .back-btn
  .mobile-back {
  padding: 20px;
  color: #2b2b2b;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li {
  display: block;
  float: none;
  width: 100%;
  padding: 0 20px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  span {
  font-weight: 600;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  a {
  padding: 10px 15px;
  position: relative;
  color: #ffffff;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  a
  img {
  width: 20px;
  height: auto;
  margin-right: 15px;
  vertical-align: bottom;
  float: none;
  stroke-width: 2.5px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  a
  svg {
  width: 16px;
  margin-right: 15px;
  vertical-align: bottom;
  float: none;
  stroke-width: 2.5px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  a
  .sub-arrow {
  right: 20px;
  position: absolute;
  top: 10px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  a
  .sub-arrow
  i {
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  a
  .sub-arrow:before {
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li:first-child {
  display: none !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu {
  width: 100%;
  padding: 0;
  position: relative !important;
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  > li
  a {
  padding-left: 45px !important;
  font-weight: 400;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  .submenu-title
  .according-menu {
  top: 8px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu.opensubmenu {
  display: block;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  .nav-sub-childmenu {
  display: none;
  position: relative !important;
  right: 0;
  width: 100%;
  padding: 0;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  .nav-sub-childmenu.opensubchild {
  display: block;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  .nav-sub-childmenu
  li
  a {
  padding: 6px 45px;
  padding-left: 56px !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  .nav-sub-childmenu
  li
  a:after {
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  a {
  padding: 8px 25px;
  font-size: 13px;
  color: #afafaf;
  display: block;
  position: relative;
  letter-spacing: 0.07em;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  a:hover {
  color: #adc926 !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  a:after {
  left: 26px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-submenu
  li
  a:hover {
  margin-left: 0;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container {
  display: none;
  padding: 0;
  position: relative;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container.opensubmenu {
  display: block;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box {
  width: 100%;
  padding: 0;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title {
  position: relative;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  margin-bottom: 0;
  line-height: 1.9;
  padding: 6px 25px 6px 45px;
  font-size: 13px;
  font-weight: 400;
  color: #242934;
  position: relative;
  cursor: pointer;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5:after {
  left: 26px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  .according-menu {
  top: 8px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content {
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu {
  display: block;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  ul
  li
  a {
  padding: 6px 25px 6px 50px;
  font-size: 13px;
  font-weight: 400;
  color: #242934;
  display: block;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  ul
  li
  a:after {
  display: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  ul
  li
  a {
  line-height: 1.9;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content
  ul
  li
  a:hover {
  margin-left: 0;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link {
  border-radius: 10px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link:hover {
  background-color: #adc926;
  color: #4c5a09;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active
  .according-menu
  i,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link:hover
  .according-menu
  i {
  color: #d0efe9;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active
  svg,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link:hover
  svg {
  color: #d0efe9 !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper header .menu nav {
  margin-left: auto;
  margin-right: 30px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  .mega-box
  .link-section
  .demo
  ul
  li
  a
  span
  img {
  top: 30px;
  right: -60px;
}

.page-wrapper.compact-wrapper .page-body-wrapper .mega-box.col {
  -ms-flex-preferred-size: unset;
  flex-basis: unset;
}

.page-wrapper.compact-wrapper .page-body-wrapper .according-menu {
  position: absolute;
  right: 15px;
  top: 12px;
  display: block;
}

.page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
  vertical-align: middle;
  color: #fff;
}

.page-wrapper.compact-wrapper .page-body-wrapper .main-menu.border-section {
  border: none;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  .main-menu
  .menu-left
  .main-menu-right
  .toggle-nav {
  position: absolute;
  z-index: 2;
  right: 145px;
  top: 6px;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  .main-menu
  .menu-left
  .main-menu-right
  .toggle-nav
  .sidebar-name {
  font-size: 18px;
  padding-left: 5px;
  text-transform: uppercase;
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  padding-top: 30px;
  margin-left: 290px;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  .according-menu {
  display: none !important;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav {
  width: 150px;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user {
  display: none;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu {
  height: calc(100vh - 80px);
  padding-top: 40px;
  padding-bottom: 40px;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .sidebar-main-title {
  display: none;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link {
  text-align: center;
  padding-left: unset;
  padding-right: unset;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active {
  background-color: transparent;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active
  svg {
  color: #78266a !important;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active
  span {
  color: #78266a !important;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link
  svg {
  display: block;
  width: auto;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  border-radius: 5px;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link
  span {
  display: block;
  word-wrap: break-word;
  margin-top: -5px;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .dropdown
  .menu-title.active
  ~ .menu-content {
  background-color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  display: none;
  padding-top: 20px;
  -webkit-box-shadow: 0 0 20px rgba(25, 124, 207, 0.08);
  box-shadow: 0 0 20px rgba(25, 124, 207, 0.08);
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .dropdown
  .menu-content {
  position: fixed !important;
  top: 75px;
  height: calc(100vh - 0px);
  overflow: auto;
  left: 150px;
  width: 230px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title {
  display: none !important;
}

.page-wrapper.compact-sidebar.compact-wrapper .page-body-wrapper .page-body {
  margin-left: 150px;
}

.page-wrapper.modern-sidebar {
  padding: 30px;
  background-color: rgba(36, 105, 92, 0.15);
  height: 100vh;
  overflow: hidden;
}

.page-wrapper.modern-sidebar .page-main-header {
  top: 30px;
  width: calc(100% - 60px);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 2;
}

.page-wrapper.modern-sidebar .page-main-header.close_icon {
  width: calc(100% - 60px);
}

.page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper {
  margin-bottom: auto;
  margin-top: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-radius: 20px;
  padding-top: 75px;
}

.page-wrapper.modern-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav {
  top: 113px;
  height: calc(100vh - 145px);
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-wrapper.modern-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(0);
  transform: translate(0);
}

.page-wrapper.modern-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  ~ footer {
  margin-left: 30px;
}

.page-wrapper.modern-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu {
  height: calc(100vh - 430px);
}

.page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper .page-body {
  margin-left: 290px;
  overflow-y: scroll !important;
  min-height: calc(100vh - 185px);
  max-height: calc(100vh - 185px);
  margin-bottom: auto;
  margin-top: auto;
  border-top-right-radius: 20px;
}

.page-wrapper.modern-sidebar.compact-wrapper
  .page-body-wrapper
  .page-body::-webkit-scrollbar {
  width: 6px;
}

.page-wrapper.modern-sidebar.compact-wrapper
  .page-body-wrapper
  .page-body::-webkit-scrollbar-track {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.page-wrapper.modern-sidebar.compact-wrapper
  .page-body-wrapper
  .page-body::-webkit-scrollbar-thumb {
  background-color: rgba(36, 105, 92, 0.2);
  border-radius: 13px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.page-wrapper.modern-sidebar.compact-wrapper footer {
  position: fixed;
  left: 0;
  width: calc(100% - 60px);
  z-index: 10;
  margin-left: 30px;
  margin-bottom: 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.main-navbar ul.nav-pills {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  border-top: 1px solid #e6edef;
  border-bottom: 1px solid #e6edef;
}

.main-navbar ul.nav-pills .nav-item {
  width: 50%;
}

.main-navbar ul.nav-pills .nav-item:last-child {
  border-left: 1px solid #e6edef;
}

.main-navbar ul.nav-pills .nav-item .nav-link {
  font-size: 14px;
  color: #2b2b2b;
  font-weight: 600;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
}

.main-navbar ul.nav-pills .nav-item .nav-link.active {
  border-radius: unset;
  background-color: rgba(36, 105, 92, 0.1);
  color: #78266a;
}

.main-navbar .tab-content {
  padding-left: 20px;
  padding-right: 20px;
}

.rtl .link-section {
  float: right;
}

.rtl .mega-box .link-section .demo ul li a span img {
  right: unset;
  left: -120px;
}

@media (min-width: 992px) {
  .responsive-btn {
    display: none;
  }

  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li
    .nav-submenu {
    width: 200px;
    opacity: 0;
    visibility: hidden;
    border-radius: 5px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li
    .nav-submenu
    a
    .sub-arrow {
    position: absolute;
    right: 20px;
    top: 5px;
    color: #59667a;
    font-size: 10px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li
    .nav-submenu
    li
    .nav-sub-childmenu {
    display: none;
    border-radius: 4px;
  }

  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li
    .nav-submenu
    li:hover
    .nav-sub-childmenu {
    display: block;
  }

  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li
    .nav-submenu
    li
    a:hover
    .sub-arrow {
    right: 16px;
    color: #222222;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li
    .mega-menu-container {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li:hover
    .nav-submenu {
    opacity: 1;
    visibility: visible;
    margin-top: 20px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header
    .main-navbar
    .nav-menu
    > li:hover
    .mega-menu-container {
    opacity: 1;
    visibility: visible;
    margin-top: 20px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .page-wrapper.horizontal-wrapper .page-body-wrapper .toggle-nav {
    display: none;
  }

  .page-wrapper.horizontal-wrapper .page-body-wrapper .mobile-back {
    display: none !important;
  }

  .rtl .mega .full-mega-menu {
    float: left !important;
    left: 50% !important;
    right: auto !important;
  }

  .rtl .mega .full-mega-menu.center-mega-menu {
    margin-right: 0 !important;
    margin-left: 0 !important;
    left: unset !important;
    right: 50% !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .page-wrapper.modern-sidebar
    .page-main-header
    .main-header-right
    .nav-right.right-menu
    ul {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1660px) {
  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header.main-nav
    .main-navbar
    .nav-menu
    > li
    a
    svg {
    margin-right: 6px;
  }

  .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header.main-nav
    .main-navbar
    .nav-menu
    .nav-submenu.submenu-right-xl
    li
    .nav-sub-childmenu {
    left: -200px;
    right: unset;
  }
}

@media (max-width: 1460px) {
  .rtl .pixelstrap .mega .full-mega-menu {
    margin-left: 0 !important;
    margin-right: 162px !important;
  }

  header .main-navbar .nav-menu > li {
    margin-right: 8px;
  }
}

@media (max-width: 1199px) {
  .page-wrapper.compact-wrapper
    .page-body-wrapper.sidebar-icon
    header.main-nav.close_icon
    ~ footer {
    margin-left: unset;
  }

  .page-wrapper.modern-sidebar {
    padding: unset;
    height: auto;
  }

  .page-wrapper.modern-sidebar .page-main-header {
    top: 0;
    border-radius: unset;
    width: 100%;
  }

  .page-wrapper.modern-sidebar .page-main-header.close_icon {
    width: 100%;
  }

  .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper {
    border-radius: unset;
    margin-top: unset;
    margin-bottom: unset;
    padding-top: unset;
  }

  .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    header.main-nav {
    top: 85px;
    height: auto;
  }

  .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    header.main-nav
    .main-navbar
    .nav-menu {
    height: calc(100vh - 355px);
  }

  .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    header.main-nav.close_icon
    ~ footer {
    width: 100%;
    margin-left: unset;
  }

  .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper .page-body {
    max-height: unset;
    max-height: unset;
    min-height: calc(100vh - 76px);
    margin-top: 76px;
  }

  .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    .page-body::-webkit-scrollbar {
    display: none;
  }

  .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    .page-body::-webkit-scrollbar-track {
    display: none;
  }

  .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    .page-body::-webkit-scrollbar-thumb {
    display: none;
  }

  .page-wrapper.modern-sidebar.compact-wrapper footer {
    position: unset;
    width: auto;
    border-radius: unset;
    margin-left: 275px;
    margin-bottom: unset;
  }
}

@media (max-width: 991px) {
  .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper .page-body {
    margin-top: 65px;
  }
}

@media (max-width: 767px) {
  header .menu .brand-logo img {
    width: 120px;
  }
}

@media (max-width: 576px) {
  header .menu nav {
    margin-right: 20px;
  }

  .toggle-nav i {
    font-size: 20px;
  }
}

/**=====================
    78. Sidebar CSS Ends
==========================**/

/**=====================
    06. Button CSS start
==========================**/

.btn {
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  font-weight: 600;
}

.btn-lg {
  font-size: 18px;
}

.btn-sm {
  font-size: 12px;
}

.btn-xs {
  padding: 0.2rem 0.7rem;
  font-size: 10px;
}

.large-btn {
  margin-bottom: -20px;
}

.large-btn .btn {
  margin-right: 5px;
  margin-bottom: 15px;
}

.btn-air-primary {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-primary:hover,
.btn-air-primary:active,
.btn-air-primary:not([disabled]):not(.disabled):active {
  background-color: #17433b;
  border-color: #17433b;
}

.btn-air-secondary {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-secondary:hover,
.btn-air-secondary:active,
.btn-air-secondary:not([disabled]):not(.disabled):active {
  background-color: #a07044;
  border-color: #a07044;
}

.btn-air-success {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-success:hover,
.btn-air-success:active,
.btn-air-success:not([disabled]):not(.disabled):active {
  background-color: #0e2622;
  border-color: #0e2622;
}

.btn-air-danger {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-danger:hover,
.btn-air-danger:active,
.btn-air-danger:not([disabled]):not(.disabled):active {
  background-color: #a82431;
  border-color: #a82431;
}

.btn-air-info {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-info:hover,
.btn-air-info:active,
.btn-air-info:not([disabled]):not(.disabled):active {
  background-color: #585858;
  border-color: #585858;
}

.btn-air-light {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-light:hover,
.btn-air-light:active,
.btn-air-light:not([disabled]):not(.disabled):active {
  background-color: #c7d7db;
  border-color: #c7d7db;
}

.btn-air-dark {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-dark:hover,
.btn-air-dark:active,
.btn-air-dark:not([disabled]):not(.disabled):active {
  background-color: #171a21;
  border-color: #171a21;
}

.btn-air-warning {
  -webkit-box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
  box-shadow: 0 5px 10px 2px rgba(36, 105, 92, 0.19) !important;
}

.btn-air-warning:hover,
.btn-air-warning:active,
.btn-air-warning:not([disabled]):not(.disabled):active {
  background-color: #c8ac1d;
  border-color: #c8ac1d;
}

.btn-info {
  color: #fff;
}

.input-air-primary {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-primary:focus {
  border-color: #78266a;
}

.input-air-secondary {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-secondary:focus {
  border-color: #ba895d;
}

.input-air-success {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-success:focus {
  border-color: #1b4c43;
}

.input-air-danger {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-danger:focus {
  border-color: #d22d3d;
}

.input-air-info {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-info:focus {
  border-color: #717171;
}

.input-air-light {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-light:focus {
  border-color: #e6edef;
}

.input-air-dark {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-dark:focus {
  border-color: #2c323f;
}

.input-air-warning {
  -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-warning:focus {
  border-color: #e2c636;
}

.form-bg-primary {
  background: #78266a;
  border-color: #78266a;
  color: #fff;
}

.form-bg-primary:focus {
  border-color: #78266a;
  background: #78266a;
  color: #fff;
}

.form-bg-primary:focus:active {
  border-color: #78266a;
  background: #78266a;
  color: #fff;
}

.form-bg-secondary {
  background: #ba895d;
  border-color: #ba895d;
  color: #fff;
}

.form-bg-secondary:focus {
  border-color: #ba895d;
  background: #ba895d;
  color: #fff;
}

.form-bg-secondary:focus:active {
  border-color: #ba895d;
  background: #ba895d;
  color: #fff;
}

.form-bg-success {
  background: #1b4c43;
  border-color: #1b4c43;
  color: #fff;
}

.form-bg-success:focus {
  border-color: #1b4c43;
  background: #1b4c43;
  color: #fff;
}

.form-bg-success:focus:active {
  border-color: #1b4c43;
  background: #1b4c43;
  color: #fff;
}

.form-bg-danger {
  background: #d22d3d;
  border-color: #d22d3d;
  color: #fff;
}

.form-bg-danger:focus {
  border-color: #d22d3d;
  background: #d22d3d;
  color: #fff;
}

.form-bg-danger:focus:active {
  border-color: #d22d3d;
  background: #d22d3d;
  color: #fff;
}

.form-bg-info {
  background: #717171;
  border-color: #717171;
  color: #fff;
}

.form-bg-info:focus {
  border-color: #717171;
  background: #717171;
  color: #fff;
}

.form-bg-info:focus:active {
  border-color: #717171;
  background: #717171;
  color: #fff;
}

.form-bg-light {
  background: #e6edef;
  border-color: #e6edef;
  color: #2c323f;
}

.form-bg-light:focus {
  border-color: #e6edef;
  background: #e6edef;
  color: #2c323f;
}

.form-bg-light:focus:active {
  border-color: #e6edef;
  background: #e6edef;
  color: #2c323f;
}

.form-bg-dark {
  background: #2c323f;
  border-color: #2c323f;
  color: #fff;
}

.form-bg-dark:focus {
  border-color: #2c323f;
  background: #2c323f;
  color: #fff;
}

.form-bg-dark:focus:active {
  border-color: #2c323f;
  background: #2c323f;
  color: #fff;
}

.form-bg-warning {
  background: #e2c636;
  border-color: #e2c636;
  color: #fff;
}

.form-bg-warning:focus {
  border-color: #e2c636;
  background: #e2c636;
  color: #fff;
}

.form-bg-warning:focus:active {
  border-color: #e2c636;
  background: #e2c636;
  color: #fff;
}

.btn-warning {
  color: #fff;
}

.btn-warning:hover,
.btn-warning.disabled {
  color: #fff;
}

.btn-primary-gradien {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2e8777),
    color-stop(51%, #1a4b41),
    to(#2e8777)
  );
  background-image: linear-gradient(
    to right,
    #2e8777 0%,
    #1a4b41 51%,
    #2e8777 100%
  );
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-primary-gradien:hover {
  color: #242934 !important;
}

.btn-primary-gradien:focus,
.btn-primary-gradien:active,
.btn-primary-gradien.active {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2e8777),
    color-stop(0%),
    color-stop(#1a4b41),
    color-stop(100%),
    to(#fff)
  ) !important;
  background-image: linear-gradient(
    to right,
    #2e8777,
    0%,
    #1a4b41,
    100%,
    #fff
  ) !important;
}

.btn-secondary-gradien {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#c69e7a),
    color-stop(51%, #a77547),
    to(#c69e7a)
  );
  background-image: linear-gradient(
    to right,
    #c69e7a 0%,
    #a77547 51%,
    #c69e7a 100%
  );
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-secondary-gradien:hover,
.btn-secondary-gradien:focus,
.btn-secondary-gradien:active,
.btn-secondary-gradien.active,
.btn-secondary-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#c69e7a),
    color-stop(0%),
    color-stop(#a77547),
    color-stop(100%),
    to(#fff)
  ) !important;
  background-image: linear-gradient(
    to right,
    #c69e7a,
    0%,
    #a77547,
    100%,
    #fff
  ) !important;
}

.btn-success-gradien {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#266a5e),
    color-stop(51%, #102e28),
    to(#266a5e)
  );
  background-image: linear-gradient(
    to right,
    #266a5e 0%,
    #102e28 51%,
    #266a5e 100%
  );
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-success-gradien:hover,
.btn-success-gradien:focus,
.btn-success-gradien:active,
.btn-success-gradien.active,
.btn-success-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#266a5e),
    color-stop(0%),
    color-stop(#102e28),
    color-stop(100%),
    to(#fff)
  ) !important;
  background-image: linear-gradient(
    to right,
    #266a5e,
    0%,
    #102e28,
    100%,
    #fff
  ) !important;
}

.btn-danger-gradien {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#d94f5c),
    color-stop(51%, #b02633),
    to(#d94f5c)
  );
  background-image: linear-gradient(
    to right,
    #d94f5c 0%,
    #b02633 51%,
    #d94f5c 100%
  );
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-danger-gradien:hover,
.btn-danger-gradien:focus,
.btn-danger-gradien:active,
.btn-danger-gradien.active,
.btn-danger-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#d94f5c),
    color-stop(0%),
    color-stop(#b02633),
    color-stop(100%),
    to(#fff)
  ) !important;
  background-image: linear-gradient(
    to right,
    #d94f5c,
    0%,
    #b02633,
    100%,
    #fff
  ) !important;
}

.btn-warning-gradien {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#e7d05a),
    color-stop(51%, #d1b41e),
    to(#e7d05a)
  );
  background-image: linear-gradient(
    to right,
    #e7d05a 0%,
    #d1b41e 51%,
    #e7d05a 100%
  );
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-warning-gradien:hover,
.btn-warning-gradien:focus,
.btn-warning-gradien:active,
.btn-warning-gradien.active,
.btn-warning-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#e7d05a),
    color-stop(0%),
    color-stop(#d1b41e),
    color-stop(100%),
    to(#fff)
  ) !important;
  background-image: linear-gradient(
    to right,
    #e7d05a,
    0%,
    #d1b41e,
    100%,
    #fff
  ) !important;
}

.btn-info-gradien {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#858585),
    color-stop(51%, #5d5d5d),
    to(#858585)
  );
  background-image: linear-gradient(
    to right,
    #858585 0%,
    #5d5d5d 51%,
    #858585 100%
  );
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-info-gradien:hover,
.btn-info-gradien:focus,
.btn-info-gradien:active,
.btn-info-gradien.active,
.btn-info-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#858585),
    color-stop(0%),
    color-stop(#5d5d5d),
    color-stop(100%),
    to(#fff)
  ) !important;
  background-image: linear-gradient(
    to right,
    #858585,
    0%,
    #5d5d5d,
    100%,
    #fff
  ) !important;
}

.btn-light-gradien {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(white),
    color-stop(51%, #cddbdf),
    to(white)
  );
  background-image: linear-gradient(
    to right,
    white 0%,
    #cddbdf 51%,
    white 100%
  );
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-light-gradien:hover,
.btn-light-gradien:focus,
.btn-light-gradien:active,
.btn-light-gradien.active,
.btn-light-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(white),
    color-stop(0%),
    color-stop(#cddbdf),
    color-stop(100%),
    to(#fff)
  ) !important;
  background-image: linear-gradient(
    to right,
    white,
    0%,
    #cddbdf,
    100%,
    #fff
  ) !important;
}

.btn-dark-gradien {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3d4557),
    color-stop(51%, #1b1f27),
    to(#3d4557)
  );
  background-image: linear-gradient(
    to right,
    #3d4557 0%,
    #1b1f27 51%,
    #3d4557 100%
  );
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-dark-gradien:hover,
.btn-dark-gradien:focus,
.btn-dark-gradien:active,
.btn-dark-gradien.active,
.btn-dark-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3d4557),
    color-stop(0%),
    color-stop(#1b1f27),
    color-stop(100%),
    to(#fff)
  ) !important;
  background-image: linear-gradient(
    to right,
    #3d4557,
    0%,
    #1b1f27,
    100%,
    #fff
  ) !important;
}

[class*="-gradien"]:hover {
  background-size: 50% 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
}

.btn-square {
  border-radius: 0px;
}

.btn-pill {
  border-radius: 60px;
}

.btn-group-pill .btn:first-child {
  border-radius: 60px 0 0 60px;
}

.btn-group-pill .btn:last-child {
  border-radius: 0 60px 60px 0;
}

.btn-group-square .btn:first-child {
  border-radius: 0px 0 0 0px;
}

.btn-group-square .btn:last-child {
  border-radius: 0 0px 0px 0;
}

.btn-showcase {
  margin-bottom: -10px;
}

.btn-showcase .btn {
  margin-bottom: 10px;
  margin-right: 18px;
}

.btn-dropdown-showcase {
  margin-bottom: -10px;
}

.btn-dropdown-showcase .dropdown {
  margin-bottom: 10px;
  margin-right: 18px;
}

.btn-dropdown-showcase .btn-group {
  margin-bottom: 10px;
  margin-right: 18px;
}

.btn-group .radio label::before {
  top: 2px;
}

.btn-group .radio label::after {
  top: 7px;
}

.btn-group .checkbox label {
  margin-top: 3px;
}

.btn-group-showcase > div {
  margin-bottom: -20px;
}

.btn-group-showcase > div label {
  margin-bottom: 0;
}

.btn-group-showcase .btn-group {
  margin-right: 20px;
  margin-bottom: 20px;
}

.btn-group-showcase .checkbox input[type="checkbox"] {
  display: none;
}

.tooltiptext {
  visibility: visible;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.topright-general,
.toprightarrow-primary,
.toprightarrow-secondary {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.topright-general:before,
.toprightarrow-primary:before,
.toprightarrow-secondary:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toprightarrow-primary {
  color: #78266a;
  background-color: rgba(36, 105, 92, 0.1);
}

.toprightarrow-secondary {
  color: #ba895d;
  background-color: rgba(186, 137, 93, 0.1);
}

.btn-arrow {
  font-size: 12px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.btn-arrow.arrow-primary {
  color: #78266a;
}

.btn-arrow.arrow-secondary {
  color: #ba895d;
}

.btn-iconsolid {
  border-radius: 5px;
  background-color: #78266a;
  padding: 8px 12px;
}

.btn-iconsolid i {
  color: #fff;
  font-weight: 700;
}

/**=====================
     06. Button CSS end
==========================**/

/**=====================
     07. Color CSS Start
==========================**/

.color-box {
  margin-bottom: -10px;
}

.color-box button {
  margin-bottom: 10px;
}

.color-box button + button {
  margin-left: 5px;
}

.primary-color ul li:nth-child(13) {
  background-color: #266f61;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(13) span:before {
  content: "#266f61";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(12) {
  background-color: #287466;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(12) span:before {
  content: "#287466";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(11) {
  background-color: #2a7a6b;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(11) span:before {
  content: "#2a7a6b";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(10) {
  background-color: #2c8070;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(10) span:before {
  content: "#2c8070";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(9) {
  background-color: #2e8575;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(9) span:before {
  content: "#2e8575";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(8) {
  background-color: #308b7a;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(8) span:before {
  content: "#308b7a";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(7) {
  background-color: #32917f;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(7) span:before {
  content: "#32917f";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(6) {
  background-color: #349784;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(6) span:before {
  content: "#349784";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(5) {
  background-color: #369c89;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(5) span:before {
  content: "#369c89";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(4) {
  background-color: #38a28e;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(4) span:before {
  content: "#38a28e";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(3) {
  background-color: #39a893;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(3) span:before {
  content: "#39a893";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(2) {
  background-color: #3bad98;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(2) span:before {
  content: "#3bad98";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.primary-color ul li:nth-child(1) {
  background-color: #3db39d;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.primary-color ul li:nth-child(1) span:before {
  content: "#3db39d";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(13) {
  background-color: #bf9168;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(13) span:before {
  content: "#bf9168";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(12) {
  background-color: #c39972;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(12) span:before {
  content: "#c39972";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(11) {
  background-color: #c8a07d;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(11) span:before {
  content: "#c8a07d";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(10) {
  background-color: #cca888;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(10) span:before {
  content: "#cca888";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(9) {
  background-color: #d1b093;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(9) span:before {
  content: "#d1b093";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(8) {
  background-color: #d5b89d;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(8) span:before {
  content: "#d5b89d";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(7) {
  background-color: #dac0a8;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(7) span:before {
  content: "#dac0a8";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(6) {
  background-color: #dfc8b3;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(6) span:before {
  content: "#dfc8b3";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(5) {
  background-color: #e3cfbe;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(5) span:before {
  content: "#e3cfbe";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(4) {
  background-color: #e8d7c8;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(4) span:before {
  content: "#e8d7c8";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(3) {
  background-color: #ecdfd3;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(3) span:before {
  content: "#ecdfd3";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(2) {
  background-color: #f1e7de;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(2) span:before {
  content: "#f1e7de";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.secondary-color ul li:nth-child(1) {
  background-color: #f5efe8;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.secondary-color ul li:nth-child(1) span:before {
  content: "#f5efe8";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(13) {
  background-color: #1c4f46;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(13) span:before {
  content: "#1c4f46";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(12) {
  background-color: #1d5349;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(12) span:before {
  content: "#1d5349";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(11) {
  background-color: #1f564c;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(11) span:before {
  content: "#1f564c";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(10) {
  background-color: #205a4f;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(10) span:before {
  content: "#205a4f";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(9) {
  background-color: #215d52;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(9) span:before {
  content: "#215d52";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(8) {
  background-color: #226055;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(8) span:before {
  content: "#226055";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(7) {
  background-color: #236458;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(7) span:before {
  content: "#236458";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(6) {
  background-color: #25675b;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(6) span:before {
  content: "#25675b";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(5) {
  background-color: #266a5e;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(5) span:before {
  content: "#266a5e";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(4) {
  background-color: #276e61;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(4) span:before {
  content: "#276e61";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(3) {
  background-color: #287164;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(3) span:before {
  content: "#287164";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(2) {
  background-color: #297567;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(2) span:before {
  content: "#297567";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.success-color ul li:nth-child(1) {
  background-color: #2b786a;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.success-color ul li:nth-child(1) span:before {
  content: "#2b786a";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(13) {
  background-color: #777777;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(13) span:before {
  content: "#777777";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(12) {
  background-color: #7e7e7e;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(12) span:before {
  content: "#7e7e7e";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(11) {
  background-color: #848484;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(11) span:before {
  content: "#848484";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(10) {
  background-color: #8b8b8b;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(10) span:before {
  content: "#8b8b8b";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(9) {
  background-color: #919191;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(9) span:before {
  content: "#919191";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(8) {
  background-color: #979797;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(8) span:before {
  content: "#979797";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(7) {
  background-color: #9e9e9e;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(7) span:before {
  content: "#9e9e9e";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(6) {
  background-color: #a4a4a4;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(6) span:before {
  content: "#a4a4a4";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(5) {
  background-color: #aaaaaa;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(5) span:before {
  content: "#aaaaaa";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(4) {
  background-color: #b1b1b1;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(4) span:before {
  content: "#b1b1b1";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(3) {
  background-color: #b7b7b7;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(3) span:before {
  content: "#b7b7b7";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(2) {
  background-color: #bebebe;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(2) span:before {
  content: "#bebebe";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.info-color ul li:nth-child(1) {
  background-color: #c4c4c4;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.info-color ul li:nth-child(1) span:before {
  content: "#c4c4c4";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(13) {
  background-color: #e4ca43;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(13) span:before {
  content: "#e4ca43";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(12) {
  background-color: #e6ce51;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(12) span:before {
  content: "#e6ce51";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(11) {
  background-color: #e8d15e;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(11) span:before {
  content: "#e8d15e";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(10) {
  background-color: #ead56b;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(10) span:before {
  content: "#ead56b";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(9) {
  background-color: #ecd979;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(9) span:before {
  content: "#ecd979";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(8) {
  background-color: #eedd86;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(8) span:before {
  content: "#eedd86";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(7) {
  background-color: #f0e194;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(7) span:before {
  content: "#f0e194";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(6) {
  background-color: #f1e4a1;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(6) span:before {
  content: "#f1e4a1";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(5) {
  background-color: #f3e8ae;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(5) span:before {
  content: "#f3e8ae";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(4) {
  background-color: #f5ecbc;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(4) span:before {
  content: "#f5ecbc";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(3) {
  background-color: #f7f0c9;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(3) span:before {
  content: "#f7f0c9";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(2) {
  background-color: #f9f4d6;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(2) span:before {
  content: "#f9f4d6";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.yellow-color ul li:nth-child(1) {
  background-color: #fbf7e4;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.yellow-color ul li:nth-child(1) span:before {
  content: "#fbf7e4";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(13) {
  background-color: #d43545;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(13) span:before {
  content: "#d43545";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(12) {
  background-color: #d63e4d;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(12) span:before {
  content: "#d63e4d";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(11) {
  background-color: #d74654;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(11) span:before {
  content: "#d74654";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(10) {
  background-color: #d94f5c;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(10) span:before {
  content: "#d94f5c";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(9) {
  background-color: #db5764;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(9) span:before {
  content: "#db5764";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(8) {
  background-color: #dd5f6c;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(8) span:before {
  content: "#dd5f6c";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(7) {
  background-color: #df6873;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(7) span:before {
  content: "#df6873";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(6) {
  background-color: #e0707b;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(6) span:before {
  content: "#e0707b";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(5) {
  background-color: #e27983;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(5) span:before {
  content: "#e27983";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(4) {
  background-color: #e4818b;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(4) span:before {
  content: "#e4818b";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(3) {
  background-color: #e68992;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(3) span:before {
  content: "#e68992";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(2) {
  background-color: #e8929a;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(2) span:before {
  content: "#e8929a";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.red-color ul li:nth-child(1) {
  background-color: #e99aa2;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.red-color ul li:nth-child(1) span:before {
  content: "#e99aa2";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(13) {
  background-color: #eb2067;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(13) span:before {
  content: "#eb2067";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(12) {
  background-color: #ec2e70;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(12) span:before {
  content: "#ec2e70";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(11) {
  background-color: #ed3c7a;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(11) span:before {
  content: "#ed3c7a";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(10) {
  background-color: #ee4a83;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(10) span:before {
  content: "#ee4a83";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(9) {
  background-color: #f0588d;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(9) span:before {
  content: "#f0588d";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(8) {
  background-color: #f16696;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(8) span:before {
  content: "#f16696";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(7) {
  background-color: #f274a0;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(7) span:before {
  content: "#f274a0";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(6) {
  background-color: #f482aa;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(6) span:before {
  content: "#f482aa";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(5) {
  background-color: #f590b3;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(5) span:before {
  content: "#f590b3";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(4) {
  background-color: #f69ebd;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(4) span:before {
  content: "#f69ebd";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(3) {
  background-color: #f7acc6;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(3) span:before {
  content: "#f7acc6";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(2) {
  background-color: #f9bad0;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(2) span:before {
  content: "#f9bad0";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.pink-color ul li:nth-child(1) {
  background-color: #fac8d9;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.pink-color ul li:nth-child(1) span:before {
  content: "#fac8d9";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(13) {
  background-color: #919191;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(13) span:before {
  content: "#919191";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(12) {
  background-color: #989898;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(12) span:before {
  content: "#989898";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(11) {
  background-color: #a0a0a0;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(11) span:before {
  content: "#a0a0a0";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(10) {
  background-color: #a8a8a8;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(10) span:before {
  content: "#a8a8a8";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(9) {
  background-color: #afafaf;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(9) span:before {
  content: "#afafaf";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(8) {
  background-color: #b7b7b7;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(8) span:before {
  content: "#b7b7b7";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(7) {
  background-color: #bfbfbf;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(7) span:before {
  content: "#bfbfbf";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(6) {
  background-color: #c6c6c6;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(6) span:before {
  content: "#c6c6c6";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(5) {
  background-color: #cecece;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(5) span:before {
  content: "#cecece";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(4) {
  background-color: #d6d6d6;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(4) span:before {
  content: "#d6d6d6";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(3) {
  background-color: #dddddd;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(3) span:before {
  content: "#dddddd";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(2) {
  background-color: #e5e5e5;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(2) span:before {
  content: "#e5e5e5";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.gray-color ul li:nth-child(1) {
  background-color: #ececec;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.gray-color ul li:nth-child(1) span:before {
  content: "#ececec";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:last-child {
  margin-bottom: 0;
}

.light-font {
  color: rgba(36, 41, 52, 0.7);
}

/**=====================
     07. Color CSS Ends
==========================**/

/**=====================
      08. GRID CSS Start
==========================**/

.grid-showcase {
  margin-bottom: -10px;
}

.grid-showcase span {
  display: block;
  border: 1px solid #e6edef;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  background-color: rgba(36, 105, 92, 0.1);
  color: #78266a;
  font-weight: 600;
}

.grid-align {
  margin-bottom: -30px;
}

.grid-align .row {
  padding: 15px 0;
  min-height: 10rem;
  border: 1px solid rgba(36, 105, 92, 0.03);
  margin-bottom: 30px;
  background-color: rgba(36, 105, 92, 0.03);
}

.grid-wrrapper .table-striped tbody tr:nth-of-type(odd) {
  --bs-table-accent-bg: unset;
  background-color: rgba(36, 105, 92, 0.1);
  color: #78266a;
}

.grid-wrrapper .table-striped tbody tr:nth-of-type(odd) th,
.grid-wrrapper .table-striped tbody tr:nth-of-type(odd) td {
  color: #78266a;
}

/**=====================
      08. GRID CSS Ends
==========================**/

/**=====================
     09. Error-page  CSS Start
==========================**/

.error-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.error-wrapper.maintenance-bg {
  /* background-image: url(../images/other-images/maintenance-bg.jpg); */
  background-blend-mode: overlay;
}

.error-wrapper .maintenance-icons li i {
  color: rgba(36, 105, 92, 0.5);
  font-size: 50px;
  animation: rotate-effect 40s infinite linear reverse;
}

.error-wrapper .maintenance-icons li:nth-child(2) i {
  font-size: 100px;
  margin-left: 110px;
  margin-top: -25px;
  animation: rotate-effect 40s linear infinite reverse;
}

.error-wrapper .maintenance-icons li:nth-child(3) i {
  -webkit-animation: rotate-effect 30s infinite linear;
  animation: rotate-effect 30s infinite linear;
  font-size: 150px;
  margin-top: -85px;
  margin-right: 120px;
}

.error-wrapper .maintenance-heading {
  margin-top: 70px;
}

.error-wrapper .maintenance-heading .headline {
  font-size: 99px;
  font-weight: 900;
  letter-spacing: 10px;
  color: #ba895d;
  z-index: 2;
  position: relative;
  margin-top: -70px;
}

.error-wrapper .error-heading {
  margin-top: 115px;
}

.error-wrapper .error-heading .headline {
  font-size: 285px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: -130px;
}

.error-wrapper .sub-content {
  font-size: 18px;
  color: #999;
  letter-spacing: 1px;
  font-weight: normal;
  line-height: 35px;
  z-index: 3;
  position: relative;
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: 600;
}

.error-wrapper .btn {
  margin-top: 40px;
}

.error {
  margin: 70px 0 100px;
  color: #000;
}

.error .error-title {
  font-size: 9.5rem;
  color: #fff;
  text-shadow: 6px 6px 7px rgba(0, 0, 0, 0.32);
}

.error .line-bottom {
  width: 10%;
  height: 5px;
  background-color: #e2c636;
  border: none;
}

.error .c-white {
  color: #fff;
}

.error .error-btn {
  text-align: center;
}

.error .padd {
  padding: 10px 30px 10px 30px;
}

.error .btn-back-home {
  background-color: #e6edef;
  color: #000;
}

.error .btn-back-home:hover {
  background-color: #e2c636;
  color: #fff;
  -webkit-box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
  box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
}

.error .btn-adnc-serc {
  background-color: #2c323f;
  color: #fff;
}

.error .btn-adnc-serc:hover {
  background-color: #e2c636;
  color: #fff;
  -webkit-box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
  box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
}

.maintenance-sec .error-wrapper .maintenance-heading .headline {
  margin-bottom: 0;
}

.maintenance-sec .error-wrapper .sub-content {
  margin-top: 15px;
}

/**=====================
     09. Error-page CSS Ends
==========================**/

@-webkit-keyframes wheel-rotate {
  50% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }

  100% {
    -webkit-transform: rotate(960deg);
    transform: rotate(960deg);
  }
}

@keyframes wheel-rotate {
  50% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }

  100% {
    -webkit-transform: rotate(960deg);
    transform: rotate(960deg);
  }
}

@-webkit-keyframes bicycle-run {
  0% {
    -webkit-transform: translateX(-1300px);
    transform: translateX(-1300px);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }

  100% {
    -webkit-transform: translateX(1300px);
    transform: translateX(1300px);
  }
}

@keyframes bicycle-run {
  0% {
    -webkit-transform: translateX(-1300px);
    transform: translateX(-1300px);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }

  100% {
    -webkit-transform: translateX(1300px);
    transform: translateX(1300px);
  }
}

@-webkit-keyframes move-1 {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes move-1 {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.error-page1 .danger-color {
  fill: #d22d3d;
}

.error-page1 .warning-color {
  fill: #e2c636;
}

.error-page1 .primary-color {
  fill: #78266a;
}

.error-page1 .primary-color.opacity-05 {
  fill: rgba(36, 105, 92, 0.5);
}

.error-page1 .secondary-color {
  fill: #ba895d;
}

.error-page1 .secondary-color.opacity-o6 {
  opacity: 0.6;
}

.error-page1 .secondary-color.opacity-o4 {
  opacity: 0.4;
}

.error-page1 .secondary-color.lighten-5 {
  fill: #b27c4c;
}

.error-page1 .svg-wrraper {
  margin-bottom: 35px;
}

.error-page1 .svg-wrraper svg.svg-45 {
  width: 45%;
}

.error-page1 .svg-wrraper svg.svg-60 {
  width: 60%;
}

.error-page1 .svg-wrraper svg.svg-40 {
  width: 40%;
}

.error-page1 .wheel {
  -webkit-animation: wheel-rotate 6s ease infinite;
  animation: wheel-rotate 6s ease infinite;
  -webkit-transform-origin: center;
  transform-origin: center;
  transform-box: fill-box;
}

.error-page1 .bicycle {
  -webkit-animation: bicycle-run 15s ease infinite;
  animation: bicycle-run 15s ease infinite;
}

.error-page1 .circle {
  -webkit-animation: circle-anim ease infinite;
  animation: circle-anim ease infinite;
  -webkit-transform-origin: center;
  transform-origin: center;
  transform-box: fill-box;
  -webkit-perspective: 0;
  perspective: 0;
}

.error-page1 .rocket,
.error-page1 .jacket-man {
  -webkit-animation: move-1 6s linear infinite;
  animation: move-1 6s linear infinite;
}

.error-page1 h3 {
  font-weight: 700;
  text-transform: capitalize;
}

.error-page1 .btn-primary-gradien {
  color: #fff;
  font-weight: 700;
}

@media (max-width: 767px) {
  .error-page1 h3 {
    font-size: 22px;
  }
}

@media (max-width: 575px) {
  .error-page1 .svg-wrraper {
    margin-bottom: 10px;
  }

  .error-page1 .svg-wrraper svg.svg-40 {
    width: 50%;
  }
}

/**=====================
    10. ICON CSS Start
==========================**/

.icon-lists h6 {
  color: #898989;
  font-weight: 400;
}

.icon-lists > div:hover {
  background-color: rgba(36, 105, 92, 0.1);
  color: #78266a;
}

.icon-lists > div:hover i {
  color: #78266a;
}

.icon-lists div {
  cursor: pointer;
  line-height: 60px;
  white-space: nowrap;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.icon-lists div i {
  margin: 0 15px 0 10px;
  font-size: 24px;
}

.icon-lists.whether-icon svg {
  height: 50px;
  width: 50px;
}

.icon-lists.feather-icons .media svg {
  margin: 0 15px 0 10px;
}

.icon-lists .feather-icon .icon-first h6 {
  display: none;
}

.flag-icons,
.feather-icons {
  margin-bottom: -15px;
  margin-top: -15px;
}

.flag-icons div,
.feather-icons div {
  line-height: 1;
  word-wrap: break-word;
  overflow: auto;
}

.flag-icons div .flag-icon,
.feather-icons div .flag-icon {
  height: 50px;
  width: 50px;
}

.flag-icons div .media,
.feather-icons div .media {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}

.flag-icons div .media-body h6,
.feather-icons div .media-body h6 {
  margin-bottom: 0;
  font-size: 14px;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.flag-icons div .media-body h5,
.feather-icons div .media-body h5 {
  background-color: #78266a;
  display: inline-block;
  padding: 3px 5px;
  font-size: 11px;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #fff;
}

.icon-hover-bottom {
  background-color: #fdfeff;
  width: 100%;
  bottom: 0;
  left: 0;
  -webkit-box-shadow: 0px 0px 1px 1px #e6edef;
  box-shadow: 0px 0px 1px 1px #e6edef;
  z-index: 999;
}

.icon-hover-bottom .icon-popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

.icon-hover-bottom .icon-popup .close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  color: #cccccc;
  cursor: pointer;
}

.icon-hover-bottom .icon-popup > div {
  display: inline-block;
}

.icon-hover-bottom .icon-popup > div .flag-icon {
  width: 60px;
  height: 60px;
}

.icon-hover-bottom .icon-popup .icon-class {
  margin: 0 20px;
  cursor: pointer;
}

.icon-hover-bottom .icon-title {
  display: block;
  color: #2c323f;
  text-transform: uppercase;
  font-weight: 500;
}

.icon-hover-bottom .form-group input {
  min-width: 300px;
  background-color: #fff;
}

.fa-fa-icon-show-div,
.icons-show-div {
  display: none;
}

.icon-first i {
  color: #898989;
}

.icon-first h6 {
  display: none;
}

@media only screen and (max-width: 480px) {
  .flag-icons div .media,
  .feather-icons div .media {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

/**=====================
     10. ICON CSS Ends
==========================**/

/**=====================
     11. Form Builder 1 CSS Start
==========================**/

.form-builder .drag-box {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-builder .drag-box fieldset {
  border: 1px dotted #e6edef;
  min-height: 655px;
  padding: 30px;
  width: 100%;
  overflow: hidden;
  margin-top: 30px;
  border-radius: 4px;
}

.form-builder .component {
  cursor: pointer;
}

.form-builder .component label {
  padding-top: 8px;
}

.form-builder .component .form-group input,
.form-builder .component .form-group textarea,
.form-builder .component .form-group select {
  border-radius: 4px;
}

.form-builder .component .form-group .input-group #prependedcheckbox,
.form-builder .component .form-group .input-group #prependedtext {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-builder .component .form-group .input-group #appendedcheckbox {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-color: transparent;
}

.form-builder .component .form-group .input-group #appendedtext,
.form-builder .component .form-group .input-group #buttondropdown {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-builder .component .form-group .input-group #buttondropdown {
  margin-right: -1px;
}

.form-builder .component .form-group .input-group .input-group-btn button {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-builder
  .component
  .form-group
  .input-group
  .input-group-prepend
  .btn-right,
.form-builder
  .component
  .form-group
  .input-group
  .input-group-prepend
  .checkbox-radius {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-builder .component .form-group .pl-md-radios {
  padding-left: 37px;
}

.form-builder input {
  cursor: pointer;
}

.form-builder textarea {
  resize: vertical;
}

.form-builder .popover {
  z-index: 8;
  opacity: 1;
  left: -290px !important;
  top: 95px !important;
}

.form-builder .popover .control-group {
  cursor: default;
}

.form-builder .popover input {
  cursor: pointer;
}

.form-builder .render {
  min-height: 500px;
  width: 100%;
  border-radius: 4px;
  background-color: #f5f7fb;
  border-color: #e6edef;
}

.form-builder .popover .controls {
  margin-left: 0;
}

.form-builder .popover .control-label {
  text-align: left;
}

.form-builder .popover form {
  width: 100%;
}

.form-builder .popover .fade.left.in {
  left: -215px;
}

.form-builder .target:after {
  float: left;
  content: " ";
  height: 77px;
  margin-top: 3px;
  background: #e6edef;
  width: 100%;
  border: 1px dashed #e6edef;
}

.drag form {
  padding: 10px 5px;
  border: 1px dotted #e6edef;
  position: absolute;
  background: #fff;
  -webkit-box-shadow: 0 0 30px #e6edef;
  box-shadow: 0 0 30px #e6edef;
  overflow: hidden;
}

.drag form input {
  border-radius: 5px;
}

.form-builder-header-1 {
  background-color: #e6edef;
  padding: 15px;
  border-radius: 5px;
  min-height: 67px;
}

.form-builder-header-1 h6 {
  margin-bottom: 0;
  margin-top: 10px;
  line-height: 2.2;
}

.form-builder .help-block {
  font-size: 12px;
}

.form-builder input {
  border-radius: 4px !important;
}

.form-builder .form-group .col-md-12 {
  padding: 0;
}

.form-builder .popover {
  width: 300px;
}

.form-builder .popover-header {
  background-color: #78266a;
  padding: 15px;
}

.form-builder .popover-body {
  padding: 15px;
}

.form-builder .popover-body .form-group.row {
  padding: 0 15px;
}

.form-builder .popover-body .form-group.row .col-lg-12 {
  padding: 0;
}

.dropdown-menu {
  background-color: #f5f7fb;
}

/**=====================
    11. Form Builder 1 CSS Ends
==========================**/

/**=====================
     12. Form Builder 2 CSS Start
==========================**/

.form-builder-column #pills-tabContent .theme-form .form-group,
.form-builder-column #pills-tabContent .theme-form .ui-draggable {
  margin-bottom: 0 !important;
}

.form-builder-column #pills-tabContent .theme-form .form-group .checkbox label,
.form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .checkbox
  label {
  margin-top: 0;
}

.form-builder-column #pills-tabContent .theme-form .form-group input,
.form-builder-column #pills-tabContent .theme-form .ui-draggable input {
  border-radius: 4px;
}

.form-builder-column
  #pills-tabContent
  .theme-form
  .form-group
  .input-group
  #prependedcheckbox,
.form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .input-group
  #prependedcheckbox {
  margin-right: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-builder-column
  #pills-tabContent
  .theme-form
  .form-group
  .input-group
  input,
.form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .input-group
  input {
  margin-right: 15px;
}

.form-builder-column
  #pills-tabContent
  .theme-form
  .form-group
  .input-group
  .input-group-prepend
  .input-group-text
  input,
.form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .input-group
  .input-group-prepend
  .input-group-text
  input {
  margin-right: 0;
}

.form-builder-column
  #pills-tabContent
  .theme-form
  .form-group
  .input-group
  .input-group-btn
  button,
.form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .input-group
  .input-group-btn
  button {
  border-radius: 4px;
}

.form-builder-column #pills-tabContent .theme-form .form-group select,
.form-builder-column #pills-tabContent .theme-form .ui-draggable select {
  border-radius: 4px;
}

.form-builder-column #pills-tabContent .theme-form .form-group .m-t-help-block,
.form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .m-t-help-block {
  margin-top: -3px;
}

.form-builder-column #pills-tabContent #pills-button .theme-form button {
  margin-bottom: 6px;
}

.form-builder-column .droppable-active {
  background-color: #78266a !important;
  opacity: 0.1;
}

.form-builder-column .form-body {
  min-height: 540px;
}

.form-builder-column .draggable {
  cursor: move;
}

.form-builder-column .drag-bx {
  border: 1px dotted #e6edef;
  border-radius: 4px;
}

.form-builder-column .help-block {
  color: #e6edef;
}

.form-builder-column .tools a {
  cursor: pointer;
  font-size: 80%;
}

.form-builder-2 .form-group {
  margin-bottom: 0 !important;
}

.form-builder-2 .form-group .checkbox label {
  margin-top: 0;
}

.form-builder-2 .form-group input {
  border-radius: 4px;
}

.form-builder-2 .form-group .input-group #prependedcheckbox {
  margin-right: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-builder-2 .form-group .input-group input {
  margin-right: 15px;
}

.form-builder-2
  .form-group
  .input-group
  .input-group-prepend
  .input-group-text
  input {
  margin-right: 0;
}

.form-builder-2 .form-group .input-group .input-group-btn button {
  border-radius: 4px;
}

.form-builder-2 .form-group select {
  border-radius: 4px;
}

.form-builder-2 .form-group .m-t-help-block {
  margin-top: -3px;
}

.form-builder-2-header {
  background-color: #e6edef;
  padding: 15px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}

.form-builder-2-header > div {
  display: inline-block;
  width: 49%;
}

.form-builder-2-header > div nav {
  text-align: right;
  float: right;
}

.draggable input {
  border-radius: 5px;
}

.draggable select {
  border-radius: 4px;
}

/**=====================
     12. Form Builder 2 CSS Ends
==========================**/

/**====================================
    13.  Email Application  CSS Start
========================================**/

.email-wrap .email-app-sidebar .media img {
  border: 2px solid #e6edef;
}

.email-wrap .email-app-sidebar .main-menu > li {
  width: 100%;
  text-align: left;
}

.email-wrap .email-app-sidebar .main-menu > li a {
  color: #898989;
  line-height: 39px;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 500;
}

.email-wrap .email-app-sidebar .main-menu > li a i {
  margin-right: 10px;
}

.email-wrap .email-app-sidebar .main-menu > li a.active > a {
  color: #000;
}

.email-wrap .email-app-sidebar .main-menu > li a:hover {
  background-color: rgba(36, 105, 92, 0.1);
  text-decoration: none;
}

.email-wrap .email-app-sidebar .main-menu > li a:hover > .title {
  color: #78266a;
}

.email-wrap .email-app-sidebar .main-menu > li a:hover > .badge {
  color: #78266a;
}

.email-wrap .email-app-sidebar ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.email-wrap .email-app-sidebar ul li a > .title {
  width: 100%;
}

.email-wrap .email-app-sidebar ul li a > .badge {
  -ms-flex-item-align: center;
  align-self: center;
  color: #898989;
}

.email-wrap .email-left-aside .card-body {
  padding: 20px;
}

.email-wrap .email-right-aside .email-body .modal-content .btn-close {
  top: 30px;
  right: 25px;
}

.email-wrap .email-right-aside .email-body .inbox {
  overflow: auto;
  overflow-x: hidden;
}

.email-wrap .email-right-aside .email-body .inbox .media {
  position: relative;
}

.email-wrap .email-right-aside .email-body .inbox .media .media-body span {
  position: absolute;
  right: 20px;
}

.email-wrap .email-right-aside .email-body .inbox svg {
  width: 18px;
  margin-right: 10px;
  stroke: #898989;
  cursor: pointer;
}

.email-wrap .email-right-aside .email-body .inbox svg.like {
  fill: #e2c636;
  stroke: #e2c636;
}

.email-wrap .email-right-aside .email-body .inbox .media-size-email {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.email-wrap .email-right-aside .email-body .inbox .media-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.email-wrap .email-right-aside .email-body .inbox img {
  height: 20px;
}

.email-wrap .email-right-aside .email-body .inbox .media {
  padding: 12px 20px;
  -webkit-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.email-wrap .email-right-aside .email-body .inbox .media:hover {
  background-color: #f2f6f7;
  -webkit-transform: translateY(-1px) scale(1.01);
  transform: translateY(-1px) scale(1.01);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.email-wrap .email-right-aside .email-body .attachment {
  margin-top: 10px;
}

.email-wrap .email-right-aside .email-body .attachment ul li {
  display: inline-block;
}

.email-wrap .email-right-aside .email-body .attachment ul li img {
  width: 90px;
  height: 90px;
}

.email-wrap .email-right-aside .email-profile .inbox p {
  color: #999;
  font-weight: 500;
  display: inline-block;
  width: calc(100% - 200px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 15px;
  max-width: 600px;
}

.email-wrap .email-right-aside .email-profile > div:first-child .email-top {
  padding: 20px;
}

.email-wrap
  .email-right-aside
  .email-profile
  > div:first-child
  .email-top
  .email-chek {
  margin-right: 20px;
}

.email-wrap
  .email-right-aside
  .email-profile
  > div:first-child
  .email-top
  .checkbox_animated:after {
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 4px;
}

.email-wrap
  .email-right-aside
  .email-profile
  > div:first-child
  .email-top
  .checkbox_animated:before {
  left: 0.4rem;
  top: 0.55rem;
  width: 1.1rem;
  height: 0.5rem;
}

.email-wrap .btn-mail {
  border-radius: 2px;
  text-align: left;
  padding: 13px 15px;
  margin: 20px 0 20px;
  letter-spacing: 1px;
  line-height: 12px !important;
  color: #fff !important;
}

.email-wrap .dropdown-menu {
  background-color: #fff;
  border-color: #e6edef;
}

.email-wrap .cke_chrome {
  background-color: #f5f7fb;
}

.email-wrap .cke_chrome .cke_top {
  background-color: transparent;
}

.email-wrap .cke_top {
  background-color: transparent;
}

.email-wrap p {
  margin-bottom: 0;
  color: #999;
}

.email-wrap .media-body h6 {
  margin-bottom: 2px;
  color: #78266a;
  margin-top: 3px;
  font-weight: 600;
  display: inline-block;
  font-size: 14px;
}

.email-wrap .media-body h6 span {
  font-weight: 600;
}

.email-wrap .media-body h6 small span {
  color: #717171;
}

.email-wrap .email-top {
  padding: 20px;
  border-bottom: 1px solid #e6edef;
}

.email-wrap .email-top h5 {
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.email-wrap .email-top .email-right {
  padding: 13px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.email-wrap .email-wrapper {
  padding: 20px;
}

.email-wrap .email-wrapper h5 {
  margin-bottom: 20px;
}

.email-wrap .email-wrapper .emailread-group:nth-child(n + 2) {
  border-top: 1px solid #e6edef;
  padding-top: 20px;
  margin-top: 20px;
}

.email-wrap .email-wrapper .emailread-group h5 {
  margin-bottom: 10px;
}

.email-wrap .email-wrapper .emailread-group h6 {
  font-size: 14px;
}

.email-wrap .email-wrapper .emailread-group .read-group:nth-child(n + 2) {
  margin-top: 15px;
}

.email-wrap .email-wrapper .emailread-group textarea {
  text-transform: capitalize;
  padding: 15px;
}

.email-wrap .email-wrapper .emailread-group .action-wrapper {
  margin-top: 15px;
}

.email-wrap .email-wrapper h6 {
  margin-bottom: 10px;
  float: left;
}

.email-wrap .email-wrapper .right-download {
  float: right;
}

.email-wrap .email-content .email-top .user-emailid:after {
  content: "";
  position: relative;
  height: 20px;
  width: 1px;
  right: 0;
  top: 3px;
  z-index: 2;
  border: 1px solid #efefef;
  float: right;
  padding: 0;
  margin: 0 20px;
}

.email-wrap .email-body .attachment ul li img {
  width: 80px;
  height: 80px;
}

.email-wrap .email-body .attachment ul li:nth-child(n + 2) {
  margin-left: 10px;
}

.email-wrap .email-body .attachment .list-inline .list-inline-item {
  padding-right: 13px;
  margin-right: unset;
}

.email-wrap .email-body .email-compose .cke_contents.cke_reset {
  max-height: 100px;
  width: 100%;
  border: 1px solid #e6edef;
  margin-bottom: 20px;
}

.email-wrap .email-body .email-compose .cke_inner {
  background-color: transparent;
}

.email-wrap .email-body .email-compose .email-top h4 {
  margin-bottom: 0;
}

.email-wrap .email-body .email-compose .compose-border {
  margin: -1px;
}

.email-wrap .email-body .email-compose .compose-border .btn-middle {
  padding-top: 5px;
}

.email-wrap .email-body .email-compose .compose-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.email-wrap .email-body .email-compose .dropzone i {
  display: block;
  margin-bottom: 10px;
}

.email-wrap .email-body .email-compose .dropzone h6 {
  float: unset;
}

.email-wrap .email-body .email-compose .dropzone .needsclick {
  display: block;
  margin: 0;
}

.email-wrap .actions {
  text-align: right;
}

.email-wrap .actions li {
  display: inline-block;
}

.email-wrap .actions li:nth-child(n + 2) {
  margin-left: 15px;
}

.email-wrap .email-aside-toggle {
  text-transform: capitalize;
  display: none;
}

.cke_top {
  border-bottom: 1px solid #e6edef !important;
}

@media (max-width: 575px) {
  .email-wrap .email-app-sidebar .main-menu > li a {
    line-height: 30px;
  }

  .email-wrap .email-right-aside .tab-content .email-wrapper h6 {
    font-size: 12px;
  }

  .email-wrap .email-right-aside .tab-content .email-wrapper .right-download {
    font-size: 12px;
  }
}

/**=================================
    13.  Email Application  CSS Ends
====================================**/

/**=====================
     14. Chat CSS Start
==========================**/

.chat-box .chat-menu {
  max-width: 340px;
}

.chat-box .people-list .search {
  position: relative;
}

.chat-box .people-list .search .form-control {
  border: 1px solid #e6edef;
}

.chat-box .people-list .search .form-control::-webkit-input-placeholder {
  color: #59667a;
}

.chat-box .people-list .search .form-control::-moz-placeholder {
  color: #59667a;
}

.chat-box .people-list .search .form-control:-ms-input-placeholder {
  color: #59667a;
}

.chat-box .people-list .search .form-control::-ms-input-placeholder {
  color: #59667a;
}

.chat-box .people-list .search .form-control::placeholder {
  color: #59667a;
}

.chat-box .people-list .search i {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 14px;
  color: #e6edef;
}

.chat-box .people-list ul {
  padding: 0;
}

.chat-box .people-list ul li {
  padding-bottom: 20px;
}

.chat-box .people-list ul li:last-child {
  padding-bottom: 0;
}

.chat-box .user-image {
  float: left;
  width: 52px;
  height: 52px;
  margin-right: 5px;
}

.chat-box .about {
  float: left;
  margin-top: 5px;
  padding-left: 10px;
}

.chat-box .about .name {
  color: #2c323f;
  letter-spacing: 1px;
  font-weight: 600;
}

.chat-box .status {
  color: #999;
  letter-spacing: 1px;
  font-size: 12px;
  margin-top: 5px;
}

.chat-box .status .chat-status {
  font-weight: 600;
  color: #242934;
}

.chat-box .status p {
  font-size: 14px;
}

.chat-box .chat-right-aside .chat .chat-header {
  padding: 15px;
  border-bottom: 1px solid #e6edef;
}

.chat-box .chat-right-aside .chat .chat-header img {
  float: left;
  width: 50px;
  -webkit-box-shadow: 1px 1px 4px 1px #e6edef;
  box-shadow: 1px 1px 4px 1px #e6edef;
}

.chat-box .chat-right-aside .chat .chat-header .chat-menu-icons {
  margin-top: 15px;
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li {
  margin-right: unset;
}

.chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
  color: rgba(36, 41, 52, 0.7);
  font-size: 25px;
  cursor: pointer;
}

.chat-box
  .chat-right-aside
  .chat
  .chat-header
  .chat-menu-icons
  li:nth-child(n + 2) {
  margin-left: 24px;
}

.chat-box .chat-right-aside .chat .chat-msg-box {
  padding: 20px;
  overflow-y: auto;
  height: 560px;
  margin-bottom: 90px;
}

.chat-box .chat-right-aside .chat .chat-msg-box .chat-user-img {
  margin-top: -35px;
}

.chat-box .chat-right-aside .chat .chat-msg-box .message-data {
  margin-bottom: 10px;
}

.chat-box .chat-right-aside .chat .chat-msg-box .message-data-time {
  letter-spacing: 1px;
  font-size: 12px;
  color: #59667a;
  font-weight: 600;
}

.chat-box .chat-right-aside .chat .chat-msg-box .message {
  color: #999;
  padding: 20px;
  line-height: 1.9;
  letter-spacing: 1px;
  font-size: 14px;
  margin-bottom: 30px;
  width: 50%;
  position: relative;
}

.chat-box .chat-right-aside .chat .chat-msg-box .my-message {
  border: 1px solid #e6edef;
  border-radius: 10px;
  border-top-left-radius: 0;
}

.chat-box .chat-right-aside .chat .chat-msg-box .other-message {
  border: 1px solid #e6edef;
  border-radius: 10px;
  border-top-right-radius: 0;
}

.chat-box .chat-right-aside .chat .chat-message {
  padding: 20px;
  border-top: 1px solid #fafdff;
  position: absolute;
  width: calc(100% - 15px);
  bottom: 0;
}

.chat-box .chat-right-aside .chat .chat-message .smiley-box {
  background: #efefef;
  padding: 10px;
  display: block;
  border-radius: 4px;
  margin-right: 0.5rem;
}

.chat-box .chat-right-aside .chat .chat-message .text-box {
  position: relative;
}

.chat-box .chat-right-aside .chat .chat-message .text-box .input-txt-bx {
  height: 50px;
  border: 2px solid #78266a;
  padding-left: 18px;
  font-size: 12px;
  letter-spacing: 1px;
}

.chat-box .chat-right-aside .chat .chat-message .text-box i {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  color: #e6edef;
  cursor: pointer;
}

.chat-box .chat-right-aside .chat .chat-message .text-box .btn {
  font-size: 16px;
  font-weight: 500;
}

.chat-box .chat-menu {
  right: 10px;
  opacity: 0;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
  visibility: hidden;
  top: 95px;
  position: absolute;
  z-index: 9;
  background-color: #fff;
  border: 1px solid #e6edef;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  padding-right: unset;
}

.chat-box .chat-menu .tab-pane {
  padding: 0 15px;
}

.chat-box .chat-menu ul li .about .status i {
  font-size: 10px;
}

.chat-box .chat-menu .user-profile {
  margin-top: 30px;
}

.chat-box .chat-menu .user-profile .user-content h5 {
  margin: 15px 0;
  font-weight: 600;
}

.chat-box .chat-menu .user-profile .user-content p {
  font-size: 14px;
}

.chat-box .chat-menu .user-profile .user-content .social-list li {
  display: inline-block;
}

.chat-box .chat-menu .user-profile .user-content .social-list li a {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: rgba(36, 105, 92, 0.08);
  color: #78266a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.chat-box .chat-menu .user-profile .user-content .social-list li:hover a {
  background-color: #78266a;
  color: #fff;
}

.chat-box
  .chat-menu
  .user-profile
  .user-content
  .social-list
  li:nth-child(n + 2) {
  margin-left: 5px;
}

.chat-box .chat-menu .user-profile .image {
  position: relative;
}

.chat-box .chat-menu .user-profile .image .icon-wrapper {
  position: absolute;
  bottom: 0;
  left: 55%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  overflow: hidden;
  margin: 0 auto;
  font-size: 14px;
  -webkit-box-shadow: 1px 1px 3px 1px #e6edef;
  box-shadow: 1px 1px 3px 1px #e6edef;
}

.chat-box .chat-menu .user-profile .image .icon-wrapper i {
  color: #78266a;
}

.chat-box .chat-menu .user-profile .image .avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.chat-box .chat-menu .user-profile .image .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #e6edef;
}

.chat-box .chat-menu .user-profile .border-right {
  border-right: 1px solid #e6edef;
}

.chat-box .chat-menu .user-profile .follow {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #e6edef;
}

.chat-box .chat-menu .user-profile .follow .follow-num {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  line-height: 1;
}

.chat-box .chat-menu .user-profile .follow span {
  color: #999;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
}

.chat-box .chat-menu .user-profile .digits {
  border-top: 1px solid #e6edef;
  margin-top: 15px;
  padding-top: 15px;
}

.chat-box .chat-menu .user-profile .digits p {
  margin-bottom: unset;
}

.chat-box .chat-menu .user-profile .social-media a {
  color: #59667a;
  font-size: 15px;
  padding: 0 7px;
}

.chat-box .chat-menu .user-profile .chat-profile-contact p {
  font-size: 14px;
  color: #999;
}

.chat-box .chat-menu .nav {
  margin-bottom: 20px;
}

.chat-box .chat-menu .nav-tabs .nav-item {
  width: 33.33%;
}

.chat-box .chat-menu .nav-tabs .nav-item a {
  padding: 15px !important;
  color: #59667a !important;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.chat-box .chat-menu .nav-tabs .nav-item .material-border {
  border-width: 1px;
  border-color: #78266a;
}

.chat-box .chat-menu .nav-tabs .nav-item .nav-link.active {
  color: #78266a !important;
}

.chat-box .chat-menu.show {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  padding-bottom: 25px;
  position: absolute;
  top: 60px;
}

.chat-box .chat-history .call-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 300px;
  min-height: 650px;
  height: 100%;
}

.chat-box .chat-history .call-content > div {
  width: 100%;
  height: 100%;
  padding: 30px;
}

.chat-box .chat-history .total-time h2 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #999;
}

.chat-box .chat-history .receiver-img {
  margin-top: 55px;
}

.chat-box .chat-history .receiver-img img {
  border-radius: 5px;
}

.chat-box .chat-history .call-icons {
  margin-bottom: 35px;
}

.chat-box .chat-history .call-icons ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.chat-box .chat-history .call-icons ul li {
  width: 55px;
  height: 55px;
  border: 1px solid #e6edef;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.chat-box .chat-history .call-icons ul li + li {
  margin-left: 10px;
}

.chat-box .chat-history .call-icons ul li a {
  color: #999;
  font-size: 22px;
}

.chat-box .chat-history .caller-img {
  height: 100%;
}

.chat-left-aside > .media {
  margin-bottom: 15px;
}

.chat-left-aside .people-list {
  height: 625px;
}

.chat-left-aside ul li {
  position: relative;
}

.status-circle {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 44px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.away {
  background-color: #e2c636;
}

.online {
  background-color: #1b4c43;
}

.offline {
  background-color: #d22d3d;
}

.chat-container .aside-chat-left {
  width: 320px;
}

.chat-container .chat-right-aside {
  width: 320px;
}

.call-chat-sidebar {
  max-width: 320px;
}

.call-chat-sidebar .card .card-body,
.chat-body .card .card-body {
  padding: 15px;
  margin-bottom: 15px;
}

.chart-widget .chart-widget-top h4 {
  font-weight: 600;
}

.chart-widget .chart-widget-top .num i {
  margin-left: 5px;
}

.apexcharts-menu {
  border-color: #e6edef !important;
}

@media (max-width: 1366px) {
  .chat-box .chat-right-aside .chat .chat-message {
    bottom: 0;
  }
}

@media (max-width: 360px) {
  .chat-box .chat-menu {
    width: 220px;
  }
}

/**=====================
    14.  Chat CSS Ends
==========================**/

/**=====================
     15. Alert CSS start
==========================**/

.notify-alert .close {
  background: unset;
  border: none;
  font-size: 25px;
}

.alert {
  border-radius: 0.15rem;
  padding: 15px;
}

.alert svg {
  vertical-align: middle;
}

.alert .progress {
  margin-top: 15px;
}

.alert [data-notify="icon"] {
  margin-right: 10px;
  line-height: 20px;
  position: absolute;
}

.alert [data-notify="message"] {
  display: inline-block;
  width: 100%;
}

.alert i {
  margin-right: 5px;
  font-size: 16px;
}

.alert p {
  margin-bottom: 0;
}

.alert .close {
  opacity: 1;
}

.alert .close span {
  font-size: 24px;
  font-weight: 400;
  display: inline-block;
  border-radius: 3px;
  text-shadow: none;
  padding: 0 5px;
  padding-right: 0;
}

.alert-dismissible .close {
  padding: 10px 1.25rem;
  top: 4px;
  padding-right: 13px;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.alert-dismissible .close:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 1;
  color: inherit;
}

.card-body button:focus {
  outline: none;
}

.card-body .alert:last-child {
  margin-bottom: 0;
}

.card-body .alert svg {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  position: absolute;
  top: 18px;
}

.card-body .alert svg ~ p {
  padding-left: 20px;
}

strong {
  display: inline-block;
}

strong ~ p {
  display: inline-block;
}

.alert-primary {
  background-color: rgba(36, 105, 92, 0.8);
  border-color: rgba(36, 105, 92, 0.9);
  color: #fff;
}

.alert-primary .progress {
  height: 5px;
  background-color: #236559;
  border-radius: 0;
}

.alert-primary .progress-bar {
  background-color: #aae2d7;
}

.alert-primary .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-primary .alert-link {
  color: #78266a;
}

.alert-primary hr {
  border-top-color: #78266a;
}

.alert-primary.dark {
  background-color: #78266a;
  border-color: #78266a;
  color: #fafafa;
}

.alert-primary.dark .alert-link {
  color: #fff;
}

.alert-primary.outline,
.alert-primary.outline-2x {
  background-color: transparent;
  border-color: #78266a;
  color: #78266a;
}

.alert-primary.outline .btn-close,
.alert-primary.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-primary.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #78266a;
  color: #242934;
}

.alert-primary.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #78266a;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-primary.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #78266a;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-primary.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.alert-secondary {
  background-color: rgba(186, 137, 93, 0.8);
  border-color: rgba(186, 137, 93, 0.9);
  color: #fff;
}

.alert-secondary .progress {
  height: 5px;
  background-color: #b88659;
  border-radius: 0;
}

.alert-secondary .progress-bar {
  background-color: white;
}

.alert-secondary .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-secondary .alert-link {
  color: #ba895d;
}

.alert-secondary hr {
  border-top-color: #ba895d;
}

.alert-secondary.dark {
  background-color: #ba895d;
  border-color: #ba895d;
  color: #fafafa;
}

.alert-secondary.dark .alert-link {
  color: #fff;
}

.alert-secondary.outline,
.alert-secondary.outline-2x {
  background-color: transparent;
  border-color: #ba895d;
  color: #ba895d;
}

.alert-secondary.outline .btn-close,
.alert-secondary.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-secondary.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #ba895d;
  color: #242934;
}

.alert-secondary.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #ba895d;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-secondary.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #ba895d;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-secondary.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.alert-success {
  background-color: rgba(27, 76, 67, 0.8);
  border-color: rgba(27, 76, 67, 0.9);
  color: #fff;
}

.alert-success .progress {
  height: 5px;
  background-color: #1a4840;
  border-radius: 0;
}

.alert-success .progress-bar {
  background-color: #8fd7ca;
}

.alert-success .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-success .alert-link {
  color: #1b4c43;
}

.alert-success hr {
  border-top-color: #1b4c43;
}

.alert-success.dark {
  background-color: #1b4c43;
  border-color: #1b4c43;
  color: #fafafa;
}

.alert-success.dark .alert-link {
  color: #fff;
}

.alert-success.outline,
.alert-success.outline-2x {
  background-color: transparent;
  border-color: #1b4c43;
  color: #1b4c43;
}

.alert-success.outline .btn-close,
.alert-success.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-success.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #1b4c43;
  color: #242934;
}

.alert-success.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #1b4c43;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-success.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #1b4c43;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-success.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.alert-danger {
  background-color: rgba(210, 45, 61, 0.8);
  border-color: rgba(210, 45, 61, 0.9);
  color: #fff;
}

.alert-danger .progress {
  height: 5px;
  background-color: #ce2c3c;
  border-radius: 0;
}

.alert-danger .progress-bar {
  background-color: white;
}

.alert-danger .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-danger .alert-link {
  color: #d22d3d;
}

.alert-danger hr {
  border-top-color: #d22d3d;
}

.alert-danger.dark {
  background-color: #d22d3d;
  border-color: #d22d3d;
  color: #fafafa;
}

.alert-danger.dark .alert-link {
  color: #fff;
}

.alert-danger.outline,
.alert-danger.outline-2x {
  background-color: transparent;
  border-color: #d22d3d;
  color: #d22d3d;
}

.alert-danger.outline .btn-close,
.alert-danger.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-danger.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #d22d3d;
  color: #242934;
}

.alert-danger.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #d22d3d;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-danger.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #d22d3d;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-danger.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.alert-warning {
  background-color: rgba(226, 198, 54, 0.8);
  border-color: rgba(226, 198, 54, 0.9);
  color: #fff;
}

.alert-warning .progress {
  height: 5px;
  background-color: #e1c532;
  border-radius: 0;
}

.alert-warning .progress-bar {
  background-color: white;
}

.alert-warning .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-warning .alert-link {
  color: #e2c636;
}

.alert-warning hr {
  border-top-color: #e2c636;
}

.alert-warning.dark {
  background-color: #e2c636;
  border-color: #e2c636;
  color: #fafafa;
}

.alert-warning.dark .alert-link {
  color: #fff;
}

.alert-warning.outline,
.alert-warning.outline-2x {
  background-color: transparent;
  border-color: #e2c636;
  color: #e2c636;
}

.alert-warning.outline .btn-close,
.alert-warning.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-warning.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #e2c636;
  color: #242934;
}

.alert-warning.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #e2c636;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-warning.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #e2c636;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-warning.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.alert-info {
  background-color: rgba(113, 113, 113, 0.8);
  border-color: rgba(113, 113, 113, 0.9);
  color: #fff;
}

.alert-info .progress {
  height: 5px;
  background-color: #6e6e6e;
  border-radius: 0;
}

.alert-info .progress-bar {
  background-color: #f1f1f1;
}

.alert-info .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-info .alert-link {
  color: #717171;
}

.alert-info hr {
  border-top-color: #717171;
}

.alert-info.dark {
  background-color: #717171;
  border-color: #717171;
  color: #fafafa;
}

.alert-info.dark .alert-link {
  color: #fff;
}

.alert-info.outline,
.alert-info.outline-2x {
  background-color: transparent;
  border-color: #717171;
  color: #717171;
}

.alert-info.outline .btn-close,
.alert-info.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-info.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #717171;
  color: #242934;
}

.alert-info.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #717171;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-info.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #717171;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-info.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.alert-light {
  background-color: rgba(230, 237, 239, 0.8);
  border-color: rgba(230, 237, 239, 0.9);
  color: #fff;
  color: #2c323f;
  background-color: #f4f4f4;
}

.alert-light .progress {
  height: 5px;
  background-color: #e3ebed;
  border-radius: 0;
}

.alert-light .progress-bar {
  background-color: white;
}

.alert-light .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-light .alert-link {
  color: #e6edef;
  color: #2c323f;
}

.alert-light .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-light hr {
  border-top-color: #e6edef;
}

.alert-light.dark {
  background-color: #e6edef;
  border-color: #e6edef;
  color: #fafafa;
  color: #2c323f;
}

.alert-light.dark .alert-link {
  color: #fff;
  color: #2c323f;
}

.alert-light.outline,
.alert-light.outline-2x {
  background-color: transparent;
  border-color: #e6edef;
  color: #e6edef;
  color: #2c323f;
}

.alert-light.outline .btn-close,
.alert-light.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-light.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #e6edef;
  color: #242934;
}

.alert-light.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #e6edef;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-light.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #e6edef;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-light.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.alert-dark {
  background-color: rgba(44, 50, 63, 0.8);
  border-color: rgba(44, 50, 63, 0.9);
  color: #fff;
}

.alert-dark .progress {
  height: 5px;
  background-color: #2a303c;
  border-radius: 0;
}

.alert-dark .progress-bar {
  background-color: #a8b0c2;
}

.alert-dark .btn-close {
  -webkit-filter: brightness(1) invert(1);
  filter: brightness(1) invert(1);
}

.alert-dark .alert-link {
  color: #2c323f;
}

.alert-dark hr {
  border-top-color: #2c323f;
}

.alert-dark.dark {
  background-color: #2c323f;
  border-color: #2c323f;
  color: #fafafa;
}

.alert-dark.dark .alert-link {
  color: #fff;
}

.alert-dark.outline,
.alert-dark.outline-2x {
  background-color: transparent;
  border-color: #2c323f;
  color: #2c323f;
}

.alert-dark.outline .btn-close,
.alert-dark.outline-2x .btn-close {
  -webkit-filter: unset;
  filter: unset;
}

.alert-dark.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #2c323f;
  color: #242934;
}

.alert-dark.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #2c323f;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.alert-dark.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #2c323f;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.alert-dark.inverse .btn-close {
  -webkit-filter: unset;
  filter: unset;
  top: -3px;
}

.dismiss-text .alert .btn-close {
  background: unset;
  opacity: 1;
  top: -6px;
  right: 40px;
}

.dismiss-text .alert .btn-close span {
  display: inline-block;
  border-radius: 3px;
  text-shadow: none;
  padding: 0 5px;
}

.outline-2x {
  border-width: 2px;
  padding: 11px 1.25rem;
}

/**=====================
     15. Alert CSS end
==========================**/

/**=====================
     16. Popover CSS Start
==========================**/

.popover {
  border: 1px solid #e6edef;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.popover-header {
  background-color: rgba(36, 105, 92, 0.1);
  border-color: #e6edef;
  color: #78266a;
}

.popover-body {
  border-color: #e6edef;
  background-color: #fff;
}

.popover-main .btn-showcase a {
  color: #fff;
}

.popover-main .btn-showcase button:last-child {
  margin-right: 0;
}

/**=====================
     16. Popover CSS Ends
==========================**/

/**=====================
     17. List CSS Start
==========================**/

.list-group-item.active {
  background-color: #78266a;
  border-color: #78266a;
}

.list-group i {
  margin-right: 10px;
}

.list-group img {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

/**=====================
     17. List CSS Ends
==========================**/

/**=====================
    18. Switch  CSS Start
==========================**/

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.switch input:checked + .switch-state {
  background-color: #78266a;
}

.switch input:checked + .switch-state:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.switch input:focus + .switch-state {
  -webkit-box-shadow: 0 0 1px #78266a;
  box-shadow: 0 0 1px #78266a;
}

.switch-state {
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e6edef;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-state:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;
}

.icon-state .switch-state:after {
  content: "\efb8";
  font-family: IcoFont;
  left: 10px;
  position: absolute;
  top: 10px;
  color: #2c323f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 0.3;
}

.icon-state input:checked + .switch-state:after {
  content: "\efad";
  left: 36px;
  opacity: 1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-lg .switch {
  width: 75px;
  height: 40px;
}

.switch-lg .switch .switch-state:before {
  height: 30px;
  width: 30px;
  left: 7px;
  bottom: 5px;
  top: 4px;
}

.switch-sm .switch {
  width: 25px;
  height: 16px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.switch-sm .switch .switch-state:before {
  height: 8px;
  width: 8px;
  left: 4px;
  bottom: 4px;
}

.switch-sm input:checked + .switch-state:before {
  left: -13px;
}

.switch-lg.icon-state .switch .switch-state:after {
  left: 12px;
  font-size: 18px;
}

.switch-lg.icon-state .switch input:checked + .switch-state:after {
  left: 47px;
}

.switch-lg.icon-state .switch input:checked + .switch-state:before {
  -webkit-transform: translateX(32px);
  transform: translateX(32px);
}

.switch-sm.icon-state .switch .switch-state:after {
  top: 4px;
  left: 4px;
  font-size: 8px;
}

.switch-sm.icon-state .switch input:checked + .switch-state:after {
  left: 13px;
  top: 4px;
}

.switch-outline .switch-state {
  background-color: #fff;
  border: 1px solid #78266a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-outline .switch-state:before {
  background-color: #e6edef;
  bottom: 3px;
  color: #fff;
}

.switch-outline .switch input {
  display: none;
}

.switch-outline .switch input:checked + .switch-state {
  background-color: transparent;
  border: 1px solid #78266a;
}

.switch-outline .switch input:checked + .switch-state:before {
  background-color: #78266a;
  bottom: 3px;
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.switch-outline .switch input:checked + .switch-state:after {
  color: #fff;
}

.switch-outline .switch-state.bg-primary {
  border: 1px solid #78266a;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-primary {
  background-color: transparent !important;
  border: 1px solid #78266a;
}

.switch-outline .switch input:checked + .switch-state.bg-primary:before {
  background-color: #78266a;
}

.switch-outline .switch-state.bg-dark {
  border: 1px solid #2c323f;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-dark {
  background-color: transparent !important;
  border: 1px solid #2c323f;
}

.switch-outline .switch input:checked + .switch-state.bg-dark:before {
  background-color: #2c323f;
}

.switch-outline .switch-state.bg-warning {
  border: 1px solid #e2c636;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-warning {
  background-color: transparent !important;
  border: 1px solid #e2c636;
}

.switch-outline .switch input:checked + .switch-state.bg-warning:before {
  background-color: #e2c636;
}

.switch-outline .switch-state.bg-success {
  border: 1px solid #1b4c43;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-success {
  background-color: transparent !important;
  border: 1px solid #1b4c43;
}

.switch-outline .switch input:checked + .switch-state.bg-success:before {
  background-color: #1b4c43;
}

.switch-outline .switch-state.bg-info {
  border: 1px solid #717171;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-info {
  background-color: transparent !important;
  border: 1px solid #717171;
}

.switch-outline .switch input:checked + .switch-state.bg-info:before {
  background-color: #717171;
}

.switch-outline .switch-state.bg-danger {
  border: 1px solid #d22d3d;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-danger {
  background-color: transparent !important;
  border: 1px solid #d22d3d;
}

.switch-outline .switch input:checked + .switch-state.bg-danger:before {
  background-color: #d22d3d;
}

.switch-outline .switch-state.bg-primary {
  border: 1px solid #78266a;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-primary {
  background-color: transparent !important;
  border: 1px solid #78266a;
}

.switch-outline .switch input:checked + .switch-state.bg-primary:before {
  background-color: #78266a;
}

.switch-outline .switch-state.bg-secondary {
  border: 1px solid #ba895d;
  background-color: transparent !important;
}

.switch-outline .switch input:checked + .switch-state.bg-secondary {
  background-color: transparent !important;
  border: 1px solid #ba895d;
}

.switch-outline .switch input:checked + .switch-state.bg-secondary:before {
  background-color: #ba895d;
}

.switch-showcase {
  margin-bottom: -8px;
}

.switch-showcase .media {
  line-height: 1;
}

/**=====================
     18. Switch  CSS Ends
==========================**/

/**=====================
   19. Mega option CSS Start
==========================**/

.mega-inline {
  margin-bottom: -30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mega-inline .card {
  border: 1px solid #e6edef;
  -webkit-box-shadow: 0 0 11px 5px #e6edef;
  box-shadow: 0 0 11px 5px #e6edef;
}

.mega-inline .mega-title-badge {
  display: table;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.mega-inline .media .radio {
  margin-left: 3px;
}

.mega-vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.mega-vertical .card {
  -webkit-box-shadow: 0 0 11px 5px rgba(113, 113, 113, 0.08);
  box-shadow: 0 0 11px 5px rgba(113, 113, 113, 0.08);
}

.mega-vertical .mega-title-badge {
  display: table;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.mega-vertical .media .radio {
  margin-left: 3px;
}

.mega-title {
  font-weight: 600;
}

.mega-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.mega-horizontal .card {
  border: 1px solid #f3f3f3;
  -webkit-box-shadow: 0 0 11px 5px rgba(113, 113, 113, 0.08);
  box-shadow: 0 0 11px 5px rgba(113, 113, 113, 0.08);
}

.mega-horizontal .mega-title-badge {
  display: table;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.mega-horizontal .media .radio {
  margin-left: 3px;
}

.plain-style .card {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.border-style .card {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e6edef;
}

.offer-style .card {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px dashed #e6edef;
}

.rating-star-wrapper {
  color: #797979;
}

/**=====================
     19. Mega option CSS Ends
==========================**/

/**=====================
    20.  Touchspin  CSS Start
==========================**/

.bootstrap-touchspin input.touchspin {
  padding: 0 12px;
}

.bootstrap-touchspin .input-group .btn {
  padding: 10px 20px;
}

.bootstrap-touchspin
  .touchspin-vertical-tab
  .input-group
  .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}

.bootstrap-touchspin
  .touchspin-vertical-tab
  .input-group
  .input-group-btn-vertical
  .bootstrap-touchspin-down {
  margin-top: -2px;
}

.bootstrap-touchspin
  .touchspin-vertical-tab
  .input-group
  .input-group-btn-vertical
  i {
  position: absolute;
  top: 4px;
  left: 7px;
  font-size: 10px;
  font-weight: normal;
}

.bootstrap-touchspin
  .touchspin-vertical-tab
  .input-group
  .input-group-btn-vertical
  > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 9px;
  margin-left: -1px;
  position: relative;
}

.bootstrap-touchspin .input-group {
  font-family: "Rubik", sans-serif, sans-serif;
}

.bootstrap-touchspin .input-group .btn {
  border-radius: 0 !important;
}

.bootstrap-touchspin .dropdown-basic .dropdown .dropbtn {
  padding: 9px 30px;
}

.bootstrap-touchspin .dropdown-basic .dropdown .dropdown-content {
  z-index: 9999;
}

/**=====================
     20. Touchspin  CSS Ends
==========================**/

/**=====================
     21. Select 2 CSS Start
==========================**/

.select2 {
  max-width: 100%;
}

.add-post #cke_text-box {
  border: 1px solid #efefef;
}

.add-post form .m-checkbox-inline label {
  margin-bottom: 0;
}

.add-post form .form-group {
  margin-bottom: 30px;
}

.add-post form .form-group .form-control {
  border: 1px solid #efefef;
}

.add-post form .form-group .col-form-label {
  padding-bottom: 0;
  padding-top: 0;
}

.add-post form .form-group .col-form-label .select2-container--default {
  margin-top: 10px;
  max-width: 100%;
}

.add-post
  form
  .form-group
  .col-form-label
  .select2-container--default
  .selection
  .select2-selection
  .select2-search__field {
  padding: 2px;
}

.add-post
  form
  .form-group
  .col-form-label
  .select2-container--default
  .select2-selection--multiple {
  border: 1px solid #efefef;
  max-width: 100%;
}

.add-post
  form
  .form-group
  .col-form-label
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  margin-top: 6px !important;
}

.add-post .dropzone {
  margin-bottom: 30px;
}

.select2-drpdwn .select2-selection {
  border-radius: 0 !important;
  border-color: #e6edef !important;
  height: 40px !important;
  padding: 5px;
}

.select2-drpdwn .form-control {
  border-radius: 5px;
}

.select2-drpdwn .form-control-primary {
  border-color: #78266a;
  color: #78266a;
}

.select2-drpdwn .form-control-secondary {
  border-color: #ba895d;
  color: #ba895d;
}

.select2-drpdwn .form-control-success {
  border-color: #1b4c43;
  color: #1b4c43;
}

.select2-drpdwn .form-control-info {
  border-color: #717171;
  color: #717171;
}

.select2-drpdwn .form-control-warning {
  border-color: #e2c636;
  color: #e2c636;
}

.select2-drpdwn .form-control-danger {
  border-color: #d22d3d;
  color: #d22d3d;
}

.select2-drpdwn .form-control-inverse {
  border-color: #2c323f;
  color: #2c323f;
}

.select2-drpdwn .form-control-primary-fill {
  background-color: #78266a;
  color: #fff;
}

.select2-drpdwn .form-control-primary-fill:focus {
  background-color: #78266a;
}

.select2-drpdwn .form-control-secondary-fill {
  background-color: #ba895d;
  color: white;
}

.select2-drpdwn .form-control-secondary-fill:focus {
  background-color: #ba895d;
}

.select2-drpdwn .form-control-success-fill {
  background-color: #1b4c43;
  color: #fff;
}

.select2-drpdwn .form-control-success-fill:focus {
  background-color: #1b4c43;
}

.select2-drpdwn .form-control-info-fill {
  background-color: #717171;
  color: #fff;
}

.select2-drpdwn .form-control-info-fill:focus {
  background-color: #717171;
}

.select2-drpdwn .form-control-warning-fill {
  background-color: #e2c636;
  color: #fff;
}

.select2-drpdwn .form-control-warning-fill:focus {
  background-color: #e2c636;
}

.select2-drpdwn .form-control-danger-fill {
  background-color: #d22d3d;
  color: #fff;
}

.select2-drpdwn .form-control-danger-fill:focus {
  background-color: #d22d3d;
}

.select2-drpdwn .form-control-inverse-fill {
  background-color: #2c323f;
  color: #fff;
}

.select2-drpdwn .form-control-inverse-fill:focus {
  background-color: #2c323f;
}

.selection .select2-selection {
  font-family: "Rubik", sans-serif, sans-serif;
  border-radius: 5px !important;
}

.selection .select2-selection .select2-search__field {
  margin-top: 0;
  padding: 0px 0 0 15px;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice {
  padding: 2px 6px !important;
  margin-top: 0 !important;
  background-color: #78266a !important;
  border-color: #17433b !important;
  color: #fff;
  margin-right: 8px !important;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #fff !important;
  float: right;
  margin-left: 0.5rem;
}

.select2-container--classic
  .select2-results__option--highlighted[aria-selected] {
  background-color: #e0e0e0 !important;
  color: #424242 !important;
}

.select2-container--classic
  .select2-results__options
  .select2-results__option[aria-selected="true"] {
  color: #fff !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 7px !important;
  right: 10px !important;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  padding: 2px 6px !important;
  margin-top: 0 !important;
  background-color: #78266a !important;
  border-color: #17433b !important;
  color: #fff;
  margin-right: 8px !important;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #fff !important;
  float: right;
  margin-left: 0.5rem;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #e0e0e0 !important;
  color: #424242 !important;
}

.select2-container--default
  .select2-results__options
  .select2-results__option[aria-selected="true"] {
  background-color: #78266a !important;
  color: #fff !important;
}

.select2-results > .select2-results__options {
  border: 1px solid #e6edef;
}

/**=====================
     21. Select 2 CSS Ends
==========================**/

/**=====================
     22. User profile css start
==========================**/

.user-profile .follow .follow-num {
  font-size: 18px;
  color: #2b2b2b;
  font-family: "Rubik", sans-serif, sans-serif;
  font-weight: 600;
}

.user-profile .follow span {
  color: #59667a;
}

.user-profile .follow ul.follow-list {
  border-top: 1px solid #e6edef;
}

.user-profile .follow ul.follow-list li {
  display: inline-block;
  padding-top: 15px;
}

.user-profile .follow ul.follow-list li:nth-child(n + 2) {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #e6edef;
}

.user-profile .profile-img-style {
  padding: 30px;
}

.user-profile .profile-img-style .img-container {
  margin-top: 30px;
}

.user-profile .profile-img-style .user-name {
  font-size: 18px;
  color: #2b2b2b;
  margin: 0;
  font-weight: 600;
}

.user-profile .profile-img-style p {
  line-height: 30px;
  font-size: 14px;
  color: #898989;
  margin-bottom: 0;
}

.user-profile .profile-img-style .media img {
  width: 50px;
}

.user-profile .like-comment a {
  color: #898989;
}

.user-profile .like-comment a:hover {
  color: #d22d3d;
}

.user-profile .like-comment span {
  font-family: "Rubik", sans-serif, sans-serif;
}

.user-profile .social-media ul.user-list-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 20px;
}

.user-profile .social-media ul.user-list-social li:nth-child(n + 2) {
  margin-left: 5px;
}

.user-profile .social-media ul.user-list-social li a {
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(36, 105, 92, 0.08);
}

.user-profile .social-media ul.user-list-social li a i {
  font-size: 14px;
  color: #78266a;
}

.user-profile .social-media ul.user-list-social li:hover a {
  background-color: #78266a;
}

.user-profile .social-media ul.user-list-social li:hover a i {
  color: #fff;
}

.user-profile .profile-header {
  height: 420px;
  padding: 30px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.user-profile .profile-header .profile-img-wrrap {
  display: none;
}

.user-profile .profile-header .userpro-box {
  background-color: #fff;
  padding: 30px;
  max-width: 380px;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.user-profile .profile-header .userpro-box .img-wrraper {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.user-profile .profile-header .userpro-box .avatar {
  width: 100px;
  height: 100px;
  max-width: 155px;
  max-height: 155px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  border: 7px solid rgba(36, 105, 92, 0.08);
  margin-bottom: 15px;
}

.user-profile .profile-header .userpro-box .avatar img {
  width: 100%;
  height: 100%;
}

.user-profile .profile-header .userpro-box .icon-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  overflow: hidden;
  margin: 0 auto;
  font-size: 17px;
  -webkit-box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1);
  box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1);
}

.user-profile .profile-header .userpro-box .icon-wrapper i {
  color: #78266a;
}

.user-profile .profile-header .userpro-box h4 {
  color: #2b2b2b;
  font-weight: 600;
  font-size: 20px;
}

.user-profile .profile-header .userpro-box h6 {
  font-size: 14px;
  text-transform: uppercase;
  color: #999;
  margin-bottom: 15px;
}

.user-profile .profile-post {
  padding: 30px;
}

.user-profile .profile-post .post-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

.user-profile .profile-post .post-header .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.user-profile .profile-post .post-header .media img {
  width: 60px;
}

.user-profile .profile-post .post-header .media h5 {
  color: #2b2b2b;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 3px;
}

.user-profile .profile-post .post-header .media h6 {
  color: #999;
  font-size: 14px;
  margin-bottom: 0;
}

.user-profile .profile-post .post-header .post-setting i {
  color: #999;
  font-size: 22px;
}

.user-profile .profile-post .post-body p {
  color: #999;
}

.user-profile .profile-post .post-body .post-react {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.user-profile .profile-post .post-body .post-react ul li {
  display: inline-block;
}

.user-profile .profile-post .post-body .post-react ul li:nth-child(n + 2) {
  margin-left: -20px;
}

.user-profile .profile-post .post-body .post-react ul li img {
  width: 30px;
  height: 30px;
}

.user-profile .profile-post .post-body .post-react h6 {
  font-size: 14px;
  color: #717171;
  margin-bottom: 0;
  margin-left: 15px;
}

.user-profile .profile-post .post-body .post-comment li {
  display: inline-block;
  color: #717171;
}

.user-profile .profile-post .post-body .post-comment li label {
  margin-bottom: 0;
  font-weight: 500;
}

.user-profile .profile-post .post-body .post-comment li label a {
  color: #717171;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
}

.user-profile .profile-post .post-body .post-comment li label a svg {
  width: 14px;
  height: 14px;
}

.user-profile .profile-post .post-body .post-comment li label a span {
  margin-left: 10px;
}

.user-profile .profile-post .post-body .post-comment li:nth-child(n + 2) {
  margin-left: 15px;
}

.user-profile .post-about ul {
  margin-bottom: 20px;
}

.user-profile .post-about ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.user-profile .post-about ul li .icon {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: rgba(36, 105, 92, 0.08);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 15px;
}

.user-profile .post-about ul li .icon svg {
  width: 18px;
  height: 18px;
  color: #78266a;
}

.user-profile .post-about ul li h5 {
  color: #2b2b2b;
  text-transform: capitalize;
}

.user-profile .post-about ul li p {
  font-size: 12px;
  line-height: 1;
  text-transform: capitalize;
}

.user-profile .post-about ul li:nth-child(n + 2) {
  margin-top: 20px;
}

.edit-profile .profile-title {
  border-bottom: 1px solid #e6edef;
  margin-bottom: 15px;
  padding-bottom: 20px;
}

.edit-profile .profile-title .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.edit-profile .profile-title .media .media-body {
  margin-left: 25px;
}

.edit-profile .card .card-header h4 {
  font-weight: 600;
}

.edit-profile .card .card-body h3 {
  font-weight: 600;
}

.edit-profile .table > :not(:last-child) > :last-child > * {
  border: 1px solid #e6edef;
}

/**============================
     22. User profile css Ends
==============================**/

/**=====================
     23. Progress CSS Start
==========================**/

.progress-showcase .progress {
  margin-bottom: 10px;
}

.progress-showcase .progress:last-child {
  margin-bottom: 0;
}

.sm-progress-bar {
  height: 5px;
}

.lg-progress-bar {
  height: 20px;
}

.progress-animate {
  overflow: inherit;
  background-color: transparent;
  margin-bottom: 2px;
}

.progress-animate span {
  position: absolute;
  bottom: 15px;
  right: -10px;
  font-weight: 600;
  font-size: 15px;
}

@-webkit-keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.progress-gradient-primary {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#78266a)
  );
  background-image: linear-gradient(to right, transparent, #78266a);
  position: relative;
}

.progress-gradient-primary:hover {
  background-size: 100%;
}

.progress-gradient-primary::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #78266a;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(36, 105, 92, 0.36);
  box-shadow: 0 0 17px 4px rgba(36, 105, 92, 0.36);
}

.progress-gradient-primary .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #78266a;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress-gradient-secondary {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#ba895d)
  );
  background-image: linear-gradient(to right, transparent, #ba895d);
  position: relative;
}

.progress-gradient-secondary:hover {
  background-size: 100%;
}

.progress-gradient-secondary::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #ba895d;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(186, 137, 93, 0.36);
  box-shadow: 0 0 17px 4px rgba(186, 137, 93, 0.36);
}

.progress-gradient-secondary .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #ba895d;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress-gradient-success {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#1b4c43)
  );
  background-image: linear-gradient(to right, transparent, #1b4c43);
  position: relative;
}

.progress-gradient-success:hover {
  background-size: 100%;
}

.progress-gradient-success::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #1b4c43;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(27, 76, 67, 0.36);
  box-shadow: 0 0 17px 4px rgba(27, 76, 67, 0.36);
}

.progress-gradient-success .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #1b4c43;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress-gradient-danger {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#d22d3d)
  );
  background-image: linear-gradient(to right, transparent, #d22d3d);
  position: relative;
}

.progress-gradient-danger:hover {
  background-size: 100%;
}

.progress-gradient-danger::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #d22d3d;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(210, 45, 61, 0.36);
  box-shadow: 0 0 17px 4px rgba(210, 45, 61, 0.36);
}

.progress-gradient-danger .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #d22d3d;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress-gradient-info {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#717171)
  );
  background-image: linear-gradient(to right, transparent, #717171);
  position: relative;
}

.progress-gradient-info:hover {
  background-size: 100%;
}

.progress-gradient-info::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #717171;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(113, 113, 113, 0.36);
  box-shadow: 0 0 17px 4px rgba(113, 113, 113, 0.36);
}

.progress-gradient-info .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #717171;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress-gradient-light {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#e6edef)
  );
  background-image: linear-gradient(to right, transparent, #e6edef);
  position: relative;
}

.progress-gradient-light:hover {
  background-size: 100%;
}

.progress-gradient-light::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #e6edef;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(230, 237, 239, 0.36);
  box-shadow: 0 0 17px 4px rgba(230, 237, 239, 0.36);
}

.progress-gradient-light .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #e6edef;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress-gradient-dark {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#2c323f)
  );
  background-image: linear-gradient(to right, transparent, #2c323f);
  position: relative;
}

.progress-gradient-dark:hover {
  background-size: 100%;
}

.progress-gradient-dark::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #2c323f;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(44, 50, 63, 0.36);
  box-shadow: 0 0 17px 4px rgba(44, 50, 63, 0.36);
}

.progress-gradient-dark .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #2c323f;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress-gradient-warning {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    to(#e2c636)
  );
  background-image: linear-gradient(to right, transparent, #e2c636);
  position: relative;
}

.progress-gradient-warning:hover {
  background-size: 100%;
}

.progress-gradient-warning::after {
  position: absolute;
  content: "";
  top: -3px;
  right: -1px;
  background-color: #e2c636;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 17px 4px rgba(226, 198, 54, 0.36);
  box-shadow: 0 0 17px 4px rgba(226, 198, 54, 0.36);
}

.progress-gradient-warning .animate-circle {
  position: absolute;
  top: -11px;
  right: -8px;
  height: 25px;
  width: 25px;
  border: 5px solid #e2c636;
  border-radius: 70px;
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/**=====================
     23. Progress CSS Ends
==========================**/

/**=====================
    24. According CSS start
==========================**/

.default-according .card {
  margin-bottom: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.default-according .card + .card {
  margin-top: 10px;
}

.default-according .card .btn-link {
  font-weight: 500;
  color: #242934;
  font-size: 16px;
}

.default-according .card .btn-link:focus,
.default-according .card .btn-link:hover {
  text-decoration: none;
}

.default-according .card .card-header {
  padding: 1rem 1.25rem;
  border-bottom: 1px solid #e6edef;
}

.default-according .card .card-header i {
  position: initial;
  font-size: 20px;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 5px;
}

.default-according .card .card-header h5 {
  margin-top: 2px;
}

.default-according .card .card-body {
  border-top: none !important;
  padding: 20px;
}

.default-according .card .card-body h5 {
  font-size: 14px;
  margin-bottom: 5px;
}

.default-according .card .card-body h6 {
  color: #999;
  font-size: 12px;
  margin-bottom: 5px;
}

.default-according .card .card-body ul.rating li {
  display: inline-block;
}

.default-according.style-1 button {
  width: 100%;
  text-align: left;
}

.default-according.style-1 button:before {
  right: 20px;
  position: absolute;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.default-according.style-1 button[aria-expanded="true"]:before {
  content: "";
  font-family: IcoFont;
}

.default-according.style-1 button[aria-expanded="false"]:before {
  content: "";
  font-family: IcoFont;
}

@media (max-width: 480px) {
  .default-according .card .card-header {
    padding-right: 32px;
  }

  .default-according .card .card-header h5 {
    font-size: 16px;
  }

  .default-according.style-1 button:before {
    right: 10px;
  }
}

/**=====================
     24. According CSS Ends
==========================**/

/**=====================
    25. Authentication CSS Start
==========================**/

.show-hide {
  position: absolute;
  top: 18px;
  right: 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-transform: capitalize;
  z-index: 2;
}

.show-hide span {
  cursor: pointer;
  font-size: 13px;
  color: #78266a;
}

.show-hide span .show-hide span:before {
  content: "hide";
}

.show-hide span.show:before {
  content: "show";
}

.needs-validation label {
  opacity: 0.7;
  margin-bottom: 10px;
}

.needs-validation .invalid-tooltip {
  top: -35px;
  right: 10px;
}

.login-card {
  background-color: rgba(36, 105, 92, 0.1);
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
  padding: 30px 12px;
}

.login-form {
  padding: 30px;
  width: 450px;
  background-color: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.login-form h4 {
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 22px;
}

.login-form h6 {
  font-size: 14px;
  margin-bottom: 25px;
  color: #999;
}

.login-form .btn {
  text-transform: uppercase;
  font-weight: 700;
  margin-left: auto;
  display: block;
}

.login-form p {
  font-weight: 600;
  text-align: center;
  margin-bottom: unset;
}

.login-form .form-group {
  position: relative;
}

.login-form .form-group label {
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.login-form .form-group .input-group-text {
  background-color: rgba(36, 105, 92, 0.1);
  border: none;
  color: #78266a;
}

.login-form .form-group input {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.login-form .form-group .form-control::-webkit-input-placeholder {
  font-weight: 400;
}

.login-form .form-group .form-control::-moz-placeholder {
  font-weight: 400;
}

.login-form .form-group .form-control:-ms-input-placeholder {
  font-weight: 400;
}

.login-form .form-group .form-control::-ms-input-placeholder {
  font-weight: 400;
}

.login-form .form-group .form-control::placeholder {
  font-weight: 400;
}

.login-form .form-group .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.login-form .form-group .checkbox {
  display: inline-block;
}

.login-form .form-group .checkbox label {
  padding-top: 0;
  margin-top: 0;
  color: #999;
}

.login-form .form-group .checkbox label span {
  color: rgba(36, 105, 92, 0.5);
}

.login-form .form-group .link {
  float: right;
  font-weight: 500;
}

.login-form .form-group .small-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.login-form .form-group .small-group .input-group:nth-child(n + 2) {
  margin-left: 10px;
}

.login-form ul.login-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.login-form ul.login-social li {
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: rgba(36, 105, 92, 0.08);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
}

.login-form ul.login-social li a {
  line-height: 1;
}

.login-form ul.login-social li a svg {
  width: 16px;
  height: auto;
}

.login-form ul.login-social li:nth-child(n + 2) {
  margin-left: 10px;
}

.login-form .login-social-title {
  position: relative;
  margin-bottom: 20px;
  z-index: 2;
}

.login-form .login-social-title::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #999;
  z-index: -1;
}

.login-form .login-social-title h5 {
  font-weight: 600;
  font-size: 16px;
  color: #999;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fff;
}

@media (max-width: 575px) {
  .login-form .form-group .link {
    float: unset;
  }

  .login-form .btn {
    margin-left: unset;
  }
}

@media (max-width: 480px) {
  .login-form {
    width: 100%;
  }
}

/**==========================
    25. Authentication CSS Ends
==========================**/

/**=====================
    26. Form CSS Start
==========================**/

.height-35 {
  height: 35px !important;
}

label {
  margin-bottom: 5px;
  font-weight: 600;
}

.form-group {
  margin-bottom: 20px;
}

.form-control,
.form-select {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
}

.form-control:focus,
.form-select:focus {
  border-color: #e6edef;
  background-color: #fff;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
  box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
}

.theme-form .form-control :focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
  box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
}

.theme-form.sm-form .form-group {
  margin-bottom: 14px;
}

.theme-form .form-group {
  margin-bottom: 20px;
}

.theme-form .form-group textarea {
  border-color: #efefef;
}

.theme-form .form-group textarea::-webkit-input-placeholder {
  color: #898989;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 100;
}

.theme-form .form-group input[type="text"],
.theme-form .form-group input[type="email"],
.theme-form .form-group input[type="search"],
.theme-form .form-group input[type="password"],
.theme-form .form-group input[type="number"],
.theme-form .form-group input[type="tel"],
.theme-form .form-group input[type="date"],
.theme-form .form-group input[type="datetime-local"],
.theme-form .form-group input[type="time"],
.theme-form .form-group input[type="datetime-local"],
.theme-form .form-group input[type="month"],
.theme-form .form-group input[type="week"],
.theme-form .form-group input[type="url"],
.theme-form .form-group input[type="file"],
.theme-form .form-group select {
  border-color: #e6edef;
  font-size: 14px;
  color: #898989;
}

.theme-form .form-group input[type="text"]::-webkit-input-placeholder,
.theme-form .form-group input[type="email"]::-webkit-input-placeholder,
.theme-form .form-group input[type="search"]::-webkit-input-placeholder,
.theme-form .form-group input[type="password"]::-webkit-input-placeholder,
.theme-form .form-group input[type="number"]::-webkit-input-placeholder,
.theme-form .form-group input[type="tel"]::-webkit-input-placeholder,
.theme-form .form-group input[type="date"]::-webkit-input-placeholder,
.theme-form .form-group input[type="datetime-local"]::-webkit-input-placeholder,
.theme-form .form-group input[type="time"]::-webkit-input-placeholder,
.theme-form .form-group input[type="datetime-local"]::-webkit-input-placeholder,
.theme-form .form-group input[type="month"]::-webkit-input-placeholder,
.theme-form .form-group input[type="week"]::-webkit-input-placeholder,
.theme-form .form-group input[type="url"]::-webkit-input-placeholder,
.theme-form .form-group input[type="file"]::-webkit-input-placeholder,
.theme-form .form-group select::-webkit-input-placeholder {
  color: #898989;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 100;
}

.theme-form .form-group select.form-control:not([size]):not([multiple]) {
  border-color: #efefef;
  font-size: 14px;
}

.theme-form .checkbox label {
  padding-left: 10px;
}

.theme-form .form-divider {
  position: relative;
  clear: both;
  height: 0;
  margin: 30px 0;
  border-top: 1px solid #efefef;
}

.theme-form .form-divider:before {
  position: absolute;
  top: -11px;
  left: 40%;
  margin-left: -10px;
  padding: 0 5px;
  content: "Or Signup With";
  color: #2b2b2b;
  background: #fff;
}

.theme-form .login-divider {
  position: relative;
  clear: both;
  height: 0;
  margin: 30px 0;
  border-top: 1px solid #efefef;
}

.theme-form .login-divider:before {
  position: absolute;
  top: -11px;
  left: 40%;
  margin-left: -10px;
  padding: 0 5px;
  color: #2b2b2b;
  background: #fff;
  content: "Or Login With";
}

.theme-form .btn-fb {
  background-color: #50598e;
  color: #fff;
  width: 100%;
}

.theme-form .btn-twitter {
  background-color: #6fa2d8;
  color: #fff;
  width: 100%;
}

.theme-form .btn-google {
  background-color: #c64e40;
  color: #fff;
  width: 100%;
}

.form-inline {
  display: -webkit-inline-box;
}

.form-inline .form-group {
  margin-right: 15px;
}

.form-inline .form-group .col-form-label {
  margin-right: 5px;
}

.search-form .form-group:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 25px;
  background: #e8ebf2;
  left: 82px;
  top: 50px;
}

.search-form .form-group:after {
  position: absolute;
  content: "\f002";
  font-family: FontAwesome;
  top: 50px;
  left: 53px;
  color: #8e8e8e;
}

.search-form input {
  border: 1px solid #e6edef;
  padding: 10px 10px 10px 70px;
  border-radius: 50px;
}

.form-label-align-right label {
  text-align: right;
  padding-top: 17px;
  font-weight: 500;
}

.custom-file-label {
  line-height: 1.8;
}

.custom-file-label::after {
  line-height: 1.8;
}

.input-group-text {
  border-color: #e6edef;
  font-weight: 500;
}

.dropdown-menu {
  border: 1px solid #e6edef;
}

.animated-modal .form-select {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

/**=====================
    26. Form CSS Ends
==========================**/

/**=====================
     27. Coming Soon CSS Start
==========================**/

.comingsoon-bgimg {
  /* background: url(../images/other-images/coming-soon-bg.jpg); */
  background-position: bottom;
  background-size: cover;
}

.comingsoon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  padding: 50px 0;
  background-color: rgba(36, 105, 92, 0.1);
}

.comingsoon .comingsoon-inner h5 {
  font-size: 22px;
  letter-spacing: 1px;
  color: #999;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 30px;
}

.comingsoon .comingsoon-inner .countdown {
  padding: 30px 0;
  border-top: 1px solid rgba(36, 105, 92, 0.1);
  border-bottom: 1px solid rgba(36, 105, 92, 0.1);
}

.comingsoon .comingsoon-inner .countdown .time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  color: #fff;
  font-weight: 500;
  width: 100px;
  height: 100px;
  font-size: 36px;
  background: #78266a;
}

.comingsoon .comingsoon-inner .countdown .title {
  padding-top: 13px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(36, 41, 52, 0.7);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.comingsoon .comingsoon-inner .countdown ul li {
  display: inline-block;
  text-transform: uppercase;
  margin: 0 20px;
}

.comingsoon .comingsoon-inner .countdown ul li span {
  display: block;
}

.comingsoon video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: 1s opacity;
  transition: 1s opacity;
}

/**=====================
    27. Coming Soon CSS Ends
==========================**/

/**=====================
     28. Radio CSS Start
==========================**/

.animate-chk label {
  line-height: 1.6;
  cursor: pointer;
}

.animate-chk label:last-child {
  margin-bottom: 0;
}

.checkbox_animated {
  cursor: pointer;
  position: relative;
  margin: 0 1rem 0 0;
}

.checkbox_animated:before {
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75),
    -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -webkit-transform: rotate(-45deg) scale(0, 0);
  transform: rotate(-45deg) scale(0, 0);
  content: "";
  position: absolute;
  left: 0.25rem;
  top: 0.225rem;
  z-index: 1;
  width: 0.75rem;
  height: 0.375rem;
  border: 2px solid #78266a;
  border-top-style: none;
  border-right-style: none;
}

.checkbox_animated:after {
  content: "";
  position: absolute;
  top: -0.125rem;
  left: 0;
  width: 1.3rem;
  height: 1.3rem;
  background: #fff;
  border: 2px solid #e6edef;
  cursor: pointer;
}

.checkbox_animated:checked:before {
  -webkit-transform: rotate(-45deg) scale(1, 1);
  transform: rotate(-45deg) scale(1, 1);
}

.radio_animated {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
}

.radio_animated:before {
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75),
    -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  content: "";
  position: absolute;
  top: 0;
  left: 0.125rem;
  z-index: 1;
  width: 0.75rem;
  height: 0.75rem;
  background: #78266a;
  border-radius: 50%;
}

.radio_animated:after {
  content: "";
  position: absolute;
  top: -0.25rem;
  left: -0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  background: #fff;
  border: 2px solid #e6edef;
  border-radius: 50%;
}

.radio_animated:checked:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 16px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 19px;
  height: 19px;
  left: 0;
  margin-left: -16px;
  border: 1px solid #e6edef;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #2b2b2b;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
}

.checkbox input[type="checkbox"]:focus {
  outline-offset: -2px;
}

.checkbox input[type="checkbox"]:focus + label::before {
  outline-offset: -2px;
}

.checkbox input[type="checkbox"]:checked + label::before {
  font-family: themify;
  content: "\e64c";
  text-align: center;
  line-height: 1.2;
}

.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #e6edef;
  cursor: not-allowed;
}

.checkbox .checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox .checkbox-inline {
  margin-top: 0;
}

.m-squar label::before {
  border-radius: 0;
}

.m-squar .checkbox-circle label::before {
  border-radius: 50%;
}

.m-squar .checkbox-inline {
  margin-top: 0;
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
  border-color: #78266a;
  color: #78266a;
}

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-secondary input[type="checkbox"]:checked + label::before {
  border-color: #ba895d;
  color: #ba895d;
}

.checkbox-secondary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-success input[type="checkbox"]:checked + label::before {
  border-color: #1b4c43;
  color: #1b4c43;
}

.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before {
  border-color: #d22d3d;
  color: #d22d3d;
}

.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-info input[type="checkbox"]:checked + label::before {
  border-color: #717171;
  color: #717171;
}

.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-light input[type="checkbox"]:checked + label::before {
  border-color: #e6edef;
  color: #e6edef;
}

.checkbox-light input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-dark input[type="checkbox"]:checked + label::before {
  border-color: #2c323f;
  color: #2c323f;
}

.checkbox-dark input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before {
  border-color: #e2c636;
  color: #e2c636;
}

.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-primary label:before {
  background-color: #78266a;
  border-color: #78266a;
  color: #fff;
}

.checkbox-solid-primary input[type="checkbox"]:checked + label::before {
  background-color: #78266a;
  border-color: #78266a;
  color: #fff;
}

.checkbox-solid-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-secondary label:before {
  background-color: #ba895d;
  border-color: #ba895d;
  color: #fff;
}

.checkbox-solid-secondary input[type="checkbox"]:checked + label::before {
  background-color: #ba895d;
  border-color: #ba895d;
  color: #fff;
}

.checkbox-solid-secondary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-success label:before {
  background-color: #1b4c43;
  border-color: #1b4c43;
  color: #fff;
}

.checkbox-solid-success input[type="checkbox"]:checked + label::before {
  background-color: #1b4c43;
  border-color: #1b4c43;
  color: #fff;
}

.checkbox-solid-success input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-danger label:before {
  background-color: #d22d3d;
  border-color: #d22d3d;
  color: #fff;
}

.checkbox-solid-danger input[type="checkbox"]:checked + label::before {
  background-color: #d22d3d;
  border-color: #d22d3d;
  color: #fff;
}

.checkbox-solid-danger input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-info label:before {
  background-color: #717171;
  border-color: #717171;
  color: #fff;
}

.checkbox-solid-info input[type="checkbox"]:checked + label::before {
  background-color: #717171;
  border-color: #717171;
  color: #fff;
}

.checkbox-solid-info input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-light label:before {
  background-color: #e6edef;
  border-color: #e6edef;
  color: #fff;
}

.checkbox-solid-light input[type="checkbox"]:checked + label::before {
  background-color: #e6edef;
  border-color: #e6edef;
  color: #fff;
}

.checkbox-solid-light input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-dark label:before {
  background-color: #2c323f;
  border-color: #2c323f;
  color: #fff;
}

.checkbox-solid-dark input[type="checkbox"]:checked + label::before {
  background-color: #2c323f;
  border-color: #2c323f;
  color: #fff;
}

.checkbox-solid-dark input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-solid-warning label:before {
  background-color: #e2c636;
  border-color: #e2c636;
  color: #fff;
}

.checkbox-solid-warning input[type="checkbox"]:checked + label::before {
  background-color: #e2c636;
  border-color: #e2c636;
  color: #fff;
}

.checkbox-solid-warning input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-primary label::before {
  border-color: #78266a;
}

.checkbox-secondary label::before {
  border-color: #ba895d;
}

.checkbox-success label::before {
  border-color: #1b4c43;
}

.checkbox-danger label::before {
  border-color: #d22d3d;
}

.checkbox-info label::before {
  border-color: #717171;
}

.checkbox-light label::before {
  border-color: #e6edef;
}

.checkbox-dark label::before {
  border-color: #2c323f;
}

.checkbox-warning label::before {
  border-color: #e2c636;
}

.m-checkbox-inline .checkbox {
  display: inline-block;
}

.m-checkbox-inline .radio {
  display: inline-block;
}

.m-checkbox-inline label {
  margin-right: 20px;
}

.radio label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
  cursor: pointer;
}

.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 19px;
  height: 19px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #e6edef;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}

.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 9px;
  height: 9px;
  left: 5px;
  top: 5px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #898989;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33),
    -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type="radio"] {
  opacity: 0;
}

.radio input[type="radio"]:focus + label::before {
  outline-offset: -2px;
}

.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}

.radio .radio-inline {
  margin-top: 0;
}

.radio-primary input[type="radio"] + label::before {
  border-color: #78266a;
}

.radio-primary input[type="radio"] + label::after {
  background-color: #78266a;
}

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #78266a;
}

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #78266a;
}

.radio-secondary input[type="radio"] + label::before {
  border-color: #ba895d;
}

.radio-secondary input[type="radio"] + label::after {
  background-color: #ba895d;
}

.radio-secondary input[type="radio"]:checked + label::before {
  border-color: #ba895d;
}

.radio-secondary input[type="radio"]:checked + label::after {
  background-color: #ba895d;
}

.radio-success input[type="radio"] + label::before {
  border-color: #1b4c43;
}

.radio-success input[type="radio"] + label::after {
  background-color: #1b4c43;
}

.radio-success input[type="radio"]:checked + label::before {
  border-color: #1b4c43;
}

.radio-success input[type="radio"]:checked + label::after {
  background-color: #1b4c43;
}

.radio-danger input[type="radio"] + label::before {
  border-color: #d22d3d;
}

.radio-danger input[type="radio"] + label::after {
  background-color: #d22d3d;
}

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #d22d3d;
}

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #d22d3d;
}

.radio-info input[type="radio"] + label::before {
  border-color: #717171;
}

.radio-info input[type="radio"] + label::after {
  background-color: #717171;
}

.radio-info input[type="radio"]:checked + label::before {
  border-color: #717171;
}

.radio-info input[type="radio"]:checked + label::after {
  background-color: #717171;
}

.radio-light input[type="radio"] + label::before {
  border-color: #e6edef;
}

.radio-light input[type="radio"] + label::after {
  background-color: #e6edef;
}

.radio-light input[type="radio"]:checked + label::before {
  border-color: #e6edef;
}

.radio-light input[type="radio"]:checked + label::after {
  background-color: #e6edef;
}

.radio-dark input[type="radio"] + label::before {
  border-color: #2c323f;
}

.radio-dark input[type="radio"] + label::after {
  background-color: #2c323f;
}

.radio-dark input[type="radio"]:checked + label::before {
  border-color: #2c323f;
}

.radio-dark input[type="radio"]:checked + label::after {
  background-color: #2c323f;
}

.radio-warning input[type="radio"] + label::before {
  border-color: #e2c636;
}

.radio-warning input[type="radio"] + label::after {
  background-color: #e2c636;
}

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #e2c636;
}

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #e2c636;
}

/**=====================
     28. Radio CSS Ends
==========================**/

/**=====================
     29. Form Input CSS Start
==========================**/

.icon-addon .addon-md .glyphicon,
.icon-addon .addon-md .fa {
  position: absolute;
  z-index: 2;
  left: 10px;
  font-size: 14px;
  width: 20px;
  margin-left: -2.5px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
}

.icon-addon .glyphicon,
.icon-addon .fa {
  position: absolute;
  z-index: 2;
  left: 10px;
  font-size: 14px;
  width: 20px;
  margin-left: -2.5px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
}

.input-group-square .input-group-text,
.input-group-square .form-control {
  border-radius: 0;
}

.input-group-solid .input-group-text,
.input-group-solid .form-control {
  background: #e6edef;
}

.input-group-air {
  -webkit-box-shadow: 0 3px 20px 0 #efefef;
  box-shadow: 0 3px 20px 0 #efefef;
}

.icon-addon {
  position: relative;
  color: #4c5667;
  display: block;
}

.icon-addon:after {
  display: table;
  content: "";
  clear: both;
}

.icon-addon:before {
  display: table;
  content: "";
}

.icon-addon .addon-md .form-control {
  padding-left: 30px;
  float: left;
  font-weight: normal;
}

.icon-addon .form-control {
  padding-left: 30px;
  float: left;
  font-weight: normal;
}

.pill-input-group div:last-child span {
  border-top-right-radius: 1.3rem;
  border-bottom-right-radius: 1.3rem;
}

.pill-input-group div:first-child span {
  border-top-left-radius: 1.3rem;
  border-bottom-left-radius: 1.3rem;
}

/**=====================
     29. Form Input CSS Ends
==========================**/

/**=====================
    30. Data-table CSS Start
==========================**/

div.dt-button-background {
  display: none !important;
}

div.DTS div.dataTables_scrollBody table {
  z-index: 1;
}

.dataTables_wrapper {
  padding: 0;
  width: 100%;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #e6edef;
}

.dataTables_wrapper .dt-buttons .btn {
  border-radius: 0.25rem !important;
}

.dataTables_wrapper .btn-group button {
  margin-right: 5px;
}

.dataTables_wrapper button {
  font-weight: 400;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #78266a;
  border: 1px solid #fff;
}

.dataTables_wrapper + .dataTables_wrapper {
  margin-top: 20px;
}

.dataTables_wrapper .dataTables_paginate {
  margin-left: 15px !important;
  border: 1px solid #e6edef;
  border-radius: 0.25rem;
  padding-top: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  margin: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: #78266a;
  color: #fff !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: 1px solid #78266a;
  color: #2c323f !important;
  background: transparent !important;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 30px;
}

.dataTables_wrapper .dataTables_length select {
  background-color: #fff;
  border-color: #e6edef;
  color: #2c323f;
  padding: 0 10px;
  margin: 0 10px;
  height: 2.7142em;
}

.dataTables_wrapper table.dataTable {
  border: 1px solid #efefef;
}

.dataTables_wrapper table.dataTable tbody td,
.dataTables_wrapper table.dataTable tbody th {
  background-color: #fff;
  border: 1px solid #e6edef;
}

.dataTables_wrapper table.dataTable tbody td.select-checkbox,
.dataTables_wrapper table.dataTable tbody th.select-checkbox {
  padding-right: 40px !important;
}

.dataTables_wrapper table.dataTable tbody td.select-checkbox:before,
.dataTables_wrapper table.dataTable tbody th.select-checkbox:before {
  right: 20px;
  top: 22px;
  left: unset;
}

.dataTables_wrapper table.dataTable thead th,
.dataTables_wrapper table.dataTable thead td {
  border-bottom: 2px solid #e6edef;
}

.dataTables_wrapper table.dataTable th,
.dataTables_wrapper table.dataTable td {
  padding: 0.75rem;
  border-color: #e6edef;
}

.dataTables_wrapper .dataTables_filter {
  margin-bottom: 25px;
  margin-left: 15px;
}

.dataTables_wrapper .dataTables_filter input[type="search"] {
  border: 1px solid #efefef;
  padding: 0 10px;
  margin-left: 10px;
  height: 37px;
  border-radius: 0;
}

.dataTables_scrollHeadInner {
  width: 100% !important;
}

table .fixedHeader-floating {
  position: fixed !important;
  background-color: #fff;
}

table .box > div {
  padding: 5px 15px;
  background-color: #78266a;
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
}

table.dataTable.fixedHeader-locked,
table.dataTable.fixedHeader-floating {
  width: calc(100vw - 250px) !important;
  max-width: calc(100vw - 250px) !important;
  overflow: hidden !important;
  right: 0 !important;
  z-index: 99;
  left: 260px !important;
}

table.dataTable:not(.fixedHeader-locked):not(.fixedHeader-floating) {
  width: 100% !important;
}

table.dataTable {
  border-collapse: collapse !important;
  margin-top: 0 !important;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: 12px !important;
}

table.dataTable input,
table.dataTable select {
  border: 1px solid #efefef;
  height: 37px;
}

.toolbar {
  padding: 5px 15px;
  background-color: #78266a;
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
}

code.option {
  color: #d22d3d !important;
}

code.api {
  color: #1b4c43 !important;
}

.dt-ext .dataTables_wrapper .page-item.active .page-link {
  background-color: #78266a;
  border-color: #78266a;
  color: #fff;
}

.dt-ext .dataTables_wrapper .dataTables_paginate {
  border: none;
}

.dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0;
  border: none;
}

.dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.hover,
.dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.active {
  border: none !important;
}

.dt-ext .dataTables_wrapper .dataTables_paginate .page-link {
  margin-left: 0px;
  color: #78266a;
}

.dt-ext .dataTables_wrapper button.dt-button,
.dt-ext .dataTables_wrapper div.dt-button,
.dt-ext .dataTables_wrapper a.dt-button,
.dt-ext .dataTables_wrapper button.dt-button:focus:not(.disabled),
.dt-ext .dataTables_wrapper div.dt-button:focus:not(.disabled),
.dt-ext .dataTables_wrapper a.dt-button:focus:not(.disabled),
.dt-ext .dataTables_wrapper button.dt-button:active:not(.disabled),
.dt-ext .dataTables_wrapper button.dt-button.active:not(.disabled),
.dt-ext .dataTables_wrapper div.dt-button:active:not(.disabled),
.dt-ext .dataTables_wrapper div.dt-button.active:not(.disabled),
.dt-ext .dataTables_wrapper a.dt-button:active:not(.disabled),
.dt-ext .dataTables_wrapper a.dt-button.active:not(.disabled) {
  background-color: #78266a;
  border-color: #78266a;
  border-radius: 2px;
  color: #fff;
  background-image: none;
  font-size: 14px;
}

.dt-ext .dataTables_wrapper button.dt-button.btn-success,
.dt-ext .dataTables_wrapper div.dt-button.btn-success,
.dt-ext .dataTables_wrapper a.dt-button.btn-success {
  background-color: #1b4c43;
  border-color: #1b4c43;
}

.dt-ext .dataTables_wrapper button.dt-button.btn-primary,
.dt-ext .dataTables_wrapper div.dt-button.btn-primary,
.dt-ext .dataTables_wrapper a.dt-button.btn-primary {
  background-color: #78266a;
  border-color: #78266a;
}

.dt-ext .dataTables_wrapper button.dt-button.btn-danger,
.dt-ext .dataTables_wrapper div.dt-button.btn-danger,
.dt-ext .dataTables_wrapper a.dt-button.btn-danger {
  background-color: #d22d3d;
  border-color: #d22d3d;
}

.dt-ext .dataTables_wrapper table.dataTable th.focus,
.dt-ext .dataTables_wrapper table.dataTable td.focus {
  outline: 3px solid #78266a;
}

.dt-ext
  .dataTables_wrapper
  table.dataTable.display
  tbody
  > tr.odd.selected
  > .sorting_1,
.dt-ext
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  > tr.odd.selected
  > .sorting_1 {
  background-color: #3eb59f;
}

.dt-ext .dataTables_wrapper table.dataTable.stripe tbody > tr.odd.selected,
.dt-ext .dataTables_wrapper table.dataTable.stripe tbody > tr.odd > .selected,
.dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd.selected,
.dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd > .selected {
  background-color: #3eb59f;
}

.dt-ext
  .dataTables_wrapper
  table.dataTable.display
  tbody
  > tr.even.selected
  > .sorting_1,
.dt-ext
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  > tr.even.selected
  > .sorting_1 {
  background-color: #3eb59f;
}

.dt-ext .dataTables_wrapper table.dataTable tbody > tr.selected,
.dt-ext .dataTables_wrapper table.dataTable tbody > tr > .selected {
  background-color: #3eb59f;
}

td.highlight {
  background-color: #e6edef !important;
}

td.details-control {
  /* background: url("../images/details_open.png") no-repeat center center;
	cursor: pointer; */
}

tr.shown td.details-control {
  /* background: url("../images/details_close.png") no-repeat center center; */
}

.dataTables_scroll ~ .dataTables_paginate {
  margin-top: 20px !important;
}

.product-table th:last-child {
  min-width: 120px;
}

.product-table h6 {
  font-weight: 600;
  color: #2c323f;
}

table.dataTable.display tbody tr {
  border: 1px solid #e6edef;
}

/**=====================
     30. Data-table CSS Ends
==========================**/

/**=====================
     31. JS datagrid CSS Start
==========================**/

.jsgrid .jsgrid-grid-body .jsgrid-cell {
  padding: 0.75rem;
}

.jsgrid .jsgrid-pager-container {
  text-align: right;
}

.jsgrid .jsgrid-pager {
  padding: 0;
  margin-top: 20px;
}

.jsgrid .jsgrid-pager .jsgrid-pager-nav-inactive-button {
  margin-right: -5px;
}

.jsgrid .jsgrid-pager .jsgrid-pager-nav-inactive-button:first-child {
  border-radius: 5px 0 0 5px;
}

.jsgrid .jsgrid-pager .jsgrid-pager-page ~ .jsgrid-pager-nav-button {
  margin-left: -5px;
}

.jsgrid .jsgrid-pager .jsgrid-pager-page ~ .jsgrid-pager-nav-button:last-child {
  border-radius: 0 5px 5px 0;
}

.jsgrid .jsgrid-pager [class*="jsgrid-pager"] {
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  border: 1px solid #e6edef;
}

.jsgrid .jsgrid-pager .jsgrid-pager-page a {
  color: #2c323f;
}

.jsgrid .jsgrid-pager .jsgrid-pager-current-page {
  color: #2c323f;
}

.jsgrid .jsgrid-selected-row > .jsgrid-cell {
  background: #e6edef;
  border-color: #fafafa;
}

.jsgrid .jsgrid-header-row > .jsgrid-header-cell {
  padding: 0.75rem;
}

/**=====================
     31. JS datagrid CSS Ends
==========================**/

/**=====================
     32. pagination CSS Start
==========================**/

/*test Nav bar color */

.pagination-theme .page-item .page-link {
  color: #78266a !important;
}

.pagination-theme .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-theme .page-item.active .page-link {
  color: #fff !important;
  background-color: #78266a !important;
  border-color: #78266a;
}

.pagination-primary .page-item .page-link {
  color: #78266a !important;
}

.pagination-primary .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-primary .page-item.active .page-link {
  color: #fff !important;
  background-color: #78266a !important;
  border-color: #78266a;
}

.pagination-secondary .page-item .page-link {
  color: #ba895d !important;
}

.pagination-secondary .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-secondary .page-item.active .page-link {
  color: #fff !important;
  background-color: #ba895d !important;
  border-color: #ba895d;
}

.pagination-success .page-item .page-link {
  color: #1b4c43 !important;
}

.pagination-success .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-success .page-item.active .page-link {
  color: #fff !important;
  background-color: #1b4c43 !important;
  border-color: #1b4c43;
}

.pagination-danger .page-item .page-link {
  color: #d22d3d !important;
}

.pagination-danger .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-danger .page-item.active .page-link {
  color: #fff !important;
  background-color: #d22d3d !important;
  border-color: #d22d3d;
}

.pagination-info .page-item .page-link {
  color: #717171 !important;
}

.pagination-info .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-info .page-item.active .page-link {
  color: #fff !important;
  background-color: #717171 !important;
  border-color: #717171;
}

.pagination-dark .page-item .page-link {
  color: #2c323f !important;
}

.pagination-dark .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-dark .page-item.active .page-link {
  color: #fff !important;
  background-color: #2c323f !important;
  border-color: #2c323f;
}

.pagination-warning .page-item .page-link {
  color: #e2c636 !important;
}

.pagination-warning .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-warning .page-item.active .page-link {
  color: #fff !important;
  background-color: #e2c636 !important;
  border-color: #e2c636;
}

/**=====================
     32. pagination CSS Ends
==========================**/

/**=====================
    33.  Ecommerce  CSS Start
==========================**/

/**====== Product Start ======**/

.toggle-data {
  cursor: pointer;
}

.product-sidebar .filter-section .card .card-header {
  padding: 16px 30px;
  border-radius: 5px;
}

.product-sidebar .filter-section .card .card-header h6 {
  position: relative;
}

.product-sidebar .filter-section .card .card-header h6 .pull-right i {
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 10px;
  width: 100%;
  height: 10px;
  text-align: right;
  cursor: pointer;
}

.product-wrapper .product-sidebar .filter-section .card .left-filter {
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  height: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.product-wrapper
  .product-sidebar
  .filter-section
  .card
  .left-filter
  .product-filter
  .irs-with-grid {
  margin-bottom: 15px;
}

.product-wrapper
  .product-sidebar
  .filter-section
  .card
  .left-filter
  .card-body {
  padding: 20px;
}

.product-wrapper .product-grid .product-wrapper-grid {
  margin-left: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.product-wrapper.sidebaron .product-sidebar .filter-section .card .left-filter {
  z-index: 3;
  opacity: 1;
  visibility: visible;
  position: absolute;
  width: 100%;
  top: 53px;
  height: auto;
  border: 1px solid #e6edef;
  background-color: #fff;
}

.product-filter .checkbox-animated label {
  color: #999;
}

.product-table img {
  height: 40px;
}

.product-table span,
.product-table p {
  color: #59667a;
}

.product-table tbody tr td:first-child {
  text-align: center;
}

.product-table .dataTables_wrapper button {
  padding: 2px 12px;
  font-size: 14px;
}

.d-none-productlist {
  display: none;
}

.d-none-productlist svg {
  vertical-align: middle;
  cursor: pointer;
}

.product-wrapper-grid.list-view .product-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-wrapper-grid.list-view .product-box .product-img {
  width: 20%;
}

.product-wrapper-grid.list-view .product-box .product-details {
  text-align: left;
}

.product-wrapper-grid.list-view [class*="col-"] {
  width: 100%;
}

.slider-product {
  padding: 15px 0;
  border-top: 1px solid #e6edef;
  border-bottom: 1px solid #e6edef;
  margin-bottom: 15px;
}

.products-total {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-options {
  height: 33px;
  margin-left: 10px;
}

.grid-options ul li a {
  position: relative;
}

.grid-options ul li a .line-grid {
  position: absolute;
  width: 4px;
  height: 15px;
  top: 9px;
}

.grid-options ul li a .line-grid-1 {
  left: 12px;
}

.grid-options ul li a .line-grid-2 {
  left: 18px;
}

.grid-options ul li a .line-grid-3 {
  left: 36px;
}

.grid-options ul li a .line-grid-4 {
  left: 42px;
}

.grid-options ul li a .line-grid-5 {
  left: 48px;
}

.grid-options ul li a .line-grid-6 {
  left: 66px;
}

.grid-options ul li a .line-grid-7 {
  left: 72px;
}

.grid-options ul li a .line-grid-8 {
  left: 78px;
}

.grid-options ul li a .line-grid-9 {
  left: 84px;
}

.grid-options ul li a .line-grid-10 {
  left: 103px;
}

.grid-options ul li a .line-grid-11 {
  left: 109px;
}

.grid-options ul li a .line-grid-12 {
  left: 115px;
}

.grid-options ul li a .line-grid-13 {
  left: 121px;
}

.grid-options ul li a .line-grid-14 {
  left: 127px;
}

.grid-options ul li a .line-grid-15 {
  left: 133px;
}

.square-product-setting {
  height: 36px;
  vertical-align: middle;
}

.square-product-setting a {
  color: #242934;
}

.square-product-setting .icon-grid {
  padding: 7px;
  background-color: rgba(36, 105, 92, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 10px;
}

.square-product-setting .icon-grid svg {
  width: 20px;
  height: 20px;
  color: #78266a;
}

.product-filter .banner-product {
  margin-top: 15px;
}

.product-filter h6 {
  margin-bottom: 15px;
  font-size: 18px;
}

.product-filter .checkbox-animated label {
  font-weight: 500;
}

.product-filter .color-selector {
  line-height: 0.9;
}

.product-filter .color-selector ul li {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #444;
  border-radius: 100%;
  cursor: pointer;
}

.product-filter .color-selector ul li.white {
  background-color: #fff;
}

.product-filter .color-selector ul li.gray {
  background-color: rgba(36, 41, 52, 0.7);
}

.product-filter .color-selector ul li.black {
  background-color: #000;
}

.product-filter .color-selector ul li.orange {
  background-color: #ffb17a;
}

.product-filter .color-selector ul li.green {
  background-color: #6fb866;
}

.product-filter .color-selector ul li.pink {
  background-color: pink;
}

.product-filter .color-selector ul li.yellow {
  background-color: #f2f896;
}

.product-filter .color-selector ul li.blue {
  background-color: #63b4f2;
}

.product-filter .color-selector ul li.red {
  background-color: #ff647f;
}

.product-filter.new-products {
  margin-top: 20px;
}

.product-filter.new-products button {
  width: auto;
}

.product-filter.new-products .owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  top: -46px;
  right: 0;
}

.product-filter.new-products .owl-theme .owl-nav button:focus {
  outline: transparent;
}

.product-filter.new-products .owl-theme .owl-nav button span {
  font-size: 20px;
}

.product-filter.new-products .owl-theme .owl-nav button span:focus {
  outline-color: transparent;
}

.product-filter.new-products .owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: transparent;
  color: inherit;
}

.product-filter.new-products .owl-theme .owl-item .item .product-box .media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-filter.new-products
  .owl-theme
  .owl-item
  .item
  .product-box
  .product-img {
  width: 40%;
}

.product-filter.new-products
  .owl-theme
  .owl-item
  .item
  .product-box
  .product-details {
  padding: 25px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-filter.new-products
  .owl-theme
  .owl-item
  .item
  .product-box
  .product-details
  ul
  li {
  display: inline-block;
}

.product-filter.new-products
  .owl-theme
  .owl-item
  .item
  .product-box
  + .product-box {
  margin-top: 15px;
}

.select2-drpdwn-product .form-control {
  margin-bottom: 10px;
  height: 36px;
  background-color: rgba(36, 105, 92, 0.05);
  color: #78266a;
  font-weight: 600;
}

.feature-products form .form-group {
  position: relative;
}

.feature-products form .form-group input {
  margin-bottom: 15px;
  height: 50px;
  padding-left: 30px;
}

.feature-products form .form-group i {
  position: absolute;
  top: 17px;
  right: 30px;
  color: #898989;
}

.product-box {
  background-color: #fff;
}

.product-box .product-details {
  padding: 25px;
}

.product-box .product-details h4 {
  font-weight: 600;
  color: #242934;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.product-box .product-details p {
  margin-bottom: 5px;
  color: #999;
  font-weight: 500;
}

.product-box .product-details h6 {
  color: #242934;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.product-box .product-details span {
  color: #898989;
}

.product-box .modal .modal-header {
  background-color: #fff;
}

.product-box .modal .modal-header .product-box .product-details {
  padding: 25px 0;
}

.product-box .modal .modal-header .product-box .product-details h6 {
  text-transform: capitalize;
}

.product-box .modal .modal-header .product-box .product-details .product-price {
  margin-bottom: 10px;
}

.product-box .modal .modal-header .product-box .product-details .product-view {
  padding: 20px 0;
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

.product-box .modal .modal-header .product-box .product-details .product-size {
  margin: 20px 0;
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-size
  ul
  li {
  display: inline-block;
  background-color: rgba(36, 105, 92, 0.1);
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-size
  ul
  li
  button {
  color: #78266a;
  width: 35px;
  height: 35px;
  font-size: 16px;
  padding: 0;
  font-weight: 500;
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-size
  ul
  li:nth-child(n + 2) {
  margin-left: 15px;
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-size
  ul
  li.active
  button {
  background-color: #78266a !important;
  color: #fff;
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-qnty
  fieldset {
  margin-bottom: 20px;
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-qnty
  fieldset
  .input-group {
  width: 35%;
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-qnty
  fieldset
  .input-group
  .btn {
  padding: 5px 12px;
}

.product-box
  .modal
  .modal-header
  .product-box
  .product-details
  .product-qnty
  .addcart-btn
  .btn {
  font-weight: 600;
}

.product-box .product-img {
  position: relative;
}

.product-box .product-img .product-hover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.product-box .product-img .product-hover ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-box .product-img .product-hover ul i {
  font-size: 14px;
}

.product-box .product-img .product-hover ul li {
  -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  font-size: 18px;
  border-radius: 100%;
  line-height: 1.6;
  height: 45px;
  width: 45px;
  margin: 0 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.product-box .product-img .product-hover ul li a {
  color: #78266a;
}

.product-box .product-img .product-hover ul li .btn {
  padding: 0;
}

.product-box .product-img .product-hover ul li:hover {
  background-color: #78266a;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.product-box .product-img .product-hover ul li:hover .btn,
.product-box .product-img .product-hover ul li:hover i {
  color: #fff;
}

.product-box:hover .product-hover {
  opacity: 1;
  border-radius: 0%;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pro-filter-sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pro-filter-sec .product-sidebar {
  width: 375px;
  min-width: 375px;
  margin-right: 30px;
}

.pro-filter-sec .product-search {
  width: 100%;
}

.product-page-main {
  padding: 30px;
}

.product-page-main .owl-item .item {
  border: 1px solid #e6edef;
  border-radius: 5px;
}

.product-page-main .owl-item.current .item {
  border: 1px solid #78266a;
}

.product-page-main .product-slider {
  margin-bottom: 20px;
}

.product-page-main .product-color {
  margin-top: 10px;
  margin-bottom: 10px;
}

.product-page-main .pro-group {
  padding-top: 15px;
  padding-bottom: 15px;
}

.product-page-main .pro-group tr {
  line-height: 2;
}

.product-page-main .pro-group:nth-child(n + 2) {
  border-top: 1px solid #e6edef;
}

.product-page-main .pro-group .btn {
  font-weight: 600;
}

.product-page-main .pro-slide-right {
  margin-bottom: -20px;
}

.product-page-main .pro-slide-right img {
  width: auto;
  height: 140px;
}

.product-page-main .pro-slide-right .slick-slide {
  margin-bottom: 20px;
}

.product-page-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.product-page-details h4 {
  color: #242934;
  text-transform: uppercase;
  font-size: 18px;
}

.product-page-details span {
  padding-left: 15px;
}

.product-page-details h3 {
  font-weight: 700;
  font-size: 24px;
}

.br-theme-fontawesome-stars .br-widget a,
.br-theme-fontawesome-stars .br-widget a.br-selected:after,
.br-theme-fontawesome-stars .br-widget a.br-active:after {
  color: #ffa800 !important;
  font: normal normal normal 14px/1 FontAwesome;
}

.product-price {
  font-size: 16px;
  font-weight: 700;
  color: #78266a;
}

.product-price del {
  color: #717171;
  margin-left: 15px;
  font-weight: 600;
  font-size: 14px;
}

.product-color {
  line-height: 1;
}

.product-color li {
  display: inline-block;
  border-radius: 100%;
  height: 20px;
  width: 20px;
}

.product-page-main p {
  font-size: 15px;
  font-weight: 500;
  color: #717171;
}

.product-page-main .nav-link {
  text-transform: uppercase;
  font-weight: 600;
}

.product-page-main .pro-group h6 {
  text-transform: capitalize;
}

.product-social li a {
  font-size: 15px;
  border: 1px solid #e6edef;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-block;
  text-align: center;
  line-height: 2.3;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.product-social li a:hover {
  background-color: #78266a;
}

.product-social li a:hover i {
  color: #fff;
}

.product-social li:nth-child(n + 2) {
  margin-left: 10px;
}

.filter-block h4 {
  margin-bottom: 20px;
  font-weight: 700;
}

.filter-block ul li {
  color: #999;
}

.filter-block ul li .form-check {
  margin-bottom: 0;
}

.filter-block ul li .form-check label {
  margin-bottom: 0;
  vertical-align: middle;
}

.filter-block ul li .form-check .form-check-input:checked {
  background-color: #78266a;
}

.filter-block ul li:nth-child(n + 2) {
  margin-top: 15px;
}

ul.pro-services li svg {
  color: #78266a;
  margin-right: 15px;
}

ul.pro-services li h5 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}

ul.pro-services li p {
  font-size: 14px;
}

ul.pro-services li:nth-child(n + 2) {
  margin-top: 20px;
}

.support-ticket .pro-gress .total-num {
  margin-bottom: 0;
  font-weight: 600;
}

.support-ticket .pro-gress .progress-showcase {
  margin-top: 30px;
}

.support-ticket .pro-gress span {
  font-weight: 500;
  color: #717171;
}

.support-ticket .pro-gress ul li {
  font-weight: 600;
  color: #717171;
  font-size: 12px;
}

.support-ticket .pro-gress ul li span i {
  margin-left: 10px;
}

.list-products .product-table table th {
  min-width: 150px;
}

/**====== Product  Ends ======**/

.payment-opt li {
  display: inline-block;
}

.payment-opt li img {
  height: 20px;
  margin: 15px 15px 0 0;
}

@media (max-width: 1500px) {
  .product-page-main .pro-slide-right img {
    height: 130px;
  }
}

@media (max-width: 1470px) {
  .pro-filter-sec .product-sidebar {
    width: 320px;
    min-width: 320px;
  }
}

@media (max-width: 1366px) {
  .support-ticket .pro-gress .card-body {
    padding: 20px;
  }

  .pro-filter-sec .product-sidebar {
    width: 250px;
    min-width: 250px;
  }
}

@media (max-width: 1280px) and (min-width: 1200px) {
  .product-page-main .xl-50 {
    max-width: 50% !important;
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
  }

  .product-page-main .pro-slide-right img {
    height: 120px;
  }
}

@media (max-width: 1280px) {
  .filter-block ul li:nth-child(n + 2) {
    margin-top: 10px;
  }

  ul.pro-services li:nth-child(n + 2) {
    margin-top: 10px;
  }
}

@media (max-width: 1199px) {
  .product-page-main .pro-slide-right .slick-slide {
    margin-top: 20px;
  }

  .product-page-main .pro-slide-right .slide-box {
    margin-right: 5px;
    margin-left: 5px;
  }

  .product-page-main .pro-slide-right img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 575px) {
  .payment-details .theme-form .p-r-0 {
    padding-right: 12px;
  }

  .product-sidebar .filter-section .card {
    overflow: unset !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

@media (max-width: 767px) {
  .support-ticket-font .total-num {
    font-size: 12px;
  }

  .support-ticket .card .card-body {
    padding: 15px;
  }

  .support-ticket .pro-gress span {
    font-size: 12px;
  }

  .support-ticket .pro-gress .card-body {
    padding: 15px;
  }

  .support-ticket .pro-gress ul li {
    font-size: 10px;
  }

  .support-ticket .pro-gress ul li span i {
    margin-left: 5px;
  }

  .support-ticket .pro-gress .progress-showcase {
    margin-top: 15px;
  }
}

@media (max-width: 1199px) {
  .xl-cs-35 {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .product-page-main .card .card-body {
    padding: 20px;
  }
}

@media (max-width: 991px) {
  .product-box .modal .modal-header .product-box .product-details {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
    padding-bottom: 0;
  }

  .product-box
    .modal
    .modal-header
    .product-box
    .product-details
    .product-view {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .product-box
    .modal
    .modal-header
    .product-box
    .product-details
    .product-size {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .product-box
    .modal
    .modal-header
    .product-box
    .product-details
    .product-size
    ul
    li
    button {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }

  .product-box
    .modal
    .modal-header
    .product-box
    .product-details
    .product-size
    ul
    li:nth-child(n + 2) {
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .product-page-main .singale-pro-slide img {
    width: 50%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }

  .product-page-main .pro-group .btn {
    padding: 7px 14px;
  }
}

@media (max-width: 480px) {
  .learning-box .details-main {
    margin: 15px;
  }

  .learning-box .details-main p {
    font-size: 13px;
  }

  .blog-box.blog-list .blog-details hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .product-page-main .pro-group .btn {
    padding: 7px 8px;
    font-size: 12px;
  }
}

@media (max-width: 420px) {
  ul.pro-services li h5 {
    margin-bottom: 0;
  }

  .product-page-main .singale-pro-slide img {
    width: 100%;
  }

  .product-box
    .modal
    .modal-header
    .product-box
    .product-details
    .product-qnty
    fieldset
    .input-group {
    width: 50%;
  }

  .product-box
    .modal
    .modal-header
    .product-box
    .product-details
    .product-qnty
    .addcart-btn
    .btn {
    padding: 5px 10px;
  }

  .product-page-details {
    display: block;
  }

  .product-page-details h3 {
    margin-bottom: 5px;
  }

  .product-page-main .pro-slide-right .slick-slide {
    margin-top: 15px;
  }

  .product-page-main .pro-group .btn:nth-child(n + 2) {
    margin-top: 5px;
  }
}

/**=====================
    33. Ecommerce  CSS End
==========================**/

/**=====================
     34. Pricing CSS Start
==========================**/

.pricing-content div .pricing-simple,
.pricing-content div .pricing-block {
  margin-bottom: 0;
}

.pricing-content div .pricing-simple .btn-block {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.pricing-content-ribbons div .pricing-block {
  margin-bottom: 30px;
}

.pricing-content-ribbons div:last-child .pricing-block,
.pricing-content-ribbons div:nth-child(7) .pricing-block,
.pricing-content-ribbons div:nth-child(8) .pricing-block {
  margin-bottom: 0;
}

.pricing-card-design-2 .pricing-block .pricing-inner ul {
  margin-bottom: 30px;
}

.pricing-card-design-2 .pricing-block .pricing-inner ul li {
  border-bottom: 1px solid #fafdff;
}

.pricing-block {
  overflow: hidden;
}

.pricing-block .pricing-header {
  position: relative;
  height: 170px;
}

.pricing-block .pricing-header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0% 0% 50% 50%;
  background-color: rgba(36, 105, 92, 0.05);
}

.pricing-block .pricing-header h2 {
  color: #78266a;
  font-weight: 600;
  font-size: 30px;
  text-transform: uppercase;
  margin-top: 30px;
}

.pricing-block .pricing-header h2 span {
  font-size: 50%;
}

.pricing-block .pricing-header .price-box {
  background-color: #78266a;
  border-radius: 5px;
  width: 90px;
  height: 90px;
  margin-left: auto;
  margin-right: auto;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
}

.pricing-block .pricing-header .price-box > div {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.pricing-block .pricing-header .price-box h3 {
  color: #fff;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 1;
  font-size: 24px;
}

.pricing-block .pricing-header .price-box p {
  color: #fff;
  text-transform: capitalize;
  line-height: 1;
  font-weight: 600;
}

.pricing-block svg {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-left: -1px;
}

.pricing-block .pricing-list {
  padding-top: 90px;
  padding-bottom: 30px;
}

.pricing-block .pricing-list ul {
  padding: 0;
}

.pricing-block .pricing-list ul li {
  margin-bottom: 20px;
}

.pricing-block .pricing-list ul li h6 {
  font-weight: 700;
  margin-bottom: 0;
}

.pricing-block .pricing-list ul li h6 span {
  color: #999;
  margin-left: 5px;
  display: inline-block;
}

.pricing-block .pricing-list .pricing-inner {
  margin-bottom: 30px;
}

.pricing-simple .card-body {
  border-bottom: none;
}

.pricing-simple .card-body h3 {
  position: relative;
  margin-bottom: 38px;
  font-weight: 600;
}

.pricing-simple .card-body h3:before {
  position: absolute;
  content: "";
  width: 80px;
  height: 1px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -10px;
  background-color: #999;
}

.pricing-simple .card-body h1 {
  font-size: 80px;
  line-height: 0.9;
  margin-bottom: 30px;
  font-weight: 600;
  color: #78266a;
}

.pricing-simple .card-body h6 {
  color: #999;
}

@media only screen and (max-width: 1550px) {
  .pricing-block .pricing-header {
    height: 150px;
  }

  .pricing-block .pricing-header h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1470px) {
  .pricing-col {
    margin-bottom: -30px;
  }

  .pricing-col > div {
    width: 50%;
    margin-bottom: 30px;
  }

  .pricing-block .pricing-header h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1366px) {
  .pricing-content div:nth-child(2) .pricing-simple,
  .pricing-content div:first-child .pricing-simple {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .pricing-block .ribbon-vertical-left {
    left: 3px;
    top: 0px;
  }

  .pricing-block .ribbon-bookmark {
    top: 0;
    left: -1px;
  }

  .pricing-block .ribbon-clip-right {
    top: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .pricing-block .pricing-inner h3 {
    font-size: 20px;
  }

  .pricing-block .pricing-inner ul li h6 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 991px) {
  .pricing-content div:nth-child(2) .pricing-simple,
  .pricing-content div:first-child .pricing-simple {
    margin-bottom: 20px;
  }

  .pricing-block .pricing-inner {
    padding: 20px 0 20px;
  }

  .pricing-block .pricing-inner ul,
  .pricing-block .pricing-inner h3 {
    margin-bottom: 1px;
  }

  .pricing-simple .card-body h1 {
    margin-bottom: 20px;
  }

  .pricing-simple .card-body h3 {
    margin-bottom: 28px;
  }

  .pricing-card-design-2 .pricing-block .pricing-inner ul {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-content-ribbons div .pricing-block {
    margin-bottom: 20px !important;
  }

  .pricing-content-ribbons div:last-child .pricing-block {
    margin-bottom: 0 !important;
  }

  .pricing-content div:last-child .pricing-block {
    margin-bottom: 0;
  }

  .pricing-block .pricing-inner {
    padding: 15px 0 15px;
  }

  .pricing-block .pricing-inner h3 {
    font-size: 28px;
  }

  .pricing-block .pricing-inner ul li h6 {
    font-size: 16px;
  }

  .pricing-block .pricing-header h2 {
    font-size: 24px;
  }

  .pricing-simple .card-body h1 {
    font-size: 60px;
  }

  .pricing-simple .card-body h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .pricing-content div .pricing-simple {
    margin-bottom: 15px;
  }

  .pricing-content div:last-child .pricing-simple {
    margin-bottom: 0;
  }

  .pricing-simple .card-body h1 {
    margin-bottom: 15px;
    font-size: 45px;
  }

  .pricing-block {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .pricing-block .pricing-inner {
    padding: 15px 0 15px;
  }

  .pricing-card-design-2 .pricing-block .pricing-inner ul {
    margin-bottom: 15px;
  }

  .pricing-col > div {
    width: 100%;
  }

  .pricing-block .pricing-list .btn {
    padding: 5px 15px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .pricing-block .pricing-list {
    padding-top: 80px;
  }

  .pricing-block .pricing-list ul li {
    margin-bottom: 15px;
  }

  .pricing-block .pricing-list ul li h6 {
    font-size: 14px;
  }

  .pricing-block .pricing-list .pricing-inner {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 420px) {
  .pricing-block .pricing-header {
    height: 140px;
  }

  .pricing-block .pricing-header .price-box {
    width: 80px;
    height: 80px;
  }

  .pricing-block .pricing-header .price-box h3 {
    font-size: 20px;
  }

  .pricing-block .pricing-header .price-box p {
    font-size: 12px;
  }
}

/**=====================
    34. Pricing CSS Ends
==========================**/

/**=====================
     35. Form builder css start
==========================**/

.form-builder .help-block {
  margin-bottom: 0;
  color: #cccccc;
  text-transform: capitalize;
}

.form-builder .btn-light {
  color: #cccccc;
}

.form-builder .copy-btn {
  padding: 8px;
}

.form-builder .nav-primary .nav-link.active,
.form-builder .nav-primary .nav-primary .show > .nav-link,
.form-builder .nav-primary .nav-pills.nav-primary .nav-link.active,
.form-builder .nav-primary .nav-pills.nav-primary .show > .nav-link {
  border-radius: 4px;
}

.form-builder .nav-primary .nav-link,
.form-builder .nav-primary .nav-pills.nav-primary .nav-link {
  color: #000;
  font-weight: 500;
}

.form-builder #components {
  margin-top: 30px;
}

.form-builder #components #viewhtml {
  height: 526px;
}

/**=====================
    35. Form builder css Ends
==========================**/

/**=====================
     36. NAV CSS Start
==========================**/

.nav-list {
  padding: 30px;
}

.nav-list .nav-list-disc {
  text-align: left;
  color: #898989;
}

.nav-list .nav-list-disc li {
  padding: 9px 0;
}

.nav-list .nav-list-disc li:first-child {
  padding-top: 0;
}

.nav-list .nav-list-disc li:last-child {
  padding-bottom: 0;
}

.nav-list .nav-list-disc li a {
  color: #242934;
}

.nav-list .nav-list-disc li a .fa {
  width: 14px;
  font-size: 16px;
}

.nav-list .nav-list-disc li:hover {
  color: #78266a;
}

.nav-list .nav-list-disc li:hover a {
  color: #78266a;
}

.navs-icon {
  padding: 30px;
}

.navs-icon svg {
  width: 21px;
  height: 21px;
  margin-right: 10px;
}

.navs-icon .fa {
  font-size: 16px;
  margin-right: 10px;
}

.navs-icon li {
  padding: 9px 0;
}

.navs-icon li:first-child {
  padding-top: 0;
}

.navs-icon li:last-child {
  padding-bottom: 0;
}

.navs-icon li .text-muted {
  color: #242934 !important;
}

.navs-icon li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navs-icon li ul {
  padding-top: 9px;
}

.navs-icon li ul li {
  line-height: 1;
}

.navs-icon li ul li:first-child {
  padding-top: 9px;
}

.navs-icon li ul li:hover {
  color: #78266a;
}

.navs-icon li ul li:hover a {
  color: #78266a;
}

.navs-icon li a {
  color: #242934;
}

.navs-icon li a:hover {
  color: #78266a;
}

.navs-icon li a.active {
  color: #78266a;
}

.navs-icon li a span {
  font-family: "Rubik", sans-serif, sans-serif;
}

.navs-icon li button:hover a {
  color: #fff;
}

.navs-icon .main-section {
  padding-top: 30px;
}

.navs-icon .separator {
  border-bottom: 1px solid #e6edef;
  margin: 30px 0;
  padding: 0;
}

.navs-icon .btn-link {
  text-decoration: none;
  line-height: 1;
  padding: 0;
  padding-bottom: 6px;
}

.navs-icon .btn-link:hover {
  text-decoration: none;
}

#accordionoc #collapseicon,
#accordionoc #collapseicon1 {
  padding-top: 0px;
}

#accordionoc li button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navs-icon.default-according.style-1 li button {
  position: relative;
}

.navs-icon.default-according.style-1 li button:hover {
  color: #78266a !important;
}

.navs-icon.default-according.style-1 li button[aria-expanded="true"]:before {
  right: 2px;
  top: 6px;
}

.navs-icon.default-according.style-1 li button[aria-expanded="false"]:before {
  right: 2px;
  top: 6px;
}

.navs-dropdown button {
  margin-top: 30px;
}

.navs-dropdown .onhover-show-div {
  width: 250px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 2px 2px #e6edef;
  box-shadow: 0 0 2px 2px #e6edef;
}

.navs-dropdown .onhover-show-div button {
  margin-top: 0px;
}

.navs-dropdown .onhover-show-div .navs-icon li {
  text-align: left;
}

.navs-dropdown .onhover-show-div .navs-icon li a:hover svg path,
.navs-dropdown .onhover-show-div .navs-icon li a:hover svg line,
.navs-dropdown .onhover-show-div .navs-icon li a:hover svg polyline,
.navs-dropdown .onhover-show-div .navs-icon li a.active svg path,
.navs-dropdown .onhover-show-div .navs-icon li a.active svg line,
.navs-dropdown .onhover-show-div .navs-icon li a.active svg polyline {
  color: #78266a !important;
}

.onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
  right: 77px;
  left: unset;
}

.onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
  right: 77px;
  left: unset;
}

.icon-lists {
  font-family: "Rubik", sans-serif, sans-serif;
}

.pl-navs-inline {
  padding-left: 30px !important;
}

.inline-nav li {
  line-height: 1.3;
}

.nav-tabs {
  border-color: #e6edef;
}

.nav-tabs .nav-link.active {
  background-color: rgba(36, 105, 92, 0.1);
  border-color: #e6edef;
  color: #78266a;
}

.nav-tabs .nav-item.show .nav-link {
  background-color: rgba(36, 105, 92, 0.1);
}

/**=====================
     36. NAV CSS Ends
==========================**/

/**=====================
    37. Dropdown CSS Start
==========================**/

.dropdown-basic {
  margin-bottom: -10px;
}

.dropdown-basic .btn-group {
  margin-right: 18px;
}

.dropdown-basic .btn-group .btn-round {
  border-radius: 50px;
}

.dropdown-basic .separated-btn {
  margin-left: -6px;
}

.dropdown-basic .separated-btn .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 10px;
}

.dropdown-basic button {
  max-height: 43px;
}

.dropdown-basic .dropdown {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
}

.dropdown-basic .dropdown .dropbtn {
  color: #fff;
  padding: 12px 35px;
  border: none;
  cursor: pointer;
}

.dropdown-basic .dropdown .dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #fff;
  border: 1px solid #e6edef;
  min-width: 175px;
  z-index: 1;
  left: 0;
  top: 45px;
}

.dropdown-basic .dropdown .dropdown-content a {
  color: #999;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
  border-top: 1px solid #e6edef;
}

.dropdown-basic .dropdown .dropdown-content .dropdown-header {
  padding: 12px 16px;
}

.dropdown-basic .dropdown-content a:hover {
  background-color: rgba(36, 105, 92, 0.1);
  color: #78266a;
}

.dropdown-basic .dropdown:hover .dropdown-content {
  display: block;
}

.dropup-basic .dropup {
  position: relative;
  display: inline-block;
}

.dropup-basic .dropup .dropbtn {
  color: white;
  padding: 12px;
  border: none;
}

.dropup-basic .dropup .dropup-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 170px;
  bottom: 45px;
  z-index: 999;
  left: 0;
}

.dropup-basic .dropup .dropup-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropup-basic .dropup .dropup-content a:hover {
  background-color: #f1f1f1;
}

.dropup-basic .dropup:hover .dropup-content {
  display: block;
}

.dropup-basic .dropup:hover .dropup-content a.active {
  background-color: #f1f1f1;
}

/**=====================
    37. Dropdown CSS Ends
==========================**/

/**=====================
     38. Landing CSS Start
==========================**/

body {
  background-color: #fff;
}

.landing-wrraper .section-py-space {
  padding-top: 70px;
  padding-bottom: 70px;
}

.landing-wrraper .section-pt-space {
  padding-top: 70px;
}

.landing-wrraper .section-pb-space {
  padding-bottom: 70px;
}

.landing-wrraper .title {
  margin-bottom: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.landing-wrraper .title h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
  color: #78266a;
  margin-bottom: 0;
  line-height: 0.8;
}

.landing-wrraper .light-bg {
  background-color: rgba(36, 105, 92, 0.05);
}

.landing-wrraper .tap-top {
  text-align: center;
  cursor: pointer;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  color: #fff;
  background-color: #78266a;
  border: none;
  padding: 7px;
  -webkit-box-shadow: 0 0 10px #78266a;
  box-shadow: 0 0 10px #78266a;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.landing-wrraper .tap-top:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 1;
}

.landing-wrraper .tap-top svg {
  width: 20px;
}

.landing-wrraper .custom-container {
  max-width: 1600px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.landing-wrraper .navbar-nav {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.landing-wrraper .navbar-nav .nav-item {
  position: relative;
}

.landing-wrraper .navbar-nav .nav-item .nav-link {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 12px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 1px solid;
  opacity: 0.7;
}

.landing-wrraper .navbar-nav .nav-item .nav-link:hover,
.landing-wrraper .navbar-nav .nav-item .nav-link.active {
  opacity: 1;
  text-shadow: 0 0 0 #78266a;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #78266a;
}

.landing-wrraper .btn-landing {
  border-radius: 20px;
  color: #fff;
  background-color: #78266a;
  padding: 12px 30px;
  line-height: 1;
  font-weight: 600;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.landing-wrraper .landing-header {
  -webkit-filter: drop-shadow(0px 5px 17.5px rgba(36, 105, 92, 0.06));
  filter: drop-shadow(0px 5px 17.5px rgba(36, 105, 92, 0.06));
  background-color: #fff;
  padding-top: 18px;
  padding-bottom: 18px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.landing-wrraper
  .landing-header
  ul.landing-menu
  li.nav-item
  .form-inline
  .nav-link {
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  background-color: transparent;
}

.landing-wrraper
  .landing-header
  ul.landing-menu
  li.nav-item
  .form-inline
  .nav-link:focus {
  outline: none;
}

.landing-wrraper .landing-header ul.landing-menu li.nav-item.menu-back {
  border-bottom: 1px solid #e6edef;
  padding: 22px 20px;
  text-align: right;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  display: none;
  cursor: pointer;
  margin-bottom: 15px;
}

.landing-wrraper .landing-header ul.landing-menu li.nav-item.menu-back i {
  font-size: 16px;
  margin-left: 10px;
}

.landing-wrraper .landing-header ul.landing-menu li.nav-item a.nav-link {
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #2b2b2b;
  padding: 10px 20px;
}

.landing-wrraper .landing-header ul.landing-menu li.nav-item:hover a.nav-link {
  color: #78266a;
}

.landing-wrraper .landing-header ul.landing-menu li.nav-item:hover .nav-link {
  color: #78266a;
}

.landing-wrraper .landing-header .buy-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.landing-wrraper .landing-header .buy-block .toggle-menu {
  margin-left: 20px;
  display: none;
  cursor: pointer;
}

.landing-wrraper .landing-header .buy-block .toggle-menu i {
  font-size: 24px;
  color: #78266a;
}

@-webkit-keyframes move1 {
  0% {
    -webkit-transform: rotate(0deg) translateX(10px) rotate(0deg);
    transform: rotate(0deg) translateX(10px) rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg) translateX(10px) rotate(-360deg);
    transform: rotate(360deg) translateX(10px) rotate(-360deg);
  }
}

@keyframes move1 {
  0% {
    -webkit-transform: rotate(0deg) translateX(10px) rotate(0deg);
    transform: rotate(0deg) translateX(10px) rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg) translateX(10px) rotate(-360deg);
    transform: rotate(360deg) translateX(10px) rotate(-360deg);
  }
}

@-webkit-keyframes move2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@keyframes move2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@-webkit-keyframes star {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }

  50% {
    -webkit-transform: scale(0.5) rotate(45deg);
    transform: scale(0.5) rotate(45deg);
  }

  100% {
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
}

@keyframes star {
  0% {
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }

  50% {
    -webkit-transform: scale(0.5) rotate(45deg);
    transform: scale(0.5) rotate(45deg);
  }

  100% {
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
}

.landing-wrraper .landing-home {
  height: 100vh;
  position: relative;
  border-bottom: 1px solid #edeef3;
}

.landing-wrraper .landing-home .landing-home-contain {
  height: calc(100vh - 80px);
  margin-top: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 37%;
  z-index: 2;
  position: relative;
}

.landing-wrraper .landing-home .landing-home-contain .landing-logo {
  margin-bottom: 25px;
}

.landing-wrraper .landing-home .landing-home-contain h6 {
  font-size: 16px;
  letter-spacing: 8px;
  color: #2b2b2b;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.landing-wrraper .landing-home .landing-home-contain h2 {
  font-size: calc(22px + (35 - 22) * ((100vw - 300px) / (1920 - 300)));
  color: #78266a;
  font-weight: 500;
  margin-bottom: 15px;
}

.landing-wrraper .landing-home .landing-home-contain h2 span {
  color: #2b2b2b;
  font-weight: 700;
}

.landing-wrraper .landing-home .landing-home-contain p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
  margin-bottom: 30px;
  color: #999;
}

.landing-wrraper .landing-home .position-block .circle1,
.landing-wrraper .landing-home .position-block .star,
.landing-wrraper .landing-home .position-block .cross,
.landing-wrraper .landing-home .position-block .img-parten,
.landing-wrraper .landing-home .position-block .img1,
.landing-wrraper .landing-home .position-block .img2,
.landing-wrraper .landing-home .position-block .img3,
.landing-wrraper .landing-home .position-block .img4,
.landing-wrraper .landing-home .position-block .img5,
.landing-wrraper .landing-home .position-block .img6,
.landing-wrraper .landing-home .position-block .img7,
.landing-wrraper .landing-home .position-block .img8,
.landing-wrraper .landing-home .position-block .img9 {
  position: absolute;
}

.landing-wrraper .landing-home .position-block .circle1.opicity3,
.landing-wrraper .landing-home .position-block .star.opicity3,
.landing-wrraper .landing-home .position-block .cross.opicity3,
.landing-wrraper .landing-home .position-block .img-parten.opicity3,
.landing-wrraper .landing-home .position-block .img1.opicity3,
.landing-wrraper .landing-home .position-block .img2.opicity3,
.landing-wrraper .landing-home .position-block .img3.opicity3,
.landing-wrraper .landing-home .position-block .img4.opicity3,
.landing-wrraper .landing-home .position-block .img5.opicity3,
.landing-wrraper .landing-home .position-block .img6.opicity3,
.landing-wrraper .landing-home .position-block .img7.opicity3,
.landing-wrraper .landing-home .position-block .img8.opicity3,
.landing-wrraper .landing-home .position-block .img9.opicity3 {
  opacity: 0.3;
}

.landing-wrraper .landing-home .position-block .circle1 {
  width: 13px;
  height: 13px;
  border-radius: 6px;
  border: 3px solid #78266a;
  bottom: 7%;
  left: 10%;
  -webkit-animation: move1 9s linear infinite;
  animation: move1 9s linear infinite;
}

.landing-wrraper .landing-home .position-block .star {
  top: 48%;
  left: 33%;
  font-size: 14px;
  font-weight: 700;
  color: #78266a;
  line-height: 1;
  -webkit-animation: star 7s linear infinite;
  animation: star 7s linear infinite;
}

.landing-wrraper .landing-home .position-block .star.star1 {
  top: 79%;
  left: 39%;
  -webkit-animation: star 5s linear infinite;
  animation: star 5s linear infinite;
}

.landing-wrraper .landing-home .position-block .star.star2 {
  top: 23%;
  left: 20%;
  -webkit-animation: star 8s linear infinite;
  animation: star 8s linear infinite;
}

.landing-wrraper .landing-home .position-block .star.star3,
.landing-wrraper .landing-home .position-block .star.star4,
.landing-wrraper .landing-home .position-block .star.star5 {
  top: 18%;
  left: 47%;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
  -webkit-animation: star 9s linear infinite;
  animation: star 9s linear infinite;
}

.landing-wrraper .landing-home .position-block .star.star4 {
  top: 44%;
  left: 47%;
  -webkit-animation: star 4s linear infinite;
  animation: star 4s linear infinite;
}

.landing-wrraper .landing-home .position-block .star.star5 {
  top: 50%;
  left: 3%;
  -webkit-animation: star 6s linear infinite;
  animation: star 6s linear infinite;
}

.landing-wrraper .landing-home .position-block .img1,
.landing-wrraper .landing-home .position-block .img2,
.landing-wrraper .landing-home .position-block .img3,
.landing-wrraper .landing-home .position-block .img4,
.landing-wrraper .landing-home .position-block .img5,
.landing-wrraper .landing-home .position-block .img6,
.landing-wrraper .landing-home .position-block .img7,
.landing-wrraper .landing-home .position-block .img8,
.landing-wrraper .landing-home .position-block .img9 {
  border: 1px solid #e6edef;
  position: unset;
}

.landing-wrraper .landing-home .position-block .img1 {
  -webkit-animation: move2 1.2s infinite alternate;
  animation: move2 1.2s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img2 {
  -webkit-animation: move2 1.3s infinite alternate;
  animation: move2 1.3s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img3 {
  -webkit-animation: move2 1.4s infinite alternate;
  animation: move2 1.4s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img4 {
  -webkit-animation: move2 1.5s infinite alternate;
  animation: move2 1.5s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img5 {
  -webkit-animation: move2 1.6s infinite alternate;
  animation: move2 1.6s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img6 {
  -webkit-animation: move2 1.2s infinite alternate;
  animation: move2 1.2s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img7 {
  -webkit-animation: move2 1.3s infinite alternate;
  animation: move2 1.3s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img8 {
  -webkit-animation: move2 1.4s infinite alternate;
  animation: move2 1.4s infinite alternate;
}

.landing-wrraper .landing-home .position-block .img9 {
  -webkit-animation: move2 1.5s infinite alternate;
  animation: move2 1.5s infinite alternate;
}

.landing-wrraper .landing-home .position-block ul {
  position: absolute;
  top: 80px;
  bottom: 0;
  right: 30px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  text-align: right;
}

.landing-wrraper .landing-home .position-block ul .v-align-b {
  vertical-align: bottom;
}

.landing-wrraper .landing-home .position-block ul .v-align-t {
  vertical-align: top;
}

.landing-wrraper .landing-home .position-block ul .v-align-c {
  vertical-align: middle;
}

.landing-wrraper .landing-home .position-block ul .img-parten.top-parten {
  top: 15px;
  left: -20px;
  -webkit-animation: move2 1.2s infinite alternate;
  animation: move2 1.2s infinite alternate;
}

.landing-wrraper .landing-home .position-block ul .img-parten.bottom-parten {
  top: 100px;
  left: 0;
  -webkit-animation: move2 1.3s infinite alternate;
  animation: move2 1.3s infinite alternate;
}

.landing-wrraper .landing-home .position-block ul li {
  position: relative;
  margin-left: auto;
}

.landing-wrraper .landing-home .position-block ul li img {
  margin-left: 45px;
  -webkit-filter: drop-shadow(0px 13px 17.5px #e6edef);
  filter: drop-shadow(0px 13px 17.5px #e6edef);
}

.landing-wrraper .landing-home .position-block ul li:nth-child(n + 2) {
  margin-top: 30px;
}

.landing-wrraper .demo-section .demo-block {
  margin-bottom: -30px;
}

.landing-wrraper .demo-section .demo-block > div {
  margin-bottom: 30px;
}

.landing-wrraper .demo-section .demo-box .img-wrraper {
  position: relative;
  border: 5px solid #e6edef;
  border-radius: 15px;
  overflow: hidden;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.landing-wrraper .demo-section .demo-box .img-wrraper img {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.landing-wrraper .demo-section .demo-box .img-wrraper .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.landing-wrraper .demo-section .demo-box .img-wrraper .overlay ul.demo-link {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.landing-wrraper .demo-section .demo-box .img-wrraper .overlay ul.demo-link li {
  display: inline-block;
}

.landing-wrraper
  .demo-section
  .demo-box
  .img-wrraper
  .overlay
  ul.demo-link
  li:nth-child(n + 2) {
  margin-left: 10px;
}

.landing-wrraper
  .demo-section
  .demo-box
  .img-wrraper
  .overlay
  ul.demo-link
  li
  a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid #78266a;
}

.landing-wrraper
  .demo-section
  .demo-box
  .img-wrraper
  .overlay
  ul.demo-link
  li
  a
  img {
  width: auto;
  height: 50%;
}

.landing-wrraper .demo-section .demo-box .demo-detail {
  padding-top: 15px;
}

.landing-wrraper .demo-section .demo-box .demo-detail .demo-title {
  text-align: center;
}

.landing-wrraper .demo-section .demo-box .demo-detail .demo-title h3 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0;
  color: #2b2b2b;
}

.landing-wrraper .demo-section .demo-box:hover .img-wrraper {
  border-color: rgba(36, 105, 92, 0.3);
}

.landing-wrraper .demo-section .demo-box:hover .img-wrraper img {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}

.landing-wrraper .demo-section .demo-box:hover .img-wrraper .overlay {
  visibility: visible;
  opacity: 1;
}

.landing-wrraper .demo-section .demo-box:hover .demo-detail .demo-title h3 {
  color: #78266a;
}

.landing-wrraper .framework .nav {
  margin-bottom: 50px;
}

.landing-wrraper .framework .nav .nav-item:nth-child(n + 2) {
  margin-left: 10px;
}

.landing-wrraper .framework .nav .nav-link {
  border: 2px solid rgba(36, 105, 92, 0.2);
  padding: 10px 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.landing-wrraper .framework .nav .nav-link h5 {
  font-weight: 700;
}

.landing-wrraper .framework .nav .nav-link p {
  color: #999;
}

.landing-wrraper .framework .nav .nav-link.active,
.landing-wrraper .framework .nav .nav-link.show {
  background-color: transparent;
  color: #78266a;
  border: 2px solid #78266a;
  border-radius: 0;
}

.landing-wrraper .framework .nav .nav-link img {
  height: 35px;
  margin-right: 10px;
}

.landing-wrraper .framework ul.framworks-list {
  text-align: center;
  margin-bottom: -9px;
}

.landing-wrraper .framework ul.framworks-list li {
  display: inline-block;
  background-color: rgba(36, 105, 92, 0.05);
  padding: 40px 10px;
  margin-bottom: 30px;
  border-radius: 10px;
  border: 1px solid #e6edef;
  margin: 10px;
  height: 185px;
  width: 185px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-align: center;
}

.landing-wrraper .framework ul.framworks-list li img {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.landing-wrraper .framework ul.framworks-list li h6 {
  margin-top: 10px;
  margin-bottom: unset;
}

.landing-wrraper .framework ul.framworks-list li:hover {
  background-color: rgba(36, 105, 92, 0.1);
}

.landing-wrraper .framework ul.framworks-list li:hover img {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.landing-wrraper .unique-cards .unique-block {
  margin-bottom: -30px;
}

.landing-wrraper .unique-cards .card .img-wrraper {
  -webkit-box-shadow: 0 0 37px rgba(36, 105, 92, 0.05);
  box-shadow: 0 0 37px rgba(36, 105, 92, 0.05);
}

.landing-wrraper .unique-cards .card .img-wrraper img {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.landing-wrraper .unique-cards .card .img-wrraper:hover {
  -webkit-box-shadow: 0 0 37px rgba(36, 105, 92, 0.15);
  box-shadow: 0 0 37px rgba(36, 105, 92, 0.15);
}

.landing-wrraper .unique-cards .card .img-wrraper:hover img {
  -webkit-transform: rotate3d(1, 1, 1, -3deg);
  transform: rotate3d(1, 1, 1, -3deg);
}

.landing-wrraper .ecommerce-pages .pages-box .page-detail {
  margin-top: 25px;
  text-align: center;
}

.landing-wrraper .ecommerce-pages .pages-box .page-detail h3 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0;
  color: #2b2b2b;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.landing-wrraper .ecommerce-pages .pages-box:hover .page-detail h3 {
  color: #78266a;
}

.landing-wrraper .core-feature .feature-block {
  margin-bottom: -24px;
}

.landing-wrraper .core-feature .feature-block > div {
  margin-bottom: 25px;
}

.landing-wrraper .core-feature .feature-box {
  border: 1px solid #e6edef;
  padding: 45px 16px;
  text-align: center;
  border-radius: 15px;
}

.landing-wrraper .core-feature .feature-box .icon-wrraper {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background-color: rgba(36, 105, 92, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.landing-wrraper .core-feature .feature-box .icon-wrraper svg {
  width: 50%;
  height: auto;
  color: #78266a;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.landing-wrraper .core-feature .feature-box h6 {
  margin-bottom: 0;
  margin-top: 10px;
  font-weight: 700;
  text-transform: capitalize;
}

.landing-wrraper .core-feature .feature-box:hover .icon-wrraper {
  background-color: #78266a;
}

.landing-wrraper .core-feature .feature-box:hover .icon-wrraper svg {
  color: #fff;
}

.landing-wrraper .landing-footer .footer-contain {
  text-align: center;
}

.landing-wrraper .landing-footer .footer-contain .rating-wrraper {
  margin-bottom: 10px;
}

.landing-wrraper .landing-footer .footer-contain .rating-wrraper svg {
  width: auto;
  height: calc(95px + (120 - 95) * ((100vw - 320px) / (1920 - 320)));
}

.landing-wrraper .landing-footer .footer-contain h2 {
  font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1.4;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.landing-wrraper .landing-footer .footer-contain p {
  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
  color: #999;
  font-weight: 600;
}

.landing-wrraper .landing-footer .footer-contain .star-rate {
  margin-bottom: 50px;
}

.landing-wrraper .landing-footer .footer-contain .star-rate li {
  display: inline-block;
}

.landing-wrraper .landing-footer .footer-contain .star-rate li i {
  font-size: calc(20px + (35 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.landing-wrraper
  .landing-footer
  .footer-contain
  .btn-footer
  a:nth-child(n + 2) {
  margin-left: 10px;
}

.landing-wrraper .sub-footer {
  padding-top: 15px;
  padding-bottom: 15px;
}

.landing-wrraper .sub-footer .footer-contain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.landing-wrraper .sub-footer .footer-contain p {
  font-weight: 600;
  margin-left: auto;
  color: #999;
}

/**=====================
     38. Landing CSS Ends
==========================**/

/**========================
     38. Landing CSS Start
===========================**/

@media (max-width: 1880px) {
  .landing-wrraper .landing-home .position-block .img1,
  .landing-wrraper .landing-home .position-block .img2,
  .landing-wrraper .landing-home .position-block .img3,
  .landing-wrraper .landing-home .position-block .img4,
  .landing-wrraper .landing-home .position-block .img5,
  .landing-wrraper .landing-home .position-block .img6,
  .landing-wrraper .landing-home .position-block .img7,
  .landing-wrraper .landing-home .position-block .img8,
  .landing-wrraper .landing-home .position-block .img9 {
    width: auto;
  }

  .landing-wrraper .landing-home .position-block .img1 {
    height: 80px;
  }

  .landing-wrraper .landing-home .position-block .img2 {
    height: 130px;
  }

  .landing-wrraper .landing-home .position-block .img3 {
    height: 100px;
  }

  .landing-wrraper .landing-home .position-block .img4 {
    height: 160px;
  }

  .landing-wrraper .landing-home .position-block .img5 {
    height: 230px;
  }

  .landing-wrraper .landing-home .position-block .img6 {
    height: 180px;
  }

  .landing-wrraper .landing-home .position-block .img7 {
    height: 120px;
  }

  .landing-wrraper .landing-home .position-block .img8 {
    height: 140px;
  }

  .landing-wrraper .landing-home .position-block .img9 {
    height: 210px;
  }

  .landing-wrraper .landing-home .position-block ul li img {
    margin-left: 35px;
  }

  .landing-wrraper .landing-home .position-block ul .img-parten.bottom-parten {
    top: 70px;
  }
}

@media (max-width: 1660px) {
  .landing-wrraper .custom-container {
    max-width: 1440px;
  }
}

@media (max-width: 1470px) {
  .landing-wrraper .custom-container {
    max-width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 1367px) {
  .landing-wrraper .landing-home .position-block .img1 {
    height: 70px;
  }

  .landing-wrraper .landing-home .position-block .img2 {
    height: 110px;
  }

  .landing-wrraper .landing-home .position-block .img3 {
    height: 75px;
  }

  .landing-wrraper .landing-home .position-block .img4 {
    height: 140px;
  }

  .landing-wrraper .landing-home .position-block .img5 {
    height: 200px;
  }

  .landing-wrraper .landing-home .position-block .img6 {
    height: 140px;
  }

  .landing-wrraper .landing-home .position-block .img7 {
    height: 100px;
  }

  .landing-wrraper .landing-home .position-block .img8 {
    height: 115px;
  }

  .landing-wrraper .landing-home .position-block .img9 {
    height: 160px;
  }

  .landing-wrraper .landing-home .position-block ul .img-parten {
    width: auto;
    height: 50px;
  }

  .landing-wrraper .landing-home .position-block ul .img-parten.bottom-parten {
    left: 20px;
  }

  .landing-wrraper .landing-home .position-block ul li img {
    margin-left: 25px;
  }

  .landing-wrraper .landing-home .position-block ul li:nth-child(n + 2) {
    margin-top: 20px;
  }

  .landing-wrraper
    .demo-section
    .demo-box
    .img-wrraper
    .overlay
    ul.demo-link
    li
    a {
    width: 40px;
    height: 40px;
  }

  .landing-wrraper
    .demo-section
    .demo-box
    .img-wrraper
    .overlay
    ul.demo-link
    li
    a
    img {
    height: 45%;
  }

  .landing-wrraper .framework .nav {
    margin-bottom: 40px;
  }

  .landing-wrraper .framework .nav .nav-link h5 {
    font-size: 18px;
  }

  .landing-wrraper .framework .nav .nav-link p {
    font-size: 12px;
  }

  .landing-wrraper .framework ul.framworks-list li {
    height: 175px;
    width: 175px;
  }

  .landing-wrraper .framework ul.framworks-list li img {
    width: auto;
    height: 60px;
  }
}

@media (max-width: 1199px) {
  .landing-wrraper
    .landing-header
    ul.landing-menu
    li.nav-item
    .form-inline
    .nav-link {
    padding: 5px 20px;
  }

  .landing-wrraper .custom-container {
    padding-left: 40px;
    padding-right: 40px;
  }

  .landing-wrraper .title {
    margin-bottom: 35px;
  }

  .landing-wrraper .title h2 {
    font-size: 28px;
  }

  .landing-wrraper .section-py-space {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .landing-wrraper .section-pt-space {
    padding-top: 60px;
  }

  .landing-wrraper .section-pb-space {
    padding-bottom: 60px;
  }

  .landing-wrraper .landing-header ul.landing-menu li.nav-item a.nav-link {
    padding: 5px 15px;
  }

  .landing-wrraper .landing-home .position-block .img5 {
    height: 180px;
  }

  .landing-wrraper
    .demo-section
    .demo-box
    .img-wrraper
    .overlay
    ul.demo-link
    li
    a {
    width: 35px;
    height: 35px;
  }

  .landing-wrraper
    .demo-section
    .demo-box
    .img-wrraper
    .overlay
    ul.demo-link
    li:nth-child(n + 2) {
    margin-left: 5px;
  }

  .landing-wrraper .landing-footer .footer-contain h2 {
    width: 65%;
    margin-bottom: 5px;
  }

  .landing-wrraper .landing-footer .footer-contain p {
    margin-bottom: 8px;
  }

  .landing-wrraper .landing-footer .footer-contain .star-rate {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .landing-wrraper .landing-home .landing-home-contain {
    background-color: rgba(255, 255, 255, 0.57);
    padding: 30px 0;
    border-radius: 20px;
  }

  .landing-wrraper .btn-landing {
    padding: 10px 25px;
  }

  .landing-wrraper .landing-header {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .landing-wrraper .landing-header ul.landing-menu {
    position: fixed;
    top: 0;
    right: -320px;
    width: 300px;
    height: 100vh;
    background-color: #fff;
    display: block;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .landing-wrraper .landing-header ul.landing-menu.open {
    right: 0;
  }

  .landing-wrraper .landing-header ul.landing-menu li.nav-item {
    display: block;
    background-color: #fff;
    text-align: left;
  }

  .landing-wrraper .landing-header ul.landing-menu li.nav-item.menu-back {
    display: block;
  }

  .landing-wrraper
    .landing-header
    ul.landing-menu
    li.nav-item
    .form-inline
    .nav-link {
    padding: 5px 15px;
  }

  .landing-wrraper .landing-header .buy-block .toggle-menu {
    display: block;
  }

  .landing-wrraper .landing-home {
    height: 78vh;
    overflow: hidden;
  }

  .landing-wrraper .landing-home .landing-home-contain {
    height: 58vh;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    text-align: center;
  }

  .landing-wrraper .landing-home .landing-home-contain h2 {
    font-size: 24px;
  }

  .landing-wrraper .landing-home .landing-home-contain p {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    margin-bottom: 25px;
  }

  .landing-wrraper .landing-home .landing-home-contain .btn-landing {
    margin-left: auto;
    margin-right: auto;
  }

  .landing-wrraper .landing-home .position-block ul {
    width: 100%;
    text-align: center;
    padding: 30px;
    top: 0;
    height: 100%;
  }

  .landing-wrraper .landing-home .position-block ul .img-parten {
    display: none;
  }

  .landing-wrraper .landing-home .position-block ul li {
    position: absolute;
    width: 100%;
  }

  .landing-wrraper .landing-home .position-block ul li img {
    margin-left: unset;
  }

  .landing-wrraper .landing-home .position-block ul li > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .landing-wrraper .landing-home .position-block ul li:first-child {
    top: 13%;
  }

  .landing-wrraper .landing-home .position-block ul li:nth-child(2) {
    top: 33%;
  }

  .landing-wrraper .landing-home .position-block ul li:last-child {
    bottom: 20px;
  }

  .landing-wrraper .landing-home .position-block .img1,
  .landing-wrraper .landing-home .position-block .img2,
  .landing-wrraper .landing-home .position-block .img3,
  .landing-wrraper .landing-home .position-block .img4,
  .landing-wrraper .landing-home .position-block .img5,
  .landing-wrraper .landing-home .position-block .img6,
  .landing-wrraper .landing-home .position-block .img7,
  .landing-wrraper .landing-home .position-block .img8,
  .landing-wrraper .landing-home .position-block .img9 {
    position: absolute;
  }

  .landing-wrraper .landing-home .position-block .img1 {
    top: 0;
    left: 3%;
  }

  .landing-wrraper .landing-home .position-block .img2 {
    top: 0;
    left: 20%;
  }

  .landing-wrraper .landing-home .position-block .img3 {
    top: 0;
    right: 40%;
  }

  .landing-wrraper .landing-home .position-block .img4 {
    top: 0;
    right: 10%;
  }

  .landing-wrraper .landing-home .position-block .img5 {
    height: 120px;
    top: -12px;
    left: -20px;
  }

  .landing-wrraper .landing-home .position-block .img6 {
    top: 45%;
    right: -20px;
    height: 108px;
  }

  .landing-wrraper .landing-home .position-block .img7 {
    bottom: 0;
    left: 5%;
  }

  .landing-wrraper .landing-home .position-block .img8 {
    bottom: 0;
    right: 45%;
  }

  .landing-wrraper .landing-home .position-block .img9 {
    bottom: 0;
    right: 10%;
  }

  .landing-wrraper .framework .nav {
    margin-bottom: 30px;
  }

  .landing-wrraper .framework ul.framworks-list li {
    width: 150px;
    height: 150px;
  }

  .landing-wrraper .framework ul.framworks-list li img {
    height: 40px;
  }
}

@media (max-width: 767px) {
  .landing-wrraper .custom-container {
    padding-left: 25px;
    padding-right: 25px;
  }

  .landing-wrraper .title {
    margin-bottom: 25px;
  }

  .landing-wrraper .title h2 {
    font-size: 24px;
  }

  .landing-wrraper .section-py-space {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .landing-wrraper .section-pt-space {
    padding-top: 50px;
  }

  .landing-wrraper .section-pb-space {
    padding-bottom: 50px;
  }

  .landing-wrraper .landing-header {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .landing-wrraper .landing-header ul.landing-menu li.nav-item.menu-back {
    padding: 18px 15px;
  }

  .landing-wrraper .landing-home {
    height: auto;
  }

  .landing-wrraper .landing-home .position-block ul {
    display: none;
  }

  .landing-wrraper .landing-home .landing-home-contain {
    margin-top: 60px;
    background-color: transparent;
    padding: 60px 0 0 0;
    height: auto;
  }

  .landing-wrraper .landing-home .landing-home-contain .landing-logo {
    margin-bottom: 20px;
  }

  .landing-wrraper .landing-home .landing-home-contain .landing-logo img {
    width: auto;
    height: 50px;
  }

  .landing-wrraper .landing-home .landing-home-contain h6 {
    margin-bottom: 10px;
  }

  .landing-wrraper .landing-home .landing-home-contain h2 {
    margin-bottom: 10px;
  }

  .landing-wrraper .landing-home .landing-home-contain p {
    margin-bottom: 20px;
  }

  .landing-wrraper .demo-section .demo-block > div {
    margin-bottom: 20px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .landing-wrraper .framework .nav .nav-link img {
    height: 35px;
  }

  .landing-wrraper .framework .nav .nav-link h5 {
    font-size: 16px;
  }

  .landing-wrraper .framework ul.framworks-list li {
    width: 145px;
    height: 145px;
  }

  .landing-wrraper .framework ul.framworks-list li img {
    height: 40px;
  }

  .landing-wrraper .landing-footer .footer-contain h2 {
    width: auto;
    padding-left: 50px;
    padding-right: 50px;
  }

  .landing-wrraper .landing-footer .footer-contain .star-rate {
    margin-bottom: 30px;
  }

  .landing-wrraper .landing-footer .footer-contain .btn-footer a {
    padding: 5px 15px;
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .landing-wrraper .custom-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .landing-wrraper .title h2 {
    font-size: 22px;
  }

  .landing-wrraper .section-py-space {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .landing-wrraper .section-pt-space {
    padding-top: 40px;
  }

  .landing-wrraper .section-pb-space {
    padding-bottom: 40px;
  }

  .landing-wrraper .landing-home .landing-home-contain p {
    width: auto;
  }

  .landing-wrraper .demo-section .demo-box .img-wrraper img {
    width: 100%;
  }

  .landing-wrraper .framework .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .landing-wrraper .core-feature .feature-box {
    padding: 30px 10px;
  }

  .landing-wrraper .core-feature .feature-box .icon-wrraper {
    width: 50px;
    height: 50px;
  }

  .landing-wrraper .core-feature .feature-block {
    margin-bottom: -15px;
  }

  .landing-wrraper .core-feature .feature-block > div {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 15px;
  }

  .landing-wrraper .unique-cards .unique-block {
    margin-bottom: -15px;
  }

  .landing-wrraper .landing-footer .footer-contain {
    text-align: center;
  }

  .landing-wrraper .landing-footer .footer-contain h2 {
    padding-left: unset;
    padding-right: unset;
  }

  .landing-wrraper .sub-footer .footer-contain {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .landing-wrraper .sub-footer .footer-contain p {
    margin-right: auto;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .landing-wrraper .btn-footer {
    margin-bottom: -10px;
  }

  .landing-wrraper .btn-footer .btn {
    margin-bottom: 10px;
  }

  .landing-wrraper .landing-home .landing-home-contain {
    padding: 50px 0 0 0;
  }

  .landing-wrraper .landing-home .landing-home-contain .landing-logo {
    margin-bottom: 15px;
  }

  .landing-wrraper .landing-home .landing-home-contain .landing-logo img {
    height: 40px;
  }

  .landing-wrraper .landing-home .landing-home-contain h6 {
    font-size: 14px;
    letter-spacing: 0.05em;
  }

  .landing-wrraper .landing-home .landing-home-contain h2 {
    font-size: 20px;
  }

  .landing-wrraper .demo-section .demo-box .demo-detail .demo-title h3 {
    font-size: 18px;
  }

  .landing-wrraper .framework .nav .nav-item {
    width: auto;
  }

  .landing-wrraper .framework .nav .nav-link {
    padding-bottom: 10px;
    display: block !important;
  }

  .landing-wrraper .framework .nav .nav-link img {
    height: 25px;
    margin-right: unset;
  }

  .landing-wrraper .framework .nav .nav-link .text-start {
    display: none;
  }

  .landing-wrraper .framework .nav .nav-link.active,
  .landing-wrraper .framework .nav .nav-link.show {
    border-bottom: 2px solid #78266a;
  }

  .landing-wrraper .framework ul.framworks-list li {
    width: 110px;
    height: 110px;
    padding: 25px 0;
    margin: 5px;
  }

  .landing-wrraper .framework ul.framworks-list li img {
    height: 30px;
  }

  .landing-wrraper .core-feature .feature-box .icon-wrraper {
    width: 45px;
    height: 45px;
  }

  .landing-wrraper .ecommerce-pages .pages-box .page-detail {
    margin-top: 15px;
  }

  .landing-wrraper .ecommerce-pages .pages-box .page-detail h3 {
    font-size: 18px;
  }

  .landing-wrraper .landing-footer .footer-contain .star-rate {
    margin-bottom: 20px;
  }

  .landing-wrraper .landing-footer .footer-contain .btn-footer a {
    font-size: 14px;
    padding: 3px 12px;
  }
}

/**=======================
     38. Landing CSS end
==========================**/

/**=====================
     39. Table  CSS Start
==========================**/

.table-bordered td,
.table-bordered th {
  border-color: white !important;
}

.pills-component {
  width: 20%;
}

.product-page-width {
  width: 80%;
}

.table {
  margin-bottom: 0px;
}

.table th,
.table td {
  padding: 0.75rem;
}

.table th {
  color: #242934;
}

.table td {
  color: #242934;
}

.table [class*="bg-"] {
  color: #fff;
}

.table .bg-light {
  color: #242934;
}

.table tfoot {
  font-weight: bold;
}

.table thead.bg-primary tr th {
  color: white;
}

.table thead.table-primary {
  background-color: rgba(36, 105, 92, 0.1);
  --bs-table-bg: rgba($primary-color, 0.1);
  color: white;
}

.table thead.table-primary tr th {
  color: white;
}

.table-inverse th,
.table-inverse td {
  color: #fff;
}

.table[class*="bg-"] th,
.table[class*="bg-"] td {
  color: #fff;
}

.table-hover tbody tr:hover {
  background-color: rgba(36, 105, 92, 0.1);
  --bs-table-accent-bg: unset;
}

.table-hover tbody tr:hover th,
.table-hover tbody tr:hover td {
  color: #78266a;
}

.table-xl td,
.table-xl th {
  padding: 1.25rem 2rem;
  font-size: 120%;
}

.table-lg td,
.table-lg th {
  padding: 0.9rem 2rem;
  font-size: 110%;
}

.table-de td,
.table-de th {
  padding: 0.75rem 2rem;
}

.table-sm th,
.table-sm td {
  padding: 0.5rem 2rem;
  font-size: 90%;
}

.table-xs th,
.table-xs td {
  padding: 0.2rem 2rem;
  font-size: 80%;
}

.table-border-horizontal tr,
.table-border-horizontal th,
.table-border-horizontal td {
  border-top: 1px solid #f2f4ff;
  padding: 0.75rem 2rem;
}

.table-border-vertical tr,
.table-border-vertical th,
.table-border-vertical td {
  border-top: 0;
  border-right: 1px solid #f2f4ff;
}

.table-border-vertical tr td:last-child {
  border-right: none;
}

.table-bordernone td {
  border: 0;
}

.table-bordernone .bd-t-none {
  border-top: none;
}

.table-bordernone .u-s-tb {
  padding: 11px;
}

.table-inverse {
  color: #fff;
  background-color: #292b2c;
}

.table-inverse th,
.table-inverse td {
  border-color: #fff;
}

.table-inverse.table-bordered {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(245, 247, 250, 0.5);
}

.table-bordered td,
.table-bordered th {
  border-color: #fdfeff;
}

.table > :not(:last-child) > :last-child > * {
  border-color: #e6edef;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: #e6edef;
}

.table-styling.table-primary.table-striped tbody tr:nth-of-type(odd) {
  --bs-table-accent-bg: unset;
}

.table-styling.table-primary.table-striped tbody tr:nth-of-type(odd):hover {
  color: #78266a;
}

.table-styling.table-primary.table-striped tbody tr:nth-of-type(even):hover th,
.table-styling.table-primary.table-striped tbody tr:nth-of-type(even):hover td {
  color: #fff;
}

.table-success {
  background-color: rgba(27, 76, 67, 0.1);
  --bs-table-bg: rgba($success-color, 0.1);
  color: #1b4c43;
}

.table-success tr th {
  color: #1b4c43;
}

/**=====================
     39.Table CSS Ends
==========================**/

/**=====================
    41. Chart CSS Start
==========================**/

.radial-bar.radial-bar-lg:after,
.radial-bar.radial-bar-lg > img {
  width: 70px;
  height: 70px;
  margin-left: 15px;
  margin-top: 15px;
  line-height: 70px;
}

.radial-bar.radial-bar-sm:after,
.radial-bar.radial-bar-sm > img {
  width: 35px;
  height: 35px;
  margin-left: 7.5px;
  margin-top: 7.5px;
  line-height: 35px;
}

.radial-bar.radial-bar-xs:after,
.radial-bar.radial-bar-xs > img {
  width: 20px;
  height: 20px;
  margin-left: 5.5px;
  margin-top: 4.5px;
  line-height: 21px;
}

.cke_wysiwyg_frame,
.cke_wysiwyg_div {
  background-color: #fff;
}

.chart-block canvas {
  width: 100% !important;
}

.chart-block #pie-sparkline-chart {
  width: auto !important;
}

.chart-block .chart-overflow {
  overflow: hidden;
  text-align: center;
}

.candidcahrt {
  width: 100%;
  height: 470px;
}

.amcharts-export-canvas {
  position: absolute;
  display: none;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.amcharts-export-canvas .canvas-container {
  -webkit-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out;
}

.amcharts-export-canvas.active {
  display: block;
}

.amcharts-export-canvas.dropbox .canvas-container {
  opacity: 0.5;
}

.flot-chart-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 400px;
}

.flot-chart-container #toggling-series-flot {
  width: 79% !important;
}

.all-chart .flot-chart-container {
  height: 350px;
}

.flot-chart-placeholder {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.2em;
  text-align: center;
}

.flot-chart-placeholder .legend table {
  border-spacing: 5px;
}

.morris-hover {
  position: absolute;
  z-index: 8;
}

.morris-hover.morris-default-style {
  border-radius: 10px;
  padding: 6px;
  color: #898989;
  background: #fff;
  border: solid 1px #e6edef;
  font-family: sans-serif;
  font-size: 12px;
  text-align: center;
}

.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}

.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}

.chart-sparkline {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  background: rgba(49, 49, 49, 0.7);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
  color: #fff;
  font: 10px "Rubik", sans-serif, sans-serif;
  text-align: left;
  white-space: nowrap;
  padding: 5px;
  border: 1px solid rgba(49, 49, 49, 0.75);
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  z-index: 10000;
  border-radius: 5px;
}

.chart-sparkline .jqsfield {
  color: #fff;
  font: 10px "Rubik", sans-serif, sans-serif;
  text-align: left;
}

.radial-bar {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  background-color: transparent;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 80px;
  height: 80px;
  font-size: 18px;
  background-clip: content-box;
}

.radial-bar:after {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: #455a64;
  content: attr(data-label);
  background-color: #fff;
  z-index: 1;
  width: 56px;
  height: 56px;
  margin-left: 12px;
  margin-top: 12px;
  line-height: 56px;
}

.radial-bar > img {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: #455a64;
  z-index: 3;
  width: 56px;
  height: 56px;
  margin-left: 12px;
  margin-top: 12px;
  line-height: 56px;
}

.radial-bar-danger.radial-bar-20 {
  background-image: linear-gradient(
      90deg,
      #efefef 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(162deg, #d22d3d 50%, #efefef 50%, #efefef);
}

.radial-bar.radial-bar-lg {
  width: 100px;
  height: 100px;
  font-size: 20px;
}

.radial-bar.radial-bar-sm {
  width: 50px;
  height: 50px;
  font-size: 12px;
}

.radial-bar.radial-bar-xs {
  width: 30px;
  height: 30px;
  font-size: 8px;
}

.pieLabel div {
  color: #fff !important;
}

.pieLabel div input[type="checkbox"] {
  margin: 7px;
}

.knob-chart {
  min-height: 255px;
}

.knob-chart .chart-clock-main {
  position: relative;
  width: 200px;
  margin: 45px auto;
}

.knob-chart .chart-clock-main .clock-large {
  position: absolute;
  left: -48px;
  top: -45px;
}

.knob-chart .chart-clock-main .clock-medium {
  position: absolute;
  left: 2px;
  top: 5px;
}

.knob-chart .chart-clock-main .clock-small {
  position: absolute;
  left: 15px;
  top: 55px;
}

.peity-charts svg {
  width: 100%;
  height: 250px;
}

.peity-charts.peity-chart-primary svg polygon {
  fill: rgba(36, 105, 92, 0.5);
}

.peity-charts.peity-chart-primary svg polyline {
  stroke: #78266a;
}

p.data-attributes svg {
  margin-right: 10px;
}

.knob-block {
  position: relative;
}

.knob-block input {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  height: 100% !important;
  width: 100% !important;
  left: 0 !important;
  top: 0 !important;
  margin-left: 0 !important;
  margin-top: -3px !important;
}

.apex-chart {
  margin: 0 auto;
}

.apexcharts-menu-item {
  width: 110px;
}

.word-tree {
  height: 400px;
}

/**=====================
     41. Chart CSS End
==========================**/

/**=====================
     42. Timeline CSS Start
==========================**/

.activity .media {
  margin-bottom: 30px;
}

.activity .media:last-child {
  margin-bottom: 0;
}

.activity .media .gradient-round {
  position: relative;
  width: 53px;
  height: 53px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#1693f8),
    to(#0d67e9)
  );
  background-image: linear-gradient(#1693f8, #0d67e9);
  border-radius: 100%;
  top: -18px;
}

.activity .media .gradient-round svg {
  position: absolute;
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  top: 18px;
  right: 0;
  margin: 0 auto;
}

.activity .media .gradient-round svg path,
.activity .media .gradient-round svg line,
.activity .media .gradient-round svg circle {
  color: #fff;
}

.activity .media .gradient-round.gradient-line-1:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 62px;
  bottom: -77px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.activity .media .gradient-round.small-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 19px;
  bottom: -34px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.activity .media .gradient-round.medium-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 53px;
  bottom: -68px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.activity .media .media-body h6 {
  font-weight: 600;
  margin-bottom: 30px;
}

.activity .media .media-body h6 span {
  font-weight: normal;
  color: #999;
}

.activity .media .media-body p {
  width: 80%;
}

.timeline-small .media {
  margin-bottom: 30px;
}

.timeline-small .media:last-child {
  margin-bottom: 0;
}

.timeline-small .media .timeline-round {
  position: relative;
  width: 53px;
  height: 53px;
  border-radius: 100%;
  top: -18px;
}

.timeline-small .media .timeline-round svg {
  position: absolute;
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  top: 18px;
  right: 0;
  margin: 0 auto;
}

.timeline-small .media .timeline-round svg path,
.timeline-small .media .timeline-round svg line,
.timeline-small .media .timeline-round svg circle {
  color: #fff;
}

.timeline-small .media .timeline-round.timeline-line-1:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 62px;
  bottom: -77px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.timeline-small .media .timeline-round.small-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 19px;
  bottom: -34px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.timeline-small .media .timeline-round.medium-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 53px;
  bottom: -68px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.timeline-small .media .media-body h6 {
  font-weight: 600;
  margin-bottom: 30px;
}

.timeline-small .media .media-body h6 span {
  font-weight: normal;
  color: #999;
}

.timeline-small .media .media-body p {
  width: 80%;
}

.cd-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  z-index: 1;
}

.cd-container::after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (min-width: 1170px) {
  #cd-timeline::before {
    left: 50% !important;
    margin-left: -2px;
  }

  .cd-timeline-block {
    margin: 4em 0;
  }

  .cd-timeline-block:first-child {
    margin-top: 0;
  }

  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
    padding-right: 15px;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: #e6edef;
  }

  .cd-timeline-block:nth-child(odd) .cd-timeline-content .cd-date {
    padding-left: 15px;
  }

  .cd-timeline-img {
    width: 50px;
    height: 50px;
    left: 50%;
    margin-left: -30px;
  }

  .cd-timeline-img > i {
    font-size: 18px;
    top: 50%;
    left: 50%;
    padding: 3px;
  }

  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }

  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden;
  }

  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }

  .cssanimations
    .cd-timeline-block:nth-child(even)
    .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }

  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }

  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 16px;
  }

  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: #e6edef;
  }
}

#cd-timeline {
  position: relative;
  padding: 2em 0;
}

#cd-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  font-size: 1.5rem;
  height: 100%;
  width: 2px;
  background: #e6edef;
  left: -15px;
}

.cd-timeline-block {
  position: relative;
  margin: 30px 0;
}

.cd-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}

.cd-timeline-block:first-child {
  margin-top: 0;
}

.cd-timeline-block:last-child {
  margin-bottom: 0;
}

.cd-timeline-img {
  width: 60px;
  height: 60px;
  left: 50%;
  margin-left: -30px;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: 0;
  border-radius: 4px;
}

.cd-timeline-img i {
  display: block;
  position: relative;
  left: 45%;
  top: 44%;
  margin-left: -12px;
  margin-top: -12px;
  font-size: 27px;
  color: #fff;
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.cd-timeline-content {
  position: relative;
  background-color: #f5f7fb;
  border-radius: 4px;
  padding: 30px;
  border: 1px solid #e6edef;
}

.cd-timeline-content p {
  padding-top: 9px;
}

.cd-timeline-content h4 {
  margin-bottom: 0;
  font-weight: 700;
}

.cd-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}

.cd-timeline-content audio {
  width: 100%;
}

.cd-timeline-content .cd-date {
  font-weight: 700;
  font-size: 13px;
  display: inline-block;
  float: left;
  padding: 0.8em 0 0;
  opacity: 0.7;
}

.cd-timeline-content::before {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-left: 7px solid #e6edef;
}

@media only screen and (min-width: 768px) {
  .cd-timeline-content .cd-date {
    font-size: 14px;
    padding-bottom: 0;
  }
}

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media only screen and (max-width: 1169px) {
  .cd-timeline-block .cd-timeline-img {
    left: -2px;
    height: 30px;
    width: 30px;
    margin-left: -30px;
    top: 14px;
    z-index: 8;
  }

  .cd-timeline-block .cd-timeline-img > i {
    font-size: 15px;
    top: 16px;
    left: 17px;
    padding: 3px;
  }

  .landing-main #cd-timeline::before {
    left: -39px;
  }

  .landing-main .cd-timeline-block .cd-timeline-img {
    left: -34px;
    height: 50px;
    width: 50px;
    top: 1px;
    z-index: 8;
  }

  .landing-main .cd-timeline-block .cd-timeline-img > i {
    font-size: 23px;
    top: 21px;
    left: 7px;
    padding: 3px;
  }
}

@media only screen and (max-width: 576px) {
  .cd-timeline-content {
    padding: 15px;
  }

  .cd-timeline-content h4 {
    font-size: 18px;
  }
}

/**=====================
     42. Timeline  CSS Ends
==========================**/

/**=====================
    43. Timeline 2 CSS Start
==========================**/

.timeliny {
  position: relative;
  display: block;
  padding: 10rem 0;
  width: 100%;
  border-top: 1px solid #eff4f5;
  border-bottom: 1px solid #eff4f5;
  text-rendering: optimizeLegibility;
  color: #e6edef;
  opacity: 0;
  overflow: hidden;
  cursor: move;
}

.timeliny.loaded {
  opacity: 1;
}

.timeliny::before {
  content: "";
  position: absolute;
  background-color: rgba(36, 105, 92, 0.08);
  width: 100%;
  height: 1px;
  top: 50%;
  left: 0;
}

.timeliny .timeliny-wrapper {
  display: block;
  margin: auto;
  width: 100%;
  max-width: 1024px;
}

.timeliny .timeliny-dot {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -0.6rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  border: 1px solid #898989;
  background-color: #fff;
}

.timeliny .timeliny-dot::before {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  content: attr(data-year);
  display: block;
  position: absolute;
  top: -5rem;
  left: 1.25rem;
  color: #2c323f;
  font-family: "Rubik", sans-serif, sans-serif;
  font-size: 22px;
}

.timeliny .timeliny-dot::after {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: attr(data-text);
  display: block;
  position: absolute;
  width: 256px;
  top: 5.6rem;
  left: 1.25rem;
  color: #e6edef;
  opacity: 0;
  font-size: 0.9em;
}

@media (min-width: 768px) {
  .timeliny .timeliny-dot::after {
    width: 341.33333px;
  }
}

.timeliny .timeliny-timeline {
  position: absolute;
  top: 50%;
  left: 0;
  display: table;
  width: 100%;
  z-index: 5;
}

.timeliny .timeliny-timeline .timeliny-timeblock {
  display: table-cell;
  position: relative;
}

.timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot {
  border-color: #78266a;
  background-color: #78266a;
  -webkit-box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.33);
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.33);
}

.timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot::before {
  color: #2c323f;
}

.timeliny .timeliny-timeline .timeliny-timeblock.active .timeliny-dot::after {
  color: #2c323f;
  opacity: 1;
  background-color: #f5f7fb;
  border: 1px solid #e6edef;
  padding: 10px;
  border-radius: 5px;
}

.timeliny .timeliny-timeline .timeliny-timeblock.inactive .timeliny-dot {
  cursor: move;
}

.timeliny
  .timeliny-timeline
  .timeliny-timeblock.inactive
  .timeliny-dot::before {
  color: #e6edef;
}

.timeliny
  .timeliny-timeline
  .timeliny-timeblock:not(.inactive):not(.active)
  .timeliny-dot:hover {
  border-color: #e6edef;
  background-color: #78266a;
}

.timeliny
  .timeliny-timeline
  .timeliny-timeblock:not(.inactive):not(.active)
  .timeliny-dot:hover::before {
  color: #2c323f;
}

.timeliny .timeliny-timeline .timeliny-timeblock .timeliny-dot:hover::after {
  color: #2c323f;
  opacity: 1;
  background-color: #fff;
  border: 1px solid #efefef;
  padding: 10px;
  border-radius: 5px;
}

.timeliny .timeliny-vertical-line {
  position: absolute;
  display: block;
  z-index: 1;
  left: 16%;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #78266a;
}

@media (min-width: 768px) {
  .timeliny .timeliny-vertical-line {
    left: 32%;
  }
}

.timeliny .timeliny-vertical-line::before {
  content: "";
  position: absolute;
  top: 0;
  left: -7px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #78266a;
}

.timeliny .timeliny-vertical-line::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -7px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #78266a;
}

/**=====================
     43. Timeline 2 CSS Ends
==========================**/

/**=====================
    44. Tabs CSS Start
==========================**/

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #78266a;
  display: flex;
  align-items: center;
  gap: 8px;
}

.nav-pills .nav-link {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}

.nav i {
}

.nav-theme .nav-link,
.nav-pills.nav-theme .nav-link {
  color: #78266a;
}

.nav-theme .nav-link.active,
.nav-theme .show > .nav-link,
.nav-pills.nav-theme .nav-link.active,
.nav-pills.nav-theme .show > .nav-link {
  background-color: #78266a;
  color: #fff;
}

.nav-primary .nav-link,
.nav-pills.nav-primary .nav-link {
  color: #78266a;
}

.nav-primary .nav-link.active,
.nav-primary .show > .nav-link,
.nav-pills.nav-primary .nav-link.active,
.nav-pills.nav-primary .show > .nav-link {
  background-color: #78266a;
  color: #fff;
}

.nav-secondary .nav-link,
.nav-pills.nav-secondary .nav-link {
  color: #ba895d;
}

.nav-secondary .nav-link.active,
.nav-secondary .show > .nav-link,
.nav-pills.nav-secondary .nav-link.active,
.nav-pills.nav-secondary .show > .nav-link {
  background-color: #ba895d;
  color: #fff;
}

.nav-success .nav-link,
.nav-pills.nav-success .nav-link {
  color: #1b4c43;
}

.nav-success .nav-link.active,
.nav-success .show > .nav-link,
.nav-pills.nav-success .nav-link.active,
.nav-pills.nav-success .show > .nav-link {
  background-color: #1b4c43;
  color: #fff;
}

.nav-danger .nav-link,
.nav-pills.nav-danger .nav-link {
  color: #d22d3d;
}

.nav-danger .nav-link.active,
.nav-danger .show > .nav-link,
.nav-pills.nav-danger .nav-link.active,
.nav-pills.nav-danger .show > .nav-link {
  background-color: #d22d3d;
  color: #fff;
}

.nav-info .nav-link,
.nav-pills.nav-info .nav-link {
  color: #717171;
}

.nav-info .nav-link.active,
.nav-info .show > .nav-link,
.nav-pills.nav-info .nav-link.active,
.nav-pills.nav-info .show > .nav-link {
  background-color: #717171;
  color: #fff;
}

.nav-light .nav-link,
.nav-pills.nav-light .nav-link {
  color: #e6edef;
}

.nav-light .nav-link.active,
.nav-light .show > .nav-link,
.nav-pills.nav-light .nav-link.active,
.nav-pills.nav-light .show > .nav-link {
  background-color: #e6edef;
  color: #fff;
}

.nav-dark .nav-link,
.nav-pills.nav-dark .nav-link {
  color: #2c323f;
}

.nav-dark .nav-link.active,
.nav-dark .show > .nav-link,
.nav-pills.nav-dark .nav-link.active,
.nav-pills.nav-dark .show > .nav-link {
  background-color: #2c323f;
  color: #fff;
}

.nav-warning .nav-link,
.nav-pills.nav-warning .nav-link {
  color: #e2c636;
}

.nav-warning .nav-link.active,
.nav-warning .show > .nav-link,
.nav-pills.nav-warning .nav-link.active,
.nav-pills.nav-warning .show > .nav-link {
  background-color: #e2c636;
  color: #fff;
}

.nav-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.nav-tabs.nav-bottom .nav-item .nav-link.active,
.nav-tabs.nav-bottom .nav-item .nav-link.focus,
.nav-tabs.nav-bottom .nav-item .nav-link.hover {
  border-color: #fff #dee2e6 #dee2e6;
}

.tab-content ~ .nav-tabs.border-tab {
  margin-bottom: 0;
  margin-top: 30px;
}

.border-tab.nav-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 30px;
}

.border-tab.nav-tabs .nav-item .nav-link {
  font-size: 16px;
  color: #2c323f;
  text-align: center;
  padding: 10px 25px;
  border: none;
  border-bottom: 2px solid #e6edef;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: transparent;
}

.border-tab.nav-tabs .nav-item .nav-link.active,
.border-tab.nav-tabs .nav-item .nav-link.show,
.border-tab.nav-tabs .nav-item .nav-link:focus,
.border-tab.nav-tabs .nav-item .nav-link:hover {
  color: #78266a;
}

.border-tab.nav-tabs .nav-item .nav-link.active,
.border-tab.nav-tabs .nav-item .nav-link.show,
.border-tab.nav-tabs .nav-item .nav-link:focus {
  border-bottom: 2px solid #78266a;
}

.border-tab.nav-left .nav-link {
  border-left: 2px solid transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #2c323f;
}

.border-tab.nav-left .nav-link.active,
.border-tab.nav-left .show > .nav-link {
  background-color: transparent;
  border-left-color: #78266a;
  color: #78266a;
  border-radius: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.border-tab.nav-right .nav-link {
  border-right: 2px solid transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #2c323f;
  text-align: right;
}

.border-tab.nav-right .nav-link.active,
.border-tab.nav-right .show > .nav-link {
  background-color: transparent;
  border-right-color: #78266a;
  color: #78266a;
  border-radius: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.border-tab.nav-theme .nav-link.active,
.border-tab.nav-theme .nav-item.show,
.border-tab.nav-theme .nav-link:focus,
.border-tab.nav-theme .nav-link:hover {
  color: #78266a;
  background-color: transparent;
}

.border-tab.nav-theme .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-theme .nav-item .nav-link.active,
.border-tab.nav-theme .nav-item .nav-link.show,
.border-tab.nav-theme .nav-item .nav-link:focus {
  border-bottom: 2px solid #78266a;
}

.border-tab.nav-theme .nav-item .nav-link.active,
.border-tab.nav-theme .nav-item .nav-link.show,
.border-tab.nav-theme .nav-item .nav-link:focus,
.border-tab.nav-theme .nav-item .nav-link:hover {
  color: #78266a;
}

.border-tab.nav-left.nav-theme .nav-link.active,
.border-tab.nav-left.nav-theme .show > .nav-link {
  border-left-color: #78266a;
  color: #78266a;
}

.border-tab.nav-right.nav-theme .nav-link.active,
.border-tab.nav-right.nav-theme .show > .nav-link {
  border-right-color: #78266a;
  color: #78266a;
}

.border-tab.nav-primary .nav-link.active,
.border-tab.nav-primary .nav-item.show,
.border-tab.nav-primary .nav-link:focus,
.border-tab.nav-primary .nav-link:hover {
  color: #78266a;
  background-color: transparent;
}

.border-tab.nav-primary .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-primary .nav-item .nav-link.active,
.border-tab.nav-primary .nav-item .nav-link.show,
.border-tab.nav-primary .nav-item .nav-link:focus {
  border-bottom: 2px solid #78266a;
}

.border-tab.nav-primary .nav-item .nav-link.active,
.border-tab.nav-primary .nav-item .nav-link.show,
.border-tab.nav-primary .nav-item .nav-link:focus,
.border-tab.nav-primary .nav-item .nav-link:hover {
  color: #78266a;
}

.border-tab.nav-left.nav-primary .nav-link.active,
.border-tab.nav-left.nav-primary .show > .nav-link {
  border-left-color: #78266a;
  color: #78266a;
}

.border-tab.nav-right.nav-primary .nav-link.active,
.border-tab.nav-right.nav-primary .show > .nav-link {
  border-right-color: #78266a;
  color: #78266a;
}

.border-tab.nav-secondary .nav-link.active,
.border-tab.nav-secondary .nav-item.show,
.border-tab.nav-secondary .nav-link:focus,
.border-tab.nav-secondary .nav-link:hover {
  color: #ba895d;
  background-color: transparent;
}

.border-tab.nav-secondary .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-secondary .nav-item .nav-link.active,
.border-tab.nav-secondary .nav-item .nav-link.show,
.border-tab.nav-secondary .nav-item .nav-link:focus {
  border-bottom: 2px solid #ba895d;
}

.border-tab.nav-secondary .nav-item .nav-link.active,
.border-tab.nav-secondary .nav-item .nav-link.show,
.border-tab.nav-secondary .nav-item .nav-link:focus,
.border-tab.nav-secondary .nav-item .nav-link:hover {
  color: #ba895d;
}

.border-tab.nav-left.nav-secondary .nav-link.active,
.border-tab.nav-left.nav-secondary .show > .nav-link {
  border-left-color: #ba895d;
  color: #ba895d;
}

.border-tab.nav-right.nav-secondary .nav-link.active,
.border-tab.nav-right.nav-secondary .show > .nav-link {
  border-right-color: #ba895d;
  color: #ba895d;
}

.border-tab.nav-success .nav-link.active,
.border-tab.nav-success .nav-item.show,
.border-tab.nav-success .nav-link:focus,
.border-tab.nav-success .nav-link:hover {
  color: #1b4c43;
  background-color: transparent;
}

.border-tab.nav-success .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-success .nav-item .nav-link.active,
.border-tab.nav-success .nav-item .nav-link.show,
.border-tab.nav-success .nav-item .nav-link:focus {
  border-bottom: 2px solid #1b4c43;
}

.border-tab.nav-success .nav-item .nav-link.active,
.border-tab.nav-success .nav-item .nav-link.show,
.border-tab.nav-success .nav-item .nav-link:focus,
.border-tab.nav-success .nav-item .nav-link:hover {
  color: #1b4c43;
}

.border-tab.nav-left.nav-success .nav-link.active,
.border-tab.nav-left.nav-success .show > .nav-link {
  border-left-color: #1b4c43;
  color: #1b4c43;
}

.border-tab.nav-right.nav-success .nav-link.active,
.border-tab.nav-right.nav-success .show > .nav-link {
  border-right-color: #1b4c43;
  color: #1b4c43;
}

.border-tab.nav-danger .nav-link.active,
.border-tab.nav-danger .nav-item.show,
.border-tab.nav-danger .nav-link:focus,
.border-tab.nav-danger .nav-link:hover {
  color: #d22d3d;
  background-color: transparent;
}

.border-tab.nav-danger .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-danger .nav-item .nav-link.active,
.border-tab.nav-danger .nav-item .nav-link.show,
.border-tab.nav-danger .nav-item .nav-link:focus {
  border-bottom: 2px solid #d22d3d;
}

.border-tab.nav-danger .nav-item .nav-link.active,
.border-tab.nav-danger .nav-item .nav-link.show,
.border-tab.nav-danger .nav-item .nav-link:focus,
.border-tab.nav-danger .nav-item .nav-link:hover {
  color: #d22d3d;
}

.border-tab.nav-left.nav-danger .nav-link.active,
.border-tab.nav-left.nav-danger .show > .nav-link {
  border-left-color: #d22d3d;
  color: #d22d3d;
}

.border-tab.nav-right.nav-danger .nav-link.active,
.border-tab.nav-right.nav-danger .show > .nav-link {
  border-right-color: #d22d3d;
  color: #d22d3d;
}

.border-tab.nav-info .nav-link.active,
.border-tab.nav-info .nav-item.show,
.border-tab.nav-info .nav-link:focus,
.border-tab.nav-info .nav-link:hover {
  color: #717171;
  background-color: transparent;
}

.border-tab.nav-info .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-info .nav-item .nav-link.active,
.border-tab.nav-info .nav-item .nav-link.show,
.border-tab.nav-info .nav-item .nav-link:focus {
  border-bottom: 2px solid #717171;
}

.border-tab.nav-info .nav-item .nav-link.active,
.border-tab.nav-info .nav-item .nav-link.show,
.border-tab.nav-info .nav-item .nav-link:focus,
.border-tab.nav-info .nav-item .nav-link:hover {
  color: #717171;
}

.border-tab.nav-left.nav-info .nav-link.active,
.border-tab.nav-left.nav-info .show > .nav-link {
  border-left-color: #717171;
  color: #717171;
}

.border-tab.nav-right.nav-info .nav-link.active,
.border-tab.nav-right.nav-info .show > .nav-link {
  border-right-color: #717171;
  color: #717171;
}

.border-tab.nav-light .nav-link.active,
.border-tab.nav-light .nav-item.show,
.border-tab.nav-light .nav-link:focus,
.border-tab.nav-light .nav-link:hover {
  color: #e6edef;
  background-color: transparent;
}

.border-tab.nav-light .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-light .nav-item .nav-link.active,
.border-tab.nav-light .nav-item .nav-link.show,
.border-tab.nav-light .nav-item .nav-link:focus {
  border-bottom: 2px solid #e6edef;
}

.border-tab.nav-light .nav-item .nav-link.active,
.border-tab.nav-light .nav-item .nav-link.show,
.border-tab.nav-light .nav-item .nav-link:focus,
.border-tab.nav-light .nav-item .nav-link:hover {
  color: #e6edef;
}

.border-tab.nav-left.nav-light .nav-link.active,
.border-tab.nav-left.nav-light .show > .nav-link {
  border-left-color: #e6edef;
  color: #e6edef;
}

.border-tab.nav-right.nav-light .nav-link.active,
.border-tab.nav-right.nav-light .show > .nav-link {
  border-right-color: #e6edef;
  color: #e6edef;
}

.border-tab.nav-dark .nav-link.active,
.border-tab.nav-dark .nav-item.show,
.border-tab.nav-dark .nav-link:focus,
.border-tab.nav-dark .nav-link:hover {
  color: #2c323f;
  background-color: transparent;
}

.border-tab.nav-dark .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-dark .nav-item .nav-link.active,
.border-tab.nav-dark .nav-item .nav-link.show,
.border-tab.nav-dark .nav-item .nav-link:focus {
  border-bottom: 2px solid #2c323f;
}

.border-tab.nav-dark .nav-item .nav-link.active,
.border-tab.nav-dark .nav-item .nav-link.show,
.border-tab.nav-dark .nav-item .nav-link:focus,
.border-tab.nav-dark .nav-item .nav-link:hover {
  color: #2c323f;
}

.border-tab.nav-left.nav-dark .nav-link.active,
.border-tab.nav-left.nav-dark .show > .nav-link {
  border-left-color: #2c323f;
  color: #2c323f;
}

.border-tab.nav-right.nav-dark .nav-link.active,
.border-tab.nav-right.nav-dark .show > .nav-link {
  border-right-color: #2c323f;
  color: #2c323f;
}

.border-tab.nav-warning .nav-link.active,
.border-tab.nav-warning .nav-item.show,
.border-tab.nav-warning .nav-link:focus,
.border-tab.nav-warning .nav-link:hover {
  color: #e2c636;
  background-color: transparent;
}

.border-tab.nav-warning .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.border-tab.nav-warning .nav-item .nav-link.active,
.border-tab.nav-warning .nav-item .nav-link.show,
.border-tab.nav-warning .nav-item .nav-link:focus {
  border-bottom: 2px solid #e2c636;
}

.border-tab.nav-warning .nav-item .nav-link.active,
.border-tab.nav-warning .nav-item .nav-link.show,
.border-tab.nav-warning .nav-item .nav-link:focus,
.border-tab.nav-warning .nav-item .nav-link:hover {
  color: #e2c636;
}

.border-tab.nav-left.nav-warning .nav-link.active,
.border-tab.nav-left.nav-warning .show > .nav-link {
  border-left-color: #e2c636;
  color: #e2c636;
}

.border-tab.nav-right.nav-warning .nav-link.active,
.border-tab.nav-right.nav-warning .show > .nav-link {
  border-right-color: #e2c636;
  color: #e2c636;
}

.tabbed-card ul {
  position: absolute;
  right: 15px;
  top: 22px;
}

.tabbed-card ul.border-tab.nav-tabs {
  top: 0;
}

.tabbed-card ul.border-tab.nav-tabs .nav-item {
  float: right;
}

.tabbed-card ul.border-tab.nav-tabs .nav-item .nav-link {
  padding: 28px 15px;
}

/**=====================
     44. Tabs CSS Ends
==========================**/

/**=====================
     45. Gallery CSS Start
==========================**/

.profile-img-style img,
.scroll-bar-wrap img {
  width: 100%;
}

.gallery {
  margin-bottom: -30px;
}

.gallery > a {
  margin-bottom: 30px;
}

.gallery > a:before {
  content: "\edee";
  font-family: IcoFont;
  position: absolute;
  height: calc(100% - 10px);
  left: 20px;
  width: calc(100% - 40px);
  background-color: rgba(255, 255, 255, 0.27);
  top: 5px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 30px;
  color: #000;
}

.gallery > a:hover:before {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.gallery > a .img-thumbnail {
  border-radius: 0;
}

.gallery-with-description {
  margin-bottom: -30px;
}

.gallery-with-description a > div {
  padding: 10px;
  border: 1px solid #e4e6ec;
  margin-bottom: 30px;
}

.gallery-with-description a:hover {
  text-decoration: none !important;
}

.gallery-with-description h4 {
  color: #2c323f;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
}

.gallery-with-description p {
  color: #999;
}

.lg-backdrop {
  background-color: #fff;
}

.lg-outer .lg-actions .lg-next,
.lg-outer .lg-actions .lg-prev {
  background-color: white;
  border: 1px solid #efefef;
}

.lg-outer .lg-actions .lg-next:hover,
.lg-outer .lg-actions .lg-prev:hover {
  color: #2c323f;
}

.lg-outer .lg-toolbar {
  background-color: rgba(255, 255, 255, 0.45);
  border-bottom: 1px solid #2c323f;
}

.lg-outer .lg-toolbar .lg-icon:hover {
  color: #2c323f;
}

.lg-outer #lg-counter {
  color: #2c323f;
}

.lg-outer .lg-sub-html {
  background-color: rgba(255, 255, 255, 0.45);
}

.lg-outer .lg-sub-html h4 {
  font-size: inherit;
  color: #2c323f;
}

.lg-outer .lg-sub-html p {
  color: #2c323f;
}

.lg-outer .lg-thumb-outer {
  background-color: #e6edef;
}

.lg-outer .lg-thumb-item {
  padding: 4px;
  border: 1px solid #efefef;
  border-radius: 0;
}

.lg-outer .lg-thumb-item .active,
.lg-outer .lg-thumb-item:hover {
  border-color: #000;
}

.gallery a:before,
.gallery a:after {
  content: none;
}

.gallery .img-hover > div {
  overflow: hidden;
}

.gallery .hover-1 img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-1 img:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.gallery .hover-2 img {
  width: 100%;
  height: auto;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}

.gallery .hover-2:hover img {
  -webkit-transform: translateX(40px) scale(1.2);
  transform: translateX(40px) scale(1.2);
}

.gallery .hover-3 img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-3:hover img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.gallery .hover-4 img {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-4:hover img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.gallery .hover-5 img {
  margin-left: 30px;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-5:hover img {
  margin-left: 0;
}

.gallery .hover-6 img {
  -webkit-transform: rotate(15deg) scale(1.4);
  transform: rotate(15deg) scale(1.4);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-6:hover img {
  -webkit-transform: rotate(0) scale(1);
  transform: rotate(0) scale(1);
}

.gallery .hover-7 img {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-7:hover img {
  -webkit-filter: blur(0);
  filter: blur(0);
}

.gallery .hover-8 img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-8:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.gallery .hover-9 img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-9:hover img {
  -webkit-filter: sepia(0);
  filter: sepia(0);
}

.gallery .hover-10 img {
  -webkit-filter: grayscale(0) blur(0);
  filter: grayscale(0) blur(0);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-10:hover img {
  -webkit-filter: grayscale(100%) blur(3px);
  filter: grayscale(100%) blur(3px);
}

.gallery .hover-11 img {
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery .hover-11:hover img {
  opacity: 0.5;
}

@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.gallery .hover-13:hover img {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.gallery .hover-14 div {
  position: relative;
}

.gallery .hover-14 div:before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 0.3))
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.gallery .hover-14 div:hover:before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

.gallery .hover-15 div {
  position: relative;
}

.gallery .hover-15 div:before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}

.gallery .hover-15 div:hover:before {
  -webkit-animation: circle 0.75s;
  animation: circle 0.75s;
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.grid {
  position: relative;
}

/* clear fix */

.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

.grid-item img {
  max-width: 100%;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.gallery-with-description .grid-item img {
  border: 0;
  padding: 0;
}

/**=====================
     45. Gallery CSS Ends
==========================**/

/**=====================
    46. Blog CSS start
==========================**/

.blog-box .blog-wrraper {
  overflow: hidden;
}

.blog-box img {
  border-radius: 0.25rem;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.blog-box .top-radius-blog {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.blog-box h6 {
  font-weight: 600;
  margin: 15px 0;
  color: #242934;
}

.blog-box .blog-details {
  padding-right: 20px;
}

.blog-box .blog-details .blog-social li {
  display: inline-block;
  font-size: 14px;
  color: #999;
  font-weight: 500;
}

.blog-box .blog-details .blog-social li i {
  padding-right: 5px;
}

.blog-box .blog-details .blog-social li:nth-child(n + 2) {
  border-left: 1px solid #e6edef;
  padding-left: 20px;
  margin-left: 20px;
}

.blog-box .blog-details-main .blog-social {
  margin: 15px 0;
}

.blog-box .blog-details-main .blog-social li {
  display: inline-block;
  color: rgba(36, 41, 52, 0.7);
  font-size: 12px;
  padding: 0 15px;
  font-weight: 500;
  border-right: 1px solid #e6edef;
}

.blog-box .blog-details-main .blog-social li:last-child {
  border-right: 0;
}

.blog-box .blog-details-main .blog-social li i {
  padding-right: 5px;
}

.blog-box .blog-details-main .blog-bottom-details {
  margin: 20px;
  font-size: 14px;
  line-height: 1.6;
  font-weight: 700;
}

.blog-box .blog-date {
  color: rgba(36, 41, 52, 0.7);
  margin-bottom: 5px;
}

.blog-box .blog-date span {
  font-size: 36px;
  font-weight: 600;
  padding-right: 5px;
  color: #78266a;
  line-height: 1;
}

.blog-box .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-box .blog-details-second {
  padding: 20px;
}

.blog-box .blog-details-second .blog-post-date {
  margin-top: -48px;
  z-index: 2;
  position: relative;
}

.blog-box .blog-details-second .blog-post-date .blg-month,
.blog-box .blog-details-second .blog-post-date .blg-date {
  padding: 5px;
  display: block;
  width: 50px;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  font-weight: 600;
}

.blog-box .blog-details-second .blog-post-date .blg-month {
  background-color: #78266a;
}

.blog-box .blog-details-second .blog-post-date .blg-date {
  background-color: #ba895d;
}

.blog-box .blog-details-second h6 {
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 10px;
}

.blog-box .blog-details-second p {
  color: #999;
  margin-bottom: 15px;
}

.blog-box .blog-details-second .detail-footer {
  padding: 15px 20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  border-top: 1px solid #e6edef;
}

.blog-box .blog-details-second .detail-footer ul.sociyal-list li {
  display: inline-block;
  color: #999;
  text-transform: capitalize;
  font-weight: 600;
}

.blog-box .blog-details-second .detail-footer ul.sociyal-list li i {
  font-size: 12px;
  margin-right: 5px;
  font-weight: 600;
}

.blog-box
  .blog-details-second
  .detail-footer
  ul.sociyal-list
  li:nth-child(n + 2) {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #e6edef;
}

.blog-box:hover .blog-wrraper img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.blog-box.blog-shadow {
  height: 560px;
}

.blog-box.blog-shadow:before {
  -webkit-box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.6);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 5px;
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  bottom: 0;
}

.blog-box.blog-shadow:hover:before {
  -webkit-box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.65);
  box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.65);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.blog-box.blog-shadow .blog-details {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 40px;
}

.blog-box.blog-shadow .blog-details h4 {
  color: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
}

.blog-box.blog-shadow .blog-details p {
  font-size: 14px;
  color: #fff;
  padding-top: unset;
  margin-top: 0;
}

.blog-box.blog-shadow .blog-details .blog-social li {
  color: #fff;
}

.blog-list .blog-details hr {
  margin-top: 15px;
  margin-bottom: 15px;
}

.blog-list .blog-details .blog-social {
  margin-top: 0;
}

.blog-list .blog-details h6 {
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 1.6;
  font-weight: 600;
  color: #242934;
}

.blog-list img {
  width: 100%;
  padding: 17px;
}

.blog-grid img {
  width: 100%;
}

.blog-grid .blog-details {
  padding: 20px;
}

.blog-grid .blog-details h6 {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1.8;
}

.blog-box {
  position: relative;
  max-height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-box .blog-details p {
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.7px;
  margin-top: 10px;
  margin-bottom: 0;
  color: #999;
}

.blog-box .blog-details h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  margin-top: 20px;
  margin-bottom: 0;
}

.blog-box .blog-details .single-blog-content-top {
  border-top: 1px solid #e6edef;
}

.blog-box .blog-details .blog-social li,
.blog-box .blog-details .comment-social li {
  font-size: 14px;
  color: #999;
  font-weight: 600;
}

.comment-box {
  margin-top: 30px;
}

.comment-box h4 {
  font-weight: 700;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6edef;
}

.comment-box li {
  margin-bottom: 30px;
  clear: both;
}

.comment-box li:last-child {
  margin-bottom: 0;
}

.comment-box ul ul {
  margin-left: 135px;
  text-align: right;
}

.comment-box p {
  font-size: 14px;
  line-height: 1.8;
  text-align: justify;
  margin-bottom: 0;
  color: #999;
}

.comment-box .media img {
  margin-right: 45px;
  border-radius: 50%;
  height: 90px;
  padding: 7px;
  width: 90px;
  border: 1px solid #efefef;
}

.comment-box .media h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: left;
}

.comment-box .media h6 span {
  padding-left: 10px;
  font-size: 13px;
  color: #999;
  font-weight: 400;
}

.comment-box .comment-social {
  margin-left: 0;
}

.comment-box .comment-social li {
  display: inline-block;
  color: #cccccc;
  margin-bottom: 0;
  font-weight: 500;
  text-transform: uppercase;
}

.comment-box .comment-social li:nth-child(n + 2) {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #e6edef;
}

.comment-box .comment-social li i {
  padding-right: 10px;
}

.blog-single .blog-box .banner-wrraper {
  height: 400px;
}

.blog-single .blog-box .blog-details p {
  margin-top: 15px;
}

.blog-single .single-blog-content-top {
  margin-top: 15px;
}

.blog-single .comment-social {
  margin-left: unset;
}

@media (max-width: 1500px) {
  .blog-box.blog-shadow {
    height: 480px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1530px) {
  .blog-box .blog-details-second {
    padding: 15px;
  }

  .blog-box .blog-details-second p {
    font-size: 13px;
  }

  .blog-box .blog-details-second h6 {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .blog-box .blog-details-second .blog-post-date {
    margin-top: -35px;
  }

  .blog-box .blog-details-second .blog-post-date .blg-month,
  .blog-box .blog-details-second .blog-post-date .blg-date {
    font-size: 10px;
    padding: 3px;
  }

  .blog-box .blog-details-second .detail-footer {
    margin-bottom: -15px;
    margin-left: -15px;
    margin-right: -15px;
    padding: 10px 15px;
  }

  .blog-box .blog-details-second .detail-footer ul.sociyal-list li {
    font-size: 12px;
  }

  .blog-box
    .blog-details-second
    .detail-footer
    ul.sociyal-list
    li:nth-child(n + 2) {
    margin-left: 10px;
    padding-left: 10px;
  }
}

@media (max-width: 1400px) {
  .blog-box.blog-shadow {
    height: 440px;
  }
}

@media (max-width: 1365px) {
  .blog-box.blog-shadow {
    height: 440px;
  }
}

@media (max-width: 1280px) {
  .blog-box.blog-shadow {
    height: 365px;
  }
}

@media (max-width: 1199px) {
  .comment-box .blog-details h4 {
    margin-top: 50px;
  }

  .blog-box.blog-shadow {
    height: 380px;
  }

  .blog-box .media {
    display: block;
  }

  .blog-box .blog-details {
    padding: 20px;
  }

  .blog-list img {
    width: 100%;
    padding: 0;
  }

  .blog-single .blog-box .blog-details {
    padding: unset;
  }
}

@media (max-width: 991px) {
  .blog-box .blog-details p {
    margin-top: 10px;
  }

  .blog-box .blog-details .single-blog-content-top {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .comment-box ul ul {
    text-align: left;
  }

  .blog-single .blog-box .blog-details h4 {
    margin-top: 10px;
  }

  .blog-single .blog-box .blog-details .single-blog-content-top {
    margin-top: 10px;
  }

  .blog-single .blog-box .blog-details p {
    margin-top: 10px;
  }

  .blog-box .blog-details-second {
    padding: 15px;
  }

  .blog-box .blog-details-second h6 {
    margin-top: 20px;
  }

  .blog-box .blog-details-second .detail-footer {
    padding: 15px 15px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
  }

  .blog-box .blog-details-second .detail-footer ul.sociyal-list li {
    font-size: 12px;
  }

  .blog-box
    .blog-details-second
    .detail-footer
    ul.sociyal-list
    li:nth-child(n + 2) {
    margin-left: 10px;
    padding-left: 10px;
  }

  .blog-box .blog-details-second .blog-post-date {
    margin-top: -35px;
  }

  .blog-box .blog-details-second .blog-post-date .blg-month,
  .blog-box .blog-details-second .blog-post-date .blg-date {
    padding: 3px;
    font-size: 10px;
  }

  .blog-box .blog-details .blog-social li:nth-child(n + 2) {
    padding-left: 10px;
    margin-left: 10px;
  }

  .blog-box .blog-details .blog-social li,
  .blog-box .blog-details .comment-social li {
    font-size: 13px;
  }
}

@media (max-width: 575px) {
  .blog-single .comment-box .comment-social li {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .blog-box .blog-details-second h6 {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .blog-box .blog-details-second p {
    margin-bottom: 10px;
  }
}

/**=====================
    46. Blog CSS ends
==========================**/

/**=====================
     47. Footer CSS Start
==========================**/

.footer-links {
  text-align: right;
}

.footer-links li {
  display: inline-block;
  padding-right: 15px;
}

.footer-links li a {
  font-size: 12px;
}

footer {
  background-color: #fff;
  -webkit-box-shadow: 0 0 20px 16px rgba(4, 122, 251, 0.04);
  box-shadow: 0 0 20px 16px rgba(4, 122, 251, 0.04);
  padding: 15px;
  bottom: 0;
  left: 0;
  margin-left: 275px;
}

footer a {
  font-weight: 600;
}

footer.footer-starter-kit {
  bottom: -52px;
}

footer p {
  font-size: 11px;
  font-weight: 500;
  color: #999;
}

.page-wrapper .page-body-wrapper .footer-dark {
  background-color: #2b2b2b;
}

.page-wrapper .page-body-wrapper .footer-dark p {
  color: #fff !important;
}

.page-wrapper .page-body-wrapper .footer-dark li a {
  color: #fff;
}

.footer-fix {
  width: calc(100% - 255px);
  position: fixed;
}

.page-wrapper.horizontal-wrapper .page-body-wrapper footer {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  bottom: 0;
  z-index: 8;
  margin-left: 0;
}

.page-wrapper.horizontal-wrapper .page-body-wrapper footer p {
  color: #242934;
}

.page-wrapper.horizontal-wrapper .page-body-wrapper footer p i {
  color: #ba895d;
  margin-left: 5px;
}

/**=====================
     47. Footer CSS Ends
==========================**/

/**===========================
     48. Form Wizard CSS Start
=============================**/

/*Form Wizard One Start*/

.form-wizard .tab {
  display: none;
}

.form-wizard .btn-mb {
  margin-bottom: 30px;
}

.form-wizard .step {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #78266a;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.form-wizard .step.active {
  opacity: 1;
}

.form-wizard .step.finish {
  background-color: #ba895d;
}

/*Form Wizard One ends*/

/*Form Wizard Two Start*/

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}

.stepwizard .stepwizard-row {
  display: table-row;
}

.stepwizard .stepwizard-row:before {
  top: 16px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #e6edef;
}

.stepwizard .stepwizard-row .stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.stepwizard .stepwizard-row .stepwizard-step p {
  margin-top: 10px;
}

.stepwizard .stepwizard-row .stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard .stepwizard-row .stepwizard-step .btn {
  font-weight: 600;
}

/*Form Wizard Two Ends*/

/*Form Wizard Three Start*/

.f1 fieldset {
  display: none;
}

.f1 .input-error {
  border-color: #d22d3d;
}

.f1 .f1-steps {
  overflow: hidden;
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}

.f1 .f1-steps .f1-progress {
  position: absolute;
  top: 24px;
  width: 100%;
  height: 1px;
  background-color: #e6edef;
}

.f1 .f1-steps .f1-progress .f1-progress-line {
  height: 1px;
  background-color: #78266a;
  width: 16%;
}

.f1 .f1-steps .f1-step {
  position: relative;
  float: left;
  width: 33.333333%;
  text-align: center;
}

.f1 .f1-steps .f1-step.activated .f1-step-icon {
  border: 1px solid #78266a;
  color: #78266a;
  line-height: 38px;
}

.f1 .f1-steps .f1-step.activated p {
  color: #78266a;
}

.f1 .f1-steps .f1-step.active .f1-step-icon {
  background-color: #78266a;
  color: #fff;
}

.f1 .f1-steps .f1-step.active p {
  color: #78266a;
}

.f1 .f1-steps .f1-step p {
  color: #898989;
  font-weight: 600;
}

.f1 .f1-steps .f1-step .f1-step-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #e6edef;
  font-size: 16px;
  color: #78266a;
  line-height: 40px;
  border-radius: 50%;
}

.f1 .f1-buttons {
  text-align: right;
}

/*Form Wizard Three ends*/

/*Form Wizard Four Start*/

.wizard-4 .buttonDisabled {
  color: #2c323f;
  background-color: #e6edef;
  border: 1px solid #e6edef;
  cursor: text;
}

.wizard-4 .action-bar .btn {
  float: right;
  margin: 0 5px;
}

.wizard-4 .step-container {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0 solid #e6edef;
  overflow: hidden;
  clear: right;
  width: 70%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.wizard-4 .step-container div.content {
  display: block;
  position: absolute;
  float: left;
  height: 295px;
  text-align: left;
  overflow: auto;
  z-index: 1;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
}

.wizard-4 .loader {
  display: none;
}

.wizard-4 .msg-box {
  position: absolute;
  display: none;
  padding: 5px 10px;
  background-color: #78266a;
  left: 32%;
  bottom: 30px;
  border-radius: 2px;
}

.wizard-4 .msg-box .content {
  padding: 0;
  float: left;
  padding-top: 3px;
  color: #fff;
}

.wizard-4 .msg-box .close {
  text-shadow: none;
  color: #fff;
  margin-left: 10px;
  opacity: 1;
}

.wizard-4 ul.anchor {
  position: relative;
  display: block;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0 solid #e6edef;
  background: transparent;
  width: 30%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 30px;
}

.wizard-4 ul.anchor li {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  padding-bottom: 19px;
  float: left;
  clear: both;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 0;
}

.wizard-4 ul.anchor li a {
  display: block;
  position: relative;
  float: left;
  margin: 0;
  padding: 10px 20px;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  outline-style: none;
  z-index: 1;
  font-size: 18px;
}

.wizard-4 ul.anchor li a small {
  display: block;
  font-size: 14px;
}

.wizard-4 ul.anchor li a .stepNumber {
  position: relative;
  float: left;
  width: 30px;
  text-align: center;
  padding: 5px;
  padding-top: 0;
}

.wizard-4 ul.anchor li a.selected {
  color: #fff;
  background: #78266a;
  cursor: text;
  border-radius: 5px;
}

.wizard-4 ul.anchor li a.done {
  position: relative;
  color: #fff;
  background: #ba895d;
  z-index: 99;
  border-radius: 5px;
}

.wizard-4 ul.anchor li a.disabled {
  color: #898989;
  background: #e6edef;
  cursor: text;
  border-radius: 5px;
}

.wizard-4 ul.anchor li a.error {
  color: #898989;
  background: #fe8a7d;
  border: 1px solid #d22d3d;
}

.wizard-4 ul.anchor li a.error:hover {
  color: #000;
}

@media (max-width: 575px) {
  .f1 .f1-steps .f1-step .f1-step-icon {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }

  .f1 .f1-steps .f1-progress {
    top: 20px;
  }
}

/*Form Wizard Four Ends*/

/**=====================
    48. Form Wizard CSS Ends
==========================**/

/**=====================
    49. Badge CSS start
==========================**/

.badge {
  padding: 0.44em 0.7em;
}

.badge + .badge {
  margin-left: 5px;
}

.badge svg {
  width: 10px;
  height: 10px;
}

.pill-badge-primary {
  background-color: #78266a;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(36, 105, 92, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(36, 105, 92, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.pill-badge-secondary {
  background-color: #ba895d;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(186, 137, 93, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(186, 137, 93, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.pill-badge-success {
  background-color: #1b4c43;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(27, 76, 67, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(27, 76, 67, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.pill-badge-danger {
  background-color: #d22d3d;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(210, 45, 61, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(210, 45, 61, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.pill-badge-info {
  background-color: #717171;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(113, 113, 113, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(113, 113, 113, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.pill-badge-light {
  background-color: #e6edef;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(230, 237, 239, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(230, 237, 239, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.pill-badge-dark {
  background-color: #2c323f;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(44, 50, 63, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(44, 50, 63, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.pill-badge-warning {
  background-color: #e2c636;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(226, 198, 54, 0.25);
  box-shadow: -0.5px 4.33px 16px 0px rgba(226, 198, 54, 0.25);
  color: #fff;
  font-size: 9px;
  text-transform: capitalize;
  border-radius: 5px;
  font-weight: 600;
  padding: 5px 9px;
}

.round-badge-primary {
  border-radius: 6px;
  background-color: #78266a;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(36, 105, 92, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(36, 105, 92, 0.18);
  color: #fff !important;
}

.round-badge-secondary {
  border-radius: 6px;
  background-color: #ba895d;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(186, 137, 93, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(186, 137, 93, 0.18);
  color: #fff !important;
}

.round-badge-success {
  border-radius: 6px;
  background-color: #1b4c43;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(27, 76, 67, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(27, 76, 67, 0.18);
  color: #fff !important;
}

.round-badge-danger {
  border-radius: 6px;
  background-color: #d22d3d;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(210, 45, 61, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(210, 45, 61, 0.18);
  color: #fff !important;
}

.round-badge-info {
  border-radius: 6px;
  background-color: #717171;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(113, 113, 113, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(113, 113, 113, 0.18);
  color: #fff !important;
}

.round-badge-light {
  border-radius: 6px;
  background-color: #e6edef;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(230, 237, 239, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(230, 237, 239, 0.18);
  color: #fff !important;
}

.round-badge-dark {
  border-radius: 6px;
  background-color: #2c323f;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(44, 50, 63, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(44, 50, 63, 0.18);
  color: #fff !important;
}

.round-badge-warning {
  border-radius: 6px;
  background-color: #e2c636;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(226, 198, 54, 0.18);
  box-shadow: -0.5px -6.67px 14px 0px rgba(226, 198, 54, 0.18);
  color: #fff !important;
}

/**=====================
    49. Badge CSS Ends
==========================**/

/**=====================
     50. Tree  CSS Start
==========================**/

.jstree-default .jstree-clicked {
  background-color: rgba(36, 105, 92, 0.2);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #78266a;
}

.vakata-context,
.vakata-context ul {
  background-color: #fff;
  border: none;
  -webkit-box-shadow: 0 0 2px 2px #efefef;
  box-shadow: 0 0 2px 2px #efefef;
}

.vakata-context li a .vakata-contextmenu-sep,
.vakata-context ul li a .vakata-contextmenu-sep {
  display: none;
}

.vakata-context li a:hover,
.vakata-context ul li a:hover {
  background-color: #97dbcf;
  -webkit-box-shadow: None;
  box-shadow: None;
}

.vakata-context .vakata-context-separator,
.vakata-context ul .vakata-context-separator {
  display: none;
}

.jstree-default .jstree-anchor {
  font-weight: 500;
}

/**=====================
     50. Tree CSS Ends
==========================**/

/**=====================
     51. Search CSS Start
==========================**/

.search-page .search-form input:focus {
  outline: none;
}

.search-page .search-form .form-group {
  position: relative;
  border-radius: 50px;
  overflow: hidden;
}

.search-page .search-form .form-group .input-group-text {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 20px;
  border-color: #78266a;
  background-color: #78266a;
  padding: 0 20px;
}

.search-page .search-form .form-group .input-group-text i {
  color: #fff;
  font-weight: 600;
}

.search-page .info-block + .info-block {
  padding-top: 30px;
  border-top: 1px solid #e6edef;
  margin-top: 30px;
}

.search-page .border-tab.nav-tabs {
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

.search-page .border-tab.nav-tabs .nav-item {
  width: auto;
}

.search-page .search-links h6 {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.6;
}

.search-page p {
  margin-bottom: 0;
  color: #999;
}

.search-page ul.search-info > li {
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  color: #586082;
}

.search-page ul.search-info > li + li {
  border-left: 1px solid #a8b0c2;
  padding-left: 8px;
  margin-left: 8px;
}

.search-page ul.search-info > li i {
  color: #e2c636;
}

.search-page ul.search-info .rating li {
  display: inline-block;
}

.search-page #video-links .embed-responsive + .embed-responsive {
  margin-top: 30px;
}

.search-page .search-vid-block > div:nth-child(n + 2) {
  margin-top: 30px;
}

.search-list {
  margin-bottom: 30px;
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #78266a;
  border: none;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.search-list .nav-item {
  background-color: rgba(36, 105, 92, 0.1);
}

.search-list .nav-item .nav-link {
  border: none;
  padding: 0.7rem 1.5rem;
  opacity: 0.5;
}

.search-list .nav-item .nav-link.active {
  background-color: rgba(36, 105, 92, 0.1);
  opacity: 1;
}

.search-list .nav-item.show,
.search-list .nav-item.active {
  background-color: rgba(36, 105, 92, 0.1);
}

@media (max-width: 1199px) {
  .search-page .search-banner {
    margin-top: 30px;
  }

  .search-page .search-banner .card {
    margin-bottom: unset;
  }
}

@media (max-width: 767px) {
  .search-page .search-links h6 {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .search-page .search-form .form-group input {
    padding: 8px 8px 8px 60px;
  }

  .search-page .search-form .form-group .input-group-text {
    padding: 0 15px;
  }

  .search-page .info-block + .info-block {
    margin-top: 15px;
    padding-top: 15px;
  }

  .search-list {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-bottom: 15px;
  }

  .search-list .nav-item {
    display: inline-block;
    margin-bottom: 5px;
  }

  .search-list .nav-item .nav-link {
    padding: 8px 20px;
  }
}

/**=====================
    51. Search CSS Ends
==========================**/

/**=====================
    52. Datepicker CSS Start
==========================**/

.clockpicker-canvas line {
  stroke: #78266a;
}

.clockpicker-canvas-fg,
.clockpicker-canvas-bearing {
  fill: #78266a;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #78266a;
}

.datepicker--day-name {
  color: #000;
  font-weight: bold;
}

.datepicker--cell.-current- {
  color: #000;
  border-radius: 5px;
  font-weight: bold;
  border: 2px solid #78266a;
}

.datepicker--cell.-focus- {
  background: #78266a;
  color: #fff;
}

.datepicker--cell.-selected- {
  background: #78266a;
}

.daterangepicker.ltr {
  color: #898989;
  z-index: 8;
}

.daterangepicker.ltr tr {
  font-size: 13px;
}

.daterangepicker.ltr tr td {
  height: 34px;
  width: 34px;
}

.daterangepicker.ltr tr td.in-range {
  background-color: #fafafa;
}

.daterangepicker.ltr tr td.active {
  background-color: #78266a;
}

.daterangepicker.ltr .ranges {
  float: none;
}

.daterangepicker.ltr .ranges li {
  color: #78266a;
}

.daterangepicker.ltr .ranges li:hover {
  background-color: #78266a;
  border: 1px solid #78266a;
  color: #fff;
}

.daterangepicker.ltr .ranges li.active {
  background-color: #78266a;
  border: 1px solid #78266a;
  color: #fff;
}

.daterangepicker.ltr .calendar-time select {
  color: #898989;
}

.datetime-picker .bootstrap-datetimepicker-widget.dropdown-menu {
  width: auto;
}

.date-range-picker > div:last-child .theme-form {
  margin-bottom: 0px;
}

.daterangepicker:before {
  border-bottom: 7px solid #efefef;
}

.daterangepicker .daterangepicker_input i {
  font-size: 14px;
  color: #59667a;
}

.daterangepicker .input-mini {
  border-color: #efefef !important;
  font-size: 14px;
  color: #59667a;
}

.daterangepicker th,
.daterangepicker td {
  height: 34px;
  width: 34px;
}

.daterangepicker th:hover,
.daterangepicker td:hover {
  color: #2c323f;
}

.daterangepicker th.prev,
.daterangepicker th.next,
.daterangepicker td.prev,
.daterangepicker td.next {
  color: #fff;
  height: 34px;
  width: 34px;
  background-color: #78266a;
}

.daterangepicker th.month,
.daterangepicker td.month {
  color: #78266a;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/**=====================
   52. Datepicker CSS End
==========================**/

/**=====================
     54. Tour  CSS Start
==========================**/

.introjs-helperLayer {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
}

.introjs-tooltipReferenceLayer {
  background-color: transparent;
}

.introjs-helperNumberLayer {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  background: #d22d3d;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#78266a),
    to(#78266a)
  );
  background: linear-gradient(to bottom, #78266a 0%, #78266a 100%);
  border: 3px solid #fff;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.introjs-arrow {
  border: 12px solid #fff;
}

.introjs-arrow.top {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #78266a;
  border-left-color: transparent;
}

.introjs-arrow.top-right {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #78266a;
  border-left-color: transparent;
}

.introjs-arrow.top-middle {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #78266a;
  border-left-color: transparent;
}

.introjs-arrow.right {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #78266a;
}

.introjs-arrow.right-bottom {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #78266a;
}

.introjs-arrow.bottom {
  border-top-color: #78266a;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.introjs-arrow.left {
  border-top-color: transparent;
  border-right-color: #78266a;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.introjs-arrow.left-bottom {
  border-top-color: transparent;
  border-right-color: #78266a;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.introjs-tooltip {
  background-color: #78266a;
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  border-color: #78266a;
}

.introjs-button {
  border: 1px solid #efefef;
  text-shadow: 1px 1px 0 #fff;
  color: #242934 !important;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#fff)
  );
  background-image: linear-gradient(#fff, #fff);
}

.introjs-button:hover {
  border-color: #efefef;
}

.introjs-button:focus,
.introjs-button:active {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#e6edef),
    to(#efefef)
  );
  background-image: linear-gradient(#e6edef, #efefef);
}

.introjs-skipbutton {
  color: #78266a;
}

.introjs-prevbutton.introjs-fullbutton {
  border: 1px solid #efefef;
}

.introjs-disabled {
  color: #78266a;
  border-color: #efefef;
}

.introjs-disabled:hover,
.introjs-disabled:focus {
  color: #78266a;
  border-color: #efefef;
}

.introjs-bullets ul li a {
  background: #e6edef;
}

.introjs-bullets ul li a:hover {
  background: #fff;
}

.introjs-bullets ul li a.active {
  background: #fff;
}

.introjs-progress {
  background-color: #e6edef;
}

.introjs-progressbar {
  background-color: #78266a;
}

.introjs-hint:hover > .introjs-hint-pulse {
  border: 5px solid rgba(60, 60, 60, 0.57);
}

.introjs-hint-pulse {
  border: 5px solid rgba(60, 60, 60, 0.27);
  background-color: rgba(136, 136, 136, 0.24);
}

.introjs-hint-dot {
  border: 10px solid rgba(146, 146, 146, 0.36);
}

/**=====================
    54. Tour  CSS Ends
==========================**/

/**=====================
   55. theme layout CSS Start
==========================**/

.bg-color1 {
  background-color: #405365;
}

.bg-color2 {
  background-color: #1c1d1e;
}

.bg-color3 {
  background-color: #0c0c3c;
}

.bg-color4 {
  background-color: #0747a6;
}

.bg-color5 {
  background-color: #4d3096;
}

.bg-light {
  background-color: #e6edef;
}

.bg-dark {
  background-color: #2f3c4e;
}

.bg-pattern1 {
  /* background-image: url(../images/sidebar-pattern/1.png); */
  background-color: #2f3c4e;
}

.bg-img1 {
  /* background-image: url(../images/sidebar-image/1.jpg); */
}

.bg-pattern2 {
  /* background-image: url(../images/sidebar-pattern/2.png); */
  background-color: #2f3c4e;
}

.bg-img2 {
  /* background-image: url(../images/sidebar-image/2.jpg); */
}

.bg-pattern3 {
  /* background-image: url(../images/sidebar-pattern/3.png); */
  background-color: #2f3c4e;
}

.bg-img3 {
  /* background-image: url(../images/sidebar-image/3.jpg); */
}

/* 
.bg-pattern4 {
	background-image: url(../images/sidebar-pattern/4.png);
	background-color: #2f3c4e;
}

.bg-img4 {
	background-image: url(../images/sidebar-image/4.jpg);
}

.bg-pattern5 {
	background-image: url(../images/sidebar-pattern/5.png);
	background-color: #2f3c4e;
}

.bg-img5 {
	background-image: url(../images/sidebar-image/5.jpg);
}

.bg-pattern6 {
	background-image: url(../images/sidebar-pattern/6.png);
	background-color: #2f3c4e;
}

.bg-img6 {
	background-image: url(../images/sidebar-image/6.jpg);
} */

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="border-sidebar"]
  .sidebar-header {
  border-bottom: 1px solid rgba(124, 124, 124, 0.15);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 1)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 1)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 2)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 2)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 3)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 3)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 4)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 4)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 5)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 5)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 6)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 6)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 7)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 7)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 8)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 8)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 9)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 9)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 10)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 10)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 11)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 11)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 12)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 12)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 13)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 13)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 14)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 14)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 15)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 15)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 16)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 16)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 17)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 17)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 18)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 18)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 19)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 19)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 20)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 20)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 21)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 21)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 22)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 22)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 23)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 23)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 24)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 24)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 25)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 25)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 26)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 26)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 27)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 27)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 28)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 28)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 29)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 29)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 30)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 30)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 31)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 31)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 32)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 32)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 33)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 33)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 34)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 34)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 35)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 35)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 36)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 36)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 37)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 37)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 38)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 38)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 39)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 39)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 40)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 40)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 41)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 41)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 42)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 42)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 43)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 43)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 44)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 44)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 45)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 45)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 46)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 46)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 47)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 47)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 48)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 48)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 49)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 49)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 50)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 50)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 51)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 51)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 52)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 52)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 53)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 53)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 54)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 54)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 55)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 55)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 56)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 56)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 57)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 57)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 58)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 58)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 59)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 59)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 60)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 60)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 61)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 61)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 62)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 62)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 63)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 63)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 64)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 64)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 65)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 65)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 66)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 66)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 67)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 67)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 68)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 68)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 69)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 69)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 70)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 70)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 71)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 71)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 72)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 72)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 73)
  svg {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 73)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 74)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 74)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 75)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 75)
  a:hover
  > span {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 76)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 76)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 77)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 77)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 78)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 78)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 79)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 79)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 80)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 80)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 81)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 81)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 82)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 82)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 83)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 83)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 84)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 84)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 85)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 85)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 86)
  svg {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 86)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 87)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 87)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 88)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 88)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 89)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 89)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 90)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 90)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 91)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 91)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 92)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 92)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 93)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 93)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 94)
  svg {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 94)
  a:hover
  > span {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 95)
  svg {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 95)
  a:hover
  > span {
  color: white;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 96)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 96)
  a:hover
  > span {
  color: #38a28e;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 97)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 97)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 98)
  svg {
  color: #ecd979;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 98)
  a:hover
  > span {
  color: #d1b093;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 99)
  svg {
  color: #2f8475;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 99)
  a:hover
  > span {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 100)
  svg {
  color: #979797;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"]
  .sidebar-menu
  li:nth-child(7n + 100)
  a:hover
  > span {
  color: #e06c77;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 1)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 1)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 2)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 2)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 3)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 3)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 4)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 4)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 5)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 5)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 6)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 6)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 7)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 7)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 8)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 8)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 9)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 9)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 10)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 10)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 11)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 11)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 12)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 12)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 13)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 13)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 14)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 14)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 15)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 15)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 16)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 16)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 17)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 17)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 18)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 18)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 19)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 19)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 20)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 20)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 21)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 21)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 22)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 22)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 23)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 23)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 24)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 24)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 25)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 25)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 26)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 26)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 27)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 27)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 28)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 28)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 29)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 29)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 30)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 30)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 31)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 31)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 32)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 32)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 33)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 33)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 34)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 34)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 35)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 35)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 36)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 36)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 37)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 37)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 38)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 38)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 39)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 39)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 40)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 40)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 41)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 41)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 42)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 42)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 43)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 43)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 44)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 44)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 45)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 45)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 46)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 46)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 47)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 47)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 48)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 48)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 49)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 49)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 50)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 50)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 51)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 51)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 52)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 52)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 53)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 53)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 54)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 54)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 55)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 55)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 56)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 56)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 57)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 57)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 58)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 58)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 59)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 59)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 60)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 60)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 61)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 61)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 62)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 62)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 63)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 63)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 64)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 64)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 65)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 65)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 66)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 66)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 67)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 67)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 68)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 68)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 69)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 69)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 70)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 70)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 71)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 71)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 72)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 72)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 73)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 73)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 74)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 74)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 75)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 75)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 76)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 76)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 77)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 77)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 78)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 78)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 79)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 79)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 80)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 80)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 81)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 81)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 82)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 82)
  a:hover
  span {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 83)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 83)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 84)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 84)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 85)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 85)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 86)
  svg {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 86)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 87)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 87)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 88)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 88)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 89)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 89)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 90)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 90)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 91)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 91)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 92)
  svg {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 92)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 93)
  svg {
  color: #4b4b4b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 93)
  a:hover
  span {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 94)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 94)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 95)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 95)
  a:hover
  span {
  color: #93202b;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 96)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 96)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 97)
  svg {
  color: #10302a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 97)
  a:hover
  span {
  color: #071411;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 98)
  svg {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 98)
  a:hover
  span {
  color: #0d0e12;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 99)
  svg {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 99)
  a:hover
  span {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 100)
  svg {
  color: #8e633c;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar[sidebar-layout="iconcolor-sidebar"].light-sidebar
  .sidebar-menu
  li:nth-child(7n + 100)
  a:hover
  span {
  color: #b2991a;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-sidebar
  .sidebar-header {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.light-sidebar
  .sidebar-menu
  li:hover
  .single-header {
  background-color: #e6edef !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.light-sidebar
  .sidebar-menu
  li:hover
  .sidebar-submenu {
  background-color: #e6edef !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.light-sidebar
  .sidebar-menu
  li
  a:hover
  + ul {
  background-color: #e6edef !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.light-sidebar
  .sidebar-menu
  li
  ul
  li:hover
  .sidebar-submenu {
  background-color: #e6edef !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color1-sidebar
  .sidebar-menu
  li:hover
  .single-header {
  background-color: #405365 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color1-sidebar
  .sidebar-menu
  li:hover
  .sidebar-submenu {
  background-color: #405365 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color1-sidebar
  .sidebar-menu
  li
  a:hover
  + ul {
  background-color: #405365 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color1-sidebar
  .sidebar-menu
  li
  ul
  li:hover
  .sidebar-submenu {
  background-color: #405365 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color2-sidebar
  .sidebar-menu
  li:hover
  .single-header {
  background-color: #1c1d1e !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color2-sidebar
  .sidebar-menu
  li:hover
  .sidebar-submenu {
  background-color: #1c1d1e !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color2-sidebar
  .sidebar-menu
  li
  a:hover
  + ul {
  background-color: #1c1d1e !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color2-sidebar
  .sidebar-menu
  li
  ul
  li:hover
  .sidebar-submenu {
  background-color: #1c1d1e !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color3-sidebar
  .sidebar-menu
  li:hover
  .single-header {
  background-color: #0c0c3c !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color3-sidebar
  .sidebar-menu
  li:hover
  .sidebar-submenu {
  background-color: #0c0c3c !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color3-sidebar
  .sidebar-menu
  li
  a:hover
  + ul {
  background-color: #0c0c3c !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color3-sidebar
  .sidebar-menu
  li
  ul
  li:hover
  .sidebar-submenu {
  background-color: #0c0c3c !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color4-sidebar
  .sidebar-menu
  li:hover
  .single-header {
  background-color: #0747a6 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color4-sidebar
  .sidebar-menu
  li:hover
  .sidebar-submenu {
  background-color: #0747a6 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color4-sidebar
  .sidebar-menu
  li
  a:hover
  + ul {
  background-color: #0747a6 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color4-sidebar
  .sidebar-menu
  li
  ul
  li:hover
  .sidebar-submenu {
  background-color: #0747a6 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color5-sidebar
  .sidebar-menu
  li:hover
  .single-header {
  background-color: #4d3096 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color5-sidebar
  .sidebar-menu
  li:hover
  .sidebar-submenu {
  background-color: #4d3096 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color5-sidebar
  .sidebar-menu
  li
  a:hover
  + ul {
  background-color: #4d3096 !important;
}

.page-wrapper.horizontal-wrapper
  .page-body-wrapper.sidebar-icon
  .page-sidebar.color5-sidebar
  .sidebar-menu
  li
  ul
  li:hover
  .sidebar-submenu {
  background-color: #4d3096 !important;
}

body.light-only .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
  background-color: #fdfeff;
}

body.light-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li {
  color: #000;
  display: inline-block;
  padding: 10px;
}

body.light-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li:hover {
  background: #000;
  -webkit-box-shadow: 0 0 3px #111727;
  box-shadow: 0 0 3px #111727;
  color: #fff;
}

body.light-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li
  em {
  display: none;
}

body.dark-sidebar
  .page-main-header
  .main-header-right
  .main-header-left
  .dark-logo-wrapper {
  display: block;
}

body.dark-sidebar
  .page-main-header
  .main-header-right
  .main-header-left
  .logo-wrapper {
  display: none;
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav {
  background-color: #111727;
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li
  .nav-link.active {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li
  .nav-submenu
  li:first-child {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li:last-child {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li
  > a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  .dropdown
  ul.nav-submenu
  li:hover
  a {
  color: #fff !important;
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  .dropdown
  ul.nav-submenu
  li
  a {
  color: rgba(255, 255, 255, 0.8) !important;
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  .dropdown
  ul.nav-submenu
  li
  a.active {
  color: #fff !important;
  text-shadow: 0px 0px #fff;
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li
  span {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li
  + li:before {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  ul
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div
  h6 {
  color: #78266a;
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  .according-menu
  i {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-sidebar .page-wrapper.compact-wrapper .page-main-header {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-main-header
  .main-header-left {
  background-color: #111727;
}

body.dark-sidebar .main-navbar ul.nav-pills {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-sidebar .main-navbar ul.nav-pills .nav-item:last-child {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-sidebar .main-navbar ul.nav-pills .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

/**=====================
   55. theme layout CSS ends
==========================**/

/**=====================
    56.  Ribbon CSS Start
==========================**/

.ribbon-wrapper,
.ribbon-wrapper-right,
.ribbon-wrapper-bottom,
.ribbon-wrapper-right-bottom {
  position: relative;
  padding-top: 40px;
}

.ribbon-vertical-left-wrapper {
  padding-left: 40px;
  position: relative;
}

.ribbon-overflow {
  overflow: hidden;
}

.ribbon-vertical-right-wrapper {
  padding-right: 40px;
}

.ribbon-wrapper-bottom {
  padding-bottom: 40px;
  padding-top: 0;
}

.ribbon-wrapper-right-bottom {
  padding-right: 40px;
}

.ribbon-content {
  margin-bottom: 0;
}

.ribbon {
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  clear: left;
  position: absolute;
  top: 12px;
  left: 0px;
  color: #fff;
  z-index: 2;
}

.ribbon-space-bottom {
  top: 0px;
}

.ribbon-clip {
  left: -14px;
}

.ribbon-clip:before {
  position: absolute;
  top: 30px;
  left: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-bookmark:before {
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 15px solid #2c323f;
  border-right: 10px solid transparent;
}

.ribbon-right {
  left: auto;
  right: -2px;
}

.ribbon-clip-right {
  right: -14px;
}

.ribbon-clip-right:before {
  position: absolute;
  top: 30px;
  right: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-clip-bottom {
  left: -14px;
  bottom: 12px;
  top: auto;
}

.ribbon-clip-bottom:before {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-clip-bottom-right {
  right: -14px;
  left: auto;
  bottom: 12px;
  top: auto;
}

.ribbon-clip-bottom-right:before {
  position: absolute;
  bottom: 30px;
  right: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-bookmark.ribbon-right:before {
  right: 100%;
  left: auto;
  border-right: 15px solid #2c323f;
  border-left: 10px solid transparent;
}

.ribbon-vertical-left {
  clear: none;
  padding: 0 5px;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  left: 12px;
  top: -2px;
}

.ribbon-vertical-right {
  clear: none;
  padding: 0 5px;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  top: -2px;
  left: auto;
  right: 12px;
}

.ribbon-bookmark.ribbon-vertical-left:before,
.ribbon-bookmark.ribbon-vertical-right:before {
  top: 100%;
  left: 0;
  margin-top: -14px;
  border-right: 15px solid #2c323f;
  border-bottom: 10px solid transparent;
}

.ribbon-primary {
  background: #78266a;
}

.ribbon-primary {
  background: #78266a;
}

.ribbon-bookmark.ribbon-primary:before {
  border-color: #78266a;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-primary:before {
  border-right-color: #78266a;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-primary:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-primary:before {
  border-right-color: #78266a;
  border-bottom-color: transparent;
}

.ribbon-primary.ribbon-corner:before {
  border-top-color: #78266a;
  border-left-color: #78266a;
}

.ribbon-primary.ribbon-corner.ribbon-right:before {
  border-right-color: #78266a;
  border-left-color: transparent;
}

.ribbon-primary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #78266a;
}

.ribbon-primary.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #17433b;
  border-right-color: #17433b;
}

.ribbon-primary.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #17433b;
  border-left-color: #17433b;
}

.ribbon-primary.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #17433b;
  border-right-color: #17433b;
}

.ribbon-primary.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #17433b;
  border-right-color: transparent;
  border-left-color: #17433b;
}

.ribbon-secondary {
  background: #ba895d;
}

.ribbon-secondary {
  background: #ba895d;
}

.ribbon-bookmark.ribbon-secondary:before {
  border-color: #ba895d;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-secondary:before {
  border-right-color: #ba895d;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-secondary:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-secondary:before {
  border-right-color: #ba895d;
  border-bottom-color: transparent;
}

.ribbon-secondary.ribbon-corner:before {
  border-top-color: #ba895d;
  border-left-color: #ba895d;
}

.ribbon-secondary.ribbon-corner.ribbon-right:before {
  border-right-color: #ba895d;
  border-left-color: transparent;
}

.ribbon-secondary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #ba895d;
}

.ribbon-secondary.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #a07044;
  border-right-color: #a07044;
}

.ribbon-secondary.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #a07044;
  border-left-color: #a07044;
}

.ribbon-secondary.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #a07044;
  border-right-color: #a07044;
}

.ribbon-secondary.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #a07044;
  border-right-color: transparent;
  border-left-color: #a07044;
}

.ribbon-success {
  background: #1b4c43;
}

.ribbon-success {
  background: #1b4c43;
}

.ribbon-bookmark.ribbon-success:before {
  border-color: #1b4c43;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-success:before {
  border-right-color: #1b4c43;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-success:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-success:before {
  border-right-color: #1b4c43;
  border-bottom-color: transparent;
}

.ribbon-success.ribbon-corner:before {
  border-top-color: #1b4c43;
  border-left-color: #1b4c43;
}

.ribbon-success.ribbon-corner.ribbon-right:before {
  border-right-color: #1b4c43;
  border-left-color: transparent;
}

.ribbon-success.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #1b4c43;
}

.ribbon-success.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #0e2622;
  border-right-color: #0e2622;
}

.ribbon-success.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #0e2622;
  border-left-color: #0e2622;
}

.ribbon-success.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #0e2622;
  border-right-color: #0e2622;
}

.ribbon-success.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #0e2622;
  border-right-color: transparent;
  border-left-color: #0e2622;
}

.ribbon-danger {
  background: #d22d3d;
}

.ribbon-danger {
  background: #d22d3d;
}

.ribbon-bookmark.ribbon-danger:before {
  border-color: #d22d3d;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-danger:before {
  border-right-color: #d22d3d;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-danger:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-danger:before {
  border-right-color: #d22d3d;
  border-bottom-color: transparent;
}

.ribbon-danger.ribbon-corner:before {
  border-top-color: #d22d3d;
  border-left-color: #d22d3d;
}

.ribbon-danger.ribbon-corner.ribbon-right:before {
  border-right-color: #d22d3d;
  border-left-color: transparent;
}

.ribbon-danger.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #d22d3d;
}

.ribbon-danger.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #a82431;
  border-right-color: #a82431;
}

.ribbon-danger.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #a82431;
  border-left-color: #a82431;
}

.ribbon-danger.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #a82431;
  border-right-color: #a82431;
}

.ribbon-danger.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #a82431;
  border-right-color: transparent;
  border-left-color: #a82431;
}

.ribbon-info {
  background: #717171;
}

.ribbon-info {
  background: #717171;
}

.ribbon-bookmark.ribbon-info:before {
  border-color: #717171;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-info:before {
  border-right-color: #717171;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
  border-right-color: #717171;
  border-bottom-color: transparent;
}

.ribbon-info.ribbon-corner:before {
  border-top-color: #717171;
  border-left-color: #717171;
}

.ribbon-info.ribbon-corner.ribbon-right:before {
  border-right-color: #717171;
  border-left-color: transparent;
}

.ribbon-info.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #717171;
}

.ribbon-info.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #585858;
  border-right-color: #585858;
}

.ribbon-info.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #585858;
  border-left-color: #585858;
}

.ribbon-info.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #585858;
  border-right-color: #585858;
}

.ribbon-info.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #585858;
  border-right-color: transparent;
  border-left-color: #585858;
}

.ribbon-light {
  background: #e6edef;
}

.ribbon-light {
  background: #e6edef;
}

.ribbon-bookmark.ribbon-light:before {
  border-color: #e6edef;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-light:before {
  border-right-color: #e6edef;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-light:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-light:before {
  border-right-color: #e6edef;
  border-bottom-color: transparent;
}

.ribbon-light.ribbon-corner:before {
  border-top-color: #e6edef;
  border-left-color: #e6edef;
}

.ribbon-light.ribbon-corner.ribbon-right:before {
  border-right-color: #e6edef;
  border-left-color: transparent;
}

.ribbon-light.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #e6edef;
}

.ribbon-light.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #c7d7db;
  border-right-color: #c7d7db;
}

.ribbon-light.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #c7d7db;
  border-left-color: #c7d7db;
}

.ribbon-light.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #c7d7db;
  border-right-color: #c7d7db;
}

.ribbon-light.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #c7d7db;
  border-right-color: transparent;
  border-left-color: #c7d7db;
}

.ribbon-dark {
  background: #2c323f;
}

.ribbon-dark {
  background: #2c323f;
}

.ribbon-bookmark.ribbon-dark:before {
  border-color: #2c323f;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-dark:before {
  border-right-color: #2c323f;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-dark:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-dark:before {
  border-right-color: #2c323f;
  border-bottom-color: transparent;
}

.ribbon-dark.ribbon-corner:before {
  border-top-color: #2c323f;
  border-left-color: #2c323f;
}

.ribbon-dark.ribbon-corner.ribbon-right:before {
  border-right-color: #2c323f;
  border-left-color: transparent;
}

.ribbon-dark.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #2c323f;
}

.ribbon-dark.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #171a21;
  border-right-color: #171a21;
}

.ribbon-dark.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #171a21;
  border-left-color: #171a21;
}

.ribbon-dark.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #171a21;
  border-right-color: #171a21;
}

.ribbon-dark.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #171a21;
  border-right-color: transparent;
  border-left-color: #171a21;
}

.ribbon-warning {
  background: #e2c636;
}

.ribbon-warning {
  background: #e2c636;
}

.ribbon-bookmark.ribbon-warning:before {
  border-color: #e2c636;
  border-right-color: transparent;
}

.ribbon-bookmark.ribbon-right.ribbon-warning:before {
  border-right-color: #e2c636;
  border-left-color: transparent;
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-warning:before,
.ribbon-bookmark.ribbon-vertical-right.ribbon-warning:before {
  border-right-color: #e2c636;
  border-bottom-color: transparent;
}

.ribbon-warning.ribbon-corner:before {
  border-top-color: #e2c636;
  border-left-color: #e2c636;
}

.ribbon-warning.ribbon-corner.ribbon-right:before {
  border-right-color: #e2c636;
  border-left-color: transparent;
}

.ribbon-warning.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #e2c636;
}

.ribbon-warning.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #c8ac1d;
  border-right-color: #c8ac1d;
}

.ribbon-warning.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #c8ac1d;
  border-left-color: #c8ac1d;
}

.ribbon-warning.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #c8ac1d;
  border-right-color: #c8ac1d;
}

.ribbon-warning.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #c8ac1d;
  border-right-color: transparent;
  border-left-color: #c8ac1d;
}

/**=====================
     56. Ribbon CSS Ends
==========================**/

/**=====================
    57. breadcrumb CSS Start
==========================**/

.breadcrumb-colored {
  padding: 12px 16px;
  border-radius: 4px;
}

.breadcrumb-colored li a {
  color: #fff;
}

.breadcrumb-colored li.active {
  color: #fff;
  opacity: 0.7;
}

.breadcrumb-colored .breadcrumb-item:before {
  color: #fff;
}

.breadcrumb-colored .breadcrumb-item.txt-dark:before {
  color: #000;
}

.breadcrumb-icon {
  --bs-breadcrumb-divider: ">";
}

.breadcrumb-icon li {
  display: inline-block;
}

.breadcrumb-no-divider {
  --bs-breadcrumb-divider: "";
}

.breadcrumb-item.active {
  text-transform: capitalize;
}

/**=====================
    57. breadcrumb CSS ends
==========================**/

/**=====================
    58. Steps  CSS Start
==========================**/

.u-steps {
  margin: 0;
}

.u-step {
  position: relative;
  padding: 12px 20px;
  margin: 0;
  font-size: inherit;
  color: #a3afb7;
  vertical-align: top;
  background-color: #e6edef;
  border-radius: 0;
}

.u-step-icon {
  float: left;
  margin-right: 0.5em;
  font-size: 20px;
  line-height: 1.4;
}

.u-step-number {
  position: absolute;
  top: 50%;
  left: 20px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 40px;
  color: #fff;
  text-align: center;
  background: #78266a;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.u-step-number ~ .u-step-desc {
  min-height: 40px;
  margin-left: 50px;
}

.u-step-title {
  margin-bottom: 0;
  font-size: 20px;
  color: #526069;
  font-weight: 600;
}

.u-step-desc {
  text-align: left;
}

.u-step-desc p {
  margin-bottom: 0;
}

.u-steps-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.u-step.active,
.u-step.current {
  color: #fff;
  background-color: #78266a;
}

.u-step.active p,
.u-step.current p {
  color: #fff;
}

.u-step.active .u-step-title,
.u-step.current .u-step-title {
  color: #fff;
}

.u-step.active .u-step-number,
.u-step.current .u-step-number {
  color: #78266a;
  background-color: #fff;
}

.u-step.disabled {
  color: #e6edef;
  pointer-events: none;
  cursor: auto;
}

.u-step.disabled .u-step-title,
.u-step.disabled p {
  color: #59667a;
}

.u-step.disabled .u-step-number {
  background-color: rgba(36, 105, 92, 0.5);
}

.u-step.error {
  color: #fff;
  background-color: #d22d3d;
}

.u-step.error .u-step-title {
  color: #fff;
}

.u-step.error .u-step-number {
  color: #d22d3d;
  background-color: #fff;
}

.u-step.done {
  color: #fff;
  background-color: #1b4c43;
}

.u-step.done .u-step-title {
  color: #fff;
}

.u-step.done .u-step-number {
  color: #1b4c43;
  background-color: #fff;
}

.u-steps-lg .u-step {
  font-size: 16px;
}

.u-steps-lg .u-step-icon,
.u-steps-lg .u-step-title {
  font-size: 22px;
}

.u-steps-lg .u-step-number {
  width: 46px;
  height: 46px;
  font-size: 28px;
  line-height: 46px;
}

.u-steps-lg .u-step-number ~ .u-step-desc {
  min-height: 46px;
  margin-left: 56px;
}

.u-steps-sm .u-step {
  font-size: 12px;
}

.u-steps-sm .u-step-icon,
.u-steps-sm .u-step-title {
  font-size: 18px;
}

.u-steps-sm .u-step-number {
  width: 30px;
  height: 30px;
  font-size: 24px;
  line-height: 30px;
}

.u-steps-sm .u-step-number ~ .u-step-desc {
  min-height: 30px;
  margin-left: 40px;
}

.u-steps-xs .u-step {
  font-size: 10px;
}

.u-steps-xs .u-step-icon,
.u-steps-xs .u-step-title {
  font-size: 16px;
}

.u-steps-xs .u-step-number {
  width: 24px;
  height: 24px;
  font-size: 20px;
  line-height: 24px;
}

.u-steps-xs .u-step-number ~ .u-step-desc {
  min-height: 24px;
  margin-left: 34px;
}

.u-pearl {
  position: relative;
  padding: 0;
  margin: 0;
  text-align: center;
}

.u-pearl:after {
  position: absolute;
  top: 18px;
  z-index: 0;
  width: 50%;
  height: 4px;
  content: "";
  background-color: #e6edef;
}

.u-pearl:before {
  position: absolute;
  top: 18px;
  z-index: 0;
  width: 50%;
  height: 4px;
  content: "";
  background-color: #e6edef;
  left: 0;
}

.u-pearl:after {
  right: 0;
}

.u-pearl:first-child:before,
.u-pearl:last-child:after {
  display: none !important;
}

.u-pearl-icon {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 32px;
  color: #fff;
  text-align: center;
  background: #e6edef;
  border: 2px solid #e6edef;
  border-radius: 50%;
}

.u-pearl-icon i {
  position: absolute;
  top: 7px;
  left: 8px;
}

.u-pearl-icon .icon-check {
  left: 7px;
}

.u-pearl-number {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 32px;
  color: #78266a;
  text-align: center;
  background: #e6edef;
  border: 2px solid #e6edef;
  border-radius: 50%;
  font-size: 18px;
}

.u-pearl-icon {
  font-size: 18px;
}

.u-pearl-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0;
  font-size: 16px;
  color: #526069;
  font-weight: 600;
}

.u-pearl.active:after,
.u-pearl.active:before {
  background-color: #78266a;
}

.u-pearl.current:after,
.u-pearl.current:before {
  background-color: #78266a !important;
}

.u-pearl.active .u-pearl-icon,
.u-pearl.active .u-pearl-number {
  color: #78266a;
  border-color: #78266a;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.u-pearl.current .u-pearl-icon,
.u-pearl.current .u-pearl-number {
  color: #78266a;
  border-color: #78266a;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.u-pearl.disabled {
  pointer-events: none;
  cursor: auto;
}

.u-pearl.disabled:after,
.u-pearl.disabled:before {
  background-color: #e6edef;
}

.u-pearl.disabled .u-pearl-icon,
.u-pearl.disabled .u-pearl-number {
  color: #fff;
  background-color: #e6edef;
  border-color: #e6edef;
}

.u-pearl.error:before {
  background-color: #78266a;
}

.u-pearl.error:after {
  background-color: #e6edef;
}

.u-pearl.error .u-pearl-icon,
.u-pearl.error .u-pearl-number {
  color: #d22d3d;
  background-color: #fff;
  border-color: #d22d3d;
}

.u-pearl.done:after,
.u-pearl.done:before {
  background-color: #78266a !important;
}

.u-pearl.done .u-pearl-icon,
.u-pearl.done .u-pearl-number {
  color: #fff;
  background-color: #78266a;
  border-color: #78266a;
}

.u-pearls-lg .u-pearl:after,
.u-pearls-lg .u-pearl:before {
  top: 20px;
}

.u-pearls-lg .u-pearl-title {
  font-size: 18px;
}

.u-pearls-lg .u-pearl-icon,
.u-pearls-lg .u-pearl-number {
  width: 40px;
  height: 40px;
  line-height: 36px;
}

.u-pearls-lg .u-pearl-icon,
.u-pearls-lg .u-pearl-number {
  font-size: 20px;
}

.u-pearls-sm .u-pearl:after,
.u-pearls-sm .u-pearl:before {
  top: 16px;
}

.u-pearls-sm .u-pearl-title {
  font-size: 14px;
}

.u-pearls-sm .u-pearl-icon {
  width: 32px;
  height: 32px;
  line-height: 28px;
}

.u-pearls-sm .u-pearl-number {
  width: 32px;
  height: 32px;
  line-height: 28px;
  font-size: 16px;
}

.u-pearls-sm .u-pearl-icon {
  font-size: 14px;
}

.u-pearls-xs .u-pearl:after,
.u-pearls-xs .u-pearl:before {
  top: 12px;
  height: 2px;
}

.u-pearls-xs .u-pearl-title {
  font-size: 12px;
}

.u-pearls-xs .u-pearl-icon {
  width: 24px;
  height: 24px;
  line-height: 20px;
}

.u-pearls-xs .u-pearl-number {
  width: 24px;
  height: 24px;
  line-height: 20px;
  font-size: 12px;
}

.u-pearls-xs .u-pearl-icon {
  font-size: 12px;
}

.u-pearl.current .u-pearl-icon i {
  left: 6px;
}

/**=====================
    58. Steps  CSS ends
==========================**/

/**=====================
   59. Customizer  CSS Start
==========================**/

.customizer-links {
  position: fixed;
  right: 00px;
  top: 40%;
  background-color: #fff;
  border-radius: 8px;
  z-index: 3;
  -webkit-box-shadow: 0 0 15px 3px rgba(176, 185, 189, 0.3);
  box-shadow: 0 0 15px 3px rgba(176, 185, 189, 0.3);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.customizer-links.open {
  right: 330px;
  border-radius: 8px 0 0 8px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.customizer-links .nav-link {
  padding: 0;
  border-radius: 0;
  position: relative;
}

.customizer-links .nav-link .settings i {
  color: #78266a;
  font-size: 24px;
  padding: 15px 17px;
  display: inline-block;
  cursor: pointer;
  margin: 0;
}

.customizer-links .nav-link.active .settings i {
  color: #fff;
  background-color: #78266a;
}

.customizer-links .nav-link + .nav-link {
  border-top: 1px solid rgba(36, 105, 92, 0.1);
}

.customizer-links .nav-link span {
  position: absolute;
  right: -80px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-align: right;
  padding: 7px 10px;
  display: block;
  top: 8px;
  border-radius: 5px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
  color: #fff;
  background-color: #78266a;
}

.customizer-links .nav-link:after {
  content: "";
  position: absolute;
  top: 17px;
  left: -10px;
  width: 0;
  height: 0;
}

.customizer-links .nav-link:hover span {
  opacity: 1;
  right: 68px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.customizer-links .nav-link:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  border-top: 8px solid transparent;
  border-left: 10px solid #78266a;
  border-bottom: 8px solid transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.customizer-contain {
  direction: ltr;
  position: fixed;
  height: calc(100vh);
  top: 80px;
  width: 333px;
  right: -335px;
  background-color: #fff;
  z-index: 99;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.customizer-contain.open {
  right: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
  box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
}

.customizer-contain li {
  position: relative;
}

.customizer-contain li.active:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  font-size: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #2c323f;
  -webkit-box-shadow: 0 0 11px 5px #e2e2e2;
  box-shadow: 0 0 11px 5px #e2e2e2;
}

.customizer-contain h6 {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #212529;
}

.customizer-contain .customizer-header {
  padding: 15px 25px;
  border-bottom: 1px solid #e6edef;
}

.customizer-contain .customizer-header .icon-close {
  position: absolute;
  font-size: 18px;
  cursor: pointer;
  font-weight: 900;
  right: 30px;
  color: #78266a;
}

.customizer-contain .customizer-header h5 {
  font-weight: 700;
  text-transform: uppercase;
  color: #313131;
}

.customizer-contain .customizer-header p {
  color: #313131;
}

.customizer-contain .customizer-body {
  padding: 15px 25px;
  max-height: calc(100vh - 170px);
  overflow-y: scroll;
}

.customizer-contain .customizer-body .layout-grid.customizer-mix .color-layout {
  height: 70px;
  width: 80px;
  border: 1px solid #f6f7fb;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 7px;
  margin-right: 3px;
}

.customizer-contain .customizer-body .tab-pane > h6 {
  position: relative;
  margin-top: 25px;
  padding-top: 25px;
  margin-bottom: 20px;
  border-top: 1px solid #f3f3f3;
  font-size: 15px;
}

.customizer-contain .customizer-body .tab-pane > h6:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 14px;
  border-bottom: 2px dotted #2c323f;
  bottom: -4px;
}

.customizer-contain .customizer-body .tab-pane > h6:first-child {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}

.customizer-contain .customizer-body .tab-pane .unlimited-color-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.customizer-contain .customizer-body .tab-pane .unlimited-color-layout input {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 3px;
  border: 1px solid #b8b8b8;
  padding: 3px;
  background-color: #f5f7fb;
}

.customizer-contain .customizer-body .nac-pills {
  border: 1px solid #f1f1f1;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.customizer-contain .customizer-body .nac-pills .nav-item {
  width: 33.33%;
  text-align: center;
}

.customizer-contain .customizer-body .nac-pills .nav-item .nav-link {
  border-radius: 5px;
}

.customizer-contain .customizer-body .nac-pills .nav-item .nav-link.active {
  background-color: #78266a;
  color: #fff;
}

.customizer-contain .customizer-body .nac-pills .nav-link {
  padding: 0.2rem 1rem;
}

.customizer-contain .customizer-body li {
  cursor: pointer;
  opacity: 0.9;
}

.customizer-contain .customizer-body li.active {
  opacity: 1;
}

.customizer-contain .customizer-body .fade:not(.show) {
  display: none;
}

.customizer-contain .customizer-body .color-layout {
  height: 40px;
  width: 40px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 7px;
  margin-right: 3px;
  border: 1px solid #b8b8b8;
  padding: 3px;
}

.customizer-contain .customizer-body .color-layout > div {
  background-color: #78266a;
  height: 100%;
  width: 100%;
  border-radius: 3px;
}

.customizer-contain .customizer-body .color-layout .body .contain {
  position: absolute;
  height: auto;
  width: 100%;
}

.customizer-contain .customizer-body .color-layout .body .contain li {
  height: 1px;
  width: 65%;
  background-color: #78266a;
  border-radius: 5px;
  padding: 0;
  margin-left: 10px;
  margin-bottom: 4px;
}

.customizer-contain .customizer-body .color-layout .body .contain li:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 3px;
  background-color: #78266a;
  left: -6px;
  border-radius: 5px;
}

.customizer-contain .customizer-body .color-layout[data-attr="color-2"] div {
  background-color: #d97f3d;
}

.customizer-contain .customizer-body .color-layout[data-attr="color-3"] div {
  background-color: #168eea;
}

.customizer-contain .customizer-body .color-layout[data-attr="color-4"] div {
  background-color: #665ed5;
}

.customizer-contain .customizer-body .color-layout[data-attr="color-5"] div {
  background-color: #3a9aa8;
}

.customizer-contain .customizer-body .color-layout[data-attr="color-6"] div {
  background-color: #7951aa;
}

.customizer-contain .customizer-body > li .body .body ul {
  background-color: #f6f7fb;
}

.customizer-contain .customizer-body .dark > li {
  background-color: #2f3c4e;
}

.customizer-contain .customizer-body .dark > li .body ul {
  background-color: #2f3c4e;
}

.customizer-contain .customizer-body .main-layout > li {
  height: 70px;
  width: 86px;
  border: 1px solid #e6edef;
  border-radius: 5px;
  display: inline-block;
}

.customizer-contain .customizer-body .main-layout .box-layout {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 16px;
}

.customizer-contain .customizer-body .main-layout .box-layout .body .badge {
  left: -14px;
}

.customizer-contain .customizer-body .sidebar-type > li,
.customizer-contain .customizer-body .sidebar-setting > li {
  height: 70px;
  width: 86px;
  border: 1px solid #e6edef;
  border-radius: 5px;
  display: inline-block;
}

.customizer-contain
  .customizer-body
  .sidebar-type
  li:first-child
  .body
  ul
  li.bg-dark,
.customizer-contain
  .customizer-body
  .sidebar-type
  li:first-child
  .body
  ul
  li.sidebar {
  width: 100%;
  height: 10px;
  display: block;
}

.customizer-contain
  .customizer-body
  .sidebar-type
  li:first-child
  .body
  ul
  li.bg-light,
.customizer-contain
  .customizer-body
  .sidebar-type
  li:first-child
  .body
  ul
  li.body {
  height: calc(100% - 10px);
}

.customizer-contain .customizer-body .sidebar-bg-settings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.customizer-contain .customizer-body .sidebar-bg-settings li {
  height: 100px;
  width: 100%;
  border: 1px solid #e6edef;
  border-radius: 5px;
  display: inline-block;
  margin-right: 9px;
  -webkit-box-shadow: 0 0 11px -2px rgba(128, 128, 128, 0.4);
  box-shadow: 0 0 11px -2px rgba(128, 128, 128, 0.4);
}

.customizer-contain .customizer-body ul.layout-types {
  margin-bottom: -20px;
}

.customizer-contain .customizer-body ul.layout-types li {
  display: block;
  width: 100%;
  height: auto;
  text-align: center;
  margin-bottom: 20px;
}

.customizer-contain .customizer-body ul.layout-types li h6 {
  margin-top: 10px;
}

.customizer-contain .customizer-body ul.layout-types li + li {
  margin-left: unset;
}

.customizer-contain .customizer-footer {
  position: absolute;
  bottom: 0;
  padding: 25px;
  width: 100%;
  border-top: 1px solid #e6edef;
  background-color: #fff;
}

.layout-grid:not(.customizer-color) li {
  padding: 5px;
}

.layout-grid:not(.customizer-color) li > .header {
  height: 9px;
  border-radius: 3px;
  position: relative;
}

.layout-grid:not(.customizer-color) li > .header ul {
  position: absolute;
  top: -8px;
  left: 5px;
}

.layout-grid:not(.customizer-color) li > .header ul > li {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  top: -2px;
  background-color: #d22d3d;
  margin-right: 1px;
  padding: 0;
}

.layout-grid:not(.customizer-color) li > .header ul > li:nth-child(2) {
  background-color: #e2c636;
}

.layout-grid:not(.customizer-color) li > .header ul > li:nth-child(3) {
  background-color: #1b4c43;
}

.layout-grid:not(.customizer-color) li > .body {
  background-color: #e6edef;
  height: calc(100% - 16px);
  border-radius: 3px;
  position: relative;
  margin-top: 5px;
  text-align: center;
  line-height: 3.1;
}

.layout-grid:not(.customizer-color) li > .body ul {
  height: 100%;
  background-color: #fff;
}

.layout-grid:not(.customizer-color) li > .body ul .sidebar {
  width: 18px;
  height: 100%;
  border-radius: 3px;
  display: inline-block;
  margin-right: 3px;
  padding: 0;
}

.layout-grid:not(.customizer-color) li > .body ul .sidebar.compact {
  width: 12px;
}

.layout-grid:not(.customizer-color) li > .body ul .sidebar.compact ~ .body {
  width: calc(100% - 19px);
}

.layout-grid:not(.customizer-color) li > .body ul .sidebar.compact-icon {
  width: 7px;
}

.layout-grid:not(.customizer-color)
  li
  > .body
  ul
  .sidebar.compact-icon
  ~ .body {
  width: calc(100% - 14px);
}

.layout-grid:not(.customizer-color) li > .body ul .body {
  width: calc(100% - 25px);
  padding: 0;
  margin: 0;
  height: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 3px;
}

.layout-grid:not(.customizer-color) li > .body ul .body .badge {
  position: absolute;
}

/**=====================
     59.Customizer CSS Ends
==========================**/

/**=====================
     71. Faq CSS start
==========================**/

.faq-widgets {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.faq-widgets .media-body h5 {
  font-weight: 600;
  color: #fff;
}

.faq-widgets .media-body p {
  font-size: 14px;
  padding-right: 20px;
  color: rgba(255, 255, 255, 0.7);
}

.faq-widgets svg {
  width: 50px;
  height: 50px;
  opacity: 0.5;
}

.header-faq {
  margin-bottom: 30px;
}

.header-faq h5 {
  font-weight: 600;
  text-transform: capitalize;
}

.faq-articles h6 {
  font-size: 16px;
}

.faq-articles p {
  color: #999;
}

.features-faq .faq-image img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
}

.features-faq .card-body,
.features-faq .card-footer {
  padding: 20px;
}

.features-faq .card-body h6 {
  font-weight: 600;
  font-size: 18px;
}

.features-faq .card-body p {
  font-size: 14px;
  color: #999;
}

.features-faq ul li {
  display: inline-block;
}

.faq-accordion {
  margin-bottom: 30px;
}

.faq-accordion p {
  color: #999;
}

.faq-accordion .card .btn-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.faq-accordion .card .btn-link svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: absolute;
  left: 20px;
}

.faq-accordion .faq-header {
  position: relative;
}

.faq-accordion .faq-header svg {
  position: absolute;
  right: 20px;
  top: 20px;
}

.faq-accordion .faq-header span {
  margin-top: 3px;
}

.faq-accordion .faq-body {
  padding: 20px !important;
}

.faq-accordion .card-mb-faq {
  margin-bottom: 30px;
}

.faq-accordion label {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}

.latest-articles p {
  color: #999;
}

.faq-title {
  margin: 30px 0;
}

.faq-title h6 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: unset;
}

.faq-form {
  position: relative;
}

.faq-form .search-icon {
  position: absolute;
  right: 12px;
  top: 9px;
  width: 16px;
  height: 16px;
}

.navigation-btn {
  margin-bottom: 20px;
}

.navigation-btn a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 600;
}

.navigation-btn a svg {
  width: 15px;
  height: 15px;
}

.navigation-option ul li {
  position: relative;
  padding: 10px 10px 10px 0;
}

.navigation-option ul li:hover {
  background-color: rgba(36, 105, 92, 0.05);
}

.navigation-option ul li:hover a {
  color: #78266a;
}

.navigation-option ul li a {
  padding-left: 40px;
  color: #999;
  font-weight: 500;
}

.navigation-option ul li a svg {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 12px;
  left: 15px;
}

.updates-faq {
  width: 40px;
  height: 40px;
  border: 1px solid #78266a;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 20px;
}

.updates-faq svg {
  width: 18px;
  height: 18px;
}

.updates-bottom-time p {
  margin-bottom: 2px;
  font-weight: 600;
}

.updates-bottom-time p + p {
  color: #999;
}

.updates-faq-main {
  margin-bottom: 20px;
}

.updates-faq-main:last-child {
  margin-bottom: 0;
}

.default-according .card .card-header h5 {
  padding-left: 25px;
}

.default-according .card .card-header .btn-link {
  padding: 0;
}

@media only screen and (max-width: 1199px) {
  .faq-accordion {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .faq-accordion .faq-header {
    padding: 20px !important;
  }

  .faq-accordion .faq-header h5 {
    font-size: 20px;
  }

  .faq-accordion .faq-header svg {
    right: 20px;
    top: 20px;
    width: 20px;
    height: auto;
  }

  .navigation-option ul li {
    padding: 10px 10px 10px 0;
  }

  .navigation-option ul li a svg {
    top: 12px;
  }

  .navigation-btn,
  .updates-faq-main {
    margin-bottom: 20px;
  }

  .updates-faq {
    width: 35px;
    height: 35px;
    margin-right: 20px;
  }

  .updates-faq svg {
    width: 16px;
    height: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .xs-mt-search {
    margin-top: 30px;
  }

  .featured-tutorial .card-body,
  .featured-tutorial .card-footer {
    padding: 20px;
  }

  .updates-faq {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .xs-mt-search {
    margin-top: 0px;
  }

  .faq-widgets svg {
    width: 100px;
    height: 100px;
    position: absolute;
    opacity: 0.1;
    top: 10px;
    right: 20px;
  }

  .faq-title {
    margin: 15px 0;
  }

  .faq-title h6 {
    font-size: 16px;
  }

  .header-faq {
    margin-bottom: 15px;
  }

  .faq-accordion {
    margin-bottom: 0px;
  }

  .faq-accordion .faq-header {
    padding: 15px !important;
  }

  .faq-accordion .faq-header .pull-right {
    float: right;
  }

  .faq-accordion .faq-header svg {
    right: 15px;
    top: 15px;
  }

  .navigation-btn,
  .updates-faq-main {
    margin-bottom: 15px;
  }
}

/**=====================
     71. Faq CSS end
==========================**/

/**=====================
     73. knowledgebase CSS start
==========================**/

.knowledgebase-bg {
  height: 500px;
  margin-bottom: 30px;
}

.knowledgebase-search {
  position: absolute;
  top: -35px;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  left: 5%;
}

.knowledgebase-search h3 {
  font-weight: 600;
}

.knowledgebase-search .form-inline {
  background-color: #fff;
  width: 500px;
  padding: 12px 50px;
  border-radius: 10px;
  border: 1px solid #e6edef;
  margin-top: 10px;
  position: relative;
}

.knowledgebase-search .form-inline ::-webkit-input-placeholder {
  color: #cccccc;
}

.knowledgebase-search .form-inline ::-moz-placeholder {
  color: #cccccc;
}

.knowledgebase-search .form-inline :-ms-input-placeholder {
  color: #cccccc;
}

.knowledgebase-search .form-inline ::-ms-input-placeholder {
  color: #cccccc;
}

.knowledgebase-search .form-inline ::placeholder {
  color: #cccccc;
}

.knowledgebase-search .form-inline svg {
  position: absolute;
  left: 20px;
  width: 20px;
  top: 17px;
  stroke: #cccccc;
}

.browse > div {
  margin-bottom: 30px;
}

.browse .browse-articles {
  background-color: #fff;
  padding: 30px;
}

.browse .browse-articles h6 {
  font-weight: 600;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e6edef;
  color: #59667a;
  padding-left: 25px;
  position: relative;
  line-height: 1.5;
}

.browse .browse-articles h6 span svg {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  vertical-align: text-top;
  position: absolute;
  left: 0;
  top: 4px;
}

.browse .browse-articles ul li {
  border-radius: 8px;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}

.browse .browse-articles ul li:last-child {
  padding-bottom: 0;
}

.browse .browse-articles ul li h5 {
  vertical-align: text-top;
  font-size: 14px;
  margin-bottom: unset;
  color: #999;
  width: 100%;
  padding-left: 20px;
  line-height: 1.6;
  font-weight: 500;
  position: relative;
}

.browse .browse-articles ul li h5 span.badge {
  margin-left: auto;
}

.browse .browse-articles ul li h5 svg {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  vertical-align: text-top;
  position: absolute;
  left: 0;
  top: 4px;
}

.browse .browse-articles ul li a {
  color: #242934;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.browse .browse-articles ul li:hover h5 {
  color: #78266a;
}

@media only screen and (max-width: 1199px) {
  .knowledgebase-bg {
    height: 400px;
  }

  .knowledgebase-search .form-inline {
    width: 400px;
    padding: 7px 45px;
  }

  .knowledgebase-search .form-inline svg {
    top: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .browse .browse-articles h6 {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .browse .browse-articles {
    padding: 20px;
  }

  .browse .browse-articles h6 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .knowledgebase-bg {
    height: 250px;
  }

  .knowledgebase-search {
    width: calc(100% - 30px);
    left: 15px;
    height: calc(100% - 30px);
    background-color: rgba(255, 255, 255, 0.7);
  }

  .knowledgebase-search .form-inline {
    width: 90%;
    padding: 3px 45px;
  }

  .knowledgebase-search .form-inline svg {
    top: 8px;
  }

  .knowledgebase-search > div {
    width: 100%;
    text-align: center;
    padding: 15px;
    border-right: 10px;
  }

  .knowledgebase-search > div h3 {
    font-size: 20px;
    margin-bottom: 0;
  }

  .knowledgebase-search > div .form-group {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 360px) {
  .browse .browse-articles ul li h5 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .browse .browse-articles ul li h5 span.badge {
    display: none;
  }
}

/**=====================
     73. knowledgebase CSS end
==========================**/

/**=====================
     70.  Document CSS Start
==========================**/

.document.close .page-sidebar {
  left: -290px;
}

.document.close
  .page-wrapper.compact-wrapper
  .page-body-wrapper.sidebar-icon
  .page-body {
  margin-left: unset;
}

.document .page-main-header {
  background-color: #fff;
}

.document .page-main-header.document-header {
  height: 80px;
  width: calc(100% - 290px);
  margin-left: 290px;
  padding-left: 30px;
  padding-right: 30px;
}

.document .page-main-header.document-header.open {
  margin-left: 0;
  width: 100%;
}

.document .page-main-header .main-header-right {
  padding: 0 0;
}

.document .page-main-header .navbar-collapse .navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-right: 0;
  width: 100%;
}

.document .page-main-header .navbar-collapse .navbar-nav .nav-item {
  padding-right: 15px;
}

.document .page-main-header .navbar-collapse .navbar-nav .nav-item:last-child {
  padding-right: 0;
}

.document
  .page-main-header
  .document-right-nav
  .navbar
  .navbar-collapse
  .navbar-nav
  .nav-item
  .nav-link {
  font-weight: 600;
  text-transform: uppercase;
}

.document .page-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 290px;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  z-index: 9;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.document .page-sidebar .logo-wrapper {
  border-bottom: 1px solid #efefef;
}

.document .page-sidebar .logo-wrapper img {
  vertical-align: middle;
  border-style: none;
  padding: 23px -7px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 30px;
}

.document .page-sidebar ul.document-menu {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.document .page-sidebar ul.document-menu h4 {
  font-size: 22px;
  font-weight: 600;
  border-bottom: 1px solid #efefef;
}

.document .page-sidebar ul.document-menu li a {
  color: #242934;
  font-size: 16px;
  padding-left: unset;
  padding-right: unset;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.document .page-sidebar ul.document-menu li a.menu-title {
  font-weight: 600;
  line-height: 1;
}

.document .page-sidebar ul.document-menu li a.menu-title:hover {
  color: #78266a;
}

.document .page-sidebar ul.document-menu li .nav-submenu {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
}

.document .page-sidebar ul.document-menu li .nav-submenu li {
  padding-left: 15px;
  position: relative;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.document .page-sidebar ul.document-menu li .nav-submenu li a {
  color: #999;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
}

.document .page-sidebar ul.document-menu li .nav-submenu li a i {
  position: absolute;
  top: 7px;
  left: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.document .page-sidebar ul.document-menu li .nav-submenu li a:hover {
  color: #78266a;
}

.document .page-sidebar ul.document-menu li .nav-submenu li:hover {
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
}

.document .fa-star {
  color: #f4c705;
}

.document .card .card-header h5 {
  font-size: 20px;
  margin-bottom: 5px;
}

.document .reviews {
  margin-bottom: 20px;
}

.document .reviews h2 {
  font-weight: 600;
  font-size: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.document .reviews h5 {
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.document-content .page-sidebar.open {
  -webkit-transform: translate(-260px) !important;
  transform: translate(-260px) !important;
}

.document-content .page-sidebar.open ~ .page-body,
.document-content .page-sidebar.open ~ footer {
  margin-left: 0 !important;
}

.document-content ul.nav-pills {
  overflow-y: scroll;
  max-height: calc(100vh - 180px);
  display: block;
}

.document-content ul.nav-pills li {
  line-height: 25px;
}

.document-content ul.nav-pills li h4 {
  color: #fff;
  padding: 0 15px;
}

.document-content ul.nav-pills li .nav-link {
  border-radius: 0.25rem;
  font-size: 14px;
  display: block;
  text-decoration: none;
  color: #d4d4d4;
  font-weight: 500;
  padding: 3px 15px;
  letter-spacing: 1px;
}

.document-content ul.nav-pills li .nav-link i {
  margin-top: 5px;
  text-decoration: none;
  color: #fff;
  margin-right: 20px;
}

.document-content ul.nav-pills li .nav-link.active {
  background-color: unset !important;
  color: #78266a;
}

.document-content ul.nav-pills li .nav-link p {
  font-size: 16px;
  margin-bottom: 0;
  padding-top: 7px;
  font-weight: 600;
}

.document-content ul.nav-pills li .show > .nav-link {
  background-color: unset !important;
  color: #78266a;
}

.document-content .table thead th {
  width: 50%;
}

.document-content .set-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.document-content i.fa-star {
  color: #ffd042;
}

.document-content .col-9 {
  padding-left: 40px;
}

.document-content .col-9 .set-height {
  overflow: scroll;
  overflow-x: hidden;
}

.document-content .col-9 .tab-content {
  overflow: auto;
  overflow-x: hidden;
  max-height: calc(100% - 200px);
  position: fixed;
  width: 976px;
  margin-left: 30px;
  -webkit-box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.4);
  -o-box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.4);
  box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.4);
}

.document-content .col-9 .tab-content .section-head {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 40px;
  padding: 0;
}

.document-content .card-body.tree-color {
  padding: 0;
}

.document-content .jstree-default {
  padding: 30px;
  background-color: #111727;
  -webkit-box-shadow: 1px 1px 2px 1px rgba(255, 255, 255, 0.08);
  box-shadow: 1px 1px 2px 1px rgba(255, 255, 255, 0.08);
}

.document-content .jstree-default .jstree-anchor {
  color: #a6aaaf;
}

.document-content .jstree-default .jstree-hovered {
  background-color: #202938;
  border: 1px solid #202938;
}

.document-content .jstree-default .jstree-clicked {
  color: white !important;
  background: transparent !important;
}

.document-content .jstree-default .jstree-icon {
  color: #656b78 !important;
}

.document-content hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.document-content .html-editor {
  color: rgba(255, 255, 255, 0.7);
  background-color: #2b2b2b;
}

.document-content .html-editor pre {
  color: rgba(255, 255, 255, 0.7);
  background-color: #000;
}

.document-content .progress {
  margin-bottom: 10px;
}

.document-content ul.list-ticked li {
  margin-bottom: 10px;
  padding-left: 1rem;
}

.document-content.faq {
  font-size: 18px;
}

.document-content.faq .btn {
  font-size: 18px;
  font-weight: 600;
  color: #78266a;
}

.document-content.faq .btn.btn-link {
  color: #000;
}

.document-content.faq .btn.btn-link.collapsed {
  color: #78266a;
}

.document-content.faq ul {
  border: unset;
}

.document-content.faq ul.nav {
  display: block;
}

.document-content.faq ul.nav li .nav-link {
  border-radius: 0.25rem;
  font-size: 18px;
  display: block;
  padding: 3px 20px 3px 30px;
  text-decoration: none;
  line-height: 30px;
  color: #4c5667;
  font-weight: 500;
  border: unset;
}

.document-content.faq ul.nav li .nav-link i {
  margin-top: 3px;
  text-decoration: none;
  line-height: 25px;
  color: #78266a;
  margin-right: 20px;
}

.document-content.faq ul.nav li .nav-link.active {
  color: #78266a;
  background-color: unset;
  border: unset;
}

.document-content.faq ul.nav li .nav-link:hover {
  border: unset;
}

.document-content .reviews {
  background-color: #fff;
  padding: 30px 0;
  margin-bottom: 30px;
  -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
}

.document-content .reviews h1 {
  text-transform: uppercase;
  font-weight: 600;
}

.document-content .reviews h5 {
  text-transform: capitalize;
}

@media (max-width: 991px) {
  .document.close .page-sidebar {
    left: 0;
  }

  .document.close
    .page-wrapper.compact-wrapper
    .page-body-wrapper.sidebar-icon
    .page-body {
    margin-left: unset;
  }

  .document .navbar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .document .page-sidebar {
    left: -290px;
    z-index: 1;
  }

  .document .navbar-toggler {
    padding: 0;
  }

  .document .navbar-toggler:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

@media (max-width: 575px) {
  .document .page-main-header.document-header {
    height: 60px;
  }
}

/**=====================
     70. Document CSS Ends
==========================**/

/**=====================
     72. Job CSS start
==========================**/

.filter-cards-view .job-filter:first-child {
  margin-bottom: 30px;
}

.filter-cards-view .checkbox-animated {
  margin-top: 30px;
  margin-bottom: 30px;
}

.filter-cards-view p {
  color: #999;
  line-height: 1.6;
  letter-spacing: 0.02em;
  font-size: 13px;
}

.filter-cards-view .filter-view-group {
  margin-bottom: 10px;
}

.location-checkbox span {
  color: #999;
  padding-left: 33px;
}

.job-accordion .card {
  margin-bottom: 30px;
}

.job-accordion .btn-block {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.job-sidebar .job-toggle {
  display: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.job-search p {
  margin-top: 20px;
  color: #999;
}

.job-search .media .media-body h6 {
  margin-bottom: 3px;
}

.job-search .media .media-body h6 span {
  font-size: 13px;
  font-weight: normal;
}

.job-search .media .media-body h6 span.badge {
  color: #fff;
  font-weight: 600;
}

.job-search .media .media-body h6 .btn {
  font-weight: 600;
}

.job-search .media .media-body p {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  display: inline-block;
}

.job-search .media .media-body ul.rating {
  margin-left: 10px;
  display: inline-block;
}

.job-search .media .media-body ul.rating li {
  display: inline-block;
  letter-spacing: -2px;
}

.job-search .media .media-body .job-apply-btn {
  position: absolute;
  top: 30px;
  right: 30px;
}

.job-search .job-description {
  margin-top: 20px;
  border-top: 1px solid #ececec;
  padding-top: 20px;
}

.job-search .job-description h6 {
  font-weight: 600;
}

.job-search .job-description p {
  margin-top: 0;
  margin-bottom: 6px;
  letter-spacing: 0.04em;
}

.job-search .job-description ul {
  list-style-type: disc;
  list-style-position: inside;
}

.job-search .job-description ul li {
  margin-bottom: 10px;
  cursor: pointer;
  color: #999;
}

.job-search .job-description ul li:last-child {
  margin-bottom: 0;
}

.job-search .job-description ul li:hover {
  color: #78266a;
}

.job-search .job-description .theme-form {
  margin-top: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #efefef;
}

.job-search
  .job-description
  .theme-form
  .row
  div[class^="col-"]
  + div[class^="col-"]
  .select2-container {
  margin-top: 14px;
  max-width: 100%;
}

.job-search
  .job-description
  .theme-form
  .row
  div[class^="col-"]
  .job-select2
  ~ .select2-container {
  margin-top: 0 !important;
}

.job-search
  .job-description
  .theme-form
  .row
  div[class^="col-"]
  .input-group
  .datepicker-here {
  font-size: 14px;
  border: 1px solid #e6edef;
  padding-top: 7px;
  padding-bottom: 7px;
}

.select2-container .select2-selection--single {
  border-radius: 0.25rem !important;
  border-color: #e6edef !important;
  height: 40px !important;
  padding: 5px;
}

.job-pagination {
  margin-bottom: 30px;
}

.job-pagination nav ul {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 1366px) {
  .xl-mt-job {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
  .job-search .media .media-body .job-apply-btn {
    position: unset;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 5px;
    display: block;
  }
}

@media only screen and (max-width: 991px) {
  .filter-cards-view {
    padding: 20px !important;
  }

  .filter-cards-view .job-filter:first-child {
    margin-bottom: 20px;
  }

  .filter-cards-view .checkbox-animated {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .job-accordion .animate-chk {
    padding: 20px !important;
  }

  .job-search p {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .job-search .media .media-body .job-apply-btn {
    position: unset;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 575px) {
  .filter-cards-view {
    padding: 15px !important;
  }

  .filter-cards-view .job-filter:first-child {
    margin-bottom: 15px;
  }

  .filter-cards-view .checkbox-animated {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .job-accordion .animate-chk {
    padding: 15px !important;
  }

  .job-search p {
    margin-top: 10px;
    text-align: center;
    font-size: 13px;
  }

  .job-search .media {
    display: block;
    text-align: center;
  }

  .job-search .media .media-body {
    text-align: center;
  }

  .job-search .media .media-body .job-apply-btn {
    margin-left: auto;
    margin-right: auto;
  }

  .job-search .media .media-body h6 {
    margin-bottom: unset;
  }

  .job-search .media .media-body h6 .pull-right {
    float: none;
    display: inline-block;
    margin-left: 15px;
  }

  .job-search .media .media-body h6 span.badge {
    font-size: 10px;
    padding: 5px;
  }

  .job-search .media .media-body h6 a {
    margin-bottom: 10px;
  }

  .job-search .media .media-body p span + span {
    display: block;
  }

  .job-search .media img {
    margin-bottom: 15px;
  }

  .job-search .media .m-r-20 {
    margin-right: 0;
  }

  .job-search .job-description {
    margin-top: 15px;
    padding-top: 15px;
  }

  .job-search .job-description label {
    display: block;
    text-align: left !important;
  }

  .job-search .job-description .select2-container {
    width: 100% !important;
  }

  .job-search .job-description .theme-form {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .job-search
    .job-description
    .theme-form
    .row
    div[class^="col-"]
    + div[class^="col-"]
    .select2-container {
    margin-top: 2px;
  }

  .job-search .job-description .theme-form .xs-mt-period {
    margin-top: 15px;
  }

  .job-search .card-footer {
    padding: 20px;
  }

  .job-pagination {
    margin-bottom: 15px;
  }

  .xl-mt-job {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 420px) {
  .job-search .media .media-body h6 span.badge {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .job-search .job-description .btn {
    padding: 6px 12px;
  }
}

/**=====================
     72. Job CSS end
==========================**/

/**=====================
     75. Learning CSS start
==========================**/

.learning-box .details-main {
  margin: 30px;
}

.learning-box .details-main h6 {
  font-size: 20px;
}

.learning-box .details-main p {
  color: #59667a;
  margin-bottom: 0;
}

.learning-header {
  margin-bottom: 5px;
}

.categories {
  padding: 20px 0;
}

.categories .learning-header {
  padding-left: 30px;
  font-size: 16px;
}

.categories ul li {
  padding: 5px 30px;
}

.categories ul li a {
  color: #999;
  font-weight: 500;
}

.categories ul li:hover {
  background-color: #e6edef;
}

.categories ul li:hover a {
  color: #78266a;
}

.upcoming-course .media + .media {
  margin-top: 30px;
}

.job-accordion {
  margin-bottom: 0;
}

.learning-section .categories ul li a {
  font-size: 13px;
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .learning-comment {
    float: left !important;
  }

  .blog-box .blog-details .blog-social li:nth-child(n + 2) {
    padding-left: 15px;
    margin-left: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .categories {
    padding: 20px 0;
  }

  .categories .learning-header {
    padding-left: 20px;
  }

  .categories ul li {
    padding: 5px 20px;
  }

  .upcoming-course .media + .media {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .categories ul li a {
    font-size: 13px;
  }

  .comment-box h4 {
    margin-bottom: 20px;
    padding-bottom: 15px;
  }

  .comment-box li {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .categories {
    padding: 15px 0;
  }

  .categories .learning-header {
    padding-left: 15px;
  }

  .upcoming-course .media + .media {
    margin-top: 10px;
  }
}

/**=====================
     75. Learning CSS end
==========================**/

/**=====================
     76. Order History CSS start
==========================**/

.prooduct-details-box {
  margin-bottom: 30px;
}

.prooduct-details-box .close {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 16px;
  cursor: pointer;
}

.prooduct-details-box .media {
  border: 1px solid #e6edef;
  width: 100%;
  position: relative;
  display: block;
}

.prooduct-details-box .media .media-body {
  padding: 15px;
}

.prooduct-details-box .rating {
  color: #ffaf45;
}

.prooduct-details-box .price {
  font-size: 14px;
  font-weight: 600;
}

.prooduct-details-box .price span {
  margin-left: 5px;
  color: #717171;
}

.prooduct-details-box ul.rating li {
  display: inline-block;
}

.prooduct-details-box .product-name h6 {
  color: #242934;
  font-size: 15px;
}

.prooduct-details-box .btn {
  position: absolute;
  right: 15px;
  bottom: 15px;
  font-size: 9px;
  padding: 2px 5px;
}

.order-history .input-group {
  text-align: right;
  max-width: 300px;
}

.order-history table th {
  min-width: 170px;
}

.order-history table th,
.order-history table td {
  text-align: center;
}

.order-history table thead tr th {
  vertical-align: middle;
  border-top: none;
  background-color: rgba(36, 105, 92, 0.05);
  border: 1px solid #78266a;
  padding: 15px;
  font-size: 16px;
  color: #78266a;
  font-weight: 600;
}

.order-history table .qty-box {
  width: 120px;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
}

.order-history table .qty-box input {
  padding: 0;
  border: none;
  background-color: transparent;
}

.order-history table .qty-box input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
}

.order-history table .qty-box .input-group {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #e6edef;
  overflow: hidden;
}

.order-history table .qty-box .btn {
  background-color: transparent;
  padding: 8px 13px;
  font-size: 10px;
}

.order-history table tbody tr td {
  vertical-align: middle;
  color: #999;
  font-weight: 600;
}

.order-history table tbody tr td .product-name a {
  font-size: 15px;
  color: #898989;
}

.order-history table tbody tr td .product-name .order-process {
  font-size: 13px;
  padding-left: 15px;
}

.order-history
  table
  tbody
  tr
  td
  .product-name
  .order-process
  .order-process-circle {
  position: relative;
}

.order-history
  table
  tbody
  tr
  td
  .product-name
  .order-process
  .order-process-circle:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #898989;
  left: -15px;
  top: 4px;
}

.order-history
  table
  tbody
  tr
  td
  .product-name
  .order-process
  .order-process-circle.shipped-order:before {
  background-color: #1b4c43;
}

.order-history
  table
  tbody
  tr
  td
  .product-name
  .order-process
  .order-process-circle.cancel-order:before {
  background-color: #d22d3d;
}

.order-history table tbody tr td.total-amount {
  color: #242934;
}

.order-history .product-name h6 {
  color: #242934;
  margin-bottom: unset;
  font-size: 14px;
}

.whishlist-main > div {
  margin-bottom: -30px;
}

@media only screen and (max-width: 1199px) {
  .order-history table {
    width: 900px;
    overflow: auto;
  }
}

@media only screen and (max-width: 767px) {
  .order-history table tbody tr td .product-name a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .wishlist .text-end {
    text-align: left !important;
  }
}

/**=====================
     76. Order History CSS end
==========================**/

/**=====================
     74. internationalization css start
==========================**/

.hdg_main {
  position: relative;
}

.main .langChoice {
  position: absolute;
  right: 30px;
  top: 20px;
  padding: 10px 12px;
  background-color: rgba(36, 105, 92, 0.05);
  border-color: #e6edef;
  color: #78266a;
}

/**=====================
     74. internationalization CSS end
==========================**/

/**=====================
     79. social app css start
==========================**/

.social-tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 30px;
}

.social-tab ul {
  border-bottom: none !important;
}

.social-tab ul li a {
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(36, 105, 92, 0.05);
}

.social-tab ul li a svg {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  color: #78266a;
}

.social-tab ul li a:hover {
  border-color: transparent !important;
}

.social-tab ul li a.active {
  background-color: #78266a !important;
  color: #fff !important;
}

.social-tab ul li a.active svg {
  color: #fff;
}

.social-tab ul li:nth-child(n + 2) {
  margin-left: 20px;
}

.social-tab .input-group {
  width: 280px;
}

.social-tab .input-group .form-control {
  background-color: rgba(36, 105, 92, 0.08);
  border-color: #e6edef;
  height: 40px;
  padding-left: 13px;
  font-size: 14px;
}

.social-tab .input-group .input-group-text {
  background-color: #78266a;
}

.social-tab .input-group .input-group-text i {
  color: #fff;
}

.social-network span {
  margin-bottom: 10px;
  display: block;
}

.social-list .media {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-list .media:last-child {
  margin-bottom: 0;
}

.social-list .media .media-body a {
  color: #999;
}

.social-list .media .media-body span {
  font-weight: 600;
  color: #242934;
}

.social-list .media .media-body:hover a {
  color: #78266a;
}

.photos {
  margin-bottom: -10px;
}

.photos ul li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 65px;
}

.photos ul li img {
  width: 100%;
}

.new-users-social {
  margin-bottom: 30px;
}

.new-users-social .media img {
  width: 58px;
}

.new-users-social .media .media-body {
  margin-top: 10px;
}

.new-users-social svg circle {
  color: #999;
}

.like-content span i {
  font-size: 14px;
}

.timeline-content p {
  margin-top: 30px;
  color: #999;
}

.timeline-content .comment-number i {
  color: #999;
  margin-right: 20px;
}

.timeline-content .comments-box {
  margin-top: 30px;
}

.timeline-content .comments-box .input-group {
  margin-top: 6px;
}

.timeline-content
  .comments-box
  .input-group
  .input-txt-bx::-webkit-input-placeholder {
  color: #999;
}

.timeline-content .comments-box .input-group .input-txt-bx::-moz-placeholder {
  color: #999;
}

.timeline-content
  .comments-box
  .input-group
  .input-txt-bx:-ms-input-placeholder {
  color: #999;
}

.timeline-content
  .comments-box
  .input-group
  .input-txt-bx::-ms-input-placeholder {
  color: #999;
}

.timeline-content .comments-box .input-group .input-txt-bx::placeholder {
  color: #999;
}

.timeline-content .comments-box .input-group .input-group-append .btn i {
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #999;
}

.timeline-content .comments-box .input-group .input-group-text {
  background-color: #78266a;
  padding: 10px;
}

.timeline-content .comments-box .input-group .input-group-text img {
  width: 22px;
  height: 22px;
}

.social-chat {
  margin-top: 30px;
}

.social-chat span span {
  font-weight: normal;
  color: #999;
  font-size: 12px;
}

.social-chat .media-body {
  padding: 30px;
  border: 1px solid #e6edef;
  position: relative;
}

.social-chat .media-body p {
  margin-top: 5px;
}

.social-chat .media-body:before {
  content: "";
  position: absolute;
  top: 19px;
  right: 100%;
  border: 7px solid transparent;
  border-right: 7px solid #59667a;
}

.social-chat .media-body:after {
  content: "";
  position: absolute;
  top: 19px;
  right: 100%;
  border: 7px solid transparent;
  border-right: 7px solid #fff;
}

.social-chat .your-msg,
.social-chat .other-msg {
  margin-bottom: 30px;
}

.social-chat .other-msg {
  margin-left: 40px;
}

.socialprofile span {
  color: #999;
}

.socialprofile h5 {
  margin-bottom: 10px;
}

.socialprofile .social-btngroup {
  margin: 15px 0;
}

.socialprofile .likes-profile h5 span {
  color: #242934;
}

.socialprofile .social-group {
  margin-top: 15px;
}

.socialprofile .social-group ul li:nth-child(4) {
  margin-left: -22px;
}

.social-status form .form-group .form-control-social {
  border: 1px solid #e6edef;
  padding-left: 15px;
  margin-bottom: 30px;
  width: 100%;
  height: 40px;
  border-radius: 5px;
}

.social-status .media {
  position: relative;
  margin-bottom: 30px;
}

.social-status .media:last-child {
  margin-bottom: 0;
}

.social-status .media .social-status {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #fff;
  bottom: 0;
  left: 35px;
  border-radius: 100%;
}

.social-status .media .social-online {
  border: 3px solid #1b4c43;
}

.social-status .media .social-busy {
  border: 3px solid #e2c636;
}

.social-status .media .social-offline {
  border: 3px solid #59667a;
}

.social-status .media .media-body p {
  color: #999;
  font-size: 13px;
  margin-bottom: 5px;
}

.social-status .media .media-body span + span {
  color: #999;
}

.social-status .media .media-body .light-span {
  color: #999;
}

.social-header h5 span:first-child {
  line-height: 1.48;
}

.social-header h5 span svg circle {
  color: #999;
}

.details-about + .details-about {
  margin-top: 15px;
}

.details-about .your-details p {
  color: #999;
  line-height: 1.6;
}

.avatar-showcase .friend-pic {
  margin-right: 8px;
  margin-bottom: 10px;
}

.avatar-showcase .pepole-knows ul li {
  display: inline-block;
  margin-right: 21px;
  margin-bottom: 10px;
}

.avatar-showcase .pepole-knows ul li:last-child {
  margin-right: 0;
}

.avatar-showcase .pepole-knows ul li .add-friend h6 {
  margin-top: 3px;
}

.avatar-showcase .pepole-knows ul li .add-friend span {
  margin-bottom: 5px;
  margin-top: 5px;
}

.activity-log .my-activity + .my-activity {
  margin-top: 15px;
}

.activity-log .my-activity h6 {
  margin-bottom: 15px;
}

.activity-log .my-activity p {
  margin-bottom: 5px;
  color: #999;
}

.activity-log .my-activity p span svg {
  width: 15px;
  height: 15px;
}

.social-app-profile .card .socialprofile h5 {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 16px;
}

.social-app-profile .card .socialprofile h4 {
  font-size: 20px;
}

.social-app-profile .card .socialprofile h6 {
  margin-bottom: unset;
}

.social-app-profile .card .socialprofile span {
  font-size: 12px;
}

.social-app-profile .card .socialprofile span .badge {
  font-size: 10px;
  padding: 2px 3px;
  font-weight: 400;
}

@media (max-width: 480px) {
  .social-app-profile .card .socialprofile h4 {
    font-size: 18px;
  }

  .customers ul li img {
    width: 25px !important;
  }

  .socialprofile .social-group {
    margin-top: 10px;
  }

  .photos ul li {
    width: 49px;
  }

  .new-users-social .media img {
    width: 50px;
  }
}

/**=====================
     79. social app CSS end
==========================**/

/**=====================
    80. avatars CSS start
==========================**/

.avatars .avatar {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.avatars .avatar:last-child {
  margin-right: 0;
}

.avatars .avatar .b-r-30 {
  border-radius: 30px;
}

.avatars .avatar .b-r-35 {
  border-radius: 35px;
}

.avatars .avatar .b-r-25 {
  border-radius: 25px;
}

.avatars .avatar .b-r-15 {
  border-radius: 15px;
}

.avatars .avatar.ratio img {
  width: auto;
}

.avatars .avatar .height-100 {
  height: 100px;
}

.avatars .avatar .height-90 {
  height: 90px;
}

.avatars .avatar .height-80 {
  height: 80px;
}

.avatars .avatar .height-70 {
  height: 70px;
}

.avatars .avatar .height-60 {
  height: 60px;
}

.avatars .avatar .height-50 {
  height: 50px;
}

.avatars .avatar .status {
  position: absolute;
  background-color: #999;
  bottom: 0;
  right: 4px;
  border-radius: 100%;
  border: 1px solid #fff;
}

.avatars .avatar .status-100 {
  width: 25px;
  height: 25px;
}

.avatars .avatar .status-90 {
  width: 20px;
  height: 20px;
}

.avatars .avatar .status-80 {
  width: 15px;
  height: 15px;
}

.avatars .avatar .status-70 {
  width: 12px;
  height: 12px;
}

.avatars .avatar .status-60 {
  width: 10px;
  height: 10px;
}

.avatars .avatar .status-50 {
  width: 9px;
  height: 9px;
}

.avatars .avatar .status-40 {
  width: 8px;
  height: 8px;
}

.avatars .avatar .status-30 {
  width: 7px;
  height: 7px;
}

.customers ul li img {
  border: 2px solid #fff;
  -webkit-box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.customers ul li img:hover {
  -webkit-transform: translateY(-4px) scale(1.02);
  transform: translateY(-4px) scale(1.02);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.customers ul li + li {
  margin-left: -22px;
}

.customers ul li:nth-child(4) {
  margin-left: -4px;
}

.customers.avatar-group {
  margin-right: 30px;
}

.customers.avatar-group:last-child {
  margin-right: 0;
}

.avatar-showcase {
  margin-bottom: -10px;
}

.avatar-showcase .avatars .avatar {
  margin-bottom: 10px;
}

.avatar-showcase .avatar-group {
  margin-bottom: 10px;
}

/**=====================
     80. avatars CSS Ends
==========================**/

/**=====================
    81. Bookmark CSS start
==========================**/

.bookmark {
  text-align: right;
}

.bookmark ul li {
  border: 1px solid #e6edef;
  background-color: #fff;
  display: inline-block;
  border-radius: 8px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.bookmark ul li svg {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.bookmark ul li:hover {
  background-color: #78266a;
}

.bookmark ul li:hover a svg {
  color: #fff;
  stroke: #fff;
}

.bookmark ul li .search-form {
  position: absolute;
  bottom: 53px;
}

.bookmark ul li .search-form .form-control-search {
  top: 60px;
  position: absolute;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  right: -20px;
  -webkit-transform: translateY(-35px) scaleY(0);
  transform: translateY(-35px) scaleY(0);
  opacity: 0;
  visibility: hidden;
}

.bookmark ul li .search-form .form-control-search input {
  width: 285px;
  background-color: #fff;
  border: 1px solid #e6edef;
}

.bookmark ul li .search-form .form-control-search.open {
  -webkit-transform: translateY(0px) scaleY(1);
  transform: translateY(0px) scaleY(1);
  opacity: 1;
  visibility: visible;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  z-index: 100;
}

.bookmark ul li .search-form .form-control-search:after {
  top: 12px;
  left: 20px;
  z-index: 1;
}

.bookmark ul li .search-form .form-control-search:before {
  left: 50px;
  top: 9px;
  z-index: 2;
}

.bookmark ul li a {
  position: relative;
  color: #242934;
}

.bookmark ul li a svg {
  vertical-align: middle;
  height: 18px;
  width: 18px;
  margin: 12px;
}

.bookmark ul li:nth-child(n + 2) {
  margin-left: 8px;
}

.bookmark ul li:last-child {
  padding-right: 0;
  color: #78266a;
}

.bookmark ul li:last-child a {
  border-radius: 5px;
}

.bookmark ul li:last-child a svg {
  color: #78266a;
}

.offcanvas-bookmark.page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .bookmark
  ul
  li
  a {
  z-index: 99999;
  color: rgba(255, 255, 255, 0.5);
}

.offcanvas-bookmark:before {
  position: fixed;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 99;
  right: 0;
  left: 0;
}

/**=====================
    81. Bookmark CSS Ends
==========================**/

/**=====================
    82. cart CSS start
==========================**/

.cart .total-amount {
  text-align: right !important;
  margin-top: 30px;
}

.cart .amount-space {
  margin-left: 40px;
}

.cart .cart-btn-transform {
  text-transform: capitalize;
}

.cart .qty-box {
  width: 25%;
  margin: 0 auto;
}

.cart .qty-box .input-group .btn {
  background-color: #ddd !important;
  border-color: #ddd !important;
  padding: 5px 12px;
}

.cart-buttons {
  margin-top: 30px;
}

.cart-buttons .cart-btn {
  float: right;
  padding-right: 85px;
}

/**=====================
    82. cart CSS Ends
==========================**/

/**=====================
    83. Wishlist CSS start
==========================**/

.wishlist table tr td svg {
  color: #d22d3d;
}

/**=====================
    83. Wishlist CSS Ends
==========================**/

/**=====================
    84. Checkout CSS start
==========================**/

.checkout label {
  color: #2c323f;
}

.checkout .checkout-details {
  border: 1px solid #e6edef;
  padding: 40px;
}

.checkout .checkout-details .order-place {
  margin-top: 20px;
}

.order-box .title-box {
  padding-bottom: 20px;
  font-size: 22px;
  border-bottom: 1px solid #e6edef;
}

.order-box .title-box span {
  width: 35%;
  float: right;
  font-weight: 600;
  text-align: right;
}

.order-box .title-box h4 {
  font-weight: 600;
}

.order-box .title-box .checkbox-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.order-box .sub-total li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}

.order-box .sub-total li:nth-child(n + 2) {
  margin-top: 15px;
}

.order-box .sub-total li .count {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  width: 35%;
  float: right;
  text-align: right;
}

.order-box .sub-total .shipping-class {
  margin-bottom: 12px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e6edef;
}

.order-box .sub-total .shipping-class .shopping-checkout-option {
  margin-top: -4px;
  position: relative;
  font-size: 18px;
  line-height: 20px;
  color: #78266a;
  font-weight: 400;
  width: 35%;
  float: right;
}

.order-box .sub-total .shipping-class .shopping-checkout-option label {
  font-weight: 500;
  font-size: 14px;
  text-align: right;
}

.order-box .total {
  position: relative;
  margin-bottom: 30px;
}

.order-box .total li {
  position: relative;
  display: block;
  line-height: 20px;
  font-size: 18px;
}

.order-box .qty {
  position: relative;
  border-bottom: 1px solid #e6edef;
  margin-bottom: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.order-box .qty li {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 500;
}

.order-box .qty li:nth-child(n + 2) {
  margin-top: 15px;
}

.order-box .qty li span {
  float: right;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  width: 35%;
  text-align: right;
}

.radio-option {
  position: relative;
}

.img-paypal {
  width: 50%;
  margin-left: 15px;
}

/**=====================
    86. Checkout CSS Ends
==========================**/

/**=====================
    Responsive CSS Start
==========================**/

@media screen and (max-width: 1199px) {
  .checkout .checkout-details {
    padding: 25px;
  }

  .order-box .title-box span {
    width: 30%;
  }

  .order-box .sub-total li .count {
    width: 30%;
  }

  .order-box .sub-total .shipping-class .shopping-checkout-option {
    width: 32%;
  }

  .order-box .qty li span {
    width: 30%;
  }

  .order-box .total li .count {
    width: 30%;
  }
}

@media screen and (max-width: 991px) {
  .order-box .title-box h4 {
    font-size: 18px;
  }

  .order-box .title-box span {
    font-size: 18px;
  }

  .order-box .qty li {
    font-size: 14px;
  }

  .order-box .qty li span {
    font-size: 16px;
  }

  .order-box .sub-total li {
    font-size: 14px;
  }

  .order-box .sub-total li .count {
    font-size: 16px;
  }

  .order-box .sub-total .shipping-class .shopping-checkout-option {
    width: 30%;
  }

  .order-box .animate-chk label {
    font-size: 14px;
  }
}

@media screen and (max-width: 575px) {
  .order-box .title-box {
    padding-bottom: 15px;
  }

  .order-box .sub-total .shipping-class .shopping-checkout-option {
    width: 50%;
  }

  .order-box .sub-total li .count {
    width: 50%;
  }

  .order-box .animate-chk label {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .checkout .checkout-details {
    padding: 20px;
  }
}

@media screen and (max-width: 420px) {
  .order-box .qty li {
    font-size: 13px;
  }

  .order-box .qty li span {
    font-size: 14px;
  }

  .order-box .sub-total li .count {
    font-size: 14px;
  }
}

/**=====================
    84. Checkout CSS ends
==========================**/

/**=====================
     85. bookmark app CSS Start
==========================**/

.email-wrap .email-app-sidebar .main-menu > li a[aria-selected="true"] span {
  color: #78266a !important;
}

.modal-bookmark .modal-body,
.modal-bookmark .modal-header {
  padding: 25px;
}

.modal-bookmark .modal-body h5,
.modal-bookmark .modal-header h5 {
  font-weight: 600;
}

.bookmark-wrap .btn-mail {
  color: #78266a !important;
  width: 100%;
  font-weight: 600;
}

.bookmark-wrap .row .col-xl-3 + .col-xl-3 {
  padding-right: 15px;
}

.bookmark-wrap .left-bookmark ul li button {
  border: none;
  cursor: pointer;
}

.bookmark-wrap .left-bookmark ul li button:focus {
  outline: none;
}

.bookmark-wrap .left-bookmark ul li svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.bookmark-wrap .left-bookmark ul li > .main-title {
  color: #78266a;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
  font-size: 16px;
}

.bookmark-wrap .left-bookmark ul li > .main-title span a:hover {
  background-color: transparent;
}

.bookmark-wrap .left-bookmark ul li > .main-title svg {
  color: #898989;
  stroke-width: 1px;
}

.bookmark-wrap .left-bookmark ul li a > .title {
  text-transform: capitalize;
  color: #2b2b2b;
}

.form-bookmark a {
  text-transform: capitalize !important;
  color: #78266a !important;
  line-height: 1 !important;
  font-weight: 500;
}

.form-bookmark a:hover {
  background-color: transparent !important;
}

.form-bookmark input#index_var {
  display: none;
}

.form-bookmark .form-group label {
  font-weight: 600;
  margin-bottom: 5px;
}

.form-bookmark .form-group input,
.form-bookmark .form-group select {
  font-size: 14px;
}

.form-bookmark .form-group .select2-container .select2-selection--single {
  border-color: #e6edef !important;
}

.form-bookmark .form-group .select2 {
  width: 100% !important;
}

.form-bookmark .form-group .error {
  margin: 0 !important;
  font-size: 11px;
  color: red !important;
}

.form-bookmark .form-group .fill-color {
  width: 35px;
  height: 30px;
  padding: 3px;
}

.form-bookmark button {
  margin-top: 30px;
}

@-webkit-keyframes displayTransition {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes displayTransition {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.bookmark-tabcontent .details-bookmark {
  height: auto;
  min-height: 359px;
}

.bookmark-tabcontent .details-bookmark .bookmark-card {
  position: relative;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon {
  padding: 3px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  width: 30px;
  position: absolute;
  right: 15px;
  top: 15px;
  opacity: 0;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon i {
  color: #fff;
  font-size: 16px;
  vertical-align: text-bottom;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card
  .favourite-icon.favourite
  i {
  color: #fed112;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .desciption-data {
  width: 100%;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card
  .desciption-data
  .content-general {
  display: none;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark {
  text-align: left;
  padding: 15px;
  border-top: 1px solid #e6edef;
  position: relative;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark p {
  -webkit-animation: displayTransition 1s;
  animation: displayTransition 1s;
  font-size: 13px;
  opacity: 1;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card
  .title-bookmark
  .hover-block {
  display: none;
  margin-top: 15px;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card
  .title-bookmark
  .hover-block
  ul
  li {
  display: inline-block;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card
  .title-bookmark
  .hover-block
  ul
  li
  + li {
  margin-left: 5px;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card
  .title-bookmark
  .hover-block
  ul
  li
  svg {
  width: 16px;
  height: 16px;
  color: #898989;
}

.bookmark-tabcontent .details-bookmark .bookmark-card.card:hover {
  -webkit-box-shadow: 1px 4px 16px 3px rgba(0, 0, 0, 0.04);
  box-shadow: 1px 4px 16px 3px rgba(0, 0, 0, 0.04);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card:hover
  .title-bookmark
  p {
  opacity: 0;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card:hover
  .title-bookmark
  .hover-block {
  display: block;
  -webkit-animation: displayTransition 2s;
  animation: displayTransition 2s;
  position: absolute;
  width: 100%;
  padding: 15px;
  top: 15px;
  left: 0;
}

.bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card:hover
  .favourite-icon {
  opacity: 1;
}

.bookmark-tabcontent .details-bookmark.list-bookmark .row .col-xl-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card
  .desciption-data
  .content-general {
  display: block;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card
  .details-website {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card
  .details-website
  .title-bookmark
  p {
  display: block;
  opacity: 1;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card
  .details-website
  .title-bookmark
  .hover-block {
  position: absolute;
  right: 15px;
  display: block;
  text-align: right;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card
  .details-website:hover
  .title-bookmark
  .hover-block {
  display: block;
  -webkit-animation: displayTransition 0s;
  animation: displayTransition 0s;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card.card
  .title-bookmark {
  border-top: none;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card.card
  .title-bookmark
  p,
.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card.card
  .title-bookmark
  h6 {
  margin-top: 0;
  display: inline-block;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card.card
  .title-bookmark
  h6 {
  position: relative;
  padding-right: 15px;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card.card
  .title-bookmark
  h6::after {
  position: absolute;
  content: "-";
  right: 0;
  color: #898989;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card.card
  .title-bookmark
  .content-general {
  margin-top: 5px;
}

.bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card.card
  .title-bookmark
  .content-general
  p {
  width: 68%;
  margin-right: 20px;
  opacity: 1;
}

.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
  width: 20%;
}

.bookmark-tabcontent .tab-content .card .card-header {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #e6edef;
}

.bookmark-tabcontent .tab-content .card .card-header h5 {
  font-weight: 600;
  font-size: 22px;
}

.bookmark-tabcontent .tab-content .card .card-header svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.bookmark-tabcontent .tab-content .card .card-header ul li {
  display: inline-block;
}

.bookmark-tabcontent .tab-content .card .card-header ul li + li {
  margin-left: 5px;
}

@media only screen and (max-width: 1660px) {
  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card.card
    .title-bookmark
    .content-general
    p {
    display: none;
  }

  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card.card
    .title-bookmark
    .hover-block {
    margin-top: 6px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  /* .bookmark-wrap .left-bookmark ul li .btn-block svg {
		display: none;
	} */
}

@media only screen and (max-width: 1366px) {
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
    width: 40%;
  }

  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card.card
    .title-bookmark
    .hover-block {
    top: 80px;
  }

  .bookmark ul li a svg {
    width: 15px;
    height: 15px;
    margin: 10px;
  }
}

@media only screen and (max-width: 1199px) {
  .bookmark-wrap .left-bookmark {
    height: auto;
  }

  .bookmark-tabcontent .details-bookmark {
    min-height: auto;
  }
}

@media only screen and (max-width: 991px) {
  .modal-bookmark .modal-body,
  .modal-bookmark .modal-header {
    padding: 20px;
  }

  .form-bookmark button {
    margin-top: 20px;
  }

  .bookmark-tabcontent .tab-content .card .card-header,
  .bookmark-tabcontent .tab-content .card .card-body {
    padding: 20px;
  }

  .bookmark-tabcontent .tab-content .card .card-header h5,
  .bookmark-tabcontent .tab-content .card .card-body h5 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website
    .title-bookmark
    p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 172px;
  }

  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website:hover
    .title-bookmark
    .hover-block
    p {
    display: none;
  }

  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website:hover
    .title-bookmark
    .hover-block
    ul {
    position: relative;
    right: unset;
    top: unset;
  }

  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
    width: 45%;
  }
}

@media only screen and (max-width: 575px) {
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
    width: 46%;
  }

  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website {
    display: block;
  }

  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website
    img {
    width: 100%;
  }

  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website
    .title-bookmark
    h6,
  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website
    .title-bookmark
    p {
    display: block;
  }

  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website
    .title-bookmark
    .content-general {
    display: none;
  }

  .bookmark-tabcontent
    .details-bookmark.list-bookmark
    .bookmark-card
    .details-website
    .title-bookmark
    .hover-block {
    position: relative;
    right: unset;
    text-align: left;
    top: unset;
    padding: 0;
  }

  .modal-bookmark .modal-body,
  .modal-bookmark .modal-header {
    padding: 15px;
  }

  .form-bookmark button {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 360px) {
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
    width: 28%;
  }
}

/**=====================
   85. bookmark app CSS Ends
==========================**/

/**=====================
      86. contacts CSS Start
==========================**/

.alert-contactadd {
  background-color: #fff;
  border: 1px solid var(--theme-deafult);
  width: 12%;
  padding: 10px;
}

.updateimg {
  position: absolute;
  left: 15px;
  width: 100px;
  top: 32px;
  line-height: 93px;
  opacity: 0;
}

#right-history {
  top: 81px;
  right: -320px;
  height: 100%;
  position: fixed;
  width: 320px;
  z-index: 9;
  background-color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
}

#right-history h6 span a {
  color: #242934;
}

#right-history.show {
  right: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.history-details > div {
  padding: 30px 0;
}

.history-details .media {
  padding: 30px 15px;
  border-top: 1px solid #f4f4f4;
}

.history-details .media i {
  padding: 5px;
  background: #fed112;
  color: #fff;
  border-radius: 100%;
}

.history-details .media h6 {
  margin-bottom: 5px;
}

.delete-contact {
  display: none;
}

.more-data {
  -webkit-animation: displayTransition 1s;
  animation: displayTransition 1s;
}

.contact-editform {
  padding: 30px;
  padding-left: unset;
  -webkit-animation: displayTransition 1s;
  animation: displayTransition 1s;
}

.contact-editform a {
  display: block;
}

.contact-editform button {
  margin-top: 30px;
}

.contact-editform .select2-container .select2-selection--single {
  height: 39px !important;
  border-color: #e6edef !important;
  background-color: #f5f7fb;
}

#deletemodal .modal-header {
  border-bottom: none;
}

#deletemodal .modal-header .delete-data span svg {
  vertical-align: middle;
  margin-right: 15px;
}

.contact-options li .btn-category {
  line-height: 39px;
  border-radius: 3px;
  padding-left: 40px !important;
  padding: 0;
  color: #78266a;
  font-weight: 500;
}

.list-persons p {
  font-family: "Roboto", sans-serif, sans-serif;
}

.list-persons .profile-mail {
  padding: 30px 0;
}

.list-persons .profile-mail .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.list-persons .profile-mail .media img {
  width: auto;
  height: 100px;
}

.list-persons .profile-mail .media .media-body h5 {
  margin-bottom: 5px;
  font-weight: 700;
}

.list-persons .profile-mail .media .media-body ul {
  margin-top: 5px;
}

.list-persons .profile-mail .media .media-body ul li {
  display: inline-block;
}

.list-persons .profile-mail .media .media-body ul li a {
  font-size: 13px;
  font-weight: 500;
}

.list-persons .profile-mail .media .media-body ul li + li {
  padding-left: 10px;
  margin-left: 5px;
  border-left: 2px solid #f4f4f4;
}

.list-persons .profile-mail .email-general {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #ddd;
}

.list-persons .profile-mail .email-general ul {
  padding-right: 20px;
}

.list-persons .profile-mail .email-general ul li {
  color: #898989;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}

.list-persons .profile-mail .email-general ul li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

.list-persons .profile-mail .email-general ul li > span {
  float: right;
}

.list-persons .profile-mail .email-general p span {
  margin-left: 30px;
}

.list-persons .profile-mail .email-general .gender {
  margin-top: 30px;
}

.contacts-tabs .nav-pills {
  border-right: 1px solid #e6edef;
  height: 100%;
}

.contacts-tabs .nav-pills .nav-link.active,
.contacts-tabs .nav-pills .nav-link > .nav-link,
.contacts-tabs .nav-pills .show.active,
.contacts-tabs .nav-pills .show > .nav-link {
  background-color: rgba(36, 105, 92, 0.1);
  color: #242934;
}

.contacts-tabs .nav-pills .nav-link.active h6,
.contacts-tabs .nav-pills .nav-link > .nav-link h6,
.contacts-tabs .nav-pills .show.active h6,
.contacts-tabs .nav-pills .show > .nav-link h6 {
  color: #78266a;
}

.contacts-tabs .nav-pills .nav-link {
  color: #242934;
  padding: 0;
}

.contacts-tabs .nav-pills .nav-link .media .img-50 {
  height: 50px;
}

.contacts-tabs .nav-pills .nav-link + .nav-link {
  border-top: 1px solid #e6edef;
}

.contacts-tabs .nav-pills .media {
  padding: 20px;
}

.contacts-tabs .tab-content .tab-content-child {
  -webkit-animation: displayTransition 1s;
  animation: displayTransition 1s;
}

@media only screen and (max-width: 991px) {
  #right-history {
    top: 60px;
  }

  .updateimg {
    top: 21px;
  }

  .list-persons .profile-mail {
    padding: 20px;
  }

  .list-persons .profile-mail .email-general .gender {
    margin-top: 20px;
  }

  .contact-editform button {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-editform {
    padding: 20px;
  }

  .contact-editform.pl-0 {
    padding-left: 20px !important;
  }

  .contacts-tabs .nav-pills {
    border-right: none;
    border-bottom: 1px solid #e6edef;
  }
}

@media only screen and (max-width: 575px) {
  #right-history {
    width: 270px;
  }

  .updateimg {
    top: 16px;
    left: 29px;
  }

  .contacts-tabs .media img.m-r-20 {
    margin-right: 15px;
  }

  .contacts-tabs .nav-pills .media {
    padding: 15px;
  }

  .list-persons .profile-mail {
    padding: 15px;
  }

  .list-persons .profile-mail .media img {
    height: 80px;
    width: 80px !important;
  }

  .list-persons .profile-mail .email-general .gender {
    margin-top: 15px;
  }

  .list-persons .profile-mail .email-general ul {
    padding-right: unset;
  }

  .list-persons .profile-mail .email-general ul li {
    font-size: 12px;
  }

  .list-persons .nav-pills .nav-link .media .media-body {
    text-align: left;
  }

  .contact-editform {
    padding: 15px;
  }

  .contact-editform button {
    margin-top: 15px;
  }

  .contact-editform.pl-0 {
    padding-left: 15px !important;
  }

  .contact-editform form .row > div .row > div > div,
  .contact-options form .row > div .row > div > div {
    margin-bottom: 0;
  }

  .contact-editform form .row > div .row > div + div,
  .contact-options form .row > div .row > div + div {
    margin-top: 15px;
  }

  .list-persons .profile-mail .media .media-body h5 {
    font-size: 16px;
  }

  .bookmark-tabcontent .tab-content .card .card-header h5 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 360px) {
  .updateimg {
    line-height: 73px;
    width: 80px;
  }

  .list-persons .profile-mail .email-general p span {
    margin-left: 10px;
  }

  .list-persons .profile-mail .media {
    display: block;
  }

  .list-persons .profile-mail .media img {
    height: 80px;
    width: 80px !important;
    margin-bottom: 15px;
  }
}

/**=====================
    86. contacts CSS Ends
==========================**/

/**=====================
     87. task app CSS Start
==========================**/

.modal-open .datepickers-container {
  z-index: 1075;
}

.date-details {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.date-details > div + div {
  margin-left: 10px;
}

.taskadd table tr td {
  border-top: none;
}

.taskadd table tr td:first-child {
  padding-left: 30px;
}

.taskadd table tr td:last-child {
  padding-right: 30px;
}

.taskadd table tr td svg {
  width: 18px;
  height: 18px;
  color: #59667a;
}

.taskadd table tr:first-child td {
  padding-top: 30px;
}

.taskadd table tr:last-child td {
  padding-bottom: 30px;
}

@media only screen and (max-width: 1660px) {
  .taskadd table tr td:first-child {
    min-width: 160px;
  }

  .taskadd table tr td:nth-child(3) {
    min-width: 70px;
  }
}

@media only screen and (max-width: 991px) {
  .date-details {
    display: block !important;
    margin-bottom: -10px;
  }

  .date-details .d-inline-block {
    margin-bottom: 10px;
  }

  .taskadd table tr td:first-child {
    padding-left: 20px;
  }

  .taskadd table tr:first-child td {
    padding-top: 20px;
  }

  .taskadd table tr:last-child td {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .taskadd table tr td:nth-child(2) {
    min-width: 745px;
  }
}

@media only screen and (max-width: 575px) {
  .taskadd table tr td:first-child {
    padding-left: 15px;
  }

  .taskadd table tr:first-child td {
    padding-top: 15px;
  }

  .taskadd table tr:last-child td {
    padding-bottom: 15px;
  }
}

/**=====================
   87. task app CSS Ends
==========================**/

/**========================
    88.  Invoice CSS Start
=============================**/

.invoice p {
  color: #999;
}

.invoice .invo-header {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e6edef;
}

.invoice .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(36, 105, 92, 0.05);
  --bs-table-accent-bg: unset;
}

.invoice .table-striped tbody tr h6 {
  text-transform: capitalize;
  color: #78266a;
}

.invoice .invo-profile {
  margin-bottom: 15px;
}

@media only screen and (max-width: 1199px) {
  .invoice h4 {
    margin-bottom: 0px;
  }

  .invoice h3 {
    margin-bottom: 0px;
  }

  .invoice .invo-header .media-left img {
    width: 55% !important;
  }

  .invoice .invo-profile .media {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e6edef;
  }
}

@media only screen and (max-width: 991px) {
  .invoice .card .card-body {
    padding: 20px;
  }

  .invoice h4 {
    font-size: 20px;
  }

  .invoice h3 {
    font-size: 20px;
  }

  .invoice table td {
    min-width: 150px;
  }

  .invoice table td:first-child {
    min-width: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .invoice .invo-pal {
    margin-top: 15px;
    text-align: left !important;
  }
}

@media only screen and (max-width: 575px) {
  .invoice h4 {
    font-size: 18px;
  }

  .invoice h3 {
    font-size: 14px;
  }

  .invoice p {
    font-size: 13px;
  }

  .invoice .invo-header .media-body {
    text-align: right;
  }

  .invoice .text-xs-center {
    margin-top: 20px;
    text-align: center !important;
  }

  .invoice .text-md-end {
    text-align: left;
  }
}

@media only screen and (max-width: 420px) {
  .invoice .invo-header .media-left img {
    width: 50% !important;
  }

  .invoice .invo-profile .media {
    display: block;
  }

  .invoice .invo-profile .media .media-body {
    margin-left: 0 !important;
    margin-top: 10px;
  }
}

/**========================
    88.  Invoice CSS endp
==========================**/

/**========================
  89.  Formwidgets CSS Start
=============================**/

.clockpicker-popover .popover-title {
  border-color: #e6edef;
}

.clockpicker-popover .popover-content {
  background-color: rgba(36, 105, 92, 0.05);
}

.date-range-picker {
  margin-bottom: -25px;
}

.date-range-picker .daterange-card {
  border: 1px solid #e6edef;
  padding: 30px;
  height: 100%;
}

.date-range-picker .daterange-card .form-group {
  margin-bottom: unset;
}

.date-range-picker > div {
  margin-bottom: 25px;
}

@media screen and (max-width: 1199px) {
  .card .card-header h5 {
    font-size: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .date-range-picker .daterange-card {
    padding: 20px;
  }
}

@media screen and (max-width: 575px) {
  .date-range-picker .daterange-card {
    padding: 15px;
  }
}

/**========================
  89. Formwidgets CSS end
=============================**/

/**=====================
   90. base_inputs CSS start
==========================**/

.jkanban-container .note a {
  display: contents;
}

.jkanban-container p {
  color: #999;
}

.kanban-board-header {
  height: auto;
  width: auto;
  line-height: unset;
  background-color: rgba(36, 105, 92, 0.05);
}

.kanban-board-header.bg-primary ~ .kanban-drag {
  background-color: rgba(36, 105, 92, 0.3);
}

.kanban-board-header.bg-secondary ~ .kanban-drag {
  background-color: rgba(186, 137, 93, 0.3);
}

.kanban-board-header.bg-warning ~ .kanban-drag {
  background-color: rgba(226, 198, 54, 0.3);
}

.kanban-board-header.bg-success ~ .kanban-drag {
  background-color: rgba(27, 76, 67, 0.3);
}

.kanban-container {
  display: contents;
}

.kanban-container footer {
  padding: 0;
  margin: 0;
}

.kanban-board {
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  overflow: hidden;
  width: calc(100% / 3 - 30px) !important;
}

.kanban-board .kanban-drag {
  background: rgba(36, 105, 92, 0.05);
}

.kanban-board header {
  padding: 20px 20px 0;
}

.kanban-item {
  background: #fff;
  padding: 0;
  margin-bottom: 20px;
}

.kanban-item p {
  font-size: 13px;
}

.kanban-item:last-child {
  margin-bottom: 0;
}

.kanban-item .list {
  display: inline-block;
  opacity: 0.4;
}

.kanban-item .list li {
  display: inline-block;
  font-size: 12px;
}

.kanban-item .list li i {
  margin-right: 10px;
}

.kanban-item .list li:nth-child(n + 2) {
  margin-left: 5px;
}

.kanban-item .kanban-box {
  border: 1px solid #e6edef;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  display: block;
  color: inherit;
}

.kanban-item .kanban-box:hover {
  color: inherit;
}

.kanban-item .kanban-box + .kanban-box {
  margin-top: 20px;
}

.kanban-item .kanban-box .badge {
  font-weight: 400;
}

.kanban-item .kanban-box h6 {
  font-size: 15px;
  margin-top: 5px;
  font-weight: 600;
}

.kanban-item .kanban-box .dropdown {
  display: inline-block;
  right: 10px;
  position: absolute;
  top: 20px;
}

.kanban-item .kanban-box .dropdown .dropdown-item {
  font-size: 13px;
  font-weight: 500;
  padding: 5px 10px;
  text-align: left;
}

.kanban-item .kanban-box .dropdown svg {
  height: 14px;
}

.kanban-item .kanban-box .d-flex {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.kanban-item .kanban-box .dropdown-toggle:after {
  content: none;
}

.kanban-item .kanban-box .customers ul li + li {
  margin-left: -10px;
}

.kanban-item .kanban-box .date {
  font-size: 11px;
  opacity: 0.5;
}

.colorfull-kanban .kanban-board-header {
  padding-bottom: 20px;
}

.kanban-block {
  margin-bottom: -30px;
}

@media only screen and (max-width: 1300px) {
  .kanban-board {
    width: calc(100% / 2 - 30px) !important;
  }
}

@media only screen and (max-width: 1199px) {
  .jkanban-container .btn {
    margin-bottom: 10px;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .kanban-block {
    margin-bottom: -20px;
  }
}

@media only screen and (max-width: 700px) {
  .kanban-container .kanban-board {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .jkanban-container .btn {
    margin-left: unset;
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 480px) {
  .kanban-board .kanban-drag {
    padding: 10px;
  }

  .kanban-board header {
    padding: 10px 10px 0;
  }

  .kanban-board header .kanban-title-board {
    font-size: 14px;
  }

  .jkanban-container .card .card-body {
    padding: 15px;
  }

  .jkanban-container .btn {
    padding: 7px 12px;
  }

  .kanban-item {
    margin-bottom: 10px;
  }

  .kanban-item .kanban-box {
    padding: 15px;
  }

  .colorfull-kanban .kanban-board-header {
    padding-bottom: 10px;
  }
}

/**=====================
  90. base_inputs CSS ends
==========================**/

/**==========================
   91. Project list css start
=============================**/

.project-list .row {
  margin: 15px;
}

.project-list button:focus {
  outline: none !important;
}

.project-list .theme-form .form-group {
  margin-bottom: 15px;
}

.project-list .border-tab.nav-tabs .nav-item .nav-link {
  border: 1px solid transparent;
  padding: 5px 30px 5px 0;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
}

.project-list .btn {
  vertical-align: -12px;
  float: right;
}

.project-list .btn svg {
  vertical-align: middle;
  height: 16px;
}

.project-list ul {
  margin-bottom: 0 !important;
  border-bottom: 0;
}

.project-list ul li svg {
  height: 18px;
  vertical-align: middle;
  margin-right: 5px;
}

.tab-content .row > div.col-xl-4:nth-child(1) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 10ms;
  animation: fadeIncustom 0.5s linear 10ms;
}

.tab-content .row > div.col-xl-4:nth-child(2) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 20ms;
  animation: fadeIncustom 0.5s linear 20ms;
}

.tab-content .row > div.col-xl-4:nth-child(3) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 30ms;
  animation: fadeIncustom 0.5s linear 30ms;
}

.tab-content .row > div.col-xl-4:nth-child(4) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 40ms;
  animation: fadeIncustom 0.5s linear 40ms;
}

.tab-content .row > div.col-xl-4:nth-child(5) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 50ms;
  animation: fadeIncustom 0.5s linear 50ms;
}

.tab-content .row > div.col-xl-4:nth-child(6) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 60ms;
  animation: fadeIncustom 0.5s linear 60ms;
}

.tab-content .row > div.col-xl-4:nth-child(7) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 70ms;
  animation: fadeIncustom 0.5s linear 70ms;
}

.tab-content .row > div.col-xl-4:nth-child(8) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 80ms;
  animation: fadeIncustom 0.5s linear 80ms;
}

.tab-content .row > div.col-xl-4:nth-child(9) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 90ms;
  animation: fadeIncustom 0.5s linear 90ms;
}

.tab-content .row > div.col-xl-4:nth-child(10) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 100ms;
  animation: fadeIncustom 0.5s linear 100ms;
}

.tab-content .row > div.col-xl-4:nth-child(11) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 110ms;
  animation: fadeIncustom 0.5s linear 110ms;
}

.tab-content .row > div.col-xl-4:nth-child(12) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 120ms;
  animation: fadeIncustom 0.5s linear 120ms;
}

.tab-content .row > div.col-xl-4:nth-child(13) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 130ms;
  animation: fadeIncustom 0.5s linear 130ms;
}

.tab-content .row > div.col-xl-4:nth-child(14) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 140ms;
  animation: fadeIncustom 0.5s linear 140ms;
}

.tab-content .row > div.col-xl-4:nth-child(15) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 150ms;
  animation: fadeIncustom 0.5s linear 150ms;
}

.project-cards .tab-content {
  margin-bottom: -30px;
}

.project-box {
  border: 1px solid #e6edef;
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;
  position: relative;
}

.project-box h6 {
  font-weight: 600;
}

.project-box p {
  color: #999;
}

.project-box .badge {
  position: absolute;
  right: 15px;
  top: 15px;
}

.project-box .project-status p {
  margin-bottom: 5px;
  font-weight: 800;
}

.project-box .media {
  margin-bottom: 15px;
}

.project-box .details {
  margin-bottom: 10px;
}

.projectdetails .card .card-body {
  padding: 20px;
}

.projectdetails .card .card-footer {
  padding: 20px;
}

.projectdetails .card select {
  width: 90px;
  height: 28px;
  font-size: 11px;
  right: 20px;
  top: 20px;
}

.projectmore .details .col-4 {
  margin-bottom: 5px;
}

.projectmore .details .col-4:nth-child(even) {
  opacity: 0.5;
}

.projectmore h5 {
  font-size: 20px;
  font-weight: 600;
}

.projectmore .task-list {
  position: relative;
}

.projectmore .task-list ul li {
  margin-bottom: 5px;
}

.projectmore .task-list ul + ul {
  position: absolute;
  left: 50%;
  top: 0;
}

.new-users.activity .gradient-round {
  padding: 13px;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  top: unset !important;
}

.new-users.activity .gradient-round::after {
  display: none;
}

@media (max-width: 767px) {
  .project-list .btn {
    float: left;
    margin-top: 5px;
  }

  .project-box {
    padding: 20px;
  }
}

@media (max-width: 420px) {
  .project-box {
    padding: 15px;
  }

  .project-box .badge {
    font-size: 8px;
    top: 10px;
    right: 10px;
  }

  .project-list .border-tab.nav-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .project-list .border-tab.nav-tabs .nav-item {
    width: auto !important;
  }

  .project-list .border-tab.nav-tabs .nav-item .nav-link {
    padding: 5px 15px 5px 0;
    font-size: 14px;
  }
}

/**==========================
   91. Project list css end
=============================**/

/**=====================
    File Manager
==========================**/

.file-sidebar .card .card-body,
.file-sidebar .card .card-header,
.file-content .card .card-body,
.file-content .card .card-header {
  padding: 20px !important;
}

.file-sidebar .card .file-manager,
.file-content .card .file-manager {
  padding-top: unset !important;
}

.file-sidebar ul li + li {
  margin-top: 8px;
}

.file-sidebar .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.file-sidebar .btn.btn-light:hover {
  color: #78266a !important;
}

.file-sidebar .btn.btn-light:hover svg {
  stroke: #78266a;
}

.file-sidebar .btn svg {
  width: 15px;
  vertical-align: middle;
  margin-right: 8px;
}

.file-sidebar .pricing-plan {
  border: 1px solid #e6edef;
  border-radius: 5px;
  margin-top: 10px;
  padding: 15px;
  position: relative;
  overflow: hidden;
}

.file-sidebar .pricing-plan h6 {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  color: #898989;
}

.file-sidebar .pricing-plan h5 {
  font-weight: 600;
}

.file-sidebar .pricing-plan p {
  margin-bottom: 10px;
  color: #999;
}

.file-sidebar .pricing-plan .btn {
  display: inline-block;
}

.file-sidebar .pricing-plan .bg-img {
  position: absolute;
  top: 40px;
  opacity: 0.1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: -40px;
}

.file-sidebar h6 {
  font-size: 14px;
}

.file-manager h5 {
  font-size: 18px;
  font-weight: 600;
}

.file-manager > h6 {
  opacity: 0.6;
  font-weight: 400 !important;
  font-size: 15px;
  margin-bottom: 20px;
  color: #999;
}

.file-manager .files .file-box:nth-child(1) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 10ms;
  animation: fadeIncustom 0.5s linear 10ms;
}

.file-manager .files .file-box:nth-child(2) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 20ms;
  animation: fadeIncustom 0.5s linear 20ms;
}

.file-manager .files .file-box:nth-child(3) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 30ms;
  animation: fadeIncustom 0.5s linear 30ms;
}

.file-manager .files .file-box:nth-child(4) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 40ms;
  animation: fadeIncustom 0.5s linear 40ms;
}

.file-manager .files .file-box:nth-child(5) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 50ms;
  animation: fadeIncustom 0.5s linear 50ms;
}

.file-manager .files .file-box:nth-child(6) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 60ms;
  animation: fadeIncustom 0.5s linear 60ms;
}

.file-manager .files .file-box:nth-child(7) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 70ms;
  animation: fadeIncustom 0.5s linear 70ms;
}

.file-manager .files .file-box:nth-child(8) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 80ms;
  animation: fadeIncustom 0.5s linear 80ms;
}

.file-manager .files .file-box:nth-child(9) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 90ms;
  animation: fadeIncustom 0.5s linear 90ms;
}

.file-manager .files .file-box:nth-child(10) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 100ms;
  animation: fadeIncustom 0.5s linear 100ms;
}

.file-manager .files .file-box:nth-child(11) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 110ms;
  animation: fadeIncustom 0.5s linear 110ms;
}

.file-manager .files .file-box:nth-child(12) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 120ms;
  animation: fadeIncustom 0.5s linear 120ms;
}

.file-manager .files .file-box:nth-child(13) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 130ms;
  animation: fadeIncustom 0.5s linear 130ms;
}

.file-manager .files .file-box:nth-child(14) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 140ms;
  animation: fadeIncustom 0.5s linear 140ms;
}

.file-manager .files .file-box:nth-child(15) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 150ms;
  animation: fadeIncustom 0.5s linear 150ms;
}

.file-manager .files h6 {
  margin-top: 10px;
  margin-bottom: 0;
  text-transform: capitalize;
}

.file-manager p {
  opacity: 0.9;
  font-size: 12px;
  color: #999;
}

.files h6,
.folder h6 {
  font-size: 14px;
}

.file-content .ellips {
  position: absolute;
  top: 30px;
  right: 30px;
  opacity: 0.7;
}

.file-content .form-inline {
  border: 1px solid #e6edef;
  border-radius: 5px;
  padding: 0 20px;
}

.file-content .form-inline i {
  padding-right: 10px;
  color: #898989;
  line-height: 3;
}

.file-content .form-inline input::-webkit-input-placeholder {
  color: #898989;
}

.file-content .form-inline input:focus {
  outline: none !important;
}

.file-content .search-form input {
  padding: 5px 10px 5px 70px;
  border-radius: 5px;
}

.file-content .search-form .form-group:before {
  left: 82px;
  top: 37px;
}

.file-content .search-form .form-group:after {
  top: 39px;
  left: 53px;
}

.file-content .btn svg {
  height: 15px;
  margin-right: 2px;
  vertical-align: middle;
}

.file-content h4 {
  font-weight: 600;
}

.file-content .folder .folder-box {
  border: 1px solid #e6edef;
  border-radius: 5px;
  padding: 15px;
  background-color: rgba(36, 105, 92, 0.05);
  width: calc(25% - 15px);
  display: inline-block;
}

.file-content .folder .folder-box:nth-child(1) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 10ms;
  animation: fadeIncustom 0.5s linear 10ms;
}

.file-content .folder .folder-box:nth-child(2) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 20ms;
  animation: fadeIncustom 0.5s linear 20ms;
}

.file-content .folder .folder-box:nth-child(3) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 30ms;
  animation: fadeIncustom 0.5s linear 30ms;
}

.file-content .folder .folder-box:nth-child(4) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 40ms;
  animation: fadeIncustom 0.5s linear 40ms;
}

.file-content .folder .folder-box:nth-child(5) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 50ms;
  animation: fadeIncustom 0.5s linear 50ms;
}

.file-content .folder .folder-box:nth-child(6) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 60ms;
  animation: fadeIncustom 0.5s linear 60ms;
}

.file-content .folder .folder-box:nth-child(7) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 70ms;
  animation: fadeIncustom 0.5s linear 70ms;
}

.file-content .folder .folder-box:nth-child(8) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 80ms;
  animation: fadeIncustom 0.5s linear 80ms;
}

.file-content .folder .folder-box:nth-child(9) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 90ms;
  animation: fadeIncustom 0.5s linear 90ms;
}

.file-content .folder .folder-box:nth-child(10) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 100ms;
  animation: fadeIncustom 0.5s linear 100ms;
}

.file-content .folder .folder-box:nth-child(11) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 110ms;
  animation: fadeIncustom 0.5s linear 110ms;
}

.file-content .folder .folder-box:nth-child(12) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 120ms;
  animation: fadeIncustom 0.5s linear 120ms;
}

.file-content .folder .folder-box:nth-child(13) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 130ms;
  animation: fadeIncustom 0.5s linear 130ms;
}

.file-content .folder .folder-box:nth-child(14) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 140ms;
  animation: fadeIncustom 0.5s linear 140ms;
}

.file-content .folder .folder-box:nth-child(15) {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 150ms;
  animation: fadeIncustom 0.5s linear 150ms;
}

.file-content .files .file-box {
  border: 1px solid #e6edef;
  border-radius: 15px;
  padding: 15px;
  background-color: rgba(36, 105, 92, 0.05);
  width: calc(25% - 15px);
  display: inline-block;
  position: relative;
}

.file-content .files .file-box .file-top {
  height: 100px;
  color: #fff;
  background-image: linear-gradient(180deg, #772569 0%, #0e184a 100%);
  border: 1px solid #e6edef;
  border-radius: 5px;
  font-size: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (max-width: 1440px) {
  .file-content .folder .folder-box .media {
    display: block;
    text-align: center;
  }

  .file-content .folder .folder-box .media .media-body {
    margin-left: 0 !important;
    margin-top: 5px;
  }
}

@media screen and (max-width: 1366px) {
  .file-content .files {
    margin-bottom: -10px;
  }

  .file-content .files .file-box {
    width: calc(33.33% - 15px);
    margin-bottom: 10px;
    margin-right: 8px;
  }

  .file-content .card-header .btn {
    padding: 8px 15px;
  }

  .file-content .folder .folder-box {
    padding: 13px;
    width: calc(50% - 15px);
    margin-bottom: 10px;
  }

  .file-content .folder .folder-box .media i {
    font-size: 30px;
  }

  .file-sidebar .btn {
    padding: 8px 15px;
  }
}

@media screen and (max-width: 768px) {
  .file-content .folder {
    margin-bottom: -10px;
  }

  .file-content .folder .folder-box {
    width: calc(50% - 15px);
    margin-bottom: 10px;
    margin-right: 8px;
  }

  .file-content .media {
    display: block;
    text-align: center;
  }

  .file-content .media .media-body {
    margin-top: 10px;
    text-align: center !important;
  }
}

@media screen and (max-width: 420px) {
  .file-content .folder .folder-box,
  .file-content .files .file-box {
    width: calc(100%);
    margin-right: unset;
  }

  .file-content h4 {
    font-size: 20px;
  }

  .file-content .card-header .btn {
    padding: 7px 10px;
    font-size: 12px;
  }

  .file-manager > h6 {
    font-size: 14px;
  }
}

/**=====================
     File Manager
==========================**/

/**=====================
    53. Dashboard CSS Start
==========================**/

.trasaction-sec.card .card-body {
  margin-bottom: -35px;
}

.trasaction-sec .transaction-totalbal {
  padding-left: 30px;
}

.trasaction-sec .transaction-totalbal h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
}

.trasaction-sec #chart-3dash {
  margin-left: -15px;
  margin-right: -15px;
}

.helper-classes {
  padding: 30px;
  margin-bottom: 0;
  white-space: pre-line;
}

.box-shadow-title .sub-title {
  margin: 30px 0;
}

.list-group-item {
  background-color: #f5f7fb;
  border-color: #e6edef;
}

.list-group-item.active {
  background-color: #78266a;
}

.list-group-item.active:hover {
  background-color: #78266a;
  color: #fff;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  background-color: rgba(36, 105, 92, 0.1);
  color: #78266a;
}

.list-group-item-action.active {
  background-color: #78266a;
  color: #fff;
}

.list-group-item-action.active:hover {
  background-color: #78266a;
  color: #fff;
}

.general-widget .custom-card .card-header img {
  margin-top: -95px;
}

.dashboard-default-sec .card .card-header i,
.dashboard-2-main .card .card-header i {
  font-size: 14px;
}

.dashboard-default-sec .card .card-header p,
.dashboard-2-main .card .card-header p {
  font-weight: 500;
  color: #999;
  margin-bottom: unset;
  font-size: 12px;
}

.dashboard-default-sec .card .card-header h5,
.dashboard-2-main .card .card-header h5 {
  font-size: 18px;
  font-weight: 700;
}

.dashboard-default-sec .card .card-header ul.week-date li,
.dashboard-2-main .card .card-header ul.week-date li {
  display: inline-block;
  font-size: 12px;
  color: #717171;
  font-weight: 500;
}

.dashboard-default-sec .card .card-header ul.week-date li:nth-child(n + 2),
.dashboard-2-main .card .card-header ul.week-date li:nth-child(n + 2) {
  margin-left: 10px;
  padding-left: 15px;
  border-left: 1px solid #e6edef;
}

.dashboard-default-sec .card.income-card,
.dashboard-2-main .card.income-card {
  overflow: hidden;
}

.dashboard-default-sec .card.income-card .card-header,
.dashboard-2-main .card.income-card .card-header {
  padding-bottom: 0;
}

.dashboard-default-sec .card.income-card .card-body .round-box,
.dashboard-2-main .card.income-card .card-body .round-box {
  width: 55px;
  height: 55px;
  border-radius: 27px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.dashboard-default-sec .card.income-card .card-body .round-box svg,
.dashboard-2-main .card.income-card .card-body .round-box svg {
  width: auto;
  height: 25px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.dashboard-default-sec .card.income-card .card-body .knob-block,
.dashboard-2-main .card.income-card .card-body .knob-block {
  position: relative;
}

.dashboard-default-sec .card.income-card .card-body .knob-block input,
.dashboard-2-main .card.income-card .card-body .knob-block input {
  color: #2b2b2b !important;
}

.dashboard-default-sec .card.income-card .card-body .knob-block canvas,
.dashboard-2-main .card.income-card .card-body .knob-block canvas {
  height: 56%;
  width: 56%;
  margin-bottom: 12px;
}

.dashboard-default-sec
  .card.income-card
  .card-body
  .round-progress
  .progress-circle,
.dashboard-2-main
  .card.income-card
  .card-body
  .round-progress
  .progress-circle {
  position: relative;
}

.dashboard-default-sec .card.income-card .card-body .parrten,
.dashboard-2-main .card.income-card .card-body .parrten {
  position: absolute;
  top: -25px;
  right: -25px;
}

.dashboard-default-sec .card.income-card .card-body .parrten svg,
.dashboard-2-main .card.income-card .card-body .parrten svg {
  fill: rgba(36, 105, 92, 0.03);
  width: 100px;
  height: 100px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.dashboard-default-sec .card.income-card.card-primary .round-box,
.dashboard-2-main .card.income-card.card-primary .round-box {
  background-color: rgba(36, 105, 92, 0.1);
}

.dashboard-default-sec .card.income-card.card-primary .round-box svg,
.dashboard-2-main .card.income-card.card-primary .round-box svg {
  fill: #78266a;
}

.dashboard-default-sec .card.income-card.card-primary:hover .round-box,
.dashboard-2-main .card.income-card.card-primary:hover .round-box {
  background-color: #78266a;
}

.dashboard-default-sec .card.income-card.card-primary:hover .round-box svg,
.dashboard-2-main .card.income-card.card-primary:hover .round-box svg {
  fill: #fff;
}

.dashboard-default-sec .card.income-card.card-secondary,
.dashboard-2-main .card.income-card.card-secondary {
  border-color: #ede7e4;
}

.dashboard-default-sec .card.income-card.card-secondary .round-box,
.dashboard-2-main .card.income-card.card-secondary .round-box {
  background-color: rgba(186, 137, 93, 0.1);
}

.dashboard-default-sec .card.income-card.card-secondary .round-box svg,
.dashboard-2-main .card.income-card.card-secondary .round-box svg {
  fill: #ba895d;
}

.dashboard-default-sec .card.income-card.card-secondary:hover .round-box,
.dashboard-2-main .card.income-card.card-secondary:hover .round-box {
  background-color: #ba895d;
}

.dashboard-default-sec .card.income-card.card-secondary:hover .round-box svg,
.dashboard-2-main .card.income-card.card-secondary:hover .round-box svg {
  fill: #fff;
}

.dashboard-default-sec .card.income-card .card-body h3,
.dashboard-2-main .card.income-card .card-body h3 {
  font-weight: 700;
}

.dashboard-default-sec .card.income-card .card-body h5,
.dashboard-2-main .card.income-card .card-body h5 {
  font-size: 22px;
  font-weight: 700;
}

.dashboard-default-sec .card.income-card .card-body p,
.dashboard-2-main .card.income-card .card-body p {
  font-size: 12px;
  color: #999;
  font-weight: 500;
  text-transform: capitalize;
}

.dashboard-default-sec .card.income-card .card-body p:last-child,
.dashboard-2-main .card.income-card .card-body p:last-child {
  font-weight: 700;
}

.center-content {
  margin: auto;
  padding-right: 50px;
}

.dashboard-default-sec #chart-dashbord .apexcharts-legend.position-right,
.dashboard-default-sec #chart-dashbord .apexcharts-legend.position-left {
  left: 0 !important;
  right: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: 52%;
}

.position-unset {
  position: unset;
}

.latest-update-sec .media .media-body,
.recent-order-sec .media .media-body {
  margin-left: 20px;
}

.latest-update-sec table tbody h6,
.recent-order-sec table tbody h6 {
  font-size: 14px;
  font-weight: 600;
}

.latest-update-sec table tbody tr td a,
.recent-order-sec table tbody tr td a {
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 35px;
  font-weight: 500;
}

.latest-update-sec table tbody tr td a .btn,
.recent-order-sec table tbody tr td a .btn {
  font-size: 12px;
}

.latest-update-sec table tbody tr td a img,
.recent-order-sec table tbody tr td a img {
  width: 30px !important;
  margin-right: 1rem !important;
}

.latest-update-sec h3,
.recent-order-sec h3 {
  font-size: 24px;
  line-height: 25px;
  font-weight: 700;
  text-align: center;
}

.latest-update-sec span,
.recent-order-sec span {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}

.latest-update-sec .btn,
.recent-order-sec .btn {
  font-size: 12px;
  line-height: 25px;
  color: #78266a;
  font-weight: 700;
  text-align: center;
}

.latest-update-sec h5,
.recent-order-sec h5 {
  font-size: 18px;
  font-weight: 700;
}

.latest-update-sec h4,
.recent-order-sec h4 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
}

.latest-update-sec p,
.recent-order-sec p {
  font-size: 12px;
  font-weight: 500;
  color: #717171;
  margin-bottom: 0;
}

.latest-update-sec .recent-order-sec h5,
.recent-order-sec .recent-order-sec h5 {
  margin-bottom: 0;
}

.latest-update-sec .recent-order-sec table th,
.recent-order-sec .recent-order-sec table th {
  font-size: 14px;
  line-height: 36px;
  font-weight: 700;
}

.latest-update-sec .recent-order-sec table tr td p,
.recent-order-sec .recent-order-sec table tr td p {
  font-size: 13px;
  line-height: 18px;
  color: #717171;
  font-family: "Roboto";
  font-weight: 400;
}

.latest-update-sec .recent-order-sec table th,
.recent-order-sec .recent-order-sec table th {
  font-size: 14px;
  line-height: 36px;
  font-weight: 700;
}

.latest-update-sec .recent-order-sec table tr td p,
.recent-order-sec .recent-order-sec table tr td p {
  font-size: 13px;
  line-height: 18px;
  color: #717171;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.latest-update-sec .rate-sec .card .card-header,
.recent-order-sec .rate-sec .card .card-header {
  padding: 10px;
}

.latest-update-sec .rate-sec .card .card-header .header-top,
.recent-order-sec .rate-sec .card .card-header .header-top {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.latest-update-sec .rate-sec .card .card-body,
.recent-order-sec .rate-sec .card .card-body {
  padding: 15px;
}

.latest-update-sec .recent-order-sec .card .card-body,
.recent-order-sec .recent-order-sec .card .card-body {
  padding: 15px;
}

.latest-update-sec .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.latest-update-sec .media img,
.latest-update-sec .media svg {
  width: auto;
  height: 20px;
}

.latest-update-sec .card-body {
  padding-top: 0;
}

.latest-update-sec table tbody tr {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.latest-update-sec table tbody tr:hover {
  background-color: rgba(186, 137, 93, 0.1);
}

.latest-update-sec table tbody tr:hover td a {
  color: #ba895d;
}

.latest-update-sec table tbody tr:hover td .media svg {
  fill: #ba895d;
}

.latest-update-sec table tbody tr:hover td .media .media-body span {
  color: #ba895d;
}

.latest-update-sec table tbody tr td a {
  color: #2b2b2b;
}

.recent-order-sec .table th {
  border-bottom: none;
}

.recent-order-sec .table td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.recent-order-sec .table tr .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.recent-order-sec .table tr:hover {
  background-color: rgba(36, 105, 92, 0.1);
}

.recent-order-sec .table tr:hover td {
  color: #78266a;
}

.recent-order-sec .table tr:hover p {
  color: #78266a;
}

#chart-dashbord .apexcharts-legend-series {
  text-align: right;
}

#chart-dashbord .apexcharts-legend-series .apexcharts-legend-marker {
  display: none;
  text-align: right;
  font-family: "Roboto", sans-serif;
}

#yearly-overview-chart {
  max-width: 650px;
  margin: 35px auto;
}

.profile-greeting {
  background-image: url(../images/dashboard/bg.png);
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  border-radius: 15px;
  min-height: 95%;
}

.chart_data_left .card-body .chart-main .media .hospital-small-chart,
.chart_data_right .card-body .chart-main .media .hospital-small-chart {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(36, 105, 92, 0.05);
}

.chart_data_left .card-body .chart-main .media .hospital-small-chart .small-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar {
  position: relative;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container {
  height: 74px;
  width: 75px;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip {
  position: absolute;
  opacity: 0;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip
  .chartist-tooltip-value {
  font-size: 10px;
  padding: 5px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip.tooltip-show,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  .chartist-tooltip.tooltip-show {
  opacity: 1;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  svg,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .flot-chart-container
  svg {
  position: absolute;
  bottom: 23px;
  width: 100%;
  height: 95%;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-a
  .ct-slice-donut {
  stroke: #78266a;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  svg
  .ct-series-b
  .ct-slice-donut {
  stroke: rgba(36, 105, 92, 0.2);
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart
  .chartist-tooltip
  .chartist-tooltip-value {
  background-color: #78266a;
  -webkit-box-shadow: 1px 1.732px 8px 0px rgba(36, 105, 92, 0.5);
  box-shadow: 1px 1.732px 8px 0px rgba(36, 105, 92, 0.5);
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  .chartist-tooltip
  .chartist-tooltip-value {
  background-color: #d22d3d;
  -webkit-box-shadow: 1px 1.732px 8px 0px rgba(210, 45, 61, 0.5);
  box-shadow: 1px 1.732px 8px 0px rgba(210, 45, 61, 0.5);
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-a
  .ct-slice-donut {
  stroke: #d22d3d;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart1
  svg
  .ct-series-b
  .ct-slice-donut {
  stroke: rgba(210, 45, 61, 0.2);
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  .chartist-tooltip
  .chartist-tooltip-value {
  background-color: #78266a;
  -webkit-box-shadow: 1px 1.732px 8px 0px rgba(36, 105, 92, 0.5);
  box-shadow: 1px 1.732px 8px 0px rgba(36, 105, 92, 0.5);
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-a
  .ct-slice-donut {
  stroke: #78266a;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart2
  svg
  .ct-series-b
  .ct-slice-donut {
  stroke: rgba(36, 105, 92, 0.2);
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-a
  .ct-slice-donut {
  stroke: #d22d3d;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-point,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-line,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-bar,
.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-slice-donut,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-point,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-line,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-bar,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  svg
  .ct-series-b
  .ct-slice-donut {
  stroke: rgba(210, 45, 61, 0.2);
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  .chartist-tooltip
  .chartist-tooltip-value,
.chart_data_right
  .card-body
  .chart-main
  .media
  .hospital-small-chart
  .small-bar
  .small-chart3
  .chartist-tooltip
  .chartist-tooltip-value {
  background-color: #d22d3d;
  -webkit-box-shadow: 1px 1.732px 8px 0px rgba(210, 45, 61, 0.5);
  box-shadow: 1px 1.732px 8px 0px rgba(210, 45, 61, 0.5);
}

.chart_data_left .card-body .chart-main .media .media-body .right-chart-content,
.chart_data_right
  .card-body
  .chart-main
  .media
  .media-body
  .right-chart-content {
  margin-left: 30px;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .media-body
  .right-chart-content
  h4,
.chart_data_right
  .card-body
  .chart-main
  .media
  .media-body
  .right-chart-content
  h4 {
  line-height: 1;
  color: #2b2b2b !important;
  font-weight: 500;
}

.chart_data_left
  .card-body
  .chart-main
  .media
  .media-body
  .right-chart-content
  span,
.chart_data_right
  .card-body
  .chart-main
  .media
  .media-body
  .right-chart-content
  span {
  color: rgba(43, 43, 43, 0.7);
  font-weight: 500;
}

.chart_data_left .card-body .chart-main .media.border-none,
.chart_data_right .card-body .chart-main .media.border-none {
  border-right: none !important;
}

.activity-timeline .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.activity-timeline .media .activity-line {
  top: 150px;
  height: 57%;
  position: absolute;
  width: 2px;
  background-color: #e6edef;
  margin: 0 auto;
  left: 36px;
  z-index: 1;
}

.activity-timeline .media .circle-dot-primary {
  background-color: rgba(36, 105, 92, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #78266a;
}

.activity-timeline .media .circle-dot-secondary {
  background-color: rgba(186, 137, 93, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #ba895d;
}

.activity-timeline .media .circle-dot-success {
  background-color: rgba(27, 76, 67, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #1b4c43;
}

.activity-timeline .media .circle-dot-danger {
  background-color: rgba(210, 45, 61, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #d22d3d;
}

.activity-timeline .media .circle-dot-info {
  background-color: rgba(113, 113, 113, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #717171;
}

.activity-timeline .media .circle-dot-light {
  background-color: rgba(230, 237, 239, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #e6edef;
}

.activity-timeline .media .circle-dot-dark {
  background-color: rgba(44, 50, 63, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #2c323f;
}

.activity-timeline .media .circle-dot-warning {
  background-color: rgba(226, 198, 54, 0.25);
  border-radius: 100%;
  padding: 4px;
  font-size: 5px;
  color: #e2c636;
}

.activity-timeline .media + .media {
  margin-top: 30px;
}

.activity-timeline .media .media-body {
  margin-left: 20px;
}

.twitter-typeahead {
  display: block !important;
}

span.twitter-typeahead .league-name {
  font-size: 16px;
  padding: 6px 10px 0;
}

span.twitter-typeahead .tt-menu {
  float: left;
  width: 100%;
  min-width: 10rem;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  border: none;
  padding: 0;
}

span.twitter-typeahead .tt-suggestion {
  color: #2b2b2b;
  opacity: 0.6;
  font-size: 13px;
  padding: 6px 12px;
  border-top: 1px solid #efefef;
  background-color: #f5f7fb;
  cursor: pointer;
}

span.twitter-typeahead .tt-suggestion:hover,
span.twitter-typeahead .tt-suggestion:focus {
  background-color: #78266a;
  color: #fff;
  opacity: 1;
}

span.twitter-typeahead .tt-suggestion.active,
span.twitter-typeahead .tt-suggestion:active {
  color: #fff;
  background-color: var(--theme-deafult);
}

#draggableMultiple .ui-sortable-handle .card {
  cursor: move;
}

.crm-activity > li {
  font-weight: 600;
}

.crm-activity > li + li {
  border-top: 1px solid #efefef;
  margin-top: 12px;
  padding-top: 12px;
}

.crm-activity > li + li h6 {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 5px;
}

.crm-activity > li h6 {
  font-size: 14px;
}

.crm-activity span {
  font-size: 40px;
}

.crm-activity ul.dates h6 {
  color: #242934;
}

.crm-activity ul.dates li {
  color: #999;
  font-size: 12px;
  display: inline-block;
  line-height: 1;
}

.crm-activity ul.dates li + li {
  border-left: 1px solid #efefef;
  padding-left: 10px;
  margin-left: 5px;
}

.ecommerce-widget {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e6edef !important;
  border-radius: 5px;
}

.ecommerce-widget h6 {
  color: #717171;
}

.ecommerce-widget .total-num {
  color: #242934;
  margin: 0;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 24px;
}

.ecommerce-widget .total-num span {
  color: #000;
}

.ecommerce-widget .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ecommerce-widget .progress-showcase {
  margin-top: 43px;
}

.ecommerce-widget .progress-showcase .progress {
  height: 20px;
}

.ecommerce-widget span {
  color: #999;
  margin: 0;
  cursor: pointer;
  font-weight: 600;
}

.ecommerce-widget .icon {
  color: #efefef;
  font-size: 40px;
}

.ecommerce-widget .flot-chart-container {
  height: 100px;
  padding: 0;
  margin: 0;
  border: transparent;
}

.ecommerce-widget .morris-default-style {
  display: none !important;
}

.ecommerce-widget svg {
  -webkit-filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3));
}

.ecommerce-widget ul li {
  color: #999;
  font-weight: 600;
}

.static-top-widget:hover .icon-bg {
  -webkit-transform: rotate(-5deg) scale(1.1);
  transform: rotate(-5deg) scale(1.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.static-top-widget div.align-self-center svg {
  width: 30px;
  height: 30px;
}

.static-top-widget .media-body {
  -ms-flex-item-align: center !important;
  align-self: center !important;
  padding-left: 30px;
}

.static-top-widget .media-body .icon-bg {
  position: absolute;
  right: -14px;
  top: 6px;
  opacity: 0.2;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100px;
  height: 100px;
}

.static-top-widget span {
  font-weight: 500;
}

.static-top-widget h4 {
  font-weight: 600;
}

.bg-danger .media.static-top-widget .align-self-center {
  background-color: #d22d3d;
}

.widget-joins:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: #efefef;
  left: calc(50% - 1px);
}

.widget-joins:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #efefef;
  left: 0;
  top: 50%;
}

.widget-joins .media {
  padding: 30px;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.widget-joins .media span {
  font-weight: 500;
}

.widget-joins .media span.widget-t {
  color: #999;
}

.widget-joins .media h5 {
  font-weight: 600;
  font-size: 18px;
}

.widget-joins .media .details {
  border-left: 1px solid #e6edef;
  padding: 1px 0;
}

.widget-joins .media .media-body {
  text-align: left;
}

.widget-joins .media .media-body > span {
  color: #999;
}

.widget-joins .media .media-body svg {
  width: 40px;
  height: 40px;
}

.widget-joins .media .media-body h5 span {
  font-weight: 600;
}

.widget-joins .media .media-body h6 {
  font-weight: 600;
}

.widget-joins .media .media-body h6 span {
  color: #000;
}

.redial-social-widget {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 25px;
  margin: 0 auto;
  background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(50%, #f4f4f4),
      color-stop(50%, transparent),
      to(transparent)
    ),
    -webkit-gradient(linear, left top, right top, color-stop(50%, #e6edef), color-stop(50%, #f4f4f4), to(#f4f4f4));
  background-image: linear-gradient(
      90deg,
      #f4f4f4 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(90deg, #e6edef 50%, #f4f4f4 50%, #f4f4f4);
}

.redial-social-widget i {
  background-color: #fff;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.redial-social-widget.radial-bar-70 {
  background-image: linear-gradient(
      342deg,
      #78266a 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(
      270deg,
      #78266a 50%,
      rgba(36, 105, 92, 0.1) 50%,
      rgba(36, 105, 92, 0.1)
    );
}

.social-widget-card span {
  color: #999;
  text-transform: uppercase;
  margin-bottom: 4px;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
}

.social-widget-card h5 {
  color: #999;
  text-transform: uppercase;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 700;
}

.social-widget-card h4 {
  font-size: 18px;
  font-weight: 600;
}

.browser-widget:hover img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(1.1) rotate(5deg);
  transform: scale(1.1) rotate(5deg);
}

.browser-widget img {
  height: 80px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.browser-widget .media-body {
  text-align: center;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-rule: 1px solid #f4f4f4;
  -moz-column-rule: 1px solid #f4f4f4;
  column-rule: 1px solid #f4f4f4;
}

.browser-widget .media-body p {
  margin-bottom: 5px;
  font-weight: 600;
  color: #999;
}

.browser-widget .media-body span {
  margin-bottom: 0;
  color: rgba(36, 41, 52, 0.7);
}

.browser-widget .media-body h4 {
  color: #242934;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
}

.browser-widget .media-body h4 span {
  color: #333333;
  font-weight: 600;
}

.testimonial
  #owl-carousel-testimonial
  .owl-stage-outer
  .owl-stage
  .owl-item
  .item
  img,
.testimonial
  #owl-carousel-testimonial-rtl
  .owl-stage-outer
  .owl-stage
  .owl-item
  .item
  img {
  border-radius: 100%;
}

.testimonial i {
  font-size: 60px;
  color: rgba(43, 43, 43, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.1;
  z-index: -1;
}

.testimonial p {
  color: #999;
  font-style: italic;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-top: 15px;
}

.testimonial h5 {
  color: #242934;
  padding-top: 10px;
  margin-bottom: 0;
  font-weight: 600;
}

.testimonial span {
  color: #999;
}

.testimonial img {
  margin: 0 auto;
}

.mobile-clock-widget .sec,
.min,
.hour {
  position: absolute;
  width: 24px;
  height: 234px;
  top: 0;
  left: 102px;
  margin-top: -30px;
  margin-left: -25px;
}

.cal-date-widget .datepicker {
  padding: 20px;
  border-radius: unset;
}

.cal-date-widget .datepicker .datepicker--nav {
  border-bottom: none;
  direction: ltr;
}

.cal-date-widget .datepicker .datepicker--cell-day.-other-month-,
.cal-date-widget .datepicker .datepicker--cell-year.-other-decade- {
  opacity: 0.5;
}

.cal-date-widget span {
  font-weight: 600;
}

.cal-date-widget p {
  font-weight: 500;
}

.cal-date-widget .cal-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.cal-date-widget .cal-info h2 {
  font-size: 100px;
  color: #78266a;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 0.7;
}

.cal-date-widget .cal-info span {
  font-size: 18px;
}

.cal-date-widget .cal-info p {
  color: #999;
  margin-top: 15px;
}

.datepicker--nav-action {
  background-color: #78266a;
}

.datepicker--nav-action:hover {
  background-color: #78266a;
}

.datepicker--nav-title {
  color: #78266a;
}

.datepicker--nav-title i {
  color: #78266a;
}

.datepicker--cell.-selected-.-current- {
  background-color: #78266a;
  color: #fff;
}

.weather-widget-two {
  position: relative;
  background-color: #78266a;
  height: 350px;
  overflow: hidden;
}

.weather-widget-two p {
  color: #fff;
}

.weather-widget-two .card-body .media {
  margin-bottom: 10px;
}

.weather-widget-two svg path.climacon_component-stroke {
  fill: #fff;
}

.weather-widget-two .top-bg-whether {
  position: absolute;
  top: -50px;
  right: -28px;
  opacity: 0.1;
}

.weather-widget-two .top-bg-whether svg {
  width: 200px;
  height: 200px;
}

.weather-widget-two .num {
  font-weight: 600;
}

.weather-widget-two .climacon_component-stroke {
  stroke: #fff;
}

.weather-widget-two .bottom-whetherinfo svg {
  width: 150px;
  height: 150px;
  opacity: 0.1;
  color: #fff;
  position: relative;
  top: 0;
  left: -35px;
}

.weather-widget-two .bottom-whetherinfo .whether-content {
  text-align: right;
  position: relative;
  top: -15px;
  color: #fff;
}

.mobile-clock-widget {
  /* background: url(../images/other-images/mobile-clock-wallpaper.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  color: #fff;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-blend-mode: overlay;
  background-color: #17433b;
  position: relative;
}

.mobile-clock-widget .bg-svg {
  position: absolute;
  top: -4px;
  left: -25px;
}

.mobile-clock-widget .bg-svg svg {
  width: 150px;
  height: 150px;
  opacity: 0.08;
}

.mobile-clock-widget .clock {
  padding: 0;
  position: relative;
  list-style: none;
  margin: 0 auto;
  height: 175px;
  width: 175px;
  display: block;
  /* background: url(../images/other-images/clock-face.png) 5% center no-repeat; */
}

.mobile-clock-widget .sec {
  /* background: url(../images/sec.svg);
	z-index: 3; */
}

.mobile-clock-widget .min {
  /* background: url(../images/min.svg);
	z-index: 2; */
}

.mobile-clock-widget .hour {
  /* background: url(../images/hour.svg);
	z-index: 1; */
}

.mobile-clock-widget #date {
  margin-top: 30px;
  font-weight: 700;
}

.mobile-clock-widget p {
  font-weight: 600;
}

.flot-chart-container {
  height: 250px;
}

.calender-widget .cal-img {
  /* background: url(../images/other-images/calender-bg.png); */
  background-size: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 270px;
}

.calender-widget .cal-date {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  margin-top: -45px;
  border: 1px solid #e6edef;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.calender-widget .cal-date h5 {
  margin-bottom: 0;
  line-height: 1.5;
  padding: 17px;
  font-weight: 700;
  font-size: 18px;
}

.calender-widget .cal-desc h6 {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.calender-widget .cal-desc p {
  color: #999;
  font-size: 15px;
}

.calender-widget .cal-desc span {
  line-height: 1.6;
}

.contact-form .theme-form {
  border: 1px solid #e6edef;
  padding: 30px;
  border-radius: 4px;
}

.contact-form .theme-form .form-icon {
  width: 53px;
  height: 53px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #e6edef;
  border-radius: 100%;
  font-size: 28px;
  background-color: #fff;
  color: #78266a;
  margin-top: -55px;
  margin-bottom: 15px;
}

.contact-form .theme-form label {
  font-weight: 600;
}

.contact-form .btn {
  padding: 10px 30px;
}

.chart-widget-top #chart-widget1,
.chart-widget-top #chart-widget2,
.chart-widget-top #chart-widget3 {
  margin-bottom: -14px;
}

.chart-widget-top #chart-widget1 .apexcharts-xaxistooltip,
.chart-widget-top #chart-widget2 .apexcharts-xaxistooltip,
.chart-widget-top #chart-widget3 .apexcharts-xaxistooltip {
  display: none;
}

.bar-chart-widget .top-content {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.bar-chart-widget .earning-details {
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  letter-spacing: 1px;
}

.bar-chart-widget .earning-details i {
  font-size: 230px;
  position: absolute;
  opacity: 0.1;
  right: -30px;
  top: 0;
}

.bar-chart-widget .earning-details i:hover {
  -webkit-transform: rotate(-5deg) scale(1.1);
  transform: rotate(-5deg) scale(1.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.bar-chart-widget .num {
  font-weight: 600;
}

.bar-chart-widget .num .color-bottom {
  color: #000;
}

.bottom-content span {
  color: rgba(36, 41, 52, 0.7);
}

.bottom-content .block-bottom {
  display: block;
}

.user-status table tbody tr td {
  vertical-align: middle;
  font-weight: 500;
}

.user-status table tbody tr td .d-inline-block {
  margin-top: 11px;
}

.user-status table tbody tr td .image-sm-size img {
  width: 41px;
}

.user-status table tbody tr:last-child td {
  padding-bottom: 0;
}

.user-status table thead tr th {
  border-top: 0;
  font-size: 16px;
  color: #2c323f;
  font-weight: 600;
  padding-top: 0;
}

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -30px;
  }
}

@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -30px;
  }
}

@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0;
  }

  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}

@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0;
  }

  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}

@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.serial-chart .chart-container {
  width: 100%;
  height: 500px;
}

.serial-chart .chart-container .lastBullet {
  -webkit-animation: am-pulsating 1s ease-out infinite;
  animation: am-pulsating 1s ease-out infinite;
}

.serial-chart .chart-container .amcharts-graph-column-front {
  -webkit-transition: all 0.3s 0.3s ease-out;
  transition: all 0.3s 0.3s ease-out;
}

.serial-chart .chart-container .amcharts-graph-column-front:hover {
  fill: var(--theme-secondary);
  stroke: var(--theme-secondary);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.serial-chart .chart-container .amcharts-graph-g3 {
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  -webkit-animation: am-draw 40s;
  animation: am-draw 40s;
}

.serial-chart .chart-container .amcharts-graph-g2 .amcharts-graph-stroke {
  stroke-dasharray: 3px 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}

.speed-chart .chart-container {
  width: 100%;
  height: 530px;
}

.speed-chart .content {
  margin-top: -200px;
  margin-bottom: 20px;
}

.speed-chart .content h4 {
  font-weight: 600;
  padding-top: 2px;
}

.status-widget svg {
  width: 20px;
  height: 20px;
  vertical-align: text-top;
}

.status-widget .card .card-header h5 {
  line-height: 1.38;
}

.status-widget .status-chart {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.small-chart-widget .chart-container {
  height: 311px;
  padding: 0;
  margin: 0;
  border: none;
}

.small-chart-widget .card .card-body {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.donut-chart-widget .chart-container {
  height: 300px;
  border: 0;
  margin: 0;
  padding: 0;
}

.status-details h4 {
  font-weight: 600;
}

.status-details h4 span {
  color: inherit;
}

.status-details span {
  color: rgba(36, 41, 52, 0.7);
}

.status-chart .chart-container {
  height: 200px;
  border: 0;
  padding: 0;
  margin: 0;
}

.map-chart .chart-container {
  width: 100%;
  height: 500px;
}

.serial-chart {
  width: 100%;
}

.live-products,
.turnover,
.uses,
.monthly {
  height: 300px;
}

.live-products .ct-series-a .ct-area,
.live-products .ct-series-a .ct-point,
.live-products .ct-series-a .ct-line,
.live-products .ct-series-a .ct-bar,
.live-products .ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.77);
}

.live-products .ct-label {
  fill: #fff;
  color: #fff;
}

.live-products .ct-grid {
  stroke: rgba(255, 255, 255, 0.7);
}

.turnover .ct-series-a .ct-area,
.turnover .ct-series-a .ct-point,
.turnover .ct-series-a .ct-line,
.turnover .ct-series-a .ct-bar,
.turnover .ct-series-a .ct-slice-donut,
.uses .ct-series-a .ct-area,
.uses .ct-series-a .ct-point,
.uses .ct-series-a .ct-line,
.uses .ct-series-a .ct-bar,
.uses .ct-series-a .ct-slice-donut,
.monthly .ct-series-a .ct-area,
.monthly .ct-series-a .ct-point,
.monthly .ct-series-a .ct-line,
.monthly .ct-series-a .ct-bar,
.monthly .ct-series-a .ct-slice-donut {
  stroke: white;
  fill: transparent;
}

.turnover .ct-series-b .ct-area,
.turnover .ct-series-b .ct-point,
.turnover .ct-series-b .ct-line,
.turnover .ct-series-b .ct-bar,
.turnover .ct-series-b .ct-slice-donut,
.uses .ct-series-b .ct-area,
.uses .ct-series-b .ct-point,
.uses .ct-series-b .ct-line,
.uses .ct-series-b .ct-bar,
.uses .ct-series-b .ct-slice-donut,
.monthly .ct-series-b .ct-area,
.monthly .ct-series-b .ct-point,
.monthly .ct-series-b .ct-line,
.monthly .ct-series-b .ct-bar,
.monthly .ct-series-b .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.7);
  fill: transparent;
}

.turnover .ct-series-c .ct-area,
.turnover .ct-series-c .ct-point,
.turnover .ct-series-c .ct-line,
.turnover .ct-series-c .ct-bar,
.turnover .ct-series-c .ct-slice-donut,
.uses .ct-series-c .ct-area,
.uses .ct-series-c .ct-point,
.uses .ct-series-c .ct-line,
.uses .ct-series-c .ct-bar,
.uses .ct-series-c .ct-slice-donut,
.monthly .ct-series-c .ct-area,
.monthly .ct-series-c .ct-point,
.monthly .ct-series-c .ct-line,
.monthly .ct-series-c .ct-bar,
.monthly .ct-series-c .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.4);
  fill: transparent;
}

.turnover .ct-label,
.uses .ct-label,
.monthly .ct-label {
  fill: #fff;
  color: #fff;
}

.turnover .ct-grid,
.uses .ct-grid,
.monthly .ct-grid {
  stroke: rgba(255, 255, 255, 0.7);
}

.activity-dot-primary {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #78266a;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(36, 105, 92, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(36, 105, 92, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-primary:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.activity-dot-secondary {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #ba895d;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(186, 137, 93, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(186, 137, 93, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-secondary:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.activity-dot-success {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #1b4c43;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(27, 76, 67, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(27, 76, 67, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-success:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.activity-dot-danger {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #d22d3d;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(210, 45, 61, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(210, 45, 61, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-danger:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.activity-dot-info {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #717171;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(113, 113, 113, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(113, 113, 113, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-info:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.activity-dot-light {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #e6edef;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(230, 237, 239, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(230, 237, 239, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-light:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.activity-dot-dark {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #2c323f;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(44, 50, 63, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(44, 50, 63, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-dark:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.activity-dot-warning {
  width: 16px;
  height: 16px;
  background-color: #f5f7fb;
  border-radius: 100%;
  border: 3px solid #e2c636;
  -webkit-box-shadow: -2.5px -6.67px 15px 0px rgba(226, 198, 54, 0.25);
  box-shadow: -2.5px -6.67px 15px 0px rgba(226, 198, 54, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-warning:hover {
  background-color: rgba(36, 105, 92, 0.5);
}

.chart-vertical-center #myDoughnutGraph,
.chart-vertical-center #myPolarGraph {
  width: auto !important;
}

@media only screen and (max-width: 1800px) {
  .latest-update-sec table tbody tr td:first-child {
    min-width: 320px;
  }

  .recent-order-sec .table td {
    min-width: 110px;
  }

  .recent-order-sec .table td:first-child {
    min-width: 320px;
  }

  .user-status table thead tr th:nth-child(3) {
    min-width: 150px;
  }

  .user-status table tbody tr td:first-child {
    min-width: 300px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1680px) {
  .dashboard-default-sec .card-header .header-top,
  .dashboard-2-main .card-header .header-top {
    display: block !important;
  }

  .dashboard-default-sec .card-header .header-top .center-content,
  .dashboard-2-main .card-header .header-top .center-content {
    margin-top: 5px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1365px) {
  .des-xl-25 {
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }

  .des-xl-33 {
    max-width: 33.33333%;
    -webkit-box-flex: 33.33333%;
    -ms-flex: 33.33333%;
    flex: 33.33333%;
  }

  .des-xl-50 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .des-xl-100 {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .dashboard-default-sec .card .card-header,
  .dashboard-default-sec .card .card-body,
  .dashboard-default-sec .card .card-footer {
    padding: 20px;
  }

  .dashboard-default-sec .card .card-header h5 {
    font-size: 16px;
  }

  .dashboard-default-sec .card .card-header p {
    font-size: 10px;
  }

  .dashboard-default-sec .card .card-header i {
    font-size: 12px;
  }

  .dashboard-default-sec .card.income-card .card-body .round-box {
    width: 45px;
    height: 45px;
  }

  .dashboard-default-sec .card.income-card .card-body .round-box svg {
    height: 16px;
  }

  .dashboard-default-sec .card.income-card .card-body h5 {
    font-size: 18px;
  }

  .dashboard-default-sec .setting-list {
    top: 15px;
    right: 15px;
  }

  .dashboard-default-sec .setting-list .setting-option {
    width: 25px;
    height: 25px;
  }

  .dashboard-default-sec
    .setting-list
    .setting-option
    li:first-child
    i.icon-angle-double-right {
    font-size: 12px;
  }

  .dashboard-default-sec .setting-list .setting-primary,
  .dashboard-default-sec .setting-list .setting-secondary,
  .dashboard-default-sec .setting-list .setting-white {
    width: 25px;
    height: 25px;
  }

  .dashboard-default-sec .profile-greeting h3 {
    font-size: 22px;
  }

  .dashboard-default-sec .profile-greeting p {
    line-height: 20px;
  }

  .dashboard-default-sec .profile-greeting .card-header {
    padding-bottom: 5px !important;
  }

  #chart-dashbord .apexcharts-legend.position-right,
  #chart-dashbord .apexcharts-legend.position-left {
    display: none !important;
  }

  .trasaction-sec .transaction-totalbal {
    padding-left: 20px;
  }

  .trasaction-sec .transaction-totalbal h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1470px) {
  .bar-chart-widget .num {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .apexcharts-legend.position-right {
    left: 20px !important;
  }

  .dashboard-default-sec .card .card-header,
  .dashboard-default-sec .card .card-body,
  .dashboard-default-sec .card .card-footer {
    padding: 20px;
  }

  .profile-greeting h3 {
    font-size: 22px;
    margin-top: 5px;
  }
}

/**=====================
  53. Dashboard CSS Ends
==========================**/

/**=====================
      67. Dashboard 2 CSS Start
==========================**/

.dashboard-2-main .card .card-header span {
  font-size: 14px !important;
  color: #78266a;
  font-weight: 600 !important;
  display: inline-block;
}

.dashboard-2-main .card .card-body .media img {
  margin-right: 5px;
}

.yearly-growth-sec #myChart {
  height: 350px !important;
}

.top-dealer-sec .card {
  margin-bottom: 20px;
}

.top-dealer-sec .card .card-img-top {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 15px;
}

.top-dealer-sec .card .top-dealerbox {
  padding: 20px;
}

.top-dealer-sec .card .top-dealerbox h6 {
  font-size: 13px;
  font-weight: 600;
}

.top-dealer-sec .card .top-dealerbox p {
  font-size: 11px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 12px;
  color: #999;
}

.top-dealer-sec .card .top-dealerbox .btn {
  padding: 8px 10px;
  background-color: rgba(36, 105, 92, 0.1);
  color: #78266a;
  font-size: 11px;
  font-weight: 400;
  border-radius: 15px;
  font-family: "Roboto", sans-serif;
  line-height: 1;
}

.top-dealer-sec .card .top-dealerbox:hover {
  background-color: #fff;
}

.top-dealer-sec .card .top-dealerbox:hover .btn {
  background-color: #78266a;
  color: #fff;
}

.top-dealer-sec .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0;
}

.top-sell-sec .card-body .fa-shopping-bag {
  background-color: #78266a;
  padding: 8px;
  border-radius: 2px;
  color: #fff;
}

.top-sell-sec .card-body .media {
  background-color: rgba(36, 105, 92, 0.05);
  padding: 25px 30px;
  border-radius: 5px;
}

.top-sell-sec .card-body .media img {
  -webkit-transition: all 3s ease;
  transition: all 3s ease;
}

.top-sell-sec .card-body .media .btn:hover i:before {
  -webkit-animation: bounceIn 0.5s ease-in-out;
  animation: bounceIn 0.5s ease-in-out;
}

.top-sell-sec .card-body .media .media-body {
  margin-left: 20px;
}

.top-sell-sec .card-body .media .media-body h6 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

.top-sell-sec .card-body .media .media-body p {
  margin-bottom: 5px;
}

.top-sell-sec .card-body .media:hover img {
  -webkit-transform: rotate(-26deg);
  transform: rotate(-26deg);
}

.top-sell-sec .media .media-body .fa-star {
  color: #f5c304;
}

.target-sec {
  background-color: #fff;
  border: none;
}

.target-sec .card-header ul.target-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.target-sec .card-header ul.target-list li {
  border-radius: 10px;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 0px;
  padding-right: 0px;
  width: 50%;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.target-sec .card-header ul.target-list li:hover h6 {
  color: #fff;
}

.target-sec .card-header ul.target-list li:hover p {
  color: #fff;
}

.target-sec .card-header ul.target-list li:hover span {
  color: #fff;
}

.target-sec .card-header ul.target-list li:first-child {
  background-color: rgba(36, 105, 92, 0.05);
}

.target-sec .card-header ul.target-list li:first-child:hover {
  background-color: #78266a;
}

.target-sec .card-header ul.target-list li:nth-child(n + 2) {
  background-color: #78266a;
  margin-left: 20px;
}

.target-sec .card-header ul.target-list li:nth-child(n + 2) h6 {
  color: #fff;
}

.target-sec .card-header ul.target-list li:nth-child(n + 2) p {
  color: #fff;
}

.target-sec .card-header ul.target-list li:nth-child(n + 2) span {
  color: #fff;
}

.target-sec .card-header ul.target-list li h6 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.target-sec .card-header ul.target-list li p {
  font-size: 12px;
  margin-bottom: 10px;
  line-height: 1.4;
}

.target-sec .card-header ul.target-list li span {
  color: #78266a;
}

.target-sec .card-body .traget-img-sec {
  width: 100%;
  /* background-image: url("../images/dashboard-2/target1.png"); */
  height: 415px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.target-sec .animat-block i {
  color: #78266a;
  position: absolute;
}

.target-sec .animat-block .close1 {
  bottom: 29%;
  left: 75px;
  position: absolute;
  -webkit-animation: star 5s linear infinite;
  animation: star 5s linear infinite;
}

.target-sec .animat-block .close2 {
  bottom: 31%;
  right: 8%;
  -webkit-animation: star 7s linear infinite;
  animation: star 7s linear infinite;
}

.target-sec .animat-block .close3 {
  bottom: 65%;
  left: 30%;
  opacity: 0.2;
  -webkit-animation: star 10s linear infinite;
  animation: star 10s linear infinite;
}

.target-sec .animat-block > div {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #78266a;
  position: absolute;
}

.target-sec .animat-block .circle1 {
  bottom: 50%;
  left: 5%;
  -webkit-animation: move2 1.2s infinite alternate;
  animation: move2 1.2s infinite alternate;
}

.target-sec .animat-block .circle2 {
  bottom: 45%;
  right: 30%;
  -webkit-animation: move2 1.4s infinite alternate;
  animation: move2 1.4s infinite alternate;
}

.target-sec .animat-block .circle3 {
  bottom: 65%;
  right: 18%;
  opacity: 0.2;
  -webkit-animation: move2 1.6s infinite alternate;
  animation: move2 1.6s infinite alternate;
}

.activity-sec .activity-timeline .media {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: start !important;
}

.activity-sec .activity-timeline .media .activity-line {
  left: 37px;
}

.activity-sec .activity-timeline.update-line .media .activity-line {
  height: 80% !important;
}

.activity-sec .activity-timeline.update-line .media .media-body h6 {
  font-size: 12px;
  margin-bottom: 25px;
}

.activity-sec .activity-timeline.update-line .media .media-body h6 span {
  margin-right: 5px;
  font-weight: 600;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  h6
  .dot-notification {
  float: right;
  width: 15px;
  height: 15px;
  background-color: #78266a;
  border: 5px solid #b2b6b6;
  border-radius: 50%;
  -webkit-animation: round 1.3s ease-in-out infinite;
  animation: round 1.3s ease-in-out infinite;
}

.activity-sec .activity-timeline.update-line .media .media-body h5 {
  font-size: 14px;
  color: #242934;
  font-weight: 600;
  margin-bottom: 5px;
}

.activity-sec .activity-timeline.update-line .media .media-body p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #717171;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  ul.timeline-pro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 15px;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  ul.timeline-pro
  li {
  width: 100px;
  height: 65px;
  border-radius: 5px;
  background-color: rgba(36, 105, 92, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  ul.timeline-pro
  li
  img {
  margin: auto;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  ul.timeline-pro
  li:nth-child(n + 2) {
  margin-left: 20px;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  ul.timeline-pro
  li:hover
  img {
  -webkit-animation: tada 1s ease-out infinite;
  animation: tada 1s ease-out infinite;
}

.activity-sec .activity-timeline.update-line .media .media-body .tomorrow-sec {
  margin: auto;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  position: relative;
  z-index: 1;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  .tomorrow-sec:before {
  content: "";
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #e6edef;
  height: 1px;
  width: 90%;
  z-index: -1;
}

.activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  .tomorrow-sec
  p {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f5f7fb;
}

.activity-sec .media .tomorrow-sec p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.yearly-growth-sec #myChart {
  height: 350px !important;
}

#graph_rounded svg {
  -webkit-filter: drop-shadow(-1px 5px 9px rgba(233, 233, 255, 0.3));
  filter: drop-shadow(-1px 5px 9px rgba(233, 233, 255, 0.3));
}

@media only screen and (max-width: 1800px) {
  .activity-sec .activity-timeline.update-line .media .activity-line {
    left: 27px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .dashboard-2-main .setting-primary,
  .dashboard-2-main .setting-secondary,
  .dashboard-2-main .setting-white {
    width: 25px;
    height: 25px;
  }

  .dashboard-2-main .setting-list .setting-option {
    width: 25px;
    height: 25px;
  }

  .dashboard-2-main .setting-list .setting-option .setting-option {
    width: 25px;
    height: 25px;
  }

  .dashboard-2-main
    .setting-list
    .setting-option
    li:first-child
    i.icon-angle-double-right {
    font-size: 12px;
  }

  .dashboard-2-main .card .card-header,
  .dashboard-2-main .card .card-body,
  .dashboard-2-main .card .card-footer {
    padding: 20px;
  }

  .dashboard-2-main .card .card-header h5 {
    font-size: 16px;
  }

  .dashboard-2-main .card.income-card .card-body h5 {
    font-size: 18px;
  }

  .top-dealer-sec .card .top-dealerbox {
    padding: 15px;
  }

  .top-sell-sec .card-body .media {
    padding: 15px;
  }

  .top-sell-sec .card-body .media .btn-iconsolid {
    padding: 5px 10px;
  }

  .top-sell-sec .card-body .media .btn-iconsolid i {
    font-size: 12px;
  }

  .top-sell-sec .card-body .media .media-body h6 {
    font-size: 15px;
  }

  .top-sell-sec .card-body .media .media-body p {
    font-size: 12px;
  }

  .target-sec .card-header ul.target-list li h6 {
    font-size: 14px;
  }

  .target-sec .card-body .traget-img-sec {
    height: 410px;
  }

  .activity-sec .activity-timeline.update-line .media .media-body h6 {
    margin-bottom: 10px;
  }

  .activity-sec .activity-timeline.update-line .media .media-body p {
    font-size: 12px;
  }

  .activity-sec .activity-timeline.update-line .media .activity-line {
    left: 26px;
    top: 105px;
  }

  .activity-dot-primary {
    width: 14px;
    height: 14px;
    border-width: 2px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1500px) {
  .top-sell-sec .card-body .media .media-body p {
    display: none;
  }

  .activity-sec .activity-timeline.update-line .media .media-body p {
    display: none;
  }

  .target-sec .card-body .traget-img-sec {
    height: 328px;
  }

  .target-sec .card-header ul.target-list li {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .target-sec .card-header ul.target-list li p {
    display: none;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1365px) {
  .dashboard-2-main .des-xl-50 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .dashboard-2-main .des-xl-100 {
    max-width: 100%;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .target-sec .card-body .traget-img-sec {
    margin-top: 20px;
    background-position: top;
    height: 247px;
  }
}

@media only screen and (max-width: 1200px) {
  .traget-img-sec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1199px) {
  .activity-sec .activity-timeline.update-line .media .activity-line {
    left: 37px;
  }

  .target-sec .card-body .traget-img-sec {
    background-position: top;
  }
}

@media only screen and (max-width: 991px) {
  .activity-sec .activity-timeline.update-line .media .activity-line {
    left: 28px;
  }

  .top-dealer-sec .card .top-dealerbox {
    padding: 12px;
  }

  .target-sec .card-body .traget-img-sec {
    height: 360px;
  }
}

@media only screen and (max-width: 767px) {
  .activity-timeline .media .activity-line {
    top: 100px;
  }

  .top-sell-sec .card .card-body .media img {
    margin-right: 25px;
  }
}

@media only screen and (max-width: 991px) {
  .activity-sec .activity-timeline .media .activity-line {
    left: 26px;
  }

  .top-dealer-sec .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: -15px;
  }
}

@media only screen and (max-width: 575px) {
  .update-line .media .activity-line {
    top: 95px !important;
  }

  .top-dealer-sec .card {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .top-dealer-sec .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0;
  }
}

@media only screen and (max-width: 480px) {
  .top-sell-sec .card-body .media {
    display: block;
    position: relative;
    padding: 20px;
  }

  .top-sell-sec .card-body .media .btn {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 5px 10px;
    font-size: 12px;
  }

  .top-sell-sec .card-body .media .media-body {
    margin-left: unset;
    margin-top: 15px;
  }

  .target-sec .card-header ul.target-list {
    display: block;
  }

  .target-sec .card-header ul.target-list li {
    width: auto;
  }

  .target-sec .card-header ul.target-list li:nth-child(n + 2) {
    margin-left: unset;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 359px) {
  .update-line .media .activity-line {
    top: 125px !important;
  }

  .activity-sec .media .activity-dot-primary {
    width: 10px;
    height: 10px;
    border: 1px solid #78266a;
  }

  .activity-sec .activity-timeline .media .activity-line {
    left: 23px;
  }

  .activity-timeline .media .media-body {
    margin-left: 10px;
  }
}

/**=====================
     67. Dashboard 2  CSS End
==========================**/

/**=====================
      68. Dashboard 3 CSS Start
==========================**/

.order-chart-container .history {
  height: 294px;
}

.order-chart-container .history .chartist-tooltip {
  -webkit-box-shadow: -1.5px 1.33px 15px 0px rgba(186, 137, 93, 0.1);
  box-shadow: -1.5px 1.33px 15px 0px rgba(186, 137, 93, 0.1);
}

.order-chart-container .history .chartist-tooltip .chartist-tooltip-value {
  color: #ba895d;
}

.order-chart-container .history svg .ct-grids .ct-horizontal {
  stroke: transparent;
}

.order-chart-container .history svg .ct-grids .ct-horizontal:nth-child(4) {
  stroke: rgba(0, 0, 0, 0.2);
}

.order-chart-container .history svg .ct-point {
  stroke-width: 3px;
  fill: #fff;
}

.order-chart-container .history svg .ct-point.circle-trans {
  display: none;
}

.order-chart-container .history svg .ct-series-a .ct-point,
.order-chart-container .history svg .ct-series-a .ct-line,
.order-chart-container .history svg .ct-series-a .ct-bar,
.order-chart-container .history svg .ct-series-a .ct-slice-donut {
  stroke: #e8d7c8;
}

.order-chart-container .history svg .ct-series-b .ct-slice-pie,
.order-chart-container .history svg .ct-series-b .ct-slice-donut-solid,
.order-chart-container .history svg .ct-series-b .ct-area {
  fill: url(#gradient) !important;
  fill-opacity: 0.2;
}

.task-progress {
  margin-top: 30px;
}

.task-progress .progress {
  margin-top: 10px;
}

.task-table table tr th,
.project-table table tr th {
  border-top: none;
  border-bottom: none;
  color: rgba(36, 41, 52, 0.5);
  font-weight: 500;
}

.task-table table tr td + td,
.project-table table tr td + td {
  color: rgba(36, 41, 52, 0.5);
}

.task-table table tr:last-child,
.project-table table tr:last-child {
  padding-bottom: 0;
}

.report-status #project-status {
  margin-top: -15px;
}

.report-status ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #e6edef;
}

.report-status ul li {
  display: inline-block;
  width: 100%;
  padding: 15px;
}

.report-status ul li h5 {
  margin-bottom: 0;
}

.report-status ul li + li {
  border-left: 1px solid #e6edef;
}

.update-line .media .activity-line {
  height: 249px;
}

.project-table table thead tr th {
  color: #242934;
  font-weight: 600;
}

.project-table table tr th,
.project-table table tr td {
  vertical-align: middle;
}

.project-table table tr th span,
.project-table table tr td span {
  font-size: 12px;
}

.project-table table tr th:last-child,
.project-table table tr td:last-child {
  min-width: 150px;
}

.jvector-map-project {
  height: 304px;
}

.map-progress li h6 {
  font-weight: 400;
}

.map-progress li h6 span {
  color: rgba(36, 41, 52, 0.5);
}

.map-progress li + li {
  margin-top: 30px;
}

@media screen and (min-width: 1200px) and (max-width: 1291px) {
  .update-line .media .activity-line {
    height: 287px;
  }

  .order-chart-container .history {
    height: 321px;
  }

  #project-status {
    min-height: 320px !important;
  }

  #project-status .apexcharts-canvas,
  #project-status svg {
    height: 320px !important;
  }
}

@media only screen and (max-width: 991px) {
  .update-line .media .activity-line {
    height: 211px;
  }

  .map-progress li + li {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .map-progress {
    margin-top: 20px;
  }

  .project-table table tr td,
  .project-table table tr th {
    min-width: 130px;
  }

  .project-table table tr td:nth-child(2),
  .project-table table tr th:nth-child(2) {
    min-width: 169px;
  }
}

@media only screen and (max-width: 575px) {
  .task-table table tr th:first-child,
  .task-table table tr td:first-child {
    min-width: 280px;
  }

  .task-table table tr th:last-child,
  .task-table table tr td:last-child {
    min-width: 60px;
  }

  .map-progress {
    margin-top: 15px;
  }

  .map-progress li + li {
    margin-top: 15px;
  }

  .order-chart-container .history {
    height: 245px;
  }

  .update-line .media .activity-line {
    height: 178px;
    top: 139px;
  }
}

@media only screen and (max-width: 468px) {
  .update-line .media .activity-line {
    height: 215px;
  }
}

@media only screen and (max-width: 333px) {
  .update-line .media .activity-line {
    height: 228px;
  }
}

/**=====================
     68. Dashboard 3 CSS End
==========================**/

/**=====================
    63. Box-layout CSS start
==========================**/

@media only screen and (min-width: 1280px) {
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper {
    margin-top: 40px !important;
    overflow: hidden;
  }

  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 165px);
    margin-top: 0;
    top: 80px;
    overflow-y: scroll !important;
    max-height: calc(100vh - 220px);
    padding-bottom: 80px;
  }

  .box-layout .page-wrapper.horizontal-wrapper .page-main-header {
    margin-top: 40px;
  }

  .box-layout .page-wrapper.horizontal-wrapper .page-main-header.close_icon {
    margin-left: auto;
  }

  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper {
    width: 1280px;
  }

  .box-layout
    .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header.main-nav {
    width: 1280px;
    top: 80px;
    margin: 0;
    overflow-x: unset;
    position: relative;
  }

  .box-layout
    .page-wrapper.horizontal-wrapper
    .page-body-wrapper.horizontal-menu
    header.main-nav
    .main-navbar
    #mainnav {
    width: 1280px;
    margin-left: auto;
    margin-right: auto;
    position: unset;
  }

  .box-layout
    .page-wrapper.compact-sidebar.compact-wrapper
    .page-body-wrapper
    header.main-nav {
    height: calc(100vh - 206px);
    width: 155px;
  }

  .box-layout
    .page-wrapper.compact-sidebar.compact-wrapper
    .page-body-wrapper
    header.main-nav
    .main-navbar
    .nav-menu {
    height: calc(100vh - 240px);
    padding-top: 0;
    padding-bottom: 0;
  }

  .box-layout
    .page-wrapper.compact-sidebar.compact-wrapper
    .page-body-wrapper
    header.main-nav
    .main-navbar
    .nav-menu
    .dropdown
    .menu-title.active
    ~ .menu-content {
    left: 24.7%;
    top: 118px;
  }

  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper {
    margin-top: 40px !important;
  }

  .box-layout .page-wrapper.compact-wrapper .page-main-header {
    margin-top: 40px;
  }

  .box-layout .page-wrapper.compact-wrapper .page-main-header.close_icon {
    margin-left: auto;
  }

  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    margin-left: 255px;
    min-height: calc(100vh - 165px);
    margin-top: 0;
    top: 80px;
    overflow-y: scroll !important;
    max-height: calc(100vh - 130px);
    padding-bottom: 80px;
  }

  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    .page-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(36, 105, 92, 0.2);
  }

  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    .page-body::-webkit-scrollbar-thumb,
  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    .page-body::-webkit-scrollbar {
    width: 6px;
    border-radius: 13px;
  }

  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    .page-body::-webkit-scrollbar-thumb {
    background-color: rgba(36, 105, 92, 0.1);
  }

  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav {
    position: fixed;
    border-bottom: none;
    top: 100px;
    z-index: 3;
    height: auto;
    line-height: inherit;
    width: 255px;
    height: 100%;
    text-align: left;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding-top: 25px;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 7px;
    height: calc(100vh - 201px);
  }

  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    header.main-nav.close_icon {
    opacity: 0;
    visibility: hidden;
  }

  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    header.main-nav.close_icon
    ~ footer {
    margin-left: 0;
    width: 1280px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    header.main-nav
    .main-navbar
    .nav-menu {
    height: calc(100vh - 510px);
  }

  .box-layout
    .page-wrapper.compact-wrapper
    .page-body-wrapper
    header.main-nav.main-nav
    .main-navbar
    .nav-menu
    > li {
    padding: 0 10px;
  }

  .box-layout .page-wrapper.modern-sidebar.compact-wrapper .page-main-header {
    margin: 0 auto;
  }

  .box-layout .page-wrapper.modern-sidebar.compact-wrapper .page-body-wrapper {
    padding-top: 40px;
  }

  .box-layout
    .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    header.main-nav {
    top: 103px;
    height: calc(100vh - 190px);
    margin-left: unset;
  }

  .box-layout
    .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    .page-body {
    max-height: calc(100vh - 170px);
    min-height: calc(100vh - 170px);
    top: 0;
    margin: 0 auto;
    margin-left: 255px;
    padding-bottom: 30px;
  }

  .box-layout
    .page-wrapper.modern-sidebar.compact-wrapper
    .page-body-wrapper
    footer {
    z-index: 3;
  }

  .box-layout .page-wrapper .main-navbar .tab-content {
    padding-left: 10px;
    padding-right: 10px;
  }

  .box-layout
    .page-wrapper
    .page-main-header
    .main-header-right
    .main-header-left {
    width: 255px;
  }

  .box-layout .page-wrapper ul.close_icon > li label {
    padding: 0;
  }

  .box-layout .page-wrapper ul.close_icon > li label:after {
    display: none;
  }

  .box-layout .page-wrapper .bookmark ul {
    margin-right: -1px;
  }

  .box-layout
    .page-wrapper
    #batchDelete
    .jsgrid-grid-header
    .jsgrid-table
    tr
    th
    .btn {
    padding-left: 20px;
    padding-right: 20px;
  }

  .box-layout
    .page-wrapper
    .btn-group-showcase
    .btn-radio
    .btn-group
    .radio
    input[type="radio"] {
    display: none;
  }

  .box-layout .page-wrapper .media.bitcoin-graph {
    display: block;
  }

  .box-layout .page-wrapper .media.bitcoin-graph .top-bitcoin {
    display: inline-block;
    vertical-align: middle;
  }

  .box-layout .page-wrapper .media.bitcoin-graph .media-body {
    margin-top: 30px;
  }

  .box-layout
    .page-wrapper
    .media.bitcoin-graph
    .media-body
    .bitcoin-content
    .bitcoin-numbers
    h6 {
    font-size: 14px;
  }

  .box-layout
    .page-wrapper
    .media.bitcoin-graph
    .media-body
    .bitcoin-content.text-end {
    text-align: center !important;
  }

  .box-layout .page-wrapper .alert.inverse p {
    max-width: 238px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .box-layout .page-wrapper .page-main-header .main-header-right {
    margin: 0;
  }

  .box-layout .page-wrapper .page-main-header .vertical-menu-main .mega-menu {
    width: 1050px !important;
    max-width: 1050px !important;
    left: -80px !important;
  }

  .box-layout .page-wrapper .page-body-wrapper {
    width: 1280px;
    -webkit-box-shadow: 1px 3px 25px rgba(0, 0, 0, 0.07);
    box-shadow: 1px 3px 25px rgba(0, 0, 0, 0.07);
    margin: 0 auto;
  }

  .box-layout .page-wrapper .page-body-wrapper .learning-comment {
    margin-left: -14px !important;
    float: left !important;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .todo
    .todo-list-wrapper
    #todo-list
    li
    .task-responsive {
    min-width: 1087px;
    overflow: auto;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .browse
    div[class^="col-"]:nth-child(3) {
    margin-top: 30px;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .current-progress
    table
    tbody
    tr
    td:last-child {
    width: 147px;
  }

  .box-layout .page-wrapper .page-body-wrapper .server-chart #latency-chart {
    width: 497px;
  }

  .box-layout .page-wrapper .page-body-wrapper .active-order-table {
    max-width: 443px;
    overflow: auto;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .active-order-table
    table
    tbody
    tr
    td
    p {
    width: 100px;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .activity
    .media
    .gradient-round.gradient-line-1:after {
    height: 57px;
    bottom: -64px;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .activity
    .media
    .gradient-round.small-line:after {
    height: 36px;
    bottom: -43px;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .activity
    .media
    .gradient-round.medium-line:after {
    height: 40px;
    bottom: -48px;
  }

  .box-layout .page-wrapper .page-body-wrapper footer {
    width: 1025px;
    margin: 0 auto;
    right: 0;
    padding-right: 15px;
    margin-left: 255px;
    width: 1280px;
    margin: 0 auto;
    padding-left: 15px;
    margin-bottom: 40px !important;
    position: fixed;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    z-index: 3;
  }

  .box-layout .page-wrapper .page-body-wrapper .footer-fix {
    margin-left: 567px;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .call-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-width: 300px;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .call-content
    > div {
    z-index: 8;
    background-color: rgba(255, 255, 255, 0.75);
    background-blend-mode: overlay;
    width: 100%;
    padding: 30px;
    left: 15px;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .call-content
    button {
    width: 40%;
    font-size: 14px;
    margin: 0 auto;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .call-icons {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .call-icons
    ul
    li {
    border: 1px solid #717171;
    width: 50px;
    height: 50px;
    padding: 8px;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .receiver-img {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .receiver-img
    img {
    width: 38%;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .chat-box
    .chat-history
    .total-time
    h2 {
    font-size: 28px;
    color: #717171;
  }

  .box-layout .page-wrapper .page-body-wrapper canvas#myLineCharts {
    width: 100%;
  }

  .box-layout .page-wrapper .page-body-wrapper .chat-right-aside {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
    overflow: hidden;
  }

  .box-layout .page-wrapper .page-body-wrapper .caller-img {
    position: absolute;
    width: 100%;
    max-width: 100%;
    left: 15px;
  }

  .box-layout .page-wrapper .page-body-wrapper .caller-img img {
    opacity: 0.7;
  }

  .box-layout .page-wrapper .page-body-wrapper .browser-widget img {
    height: 65px;
  }

  .box-layout
    .page-wrapper
    .page-body-wrapper
    .weather-widget-two
    .bottom-whetherinfo
    .whether-content {
    top: 39px;
  }

  .box-layout .page-wrapper .page-body-wrapper .custom-card .card-header img {
    margin-top: -73px;
  }

  .box-layout .page-wrapper .page-body-wrapper .custom-card .card-profile img {
    height: 130px;
    top: -17px;
  }

  .box-layout .page-wrapper .page-body-wrapper .select2 {
    width: 901.781px;
  }

  .box-layout .page-wrapper .page-main-header {
    max-width: 1280px;
    margin: 0 auto;
    left: 0;
    right: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .box-layout .page-wrapper .page-main-header.open {
    padding-left: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .box-layout .page-wrapper .page-builder .ge-canvas.ge-layout-desktop {
    margin-top: 50px;
  }

  .box-layout .page-wrapper .page-builder .ge-addRowGroup {
    margin-bottom: 10px;
  }

  .box-layout .page-wrapper .pricing-wrapper-card {
    padding: 50px 20px;
  }

  .box-layout .page-wrapper .card .blog-box.blog-grid.set-min-height {
    min-height: 400px;
  }

  .box-layout
    .page-wrapper
    .flot-chart-placeholder#donut-color-chart-morris-daily {
    min-height: 430px;
  }

  .box-layout .page-wrapper .flot-chart-placeholder#donut-color-chart-morris {
    min-height: 430px;
  }

  .box-layout .page-wrapper .box-col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .box-layout .page-wrapper .box-col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .box-layout .page-wrapper .box-col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .box-layout .page-wrapper .box-col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  .box-layout .page-wrapper .box-col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  .box-layout .page-wrapper .box-col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .box-layout .page-wrapper .box-col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33%;
  }

  .box-layout .page-wrapper .chat-box .chat-right-aside {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: calc(100% - 15px);
    overflow: hidden;
  }

  .box-layout
    .page-wrapper
    .chat-box
    .chat-right-aside
    .chat
    .chat-header
    .chat-menu-icons
    li
    a
    i {
    font-size: 19px;
  }

  .box-layout .page-wrapper .chat-box .chat-right-aside.bitcoin-chat {
    max-width: 100%;
  }

  .box-layout .page-wrapper .chat-box .toogle-bar {
    display: inline-block;
    margin-right: 0 !important;
  }

  .box-layout .page-wrapper .chat-menu {
    right: 0;
    border-top: 1px solid #e6edef;
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
    visibility: hidden;
    top: 81px;
    position: absolute;
    z-index: 9;
    background-color: #f5f7fb;
    -webkit-transition: all linear 0.3s;
    transition: all linear 0.3s;
  }

  .box-layout .page-wrapper .chat-menu.show {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: all linear 0.3s;
    transition: all linear 0.3s;
    padding-bottom: 25px;
  }

  .box-layout
    .page-wrapper
    .ct-10.total-chart
    .ct-chart-bar
    .ct-series
    .ct-bar {
    stroke-width: 23px !important;
  }

  .box-layout
    .page-wrapper
    .email-wrap
    .email-body
    .email-compose
    .cke_contents.cke_reset {
    max-height: 165px;
  }

  .box-layout .page-wrapper .email-wrap .email-right-aside .email-body .inbox {
    height: 644px;
  }

  .box-layout
    .page-wrapper
    .email-wrap
    .email-content
    .email-top
    .user-emailid:after {
    right: -10px;
  }

  .box-layout .page-wrapper .todo .notification-popup {
    right: 320px;
  }

  .box-layout .page-wrapper .touchspin {
    padding: 0 10px;
  }

  .box-layout .page-wrapper .vertical-menu-main {
    width: 1280px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .box-layout .page-wrapper .vertical-menu-main .mega-menu {
    width: 1050px !important;
    max-width: 1050px !important;
    left: -320px !important;
  }

  .box-layout .page-wrapper .comingsoon video {
    min-width: 67%;
    width: 67%;
  }

  .box-layout .page-wrapper .auth-bg-effect .second-effect {
    left: 55%;
  }

  .box-layout .page-wrapper .auth-bg-video video {
    min-width: 67%;
    width: 67%;
  }

  .box-layout .page-wrapper .search-page .search-vid-block > div {
    margin-top: 30px;
  }
}

/**=====================
    63. Box-layout CSS ends
==========================**/

/**=====================
    60. RTL CSS Start
==========================**/

.rtl .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav {
  text-align: right;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-left: 1px solid #e6edef;
  border-right: unset;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon {
  margin-right: 0;
  margin-left: unset;
  -webkit-transform: translate(290px);
  transform: translate(290px);
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  ~ .page-body {
  margin-right: 0;
  margin-left: unset;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  ~ footer {
  margin-left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  .main-navbar
  .nav-menu
  > li
  a
  svg {
  float: left;
  margin-right: 10px;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  .main-navbar
  .nav-menu
  .dropdown
  .menu-title.active
  ~ .menu-content {
  display: none !important;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav.close_icon
  .according-menu {
  display: none !important;
}

.rtl .page-wrapper.compact-wrapper .page-body-wrapper header.main-nav:hover {
  -webkit-transform: translate(0px);
  transform: translate(0px);
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav:hover
  .main-navbar
  .nav-menu
  .dropdown
  .menu-title.active
  ~ .menu-content {
  display: block !important;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav:hover
  .main-navbar
  .nav-menu
  > li
  a
  svg {
  float: none;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav:hover
  .according-menu {
  display: block;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  a
  .sub-arrow {
  left: 20px;
  right: unset;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li
  + li::before {
  right: -14px;
  left: unset;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  .setting-primary {
  right: unset;
  left: 35px;
}

.rtl .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  margin-left: unset;
  margin-right: 290px;
}

.rtl .page-wrapper.compact-wrapper .page-body-wrapper .main-nav {
  text-align: right;
}

.rtl
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  .main-nav
  .main-navbar
  .nav-menu
  > li
  a
  svg {
  margin-right: unset;
  margin-left: 15px;
}

.rtl .page-wrapper.compact-wrapper .page-body-wrapper .according-menu {
  right: unset;
  left: 15px;
}

.rtl
  .page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .dropdown
  .menu-title.active
  ~ .menu-content {
  left: unset;
  right: 150px;
}

.rtl
  .page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  .page-body {
  margin-right: 150px;
  margin-left: unset;
}

.rtl .page-wrapper.modern-sidebar.compact-wrapper footer {
  margin-left: 30px;
}

.rtl
  .page-wrapper.modern-sidebar.compact-wrapper
  header.main-nav.close_icon
  ~ footer {
  margin-left: 30px;
}

.rtl .main-navbar ul.nav-pills .nav-item:last-child {
  border-right: 1px solid #e6edef;
  border-left: unset;
}

.rtl .product-social li:nth-child(n + 2) {
  margin-left: unset;
  margin-right: 10px;
}

.rtl ul.pro-services li svg {
  margin-left: 15px;
  margin-right: unset;
}

.rtl .filter-block ul li .form-check {
  padding-right: unset;
}

.rtl .filter-block ul li .form-check .form-check-input {
  float: right;
  margin-left: 15px;
}

.rtl .ms-1 {
  margin-left: unset;
  margin-right: 0.25rem !important;
}

.rtl .f-right {
  float: left;
}

.rtl .file-content .form-inline i {
  padding-right: unset;
  padding-left: 10px;
}

.rtl .file-sidebar .btn svg {
  margin-right: unset;
  margin-left: 8px;
}

.rtl .form-select {
  background-position: left 0.75rem center;
  padding: 0.375rem 0.75rem 0.375rem 1.75rem;
}

.rtl .customers ul li + li {
  margin-left: unset;
  margin-right: -10%;
}

.rtl .project-list .border-tab.nav-tabs .nav-item .nav-link {
  padding: 5px 0 5px 30px;
}

.rtl .project-list .btn {
  float: left;
}

.rtl .project-list ul li svg {
  margin-right: unset;
  margin-left: 5px;
}

.rtl .project-box .badge {
  right: unset;
  left: 15px;
}

.rtl .kanban-board {
  float: right;
}

.rtl .kanban-item .kanban-box .customers ul li + li {
  margin-left: unset;
  margin-right: -10px;
}

.rtl .kanban-item .list li i {
  margin-right: unset;
  margin-left: 10px;
}

.rtl .user-profile .social-media ul.user-list-social li:nth-child(n + 2) {
  margin-right: 5px;
  margin-left: unset;
}

.rtl .user-profile .follow ul.follow-list li:nth-child(n + 2) {
  margin-right: 15px;
  padding-right: 15px;
  padding-left: 0;
  margin-left: 0;
  border-right: 1px solid #e6edef;
  border-left: none;
}

.rtl .user-profile .post-about ul li .icon {
  margin-right: 0;
  margin-left: 15px;
}

.rtl .user-profile .profile-post .post-body .post-react ul li:nth-child(n + 2) {
  margin-left: unset;
  margin-right: -20px;
}

.rtl .user-profile .profile-post .post-body .post-react h6 {
  margin-right: 15px;
  margin-left: unset;
}

.rtl .user-profile .profile-post .post-body .post-comment li:nth-child(n + 2) {
  margin-left: 0;
  margin-right: 15px;
}

.rtl .social-tab ul li:nth-child(n + 2) {
  margin-left: unset;
  margin-right: 15px;
}

.rtl .social-tab ul li a svg {
  margin-right: unset;
  margin-left: 5px;
}

.rtl .social-tab .input-group .form-control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rtl .social-tab .input-group .input-group-text {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rtl .invo-profile .text-xl-end {
  text-align: left !important;
}

.rtl .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
  margin-right: 0;
}

.rtl .page-wrapper.horizontal-wrapper .page-body-wrapper footer {
  margin-right: 0;
}

.rtl
  .page-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:last-child {
  margin-left: unset;
}

.rtl
  .page-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  .media
  .notification-bg {
  margin-right: unset;
  margin-left: 15px;
}

.rtl .page-main-header .main-header-right .nav-right {
  text-align: right;
}

.rtl .page-main-header .main-header-right .nav-right > ul > li {
  margin-left: 20px;
  margin-right: unset !important;
}

.rtl .page-main-header .main-header-right .nav-right .btn-primary-light svg {
  margin-left: 10px;
  margin-right: unset;
  -webkit-transform: scale(-1);
  transform: scale(-1);
}

.rtl
  .page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li
  .bookmark-icon {
  margin-left: 15px;
  margin-right: unset;
}

.rtl .page-main-header .main-header-right .main-header-left {
  margin-right: unset;
  border-right: unset;
  border-left: 1px solid #e6edef;
}

.rtl .ps-2,
.rtl .px-2 {
  padding-right: 0.5rem !important;
  padding-left: unset !important;
}

.rtl .page-main-header .main-header-right .nav-right .bookmark-dropdown {
  right: -55px;
}

.rtl .page-main-header .main-header-right .nav-right .bookmark-dropdown ul li {
  text-align: right;
}

.rtl
  .page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li
  .bookmark-icon {
  margin-right: unset;
  margin-left: 15px;
}

.rtl .page-main-header .main-header-right .nav-right.right-menu ul li {
  text-align: right;
}

.rtl
  .page-main-header
  .main-header-right
  .nav-right
  .form-group
  .input-group
  .form-control {
  border-left: 1px solid #e6edef;
}

.rtl .flag-icons div .media-body h5,
.rtl .feather-icons div .media-body h5 {
  margin-right: unset;
  margin-left: 5px;
}

.rtl .apexcharts-canvas {
  direction: ltr;
}

.rtl .cal-date-widget .cal-info .pe-3 {
  padding-right: 1rem !important;
}

.rtl .me-4,
.rtl .mx-4 {
  margin-left: 1.5rem !important;
  margin-right: unset !important;
}

.rtl .pe-4,
.rtl .px-4 {
  padding-left: 1.5rem !important;
  padding-right: unset !important;
}

.rtl .me-1,
.rtl .mx-1 {
  margin-left: 0.25rem !important;
  margin-right: unset !important;
}

.rtl .navs-dropdown .onhover-show-div {
  right: 0;
}

.rtl .bitcoin-accordion button[aria-expanded="true"]:before,
.rtl .bitcoin-accordion button[aria-expanded="false"]:before {
  left: 31px !important;
  right: unset !important;
}

.rtl .default-according.style-1 button {
  text-align: right;
}

.rtl .default-according.style-1 button:before {
  left: 20px;
  right: unset;
}

.rtl .default-according.style-1 button i {
  right: 18px;
  left: unset;
}

.rtl .default-according .card .card-header i {
  right: 18px;
  left: unset;
}

.rtl .setting-list {
  right: unset;
  left: 20px;
  float: left;
}

.rtl .setting-list .setting-option {
  text-align: left;
}

.rtl .dashboard-default-sec .card .card-header ul.week-date li:nth-child(n + 2),
.rtl .dashboard-2-main .card .card-header ul.week-date li:nth-child(n + 2) {
  margin-right: 10px;
  margin-left: unset;
  padding-right: 15px;
  padding-left: unset;
  border-right: 1px solid #e6edef;
  border-left: unset;
}

.rtl .dashboard-default-sec .card .card-header .header-top,
.rtl .dashboard-2-main .card .card-header .header-top {
  direction: rtl;
}

.rtl .top-sell-sec .card-body .media .media-body {
  margin-left: unset;
  margin-right: 20px;
}

.rtl .target-sec .card-header ul.target-list li:nth-child(n + 2) {
  margin-right: 20px;
  margin-left: unset;
}

.rtl .activity-sec .activity-timeline .media .activity-line {
  right: 37px;
  left: unset;
}

.rtl
  .activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  ul.timeline-pro
  li:nth-child(n + 2) {
  margin-left: unset;
  margin-right: 20px;
}

.rtl .activity-timeline .media .media-body {
  margin-right: 20px;
  margin-left: unset;
}

.rtl .center-content {
  padding-left: 50px;
  padding-right: unset;
}

.rtl .latest-update-sec .media .media-body,
.rtl .recent-order-sec .media .media-body {
  margin-right: 20px;
  margin-left: unset;
}

.rtl .latest-update-sec table tbody tr td:last-child {
  padding-left: 30px;
  padding-right: unset;
}

.rtl .latest-update-sec table tbody tr td:first-child {
  padding-right: 30px;
  padding-left: unset;
}

.rtl .trasaction-sec .transaction-totalbal {
  padding-left: unset;
  padding-right: 30px;
}

.rtl .ProfileCard-details {
  padding-right: 30px;
  padding-left: unset;
  float: right;
}

.rtl .starter-main .card-body ul {
  padding-right: 30px;
  padding-left: unset;
}

.rtl .theme-tab .tab-title li a svg {
  margin-left: 5px;
  margin-right: unset;
}

.rtl .spent .spent-graph .project-budget {
  margin-left: 30px;
  margin-right: unset;
}

.rtl .support-table table tbody tr td:first-child {
  padding-right: 0;
  padding-left: unset;
}

.rtl .widget-joins .row .pe-0 {
  padding-right: 15px !important;
}

.rtl .widget-joins .row .ps-0 {
  padding-left: 15px !important;
}

.rtl .order-box .sub-total .shipping-class .shopping-checkout-option,
.rtl .order-box .sub-total .shipping-class span,
.rtl .order-box .sub-total li .shopping-checkout-option,
.rtl .order-box .sub-total li span,
.rtl .order-box .total .shipping-class .shopping-checkout-option,
.rtl .order-box .total .shipping-class span,
.rtl .order-box .total li .shopping-checkout-option,
.rtl .order-box .total li span,
.rtl .order-box .qty .shipping-class .shopping-checkout-option,
.rtl .order-box .qty .shipping-class span,
.rtl .order-box .qty li .shopping-checkout-option,
.rtl .order-box .qty li span {
  float: left;
}

.rtl .order-box .sub-total li .count,
.rtl .order-box .total li .count,
.rtl .order-box .qty li .count {
  float: left;
}

.rtl .img-paypal {
  margin-right: 15px;
  margin-left: unset;
}

.rtl .cart .qty-box .input-group .btn {
  border-radius: 0 !important;
}

.rtl .todo .action-box {
  text-align: left;
}

.rtl .product-qnty fieldset .input-group .input-group-prepend .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .grid-options {
  margin-right: 10px;
  margin-left: unset;
}

.rtl .grid-options ul li a .line-grid-1 {
  right: 12px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-2 {
  right: 18px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-3 {
  right: 36px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-4 {
  right: 42px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-5 {
  right: 48px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-6 {
  right: 66px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-7 {
  right: 72px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-8 {
  right: 78px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-9 {
  right: 84px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-10 {
  right: 103px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-11 {
  right: 109px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-12 {
  right: 115px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-13 {
  right: 121px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-14 {
  right: 127px;
  left: unset;
}

.rtl .grid-options ul li a .line-grid-15 {
  right: 133px;
  left: unset;
}

.rtl .product-filter.new-products .owl-theme .owl-nav {
  left: 0;
  right: unset;
}

.rtl
  .product-filter.new-products
  .owl-theme
  .owl-item
  .item
  .product-box
  .product-details.text-start {
  text-align: left !important;
}

.rtl .pro-filter-sec .product-sidebar {
  margin-right: unset;
  margin-left: 30px;
}

.rtl .product-sidebar .filter-section .card .card-header h6 .pull-right i {
  left: auto;
  right: unset;
}

.rtl .feature-products form .form-group i {
  left: 30px;
  right: unset;
}

.rtl .product-wrapper-grid.list-view .product-box .product-details {
  text-align: right;
}

.rtl .page-wrapper .page-body-wrapper .page-header .bookmark {
  margin-right: auto;
  text-align: left;
}

.rtl .prooduct-details-box .btn {
  left: 15px;
  right: unset;
}

.rtl .bookmark ul li .search-form .form-control-search {
  left: -50px;
  right: unset;
}

.rtl .bookmark ul li .search-form .form-control-search:before {
  right: 50px;
  left: unset;
}

.rtl .bookmark ul li .search-form .form-control-search:after {
  right: 20px;
  left: unset;
}

.rtl .bookmark ul li:first-child {
  padding-right: 0;
  padding-left: 5px;
}

.rtl .bookmark ul li:nth-child(n + 2) {
  margin-left: unset;
  margin-right: 8px;
}

.rtl .order-box .title-box span {
  text-align: left;
}

.rtl .order-box .qty li span {
  text-align: left;
}

.rtl .order-box .sub-total li .count {
  text-align: left;
}

.rtl .order-box .sub-total .shipping-class .shopping-checkout-option label {
  text-align: left;
}

.rtl .checkout .form-check {
  padding-right: unset;
}

.rtl .checkout .form-check .form-check-input {
  float: right;
  margin-left: 10px;
}

.rtl .checkout .checkbox_animated,
.rtl .checkout .radio_animated {
  margin: 0;
  margin-left: 10px;
}

.rtl .me-0,
.rtl .mx-0 {
  margin-left: 0 !important;
  margin-right: unset !important;
}

.rtl .footer .pull-right {
  float: left;
}

.rtl .checkbox_animated,
.rtl .radio_animated {
  margin: 0 8px 0 16px;
}

.rtl .button.remove {
  left: -10px;
  right: unset;
}

.rtl .code-box-copy__btn {
  left: 11px;
  right: unset;
}

.rtl ul.nav-menus {
  border-bottom: none;
}

.rtl ul.notification-dropdown.onhover-show-div li {
  text-align: right;
}

.rtl .badge + .badge {
  margin-right: 5px;
  margin-left: unset;
}

.rtl .offset-xl-3 {
  margin-right: 25%;
  margin-left: unset;
}

.rtl .m-l-10 {
  margin-right: 10px !important;
  margin-left: unset !important;
}

.rtl .m-r-20 {
  margin-left: 20px;
  margin-right: unset;
}

.rtl .m-r-30 {
  margin-left: 30px;
  margin-right: unset;
}

.rtl .m-r-5 {
  margin-left: 5px;
  margin-right: unset;
}

.rtl .text-start {
  text-align: right !important;
}

.rtl .m-r-10 {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .pe-3,
.rtl .px-3 {
  padding-left: 1rem !important;
  padding-right: unset !important;
}

.rtl .p-r-0 {
  padding-left: 0;
  padding-right: 15px;
}

.rtl .m-l-20 {
  margin-right: 20px !important;
  margin-left: unset !important;
}

.rtl .ps-3,
.rtl .px-3 {
  padding-right: 1rem !important;
}

.rtl .b-r-light {
  border-left: 1px solid #e6edef !important;
  border-right: unset !important;
}

.rtl .float-end {
  float: left !important;
}

.rtl .float-start {
  float: right !important;
}

.rtl .text-end {
  text-align: left !important;
}

.rtl .border-right {
  border-left: 1px solid #dee2e6 !important;
  border-right: unset !important;
}

.rtl .pe-0,
.rtl .px-0 {
  padding-left: 0 !important;
  padding-right: unset !important;
}

.rtl .ps-0,
.rtl .px-0 {
  padding-right: 0 !important;
  padding-left: unset !important;
}

.rtl .ms-0 {
  margin-left: unset !important;
  margin-right: 0.25rem !important;
}

.rtl .owl-carousel {
  direction: ltr;
}

.rtl .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.rtl .btn-group > :not(:last-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .btn-group > .btn:nth-child(2) {
  border-radius: 0 !important;
}

.rtl .btn-group .btn-group > :not(:first-child) > .btn {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .btn-group .btn + .btn,
.rtl .btn-group .btn + .btn-group {
  margin-right: -1px;
}

.rtl .btn-group .btn-group + .btn,
.rtl .btn-group .btn-group + .btn-group {
  margin-right: -1px;
}

.rtl .btn-group .btn-group-vertical .btn + .btn,
.rtl .btn-group .btn-group-vertical .btn + .btn-group {
  margin-right: -1px;
}

.rtl .btn-group .btn-group-vertical .btn-group + .btn,
.rtl .btn-group .btn-group-vertical .btn-group + .btn-group {
  margin-right: -1px;
}

.rtl .me-3,
.rtl .mx-3 {
  margin-right: unset !important;
  margin-left: 1rem !important;
}

.rtl .me-5,
.rtl .mx-5 {
  margin-left: 3rem !important;
  margin-right: unset !important;
}

.rtl .ms-3,
.rtl .mx-3 {
  margin-right: 1rem !important;
  margin-left: unset !important;
}

.rtl .m-r-15 {
  margin-left: 15px;
  margin-right: unset;
}

.rtl .b-l-light {
  border-right: 1px solid #e6edef !important;
  border-left: unset !important;
}

.rtl .p-l-0 {
  padding-left: unset;
  padding-right: 0px;
}

.rtl .ps-4 {
  padding-right: 1.5rem !important;
  padding-left: unset !important;
}

.rtl .me-2 {
  margin-left: 0.5rem !important;
  margin-right: unset !important;
}

.rtl .ms-2 {
  margin-right: 0.5rem !important;
  margin-left: unset !important;
}

.rtl .pe-2 {
  padding-right: unset !important;
  padding-left: 0.5rem !important;
}

.rtl .alert {
  text-align: right;
}

.rtl .pull-right {
  float: left;
}

.rtl .form-inline .form-group {
  margin-left: 15px;
  margin-right: 0;
}

.rtl .form-select {
  background-position: left 0.75rem center;
}

.rtl .card {
  text-align: right;
}

.rtl .card .card-header h5:not(.mb-0) {
  float: right;
}

.rtl .card .card-header span {
  clear: both;
}

.rtl .card .card-header .card-header-right {
  left: 20px;
  right: unset;
}

.rtl .card .card-header .card-header-right .card-option {
  text-align: left;
}

.rtl .card .card-header .card-header-right .card-option.list-unstyled {
  padding-right: 0;
  padding-left: unset;
}

.rtl .card .card-header .pull-left {
  float: right;
}

.rtl .card.full-card {
  left: 0;
  right: unset;
}

.rtl .card .card-body .alert svg {
  margin-left: 4px;
  margin-right: unset;
}

.rtl .card .card-body .value-left {
  margin-left: 30px;
  margin-right: unset;
}

.rtl .card .card-body .square {
  margin-left: 5px;
  margin-right: unset;
}

.rtl .modal-header .btn-close {
  margin-left: 0;
}

.rtl .modal-footer > :not(:first-child) {
  margin-right: 0.5rem;
  margin-left: 0;
}

.rtl .theme-close {
  right: unset;
  left: 0;
}

.rtl .form-inline .form-group .col-form-label {
  margin-left: 5px;
  margin-right: 0;
}

.rtl .modal-header .btn-close,
.rtl .modal-footer .btn-close,
.rtl .modal-content .btn-close {
  left: 25px;
  right: unset;
}

.rtl .alert-dismissible .btn-close {
  right: unset;
  left: 0;
}

.rtl .dismiss-text .alert .btn-close {
  left: 50px;
}

.rtl .card-body .alert svg ~ p {
  padding-right: 20px;
  padding-left: unset;
}

.rtl .default-according .card .card-header i {
  margin-left: 5px;
  margin-right: unset;
}

.rtl .was-validated .form-select:invalid,
.rtl .form-select.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) left
    calc(0.375em + 0.1875rem);
}

.rtl .was-validated .form-check {
  padding-left: 1.25rem;
}

.rtl .was-validated .form-control:invalid,
.rtl .form-control.is-invalid,
.rtl .was-validated .form-control:valid,
.rtl .form-control.is-valid {
  background-position: left calc(0.375em + 0.1875rem) center;
  padding-left: calc(1.5em + 0.75rem);
}

.rtl .was-validated .form-control:invalid {
  padding-right: 10px;
}

.rtl .breadcrumb-item + .breadcrumb-item {
  padding-right: 0.5rem;
  padding-left: unset;
}

.rtl .breadcrumb-item + .breadcrumb-item::before {
  padding-left: 0.5rem;
  padding-right: unset;
  float: right;
}

.rtl code[class*="language-"],
.rtl pre[class*="language-"] {
  text-align: right;
}

.rtl .input-group-prepend {
  margin-left: -1px;
  margin-right: unset;
}

.rtl .input-group > .form-control:not(:last-child),
.rtl .rtl .input-group > .custom-select:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .input-group > .input-group-prepend > .btn,
.rtl .input-group > .input-group-prepend > .input-group-text,
.rtl .input-group > .input-group-append:not(:last-child) > .btn,
.rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
.rtl
  .input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.rtl
  .input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl .input-group > .input-group-append > .btn,
.rtl .input-group > .input-group-append > .input-group-text,
.rtl .input-group > .input-group-prepend:not(:first-child) > .btn,
.rtl .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.rtl
  .input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .border-tab.nav-left .nav-link {
  text-align: left;
}

.rtl .socialprofile .social-group ul li:nth-child(4) {
  margin-right: -22px;
}

.rtl .social-status form .form-group .form-control-social {
  padding-right: 15px;
  padding-left: unset;
}

.rtl .social-status .media .social-status {
  right: 35px;
  left: unset;
}

.rtl .social-chat .other-msg {
  margin-right: 40px;
  margin-left: unset;
}

.rtl .social-chat .media-body:before {
  left: 100%;
  right: unset;
  border-left: 7px solid var(-semi-dark);
  border-right: unset;
}

.rtl .social-chat .media-body:after {
  left: 100%;
  right: unset;
  border-left: 7px solid #fff;
  border-right: unset;
}

.rtl .timeline-content .comment-number i {
  margin-left: 20px;
  margin-right: unset;
}

.rtl .photos ul li {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .avatar-showcase .friend-pic {
  margin-left: 8px;
  margin-right: unset;
}

.rtl .avatar-showcase .pepole-knows ul li {
  margin-left: 21px;
  margin-right: unset;
}

.rtl .avatar-showcase .pepole-knows ul li:last-child {
  margin-left: 0;
}

.rtl .edit-profile .profile-title .media .media-body {
  margin-right: 25px;
  margin-left: unset;
}

.rtl .list-persons .profile-mail .email-general ul {
  padding-right: unset;
  padding-left: 20px;
}

.rtl .list-persons .profile-mail .email-general ul li span {
  float: left;
}

.rtl .contacts-tabs .nav-pills {
  border-left: 1px solid #e6edef;
  border-right: unset;
}

.rtl .calendar-basic #menu-navi .menu-navi-right .move-btn {
  margin-right: 15px;
  margin-left: unset;
  direction: ltr;
}

.rtl .calendar-basic #dropdownMenu-calendarType i:last-child {
  margin-right: 15px;
  margin-left: unset;
}

.rtl .calendar-basic #dropdownMenu-calendarType i:first-child {
  margin-right: unset;
  margin-left: 15px;
}

.rtl .calendar-basic .lnb-calendars {
  float: left;
}

.rtl
  .calendar-basic
  input[type="checkbox"].tui-full-calendar-checkbox-round
  + span {
  margin-left: 8px;
  margin-right: unset;
}

.rtl
  .calendar-basic
  input[type="checkbox"].tui-full-calendar-checkbox-square
  + span {
  margin-left: 5px;
}

.rtl .faq-form .search-icon {
  left: 12px;
  right: unset;
}

.rtl .faq-widgets .media-body p {
  padding-left: 20px;
  padding-right: unset;
}

.rtl .header-faq,
.rtl .faq-title {
  text-align: right;
}

.rtl .faq-accordion .faq-header svg {
  left: 20px;
  right: unset;
}

.rtl .faq-accordion .card .card-header h5 {
  padding-right: 25px;
  padding-left: unset;
}

.rtl .faq-accordion .card .btn-link svg {
  margin-left: 10px;
  margin-right: unset;
  right: 20px;
  left: unset;
}

.rtl .navigation-option ul li {
  padding: 15px 0 15px 15px;
}

.rtl .navigation-option ul li a {
  padding-right: 40px;
  padding-left: unset;
}

.rtl .navigation-option ul li a svg {
  right: 15px;
  left: unset;
}

.rtl .updates-faq {
  margin-right: unset;
  margin-left: 30px;
}

.rtl .browse .browse-articles span svg {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .browse .browse-articles ul li h5 {
  padding-left: unset;
  padding-right: 20px;
}

.rtl .browse .browse-articles ul li h5 svg {
  left: unset;
  right: 0;
  margin-right: unset;
  margin-left: 10px;
}

.rtl .categories .learning-header {
  padding-right: 30px;
  padding-left: unset;
}

.rtl .main .langChoice {
  left: 30px;
  right: unset;
}

.rtl .pricing-block svg {
  direction: ltr;
}

.rtl .pricing-block .pricing-list ul li h6 span {
  margin-right: 5px;
  margin-left: unset;
}

.rtl p.data-attributes svg {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .avatars .avatar {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .avatars .avatar:last-child {
  margin-left: 0;
}

.rtl .avatars .avatar .status {
  left: 4px;
  right: unset;
}

.rtl .customers.avatar-group {
  margin-left: 30px;
  margin-right: unset;
}

.rtl .customers ul li + li {
  margin-left: unset;
  margin-right: -22px;
}

.rtl .card .card-header h5 {
  float: none !important;
}

.rtl .dashboard-default-sec .latest-update-sec table tbody tr td img,
.rtl .dashboard-default-sec .recent-order-sec table tbody tr td img {
  margin-left: 1rem !important;
  margin-right: unset !important;
}

.rtl .dashboard-2-main .card .card-body .media img {
  margin-left: 5px;
  margin-right: unset;
}

.rtl .joined-status {
  position: absolute;
  top: 23px;
  right: unset;
  left: 85px;
}

.rtl .likes-page {
  padding-right: 30px;
}

.rtl .activity-social .border-recent-success {
  border-right: 3px solid #1b4c43;
  border-left: None;
}

.rtl .activity-social .border-recent-warning {
  border-right: 3px solid #e2c636;
  border-left: None;
}

.rtl .activity-social .border-recent-info {
  border-right: 3px solid #717171;
  border-left: None;
}

.rtl .activity-social .border-recent-danger {
  border-right: 3px solid #d22d3d;
  border-left: None;
}

.rtl .activity-social .border-recent-secondary {
  border-right: 3px solid #ba895d;
  border-left: None;
}

.rtl .activity-social ul li {
  padding-right: 15px;
}

.rtl .social-user-card .social-user-top-content .customers ul li:last-child {
  margin-right: 30px;
}

.rtl .knob-chart .chart-clock-main .clock-small {
  right: 10px;
  left: unset;
}

.rtl .weather-widget-two .top-bg-whether {
  left: -28px;
  right: unset;
}

.rtl .weather-widget-two .bottom-whetherinfo svg {
  left: unset;
  right: -35px;
}

.rtl .mobile-clock-widget .bg-svg {
  left: unset;
  right: -25px;
}

.rtl .todo .todo-list-wrapper .mark-all-tasks {
  left: 30px;
  right: unset;
}

.rtl .todo .todo-list-wrapper .mark-all-tasks .btn-label {
  margin-left: 5px;
  margin-right: unset;
}

.rtl .todo .todo-list-wrapper #todo-list li .task-container .task-action-btn {
  text-align: left;
}

.rtl .todo .notification-popup {
  left: 10px;
  right: unset;
}

.rtl .todo-options .badges-todo h6 {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .todo-options .badges-todo h6:last-child {
  margin-left: 0;
}

.rtl .todo-list-btns .dropdown-basic .btn-group .form-group .checkbox {
  padding-right: 14px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-left: unset;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}

.rtl .todo-list-btns .dropdown-basic .separated-btn {
  margin-right: -6px;
  margin-left: unset;
}

.rtl .todo-list-btns .dropdown-basic .separated-btn .btn {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.rtl .search-todo .dropdown-basic .btn-group {
  margin-left: 18px;
  margin-right: unset;
}

.rtl .sticky-note .note {
  float: right;
}

.rtl .mega-menu .list-unstyled div a {
  padding: 8px 0 8px 35px;
}

.rtl .mega-menu .list-unstyled div a:hover {
  padding: 8px 10px 8px 35px;
}

.rtl
  #testimonial
  .owl-stage-outer
  .owl-stage
  .owl-item
  .slide--item
  .media
  .me-3,
.rtl
  #testimonial
  .owl-stage-outer
  .owl-stage
  .owl-item
  .slide--item
  .media
  .mx-3 {
  margin-right: 1rem !important;
  margin-left: unset !important;
}

.rtl .needs-validation .input-group > .form-control:not(:last-child),
.rtl .needs-validation .input-group > .custom-select:not(:last-child),
.rtl .form-row .input-group > .form-control:not(:last-child),
.rtl .form-row .input-group > .custom-select:not(:last-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .form-check {
  padding-right: 1.25rem;
  padding-left: unset;
}

.rtl .mega-menu .default-according .card .btn-link {
  text-align: right;
}

.rtl .mega-menu .default-according.style-1 button[aria-expanded="true"]:before,
.rtl
  .mega-menu
  .default-according.style-1
  button[aria-expanded="false"]:before {
  right: unset;
  left: 20px;
}

.rtl .email-wrap .email-app-sidebar .main-menu > li {
  text-align: right;
}

.rtl .email-wrap .email-app-sidebar .main-menu > li a i {
  margin-right: unset;
  margin-left: 10px;
}

.rtl .email-wrap .email-app-sidebar ul {
  padding-right: 0;
}

.rtl .email-wrap .email-content .email-top .user-emailid:after {
  float: left;
}

.rtl .email-wrap .email-body .attachment ul li:nth-child(n + 2) {
  margin-left: unset;
  margin-right: 10px;
}

.rtl .email-wrap .actions {
  text-align: left;
}

.rtl .email-wrap .actions li:nth-child(n + 2) {
  margin-right: 15px;
  margin-left: unset;
}

.rtl .email-wrap .email-right-aside .email-body .media .media-body .inbox svg {
  margin-right: unset;
  margin-left: 10px;
}

.rtl .email-wrap .email-right-aside .email-body .inbox svg {
  margin-right: unset;
  margin-left: 10px;
}

.rtl .email-wrap .email-right-aside .email-body .inbox .media .media-body span {
  left: 20px;
  right: unset;
}

.rtl .email-wrap .email-right-aside .email-profile .inbox p {
  margin-right: 15px;
  margin-left: unset;
}

.rtl .calendar-wrap .fc {
  direction: rtl;
  text-align: right;
}

.rtl .calendar-wrap .fc .fc-toolbar .fc-left {
  float: right;
}

.rtl .calendar-wrap .fc .fc-toolbar .fc-right {
  float: left;
}

.rtl .calendar-wrap .fc .fc-toolbar > * > * {
  margin-left: 0;
}

.rtl .calendar-wrap .fc-events-container {
  text-align: right;
}

.rtl .calendar-wrap .fc-scroller {
  margin-right: unset;
  margin-left: -17px;
}

.rtl .fc-agenda-view .fc-day-grid .fc-row {
  margin-right: 0 !important;
}

.rtl .fc-unthemed .fc-row {
  margin-right: 0 !important;
}

.rtl .btn-group-showcase .btn-group {
  margin-left: 20px;
  margin-right: unset;
}

.rtl .btn-showcase .btn {
  margin-right: unset;
  margin-left: 18px;
}

.rtl .btn-square.dropdown-toggle {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rtl.button-builder ul.the-icons li {
  float: right;
}

.rtl.button-builder .color-slelector {
  float: right;
}

.rtl.button-builder .custom-button-color .form-control {
  border-radius: 0 5px 5px 0;
}

.rtl.button-builder .custom-button-color .form-control.pull-left {
  float: right;
}

.rtl.button-builder .hint-label {
  float: right;
  padding: 4px 020px 0 0;
}

.rtl #customer-review .owl-item img {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.rtl .options > div {
  margin: 0 0 8px 8px;
}

.rtl .modal-dialog .modal-content .modal-body p a {
  margin-left: 0;
}

.rtl .drag-box fieldset .component .form-group .text-lg-start {
  text-align: right !important;
}

.rtl
  .drag-box
  fieldset
  .component
  .form-group
  .input-group
  .input-group-prepend
  .btn,
.rtl
  .drag-box
  fieldset
  .component
  .form-group
  .input-group
  .input-group-prepend
  .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl
  .drag-box
  fieldset
  .component
  .form-group
  .input-group
  .input-group-prepend
  .btn-right,
.rtl
  .drag-box
  fieldset
  .component
  .form-group
  .input-group
  .input-group-prepend
  .checkbox-radius {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .drag-box fieldset .component .form-group .input-group #prependedcheckbox {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rtl .drag-box fieldset .component .form-group .input-group #appendedcheckbox {
  border-right-color: #efefef;
}

.rtl .drag-box fieldset .component .form-group .input-group #buttondropdown {
  margin-right: unset;
  margin-left: -1px;
}

.rtl .drag form {
  text-align: right;
}

.rtl .drag form input {
  text-align: right;
}

.rtl .drag form label {
  text-align: right !important;
}

.rtl .drag form .form-group .input-group .input-group-prepend .btn,
.rtl
  .drag
  form
  .form-group
  .input-group
  .input-group-prepend
  .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl .drag form .form-group .input-group .input-group-prepend .btn-right,
.rtl .drag form .form-group .input-group .input-group-prepend .checkbox-radius {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .drag form .form-group .input-group #buttondropdown {
  margin-right: unset;
  margin-left: -1px;
}

.rtl .draggable label {
  text-align: right;
}

.rtl
  .form-builder-column
  .tab-content
  .tab-pane
  .theme-form
  .ui-draggable
  .input-group
  > .input-group-prepend
  > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl
  .form-builder-column
  #pills-tabContent
  .theme-form
  .form-group
  .input-group
  #buttondropdown,
.rtl
  .form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .input-group
  #buttondropdown {
  margin-right: unset;
  margin-left: 15px;
}

.rtl
  .form-builder-column
  #pills-tabContent
  .theme-form
  .form-group
  .input-group
  .input-group-btn
  button,
.rtl
  .form-builder-column
  #pills-tabContent
  .theme-form
  .ui-draggable
  .input-group
  .input-group-btn
  button {
  border-radius: 4px !important;
}

.rtl
  .form-horizontal.theme-form
  fieldset
  .tab-content
  .tab-pane
  .component
  .form-group
  .text-lg-start {
  text-align: right !important;
}

.rtl
  .form-horizontal.theme-form
  fieldset
  .tab-content
  .tab-pane
  .component
  .form-group
  .input-group
  .input-group-prepend
  .btn,
.rtl
  .form-horizontal.theme-form
  fieldset
  .tab-content
  .tab-pane
  .component
  .form-group
  .input-group
  .input-group-prepend
  .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl
  .form-horizontal.theme-form
  fieldset
  .tab-content
  .tab-pane
  .component
  .form-group
  .input-group
  .input-group-prepend
  .btn-right,
.rtl
  .form-horizontal.theme-form
  fieldset
  .tab-content
  .tab-pane
  .component
  .form-group
  .input-group
  .input-group-prepend
  .checkbox-radius {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl
  .form-horizontal.theme-form
  fieldset
  .tab-content
  .tab-pane
  .component
  .form-group
  .input-group
  #prependedcheckbox {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rtl
  .form-horizontal.theme-form
  fieldset
  .tab-content
  .tab-pane
  .component
  .form-group
  .input-group
  #appendedcheckbox {
  border-right-color: #efefef;
}

.rtl .form-builder .component .form-group .input-group #buttondropdown {
  margin-right: unset;
  margin-left: -1px;
}

.rtl .form-builder .component .form-group .pl-md-radios {
  padding-right: 37px;
  padding-left: unset;
}

.rtl .page-wrapper .page-main-header.open {
  margin-right: 0;
}

.rtl .page-wrapper .page-body-wrapper .logo-wrapper a {
  -webkit-transform: translate(-64px);
  transform: translate(-64px);
}

.rtl .vertical .page-main-header {
  margin-right: 0 !important;
  margin-left: unset !important;
}

.rtl .vertical-menu-mt .page-header div .pull-right {
  float: left;
}

.rtl .sm li {
  direction: rtl;
  text-align: right;
}

.rtl .vertical-menu-main #main-nav #main-menu li a svg {
  margin-left: 8px;
  margin-right: unset;
}

.rtl .pixelstrap a .sub-arrow {
  right: auto;
  left: 5px;
}

.rtl .pixelstrap a:hover,
.rtl .pixelstrap a:focus,
.rtl .pixelstrap a:active {
  padding-left: 25px;
  padding-right: unset;
}

.rtl .pixelstrap ul a .sub-arrow {
  left: 15px;
  right: unset;
}

@media (max-width: 1470px) {
  .rtl .edit-profile .profile-title .media .media-body {
    margin-right: unset;
  }
}

@media (min-width: 1200px) {
  .rtl .pixelstrap ul:not(.mega-menu) li a:before {
    left: unset;
    right: 20px;
  }

  .rtl .pixelstrap a .sub-arrow {
    right: unset;
    left: -6px;
  }

  .rtl .pixelstrap li {
    float: right;
  }

  .rtl .page-wrapper .mega-menu-header .vertical-menu-main {
    left: 390px;
    right: unset;
  }
}

.rtl .search-page .nav i {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .product-stts {
  display: inline-block;
}

.rtl .product-stts .ms-0 {
  margin-right: 0.25rem !important;
  margin-left: unset !important;
}

.rtl .search-form input {
  padding: 10px 70px 10px 10px;
  text-align: right;
}

.rtl .icon-hover-bottom .icon-title {
  text-align: right;
}

.rtl .icon-hover-bottom .icon-popup .icon-last .form-inline .form-group .btn {
  margin-right: 15px;
  margin-left: unset;
}

.rtl .icon-lists div i {
  margin: 0 10px 0 15px;
}

.rtl .icon-lists ul {
  padding-right: 0;
}

.rtl ul.icon-lists {
  padding-right: 30px;
}

.rtl .floated-customizer-btn.third-floated-btn {
  left: 35px;
  right: unset;
}

.rtl .floated-customizer-btn > span {
  margin-right: 10px;
}

.rtl .floated-customizer-panel {
  text-align: right;
  left: -85px;
  right: unset;
}

.rtl .floated-customizer-panel.active {
  left: 35px;
  right: unset;
}

.rtl .floated-customizer-panel .close-customizer-btn {
  left: 30px;
  right: unset;
}

.rtl .floated-customizer-panel ul {
  padding-right: 0;
}

.rtl .form-builder-2-header > div nav {
  float: left;
}

.rtl .radio label {
  padding-left: 0;
  padding-right: 15px;
}

.rtl .radio label::before {
  right: 0;
  margin-right: -20px;
  left: unset;
}

.rtl .page-wrapper .page-body-wrapper .page-header .row h3 {
  text-align: right;
}

.rtl .page-main-header {
  margin-right: 0;
  margin-left: unset;
}

.rtl .page-main-header .main-header-right .nav-right {
  text-align: left;
}

.rtl .page-main-header .main-header-right .nav-right .profile-dropdown li svg {
  margin-right: unset;
  margin-left: 10px;
}

.rtl .page-main-header .main-header-right .nav-right > ul > li:before {
  right: 0;
  left: unset;
}

.rtl .page-main-header .main-header-right .nav-right > ul > li .dot {
  left: 17px;
  right: unset;
}

.rtl
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li.onhover-dropdown:hover
  .onhover-show-div:before {
  right: 10px;
  left: unset;
}

.rtl
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li.onhover-dropdown:hover
  .onhover-show-div:after {
  right: 10px;
  left: unset;
}

.rtl .page-main-header .main-header-right .nav-right > ul > li i.ms-2 {
  margin-right: 0.5rem !important;
  margin-left: unset !important;
}

.rtl .page-main-header .main-header-right .nav-right .language-dropdown {
  text-align: right;
}

.rtl .page-main-header .main-header-right .nav-right .language-dropdown li a i {
  margin-left: 10px;
}

.rtl
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a
  svg {
  margin-right: unset;
  margin-left: 10px;
}

.rtl .page-main-header .main-header-right .nav-right .chat-dropdown {
  left: 0;
  right: unset;
}

.rtl .page-main-header .main-header-right .nav-right .notification-dropdown {
  left: -20px !important;
  right: unset !important;
}

.rtl .page-main-header .main-header-right .nav-right ul li:last-child {
  margin-left: 0;
  margin-right: unset;
}

.rtl .page-main-header .main-header-right .mobile-sidebar {
  padding-left: 20px;
  padding-right: unset;
}

.rtl .page-main-header.open {
  margin-right: 0;
}

.rtl ul.notification-dropdown.onhover-show-div {
  right: initial;
  left: -30px;
  padding: 0;
}

.rtl ul.notification-dropdown.onhover-show-div:before {
  right: inherit !important;
  left: 35px !important;
}

.rtl ul.notification-dropdown.onhover-show-div:after {
  right: inherit !important;
  left: 35px !important;
}

.rtl ul.notification-dropdown.onhover-show-div li {
  margin-right: 0 !important;
}

.rtl ul.notification-dropdown.onhover-show-div li span.badge {
  margin-left: unset !important;
}

.rtl ul.notification-dropdown.onhover-show-div li span svg {
  margin-right: unset;
}

.rtl ul.notification-dropdown.onhover-show-div li .notification-icon {
  margin-left: 20px;
  margin-right: unset;
}

.rtl .alert i {
  margin-left: 5px;
  margin-right: 0;
}

.rtl .inverse {
  padding: 13px 65px 13px 20px;
}

.rtl .inverse i {
  left: unset;
  right: 0;
}

.rtl .alert-primary.inverse:before {
  border-right: 7px solid #78266a;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-secondary.inverse:before {
  border-right: 7px solid #ba895d;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-success.inverse:before {
  border-right: 7px solid #1b4c43;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-danger.inverse:before {
  border-right: 7px solid #d22d3d;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-warning.inverse:before {
  border-right: 7px solid #e2c636;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-info.inverse:before {
  border-right: 7px solid #717171;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-light.inverse:before {
  border-right: 7px solid #e6edef;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-dark.inverse:before {
  border-right: 7px solid #2c323f;
  right: 54px;
  left: unset;
  border-left: unset;
}

.rtl .alert-dismissible .close {
  left: 0;
  right: unset;
}

.rtl .alert-dismissible .close span {
  padding-left: 0;
  padding-right: unset;
}

.rtl .icofont.icofont-truck {
  display: inline-block;
}

.rtl .job-search .media .media-body .job-apply-btn {
  left: 30px;
  right: unset;
}

.rtl .job-search .media ul.rating {
  margin-right: 10px;
  margin-left: unset;
}

.rtl .blog-box .blog-details {
  padding-left: 20px;
  padding-right: unset;
  text-align: right;
}

.rtl .blog-box .blog-details .blog-social {
  padding-right: 0;
}

.rtl .blog-box .blog-details .blog-social li:nth-child(n + 2) {
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #e6edef;
  border-left: none;
  padding-left: unset;
  margin-left: unset;
}

.rtl .blog-box .blog-details-main .blog-social li {
  border-left: 1px solid #e6edef;
  border-right: unset;
}

.rtl .blog-box .blog-details-main .blog-social li:last-child {
  border-left: none;
}

.rtl .blog-box .blog-date span {
  font-size: 36px;
  font-weight: 500;
  padding-left: 5px;
  padding-right: unset;
}

.rtl .blog-box.blog-shadow .blog-details {
  padding-right: 40px;
}

.rtl .blog-box .blog-details-second .detail-footer ul.sociyal-list li i {
  margin-left: 5px;
  margin-right: unset;
}

.rtl
  .blog-box
  .blog-details-second
  .detail-footer
  ul.sociyal-list
  li:nth-child(n + 2) {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #e6edef;
  margin-left: unset;
  padding-left: unset;
  border-left: unset;
}

.rtl .comment-box .media img {
  margin-left: 45px;
  margin-right: unset !important;
}

.rtl .comment-box .media h6 {
  text-align: right;
}

.rtl .comment-box ul ul {
  margin-right: 135px;
  margin-left: unset;
  padding-right: 0;
}

.rtl .comment-box .comment-social {
  text-align: left;
  margin-right: unset;
}

.rtl .comment-box .comment-social li:nth-child(n + 2) {
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #e6edef;
  margin-left: unset;
  padding-left: unset;
  border-left: unset;
}

.rtl .location-checkbox span {
  padding-right: 33px;
  padding-left: unset;
}

.rtl .support-ticket .pro-gress ul li span i {
  margin-right: 10px;
  margin-left: unset;
}

.rtl .bookmark-wrap .left-bookmark ul li button {
  text-align: right;
}

.rtl .bookmark-tabcontent .tab-content .card .card-header ul li + li {
  margin-right: 5px;
  margin-left: unset;
}

.rtl
  .bookmark-tabcontent
  .details-bookmark
  .bookmark-card.card
  .title-bookmark {
  text-align: right;
}

.rtl
  .bookmark-tabcontent
  .details-bookmark.list-bookmark
  .bookmark-card
  .details-website
  .title-bookmark
  .hover-block {
  left: 30px;
  right: unset;
}

.rtl .email-wrap .email-app-sidebar ul li a {
  padding-right: 20px;
  padding-left: unset;
}

.rtl .button-builder-wrap .form-group .btn {
  margin-left: 15px;
  margin-right: 0;
  padding: 9px;
}

.rtl .button-builder ul.the-icons {
  padding-right: 0 !important;
}

.rtl
  .call-chat-body
  .chat-box
  .chat-right-aside
  .chat
  .chat-history
  .caller-img.ps-0 {
  padding-left: 15px !important;
}

.rtl .chat-box .user-image {
  float: right;
  margin-left: 5px;
  margin-right: 0;
}

.rtl .chat-box .people-list .search i {
  left: 10px;
  right: unset;
}

.rtl .chat-box .about {
  float: right;
  padding-right: 10px;
  padding-left: unset;
  text-align: right;
}

.rtl .chat-box .chat-menu {
  left: 10px;
  right: unset;
}

.rtl .chat-box .chat-menu .nav {
  padding-right: 0;
}

.rtl .chat-box .chat-menu.ps-0 {
  padding-left: 15px !important;
}

.rtl .chat-box .chat-right-aside .chat .chat-message .smiley-box {
  margin-left: 0.5rem;
  margin-right: unset;
}

.rtl .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons {
  margin-left: unset;
  margin-right: 15px;
}

.rtl .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li {
  margin-right: unset;
  margin-left: 24px;
}

.rtl .chat-box .chat-right-aside.pe-0 {
  padding-right: 15px !important;
}

.rtl .chat-box .chat .chat-message .text-box .input-group-append {
  margin-right: -2px;
}

.rtl .chat-box .chat .chat-message .text-box .btn {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rtl .chat-msg-box ul {
  padding-right: 0;
}

.rtl .chat-left-aside .status-circle {
  left: unset;
  right: 40px;
}

.rtl .chat-right-aside .chat .chat-header .chat-menu-icons {
  padding-left: 0;
  padding-right: unset;
}

.rtl .chat-right-aside .chat .chat-header .chat-menu-icons.float-sm-right {
  float: left !important;
}

.rtl .chat-right-aside .chat .chat-header img {
  float: right;
}

.rtl .cke_toolbar,
.rtl .cke_toolgroup,
.rtl a.cke_button,
.rtl .cke_combo_text {
  float: right;
}

.rtl .cke_reset_all,
.rtl .cke_reset_all *,
.rtl .cke_reset_all a,
.rtl .cke_reset_all textarea {
  text-align: right;
}

.rtl .cke_combo_text {
  padding-left: unset;
  padding-right: 10px;
}

.rtl #editor1 #cke_editor1 #cke_1_contents iframe html {
  direction: rtl !important;
}

.rtl #clockdiv ul {
  padding-right: 0;
}

.rtl .custom-card .card-footer > div + div {
  border-right: 1px solid #efefef;
  border-left: unset;
}

.rtl .custom-card .card-social li:nth-child(n + 2) {
  margin-left: unset;
  margin-right: 10px;
}

.rtl .card-social {
  padding-right: 0;
}

.rtl .card-absolute .card-header {
  right: 15px;
  left: unset;
}

.rtl .dataTables_wrapper .dataTables_filter input[type="search"] {
  margin-right: 10px;
  margin-left: 0;
}

.rtl .dataTables_wrapper .dataTables_length {
  float: right;
}

.rtl .dataTables_wrapper .dataTables_filter {
  margin-left: unset;
  margin-right: 15px;
  float: left;
}

.rtl .dataTables_wrapper .dataTables_info {
  float: right;
}

.rtl .dataTables_wrapper .advance-5_filter {
  margin-left: 0;
}

.rtl .dataTables_wrapper .btn-group button {
  margin-left: 5px;
  margin-right: unset;
}

.rtl .dataTables_wrapper .btn-group button:last-child {
  margin-left: 0;
}

.rtl div.dataTables_wrapper div.dataTables_paginate {
  margin-right: 15px !important;
  margin-left: 0 !important;
  float: left;
}

.rtl table.dataTable thead > tr > th {
  padding-left: 30px;
  padding-right: 12px;
}

.rtl table.dataTable thead > tr > th:before {
  left: 1em !important;
  right: unset;
}

.rtl table.dataTable thead > tr > th:after {
  left: 0.5em !important;
  right: unset;
}

.rtl table.dataTable thead .sorting:before,
.rtl table.dataTable thead .sorting_asc:before,
.rtl table.dataTable thead .sorting_desc:before,
.rtl table.dataTable thead .sorting_asc_disabled:before,
.rtl table.dataTable thead .sorting_desc_disabled:before {
  left: 1em !important;
  right: unset;
}

.rtl .dataTables_scrollHeadInner {
  padding-right: unset !important;
  padding-left: 0 !important;
}

.rtl
  div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:first-child {
  padding-right: 0;
  padding-left: 0;
}

.rtl
  div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:last-child {
  padding-right: 0;
  padding-left: 0;
}

.rtl .dt-plugin-buttons button.m-r-10 {
  margin-left: 10px;
  margin-right: 0;
}

.rtl .datetime-picker .theme-form .form-group label {
  text-align: left !important;
}

.rtl .datetime-picker .theme-form .form-group .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .datetime-picker .theme-form .form-group .input-group .form-control {
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rtl .dropdown-basic .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rtl .dropdown-basic .separated-btn .btn {
  border-radius: 0.25rem;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rtl .error-wrapper .col-md-8.offset-md-2 {
  margin: 0 auto !important;
}

.rtl .product-price del {
  margin-right: 20px;
  margin-left: unset;
}

.rtl .product-box .ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
.rtl .product-box .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
  border-left-color: #007bff;
}

.rtl .product-box .ribbon-bookmark.ribbon-vertical-left:before,
.rtl .product-box .ribbon-bookmark.ribbon-vertical-right:before {
  right: 0;
  left: unset;
  border-left: 15px solid #2a3142;
}

.rtl .product-box .ribbon-bookmark.ribbon-info:before {
  border-left-color: transparent;
}

.rtl .product-box .ribbon-warning.ribbon-clip:before {
  border-left-color: #ff850d;
  border-right-color: unset;
}

.rtl .product-box .ribbon-clip {
  right: -14px;
  left: unset;
}

.rtl .product-box .ribbon-clip:before {
  right: 0;
  left: unset;
}

.rtl .product-hover ul {
  padding-right: 0;
}

.rtl .customer-review .media img {
  margin-left: 25px;
  margin-right: 0;
}

.rtl .nav {
  padding-right: 0;
}

.rtl .custom-file-label::after {
  left: 0;
  right: unset;
}

.rtl .wizard-4 ul.anchor {
  float: right;
  padding-right: 0;
  padding-left: 30px;
}

.rtl .wizard-4 .step-container {
  clear: unset;
}

.rtl .wizard-4 .form-group {
  text-align: right;
}

.rtl .wizard-4 .action-bar .btn {
  float: left;
}

.rtl .f1 .f1-buttons {
  text-align: left;
}

.rtl .f1 .f1-steps .f1-step {
  float: right;
}

.rtl .static-top-widget .media-body {
  padding-right: 30px;
  padding-left: unset;
}

.rtl .static-top-widget .media-body .icon-bg {
  left: -20px;
  right: unset;
}

.rtl .widget-joins .media .details {
  border-right: 1px solid #e6edef;
  border-left: unset;
}

.rtl .widget-joins .media .media-body {
  text-align: right;
}

.rtl .weather-widget-two .bottom-whetherinfo .whether-content {
  text-align: left;
}

.rtl .jstree-default .jstree-node {
  margin-left: unset;
  margin-right: 24px;
  background-position: -292px -4px;
  background-repeat: repeat-y;
}

.rtl .img-cropper .btn-group {
  margin-right: 0;
  margin-left: 10px;
}

.rtl .img-cropper .btn-group button:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rtl .img-cropper .btn-group button:first-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rtl .docs-toggles .btn-group .btn {
  border-radius: 0;
}

.rtl .docs-toggles .btn-group .btn:last-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rtl .docs-toggles .btn-group .btn:first-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rtl
  .btn-group
  > .btn:not(:last-child):not(.dropdown-toggle)
  > .btn-group:not(:last-child)
  > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .jsgrid-button + .jsgrid-button {
  margin-right: 5px;
  margin-left: unset;
}

.rtl .list-group {
  padding-right: 0;
}

.rtl .list-group i,
.rtl .list-group img {
  margin-left: 10px;
  margin-right: 0;
}

.rtl .authentication-box {
  text-align: right;
}

.rtl .mega-horizontal .offset-sm-3 {
  margin-right: 25%;
  margin-left: unset;
}

.rtl .navs-icon svg {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .navs-icon .fa {
  margin-left: 10px;
  margin-right: unset;
}

.rtl .nav-list .nav-list-disc {
  text-align: right;
  padding-right: 0;
}

.rtl .pl-navs-inline {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.rtl .onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
  left: 77px;
  right: unset;
}

.rtl .onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
  left: 77px;
  right: unset;
}

.rtl .navs-dropdown .onhover-show-div .navs-icon li {
  text-align: right;
}

.rtl
  .navs-icon.default-according.style-1
  li
  button[aria-expanded="true"]:before {
  left: 2px;
  right: unset;
}

.rtl
  .navs-icon.default-according.style-1
  li
  button[aria-expanded="false"]:before {
  left: 2px;
  right: unset;
}

.rtl .search-form .form-group:after {
  right: 53px;
  left: unset;
}

.rtl .search-form .form-group:before {
  right: 82px;
  left: unset;
}

.rtl
  .order-history
  table
  tbody
  tr
  td
  .product-name
  .order-process
  .order-process-circle:before {
  right: -15px;
  left: unset;
}

.rtl .order-history table th:first-child,
.rtl .order-history table td:first-child {
  text-align: right;
}

.rtl .product-page-details span {
  padding-right: 15px;
  padding-left: 0;
}

.rtl .m-checkbox-inline label {
  margin-left: 20px;
  margin-right: 0;
}

.rtl .form-label-align-right label {
  text-align: left;
}

.rtl .br-theme-bars-reversed .br-widget .br-current-rating,
.rtl .br-theme-bars-movie .br-widget .br-current-rating,
.rtl .br-theme-bars-1to10 .br-widget .br-current-rating,
.rtl .br-theme-bars-square .br-widget .br-current-rating {
  float: right;
}

.rtl .br-theme-bars-reversed .br-widget a,
.rtl .br-theme-bars-movie .br-widget a,
.rtl .br-theme-bars-1to10 .br-widget a,
.rtl .br-theme-bars-square .br-widget a {
  float: right;
}

.rtl .ribbon-left {
  right: auto;
  left: -2px;
}

.rtl .ribbon-vertical-left {
  right: auto;
  left: 12px;
}

.rtl .ribbon-vertical-right {
  right: auto;
  left: 12px;
}

.rtl .search-page ul.search-info > li + li {
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid #e6edef;
  padding-left: unset;
  margin-left: unset;
  border-left: unset;
}

.rtl
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  left: 10px !important;
  right: unset !important;
}

.rtl .editor-statusbar {
  text-align: left;
}

.rtl .editor-statusbar span {
  margin-right: 1em;
  margin-left: unset;
}

.rtl .CodeMirror-scroll {
  margin-left: -30px;
  margin-right: unset;
}

.rtl .primary-color ul,
.rtl .yellow-color ul {
  padding-right: 0;
}

.rtl .u-step-desc {
  text-align: right;
}

.rtl .u-step-number {
  right: 20px;
  left: unset;
}

.rtl .u-step-number ~ .u-step-desc {
  margin-right: 50px;
}

.rtl .u-pearl:after {
  left: 0;
  right: unset;
}

.rtl .u-pearl:before {
  right: 0;
  left: unset;
}

.rtl .u-step-icon {
  float: right;
  margin-left: 0.5em;
  margin-right: unset;
}

.rtl .list-icons {
  padding-right: 0;
}

.rtl .card-header.note-toolbar .note-color .dropdown-toggle {
  padding-right: 5px;
}

.rtl .card-header.note-toolbar .form-group.draggable {
  text-align: right;
}

.rtl .card-header.note-toolbar .form-check {
  padding-right: 24px;
  padding-left: unset;
}

.rtl .card-header.note-toolbar form .custom-control {
  padding-right: 24px;
  padding-left: unset;
}

.rtl
  .card-header.note-toolbar
  form
  .custom-control
  .custom-control-label::after {
  right: 0;
  left: unset;
}

.rtl
  .card-header.note-toolbar
  form
  .custom-control
  .custom-control-label::before {
  right: 0;
  left: unset;
}

.rtl .dropdown-toggle::after {
  margin-right: 0.255em;
  margin-left: unset;
}

.rtl .tabbed-card ul {
  left: 15px;
  right: unset;
}

.rtl .checkbox label {
  padding-right: 16px;
  padding-left: unset;
}

.rtl .checkbox label::before {
  right: 0;
  left: unset;
  margin-right: -16px;
  margin-left: unset;
}

.rtl .radio label::after {
  right: 5px;
  left: unset;
  margin-right: -20px;
  margin-left: unset;
}

.rtl .cd-timeline-content::before {
  border-right: 7px solid #e6edef;
  border-left-color: transparent;
}

.rtl .cd-timeline-img i {
  right: 40%;
  left: unset;
  margin-right: -12px;
  margin-left: unset;
}

.rtl .cd-timeline-block:nth-child(even) .cd-timeline-content {
  float: left;
  border-left: 7px solid #eeeeee;
  border-right: unset;
}

.rtl .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
  right: auto;
  left: 122%;
  text-align: left;
}

.rtl .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
  right: auto;
  left: 100%;
  border-left-color: #eeeeee;
  border-right-color: transparent;
}

.rtl .cd-timeline-content .cd-date {
  right: 122%;
  left: unset;
}

.rtl .introjs-tooltip {
  right: 0;
  left: unset;
}

.rtl .introjs-helperNumberLayer {
  right: -16px;
  left: unset;
}

.rtl .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltiptext {
  text-align: right;
}

.rtl .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons {
  text-align: left;
}

.rtl
  .introjs-tooltipReferenceLayer
  .introjs-tooltip
  .introjs-tooltipbuttons
  .introjs-skipbutton {
  margin-left: 5px;
  margin-right: unset;
}

.rtl
  .introjs-tooltipReferenceLayer
  .introjs-tooltip
  .introjs-tooltipbuttons
  .introjs-nextbutton {
  border-radius: 0.2em 0 0 0.2em;
}

.rtl .list-inline-item:not(:last-child) {
  margin-left: 0.5rem;
  margin-right: unset;
}

.rtl .like-comment ul.list-inline {
  padding-right: 0;
}

.rtl .typeahead {
  text-align: right;
}

.rtl .listing ul {
  padding-right: 0;
}

.rtl .listing ul .icofont {
  float: right;
}

.rtl .gallery .hover-5 img {
  margin-right: 30px;
}

.rtl .gallery .hover-5:hover img {
  margin-right: 0;
}

.rtl .lg-outer {
  text-align: right;
}

.rtl .lg-toolbar .lg-icon {
  float: left;
}

.rtl #lg-counter {
  padding-right: 20px;
  padding-left: unset;
  float: right;
}

.rtl .chart-widget-top .text-end {
  text-align: left !important;
}

.rtl .chart-widget-top .num .ms-0 {
  margin-right: 0.25rem !important;
}

.rtl .bar-chart-widget .earning-details i {
  left: -240px;
}

.rtl .bar-chart-widget .bottom-content .b-r-light {
  border-left: 1px solid #eeeeee !important;
  border-right: unset !important;
}

.rtl .bar-chart-widget .bottom-content .num .ms-0 {
  margin-right: 0.25rem !important;
}

.rtl .chart-widget .chart-widget-top .num i {
  margin-left: unset;
  margin-right: 5px;
}

.rtl .chart-vertical-center #myDoughnutGraph,
.rtl .chart-vertical-center #myPolarGraph {
  width: auto !important;
}

.rtl .crm-activity ul.dates li + li {
  border-right: 1px solid #ddd;
  padding-right: 10px;
  margin-right: 5px;
  border-left: unset;
  padding-left: unset;
  margin-left: unset;
}

.rtl div.dt-buttons {
  float: right;
}

.rtl .dt-button-collection {
  left: -39px !important;
}

.rtl .dropzone .dz-preview .dz-error-mark,
.rtl .dropzone .dz-preview .dz-success-mark {
  right: 50%;
  margin-right: -27px;
  margin-left: unset;
  left: unset;
}

.rtl .page-wrapper .page-body-wrapper footer {
  margin-right: 275px;
  margin-left: unset;
}

.rtl .page-wrapper .page-body-wrapper footer p i {
  margin-right: 5px;
  margin-left: unset;
}

.rtl .footer-copyright {
  text-align: right;
}

.rtl .footer-links {
  text-align: left;
}

.rtl .page-item:first-child .page-link {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rtl .page-item:last-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .popover.top > .arrow:after,
.rtl .popover.bottom > .arrow:after {
  margin-right: -10px;
  margin-left: unset;
}

.rtl .bootstrap-touchspin > .input-group-append > .btn,
.rtl .bootstrap-touchspin > .input-group-append > .input-group-text {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rtl .bootstrap-touchspin > .form-control:not(:last-child),
.rtl .bootstrap-touchspin > .custom-select:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .typeahead span.twitter-typeahead .tt-suggestion {
  text-align: right;
}

.rtl .typeahead .league-name {
  text-align: right;
  margin-right: 5px;
}

.rtl .follow .text-md-end {
  text-align: left !important;
}

.rtl .jvectormap-zoomin,
.rtl .jvectormap-zoomout,
.rtl .jvectormap-goback {
  right: 10px;
  left: unset;
}

.rtl .customizer-links {
  right: unset;
  left: 0;
}

.rtl .customizer-links.open {
  right: unset;
  left: 330px;
  border-radius: 0 8px 8px 0;
}

.rtl .customizer-contain {
  width: 330px;
  right: unset;
  left: -330px;
}

.rtl .customizer-contain.open {
  right: unset;
  left: 0px;
}

.rtl .customizer-contain .customizer-header .icon-close {
  left: unset;
  right: 30px;
}

.rtl.dark-only .page-main-header .main-header-right .nav-right > ul > li {
  border-right: 1px solid #202938;
  border-left: unset !important;
}

.rtl.dark-only
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:first-child,
.rtl.dark-only
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:nth-child(5) {
  border-right: none !important;
}

.rtl.dark-only
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:nth-child(5) {
  border-left: 1px solid #202938 !important;
}

.rtl .box-layout.page-wrapper.horizontal-wrapper .page-main-header {
  padding-right: 250px;
  padding-left: unset;
}

.rtl .box-layout.page-wrapper.horizontal-wrapper .page-main-header.open {
  padding-right: 0;
}

@media screen and (max-width: 1660px) {
  .rtl .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1660px) {
  .rtl .latest-articles .card-body .media .m-r-30 {
    margin-right: unset;
    margin-left: 10px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .rtl .activity-sec .activity-timeline.update-line .media .activity-line {
    right: 26px;
  }

  .rtl .blog-box.blog-shadow .blog-details {
    padding-right: 15px;
  }

  .rtl .blog-box .blog-details .blog-social li:nth-child(n + 2) {
    margin-right: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .rtl .learning-comment {
    float: right !important;
  }

  .rtl .comment-box .media img {
    margin-left: 25px;
  }

  .rtl .comment-box ul ul {
    margin-right: 50px;
  }
}

@media only screen and (max-width: 1366px) {
  .rtl .product-wrapper .product-grid .feature-products {
    margin-right: 0;
  }

  .rtl .product-grid {
    margin-right: 0;
  }

  .rtl .d-none-productlist {
    margin-left: 10px;
    margin-right: unset;
  }

  .rtl .chat-left-aside .status-circle {
    left: unset;
    right: 40px;
  }

  .rtl .status-circle {
    right: 37px;
    left: unset;
  }

  .rtl .file-content .files .file-box {
    margin-left: 10px;
    margin-right: unset;
  }

  .rtl .lnb-calendars {
    text-align: left;
  }
}

@media (max-width: 1280px) {
  .rtl .page-main-header .main-header-right .nav-right > ul > li {
    margin-left: 15px;
  }

  .rtl .user-profile .social-tab ul li:nth-child(n + 2) {
    margin-right: 10px;
    margin-left: 0;
  }

  .rtl .email-wrap .actions li:nth-child(n + 2) {
    margin-right: 10px;
  }
}

@media (min-width: 1200px) {
  .rtl .pixelstrap ul a:hover .sub-arrow,
  .rtl .pixelstrap ul a:focus .sub-arrow,
  .rtl .pixelstrap ul a:active .sub-arrow,
  .rtl .pixelstrap ul a.highlighted .sub-arrow {
    left: 3px;
    right: unset;
  }
}

@media only screen and (max-width: 1199px) {
  .rtl
    .page-wrapper
    .page-main-header
    .main-header-right
    .nav-right
    > ul
    li
    .submenu
    li:first-child {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .rtl .page-wrapper .page-main-header .main-header-right .nav-right > ul > li {
    margin-left: 15px;
  }

  .rtl .page-wrapper.modern-sidebar.compact-wrapper footer {
    margin-left: unset;
    margin-right: 280px;
  }

  .rtl
    .page-wrapper.modern-sidebar.compact-wrapper
    header.main-nav.close_icon
    ~ footer {
    margin-right: unset;
  }

  .rtl .email-wrap .email-app-sidebar .main-menu > li {
    text-align: right;
  }

  .rtl .email-wrap .email-app-sidebar .main-menu > li a i {
    margin-right: unset;
    margin-left: 10px;
  }

  .rtl .email-wrap .email-app-sidebar ul {
    padding-right: 0;
  }

  .rtl .email-wrap .email-content .email-top .user-emailid:after {
    float: left;
  }

  .rtl .email-wrap .email-sidebar .email-left-aside {
    right: 0;
    left: unset;
  }

  .rtl .chat-menu-icons {
    text-align: left;
  }

  .rtl .chat-box .chat-history .row .col-sm-7 {
    padding-left: 0 !important;
    padding-right: unset !important;
  }

  .rtl .chat-menu {
    left: 0;
    right: unset;
  }

  .rtl .invo-profile .text-xl-end {
    text-align: right !important;
  }

  .rtl .blog-box .blog-details {
    padding: 20px;
  }

  .rtl .blog-box.blog-shadow .blog-details {
    padding-right: 20px;
  }

  .rtl .blog-single .comment-box ul .comment-social {
    margin-right: unset;
  }

  .rtl .job-sidebar .job-left-aside {
    right: 0;
    left: unset;
  }
}

@media only screen and (max-width: 991px) {
  .rtl .faq-accordion .faq-header svg {
    left: 20px;
  }

  .rtl .categories .learning-header {
    padding-right: 20px;
  }

  .rtl .page-wrapper .page-main-header .main-header-left .logo-wrapper img {
    margin-left: 15px;
    margin-right: unset;
  }

  .rtl .page-wrapper .page-main-header .main-header-right > .mobile-toggle {
    padding-left: 20px;
    padding-right: unset;
  }

  .rtl .main .langChoice {
    left: 20px;
  }

  .rtl .cd-timeline-img i {
    right: 50% !important;
  }

  .rtl .page-wrapper .page-body-wrapper .page-header .breadcrumb {
    float: right;
    margin-right: 0;
  }

  .rtl .page-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 60px);
  }

  .rtl .page-wrapper .page-main-header {
    margin-right: 0;
  }

  .rtl .page-wrapper .page-main-header .main-header-right > .mobile-toggle {
    padding-left: 15px;
  }

  .rtl .card-body .value-left {
    margin-left: 20px;
  }

  .rtl .activity-sec .activity-timeline .media .activity-line {
    right: 26px;
  }

  .rtl .comment-box .comment-social li:nth-child(n + 2) {
    margin-right: 15px;
    padding-right: 15px;
  }

  .rtl .page-body-wrapper .page-body,
  .rtl .page-body-wrapper footer {
    margin-right: 0 !important;
    margin-left: unset !important;
  }

  .rtl
    .page-wrapper.compact-wrapper
    .page-body-wrapper.sidebar-icon
    header.main-nav.close_icon {
    -webkit-transform: translate(290px);
    transform: translate(290px);
  }

  .rtl .updates-faq {
    margin-left: 20px;
  }
}

@media (min-width: 768px) {
  .rtl .offset-md-2 {
    margin-right: 16.66667%;
  }

  .rtl .float-md-right {
    float: left !important;
  }

  .rtl .text-md-end {
    text-align: left !important;
  }

  .rtl .offset-md-3 {
    margin-right: 25%;
    margin-left: unset;
  }

  .rtl .text-md-start {
    text-align: right !important;
  }

  .rtl .ecommerce-widget .text-md-end {
    text-align: left !important;
  }

  .rtl .ecommerce-widget .text-md-end ul {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .rtl .ecommerce-widget .text-md-end {
    text-align: left !important;
  }

  .rtl .bar-chart-widget .earning-details i {
    left: -147px !important;
  }

  .rtl .footer .pull-right {
    float: unset;
  }

  .rtl
    .page-wrapper
    .page-main-header
    .main-header-right
    .main-header-left
    .toggle-sidebar {
    margin-left: unset;
    margin-right: 20px;
  }

  .rtl .comment-box ul ul {
    text-align: right;
    float: revert;
    margin-right: 0;
  }

  .rtl .blog-single .comment-box ul .comment-social {
    text-align: right;
  }

  .rtl .project-list .btn {
    float: right;
  }

  .rtl .follow .text-md-end {
    text-align: center !important;
  }

  .rtl .calendar-basic #menu-navi .menu-navi-center {
    left: 20px;
    right: unset;
  }

  .rtl .calendar-basic #menu-navi .menu-navi-right .move-btn {
    float: left;
  }

  .rtl .feature-products span.f-w-600 {
    margin-right: 5px;
    margin-left: unset;
  }
}

@media (min-width: 576px) {
  .rtl .offset-sm-3 {
    margin-right: 25%;
    margin-left: unset;
  }

  .rtl .text-sm-end {
    text-align: left !important;
  }

  .rtl .float-sm-right {
    float: left !important;
  }

  .rtl .mr-sm-2,
  .rtl .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
}

@media only screen and (max-width: 575px) {
  .rtl .page-main-header .main-header-right .nav-right > ul > li:last-child {
    padding-right: 10px;
  }

  .rtl .page-main-header .main-header-right .nav-right .notification-dropdown {
    right: -150px !important;
    left: unset !important;
  }

  .rtl .page-main-header .main-header-right .nav-right .profile-dropdown {
    right: -50px !important;
    left: unset !important;
  }

  .rtl .faq-accordion .faq-header svg {
    left: 15px;
  }

  .rtl .categories .learning-header {
    padding-right: 15px;
  }

  .rtl .main .langChoice {
    left: 15px;
  }

  .rtl .page-wrapper .search-form .form-group {
    margin-left: 0;
  }

  .rtl .card-body .value-left {
    margin-left: 15px;
  }

  .rtl .job-search .media .media-body h6 .pull-right {
    margin-right: 15px;
    margin-left: unset;
  }

  .rtl .invoice .invo-header .media-body {
    text-align: left;
  }

  .rtl .blog-box .blog-details .blog-social li:nth-child(n + 2) {
    margin-right: 15px;
    padding-right: unset;
    border-right: none;
  }

  .rtl .feature-products .select-options {
    float: right;
  }
}

@media only screen and (max-width: 575px) {
  .rtl .page-wrapper .page-body-wrapper .page-header .bookmark {
    text-align: right;
  }

  .rtl .feature-products .text-end {
    text-align: right !important;
  }

  .rtl .list-persons .nav-pills .nav-link .media .media-body {
    text-align: right;
  }

  .rtl .list-persons .profile-mail .email-general ul {
    padding-left: unset;
  }

  .rtl .contacts-tabs .media img.m-r-20 {
    margin-right: unset;
    margin-left: 20px;
  }

  .rtl .faq-widgets svg {
    left: 20px;
    right: unset;
  }

  .rtl .m-r-30 {
    margin-left: 15px;
  }

  .rtl .job-search .job-description label {
    text-align: right !important;
  }

  .rtl
    .user-profile
    .profile-post
    .post-body
    .post-react
    ul
    li:nth-child(n + 2) {
    margin-right: -15px;
  }

  .rtl .user-profile .profile-post .post-body .post-react h6 {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 420px) {
  .rtl .project-box .badge {
    left: 10px;
  }

  .rtl .file-content .files .file-box {
    margin-left: unset;
  }

  .rtl .project-list .border-tab.nav-tabs .nav-item .nav-link {
    padding: 5px 0 5px 15px;
  }
}

@media only screen and (max-width: 360px) {
  .rtl .social-app-profile .hovercard .user-image .avatar {
    margin-top: -47px;
  }

  .rtl .social-app-profile .hovercard .user-image .avatar img {
    width: 85px;
    height: 85px;
  }

  .rtl .social-chat .other-msg {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 480px) {
  .rtl .page-wrapper .page-main-header .main-header-right .nav-right > ul li {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 360px) {
  .rtl .footer {
    padding-left: 0;
    padding-right: 0 !important;
  }
}

.footer .ftr-contact a:first-child {
  border-right: 1px solid #d7d7d7;
  padding-right: 20px;
}

.footer .ftr-contact a {
  font-weight: 600;
  color: #78266a;
  margin-left: 20px;
}

.sidebar-user a {
  color: #fdfdfd;
  text-decoration: none;
}

.dashboard-ico,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link.active
  .dashboard-ico,
.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link:hover
  .dashboard-ico {
  background-image: url(../images/svg/dark/attendence.svg);
}

.menu-ico {
  background-repeat: no-repeat;
  width: auto;
  height: 18px;
}

.menu-ico span {
  font-weight: 600;
  margin-left: 35px;
}

.edsys-check-in .clock {
  margin: 37px auto;
  color: #fff;
}

.edsys-check-in .clock h1 {
  font-size: 16px;
  text-align: center;
  text-shadow: 0 0 5px #874b83;
  margin: 0;
}

.edsys-check-in ul {
  margin: 0 auto;
  padding: 0px;
  list-style: none;
  text-align: center;
}

.edsys-check-in time {
  display: inline;
  font-size: 20px;
  text-align: center;
}

/* .edsys-check-in #point {
	position:relative;
	-moz-animation:mymove 1s ease infinite;
	-webkit-animation:mymove 1s ease infinite;
	padding-left:10px; padding-right:10px;
  }
  
  @-webkit-keyframes mymove 
  {
  0% {opacity:1.0; text-shadow:0 0 20px #00c6ff;}
  50% {opacity:0; text-shadow:none; }
  100% {opacity:1.0; text-shadow:0 0 20px #00c6ff; }    
  }
  
  @-moz-keyframes mymove 
  {
  0% {opacity:1.0; text-shadow:0 0 20px #00c6ff;}
  50% {opacity:0; text-shadow:none; }
  100% {opacity:1.0; text-shadow:0 0 20px #00c6ff; }    
  } */
.cico i {
  font-size: 35px;
}

.cico.red i {
  color: #e76d6d;
}

.cico.purple i {
  color: #73276b;
}

.cico.yellow i {
  color: #ef8400;
}

.cico.blue i {
  color: #16bece;
}

.cico.grey i {
  color: #5f8295;
}

.cico.green i {
  color: #1fbca7;
}

.cico.dark-blue i {
  color: #18419b;
}

.my-status .card {
  margin-bottom: 30px;
  border: 1px solid #e6edef;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 15px;
  background-color: #fff;
  max-height: 178.4px;
}

.my-status .card .card-body {
  background-color: transparent;
  height: 165px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.media-body h1 {
  font-size: 14px;
  font-weight: 400;
  padding: 0 20px 0 0;
}

.subject-sec .container {
  max-width: 100%;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 0 23px 0;
}

.subject-sec .main .scroll {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  cursor: url(../images/dashboard/scroll.png), auto;
  overflow: scroll hidden;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  scroll-padding: 0px 1.25rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.subject-sec .main .scroll .swiper-wrapper {
  transform: translate3d(0px, 0px, 0px);
}

.subject-sec .main .scroll::-webkit-scrollbar {
  display: none;
}

.subject-sec .main .scroll .scroll-container {
  position: relative;
  display: grid;
  grid-template-columns: 125px 250px;
  border-radius: 10px;
  padding: 5px;
  margin-right: 15px;
  border-radius: 15px;
}

.subject-sec .main .scroll .scroll-container .head-block {
  width: 123px;
  height: 135px;
  padding: 5px 10px;
  border-radius: 9px;
  cursor: pointer;
}

/* Subject-1 Start*/
.subject-sec .main .scroll .scroll-container.sub-1 {
  background-color: #fecfc461;
  border: 2px solid transparent;
}

.subject-sec .main .scroll .scroll-container.sub-1:hover {
  border: 2px solid #ffbeaf;
}

.subject-sec .main .scroll .scroll-container.sub-1 .head-block {
  background-color: #ffcfc4;
  text-align: center;
}

/* Subject-1 End*/
/* Subject-2 Start*/
.subject-sec .main .scroll .scroll-container.sub-2 {
  background-color: #a7beff61;
  border: 2px solid transparent;
}

.subject-sec .main .scroll .scroll-container.sub-2:hover {
  border: 2px solid #a7beff;
}

.subject-sec .main .scroll .scroll-container.sub-2 .head-block {
  background-color: #a7beff;
  text-align: center;
}

/* Subject-2 End*/
/* Subject-3 Start*/
.subject-sec .main .scroll .scroll-container.sub-3 {
  background-color: #e2ccff61;
  border: 2px solid transparent;
}

.subject-sec .main .scroll .scroll-container.sub-3:hover {
  border: 2px solid #e2ccff;
}

.subject-sec .main .scroll .scroll-container.sub-3 .head-block {
  background-color: #e2ccff;
  text-align: center;
}

/* Subject-3 End*/
.subject-sec .main .scroll .scroll-container__text {
  padding: 25px 15px 0;
}

.subject-sec .main .scroll .scroll-container .head-block .class {
  color: #772569;
  font-size: 55px;
  font-weight: bold;
}

.subject-sec .main .scroll .scroll-container .head-block .class.blw-10 {
  padding: 0 0px 0 15px;
}

.subject-sec .main .scroll .scroll-container .head-block .class.blw-10 span {
  left: -49px;
}

.subject-sec .main .scroll .scroll-container .head-block .class span {
  color: #ffffff;
  text-shadow: 3px 3px 3px #00000029;
  font-size: 19px;
  font-weight: bold;
  position: relative;
  left: -57px;
}

.subject-sec .main .scroll .scroll-container .head-block .period {
  color: #051b26;
  font-size: 12px;
}

.subject-sec .main .scroll .scroll-container .head-block .period span {
  font-size: 16px;
  font-weight: 600;
}

.subject-sec .main .scroll .scroll-container__text h5 {
  color: #051b26;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.subject-sec .main .scroll .scroll-container__text h6 {
  display: initial;
  color: #051b26;
  background-color: #bddd25;
  font-size: 12px;
  font-weight: 400;
  padding: 3px 10px;
  border-radius: 5px;
}

.subject-sec .main .scroll .scroll-container__text p {
  color: #772569;
  font-size: 12px;
  font-weight: 400;
}

.subject-sec .main .scroll .scroll-container__text .video-conf svg {
  position: relative;
  right: -9px;
  top: -21px;
  stroke: #772569;
  fill: #772569;
  cursor: pointer;
}

.subject-sec .main .scroll .scroll-container__text .detail i {
  position: relative;
  right: -9px;
  top: 13px;
  cursor: pointer;
  font-size: 20px;
  color: #73276b;
}

.subject-sec .main .scroll .scroll-container__text .detail {
  text-align: end;
  cursor: pointer;
}

.edsys-sidebar {
  height: 350px;
  /* height:100%; */
  width: 300px;
  right: 0;
  bottom: 0;
  position: fixed !important;
  z-index: 999;
  overflow: auto;
  border-radius: 15px 0 0 0;
}

.edsys-sidebar.subject-1 {
  background-color: #fecfc4;
  border: 2px solid #ffbeaf;
}

.edsys-sidebar.subject-2 {
  background-color: #d4deff;
  border: 2px solid #90a6e5;
}

.edsys-sidebar.subject-3 {
  background-color: #e9e5fe;
  border: 2px solid #cdb3ef;
}

.edsys-animate-right {
  position: relative;
  animation: animateright 0.4s;
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

.edsys-card,
.edsys-card-2 {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.edsys-bar-block .edsys-item {
  width: 100%;
  display: block;
  padding: 8px 16px;
  text-align: left;
  border: none;
  white-space: normal;
  float: none;
  outline: 0;
}

.edsys-large {
  font-size: 18px !important;
}

.edsys-bar-block .title {
  display: block;
  color: #051b26;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.edsys-sidebar.card {
  margin-bottom: 0;
}

.edsys-sidebar.card .card-body {
  padding: 12px;
}

table.lesson-table {
  margin-top: 25px;
  margin-bottom: 40px;
  border: 1px solid #0000000d;
}

.lesson-table img {
  width: 27px;
  height: auto;
}

table.lesson-table td {
  padding: 7px 5px;
  font-size: 14px;
}

table.lesson-table td:last-child {
  padding: 3px 7px 3px 96px;
  border-top-right-radius: 10px;
}

table.lesson-table tr {
  border-bottom: 1px solid #0000000d;
}

table.lesson-table td span {
  color: #772569;
  font-weight: 500;
  margin-left: 15px;
}

.edsys-sidebar .ignore-btn {
  border: 2px solid #949fa6;
  border-radius: 20px;
  padding: 6px 6px 3px 6px;
  text-align: center;
  width: 87px;
  height: 40px;
  color: #7f8a91;
  margin: 0px 96px 0px 0px;
  cursor: pointer;
}

.edsys-sidebar .cancel-btn {
  border: 2px solid #ff7979;
  border-radius: 50%;
  padding: 6px 6px 3px 6px;
  width: 40px;
  height: 40px;
  color: #ff7979;
  margin-right: 10px;
  cursor: pointer;
}

.edsys-sidebar .proceed-btn {
  border: 2px solid #bddd25;
  background-color: #bddd25;
  border-radius: 50%;
  padding: 6px 6px 3px 6px;
  width: 40px;
  height: 40px;
  color: #fff;
  margin: 0px;
  cursor: pointer;
}

.edsys-sidebar .ignore-btn:hover {
  border: 2px solid #949fa6;
  color: #fff;
  background-color: #949fa6;
}

.edsys-sidebar .cancel-btn:hover {
  border: 2px solid #ff7979;
  color: #fff;
  background-color: #ff7979;
}

.edsys-sidebar .proceed-btn:hover {
  border: 2px solid #94ad1c;
  background-color: transparent;
  color: #94ad1c;
}

.check-in-block .check-in,
.check-in-block .check-out {
  color: #e6edef;
  background: #ffffff42;
  padding: 4px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
}

.my-status .goto {
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.edsys-table .table-responsive {
  overflow-x: auto;
  padding: 30px 30px 30px 30px;
  -webkit-overflow-scrolling: touch;
}

.edsys-table .card,
.edsys-table .card-header {
  border-radius: 15px;
}

.edsys-table .table thead tr {
  background-color: #73276b14;
}

.edsys-table .table thead tr th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.edsys-table .table thead tr th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.edsys-table .table th {
  color: #242934;
  border: none;
}

.edsys-table .table td {
  color: #242934;
  border: none;
  text-align: center;
}

.edsys-table .table td:nth-child(2) {
  text-align: start;
}

.edsys-table .table tr th {
  text-align: center;
}

.edsys-table .table thead tr th:nth-child(2) {
  text-align: start;
}

.video-container {
  position: relative;
  text-align: center;
  color: white;
}

.video-container img.user-video-bg {
  width: 100%;
  height: auto;
  border-radius: 20px;
  border: 3px solid #ffffff38;
}

.video-container .top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

.video-container .top-left .duration {
  text-align: start;
  margin-left: 36px;
  font-size: 14px;
  letter-spacing: 1px;
  display: table;
  position: relative;
  left: -5px;
  top: -11px;
}

.video-container .top-left a {
  color: #fff;
  font-size: 30px;
}

.video-container .top-left span {
  position: relative;
  top: -6px;
  left: 7px;
  font-size: 17px;
}

.video-container .top-right {
  position: absolute;
  top: 35px;
  right: 35px;
}

.video-container .top-right img {
  width: 150px;
  height: auto;
  border-radius: 10px;
  border: 3px solid #ffffff38;
  cursor: pointer;
}

.video-container .centered {
  position: absolute;
  bottom: 20px;
  text-align: center;
  left: 35%;
}

.video-container .centered .control-bar {
  color: #000;
  background-color: #ffffff;
  padding: 17px 30px;
  border-radius: 30px;
  height: 57px;
}

.video-container .centered .control-bar .message {
  margin-right: 40px;
  cursor: pointer;
}

.video-container .centered .control-bar .mic {
  margin-right: 24px;
  cursor: pointer;
}

.video-container .centered .control-bar .call-area {
  margin-right: 25px;
  width: 104px;
}

.video-container .centered .control-bar .video-btn {
  margin-right: 40px;
  cursor: pointer;
}

.video-container .centered .control-bar .video-option {
  cursor: pointer;
}

.video-container .centered .call-btn {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  left: 159px;
  top: 64px;
  background-image: linear-gradient(180deg, #ff5c5c 0%, #b93a3a 100%);
  cursor: pointer;
  border: 3px solid #fff;
}

.video-conference .modal-content {
  border-radius: 30px;
  background-color: #000;
}

.video-container .centered .call-btn svg {
  position: relative;
  top: 21px;
}

.video-container .centered .control-bar .message:hover,
.video-container .centered .control-bar .mic:hover,
.video-container .centered .control-bar .video-btn:hover,
.video-container .centered .control-bar .video-option:hover {
  color: red;
}

.video-container .centered .call-btn:hover {
  border: 3px solid #ffffff;
  background-image: linear-gradient(180deg, #ff5c5c 0%, #ff5c5c 100%);
}

.attend-sec .nav i {
  margin-right: 10px;
  font-size: 27px;
}

.attend-sec .search-list .nav-item .nav-link.active {
  background-color: rgb(115 39 107);
  opacity: 1;
  color: #fff;
}

.attend-sec .nav-link svg {
  position: relative;
  top: 7px;
  margin-right: 10px;
}

.attend-sec .search-list .nav-item .nav-link {
  border: none;
  padding: 0.7rem 1.5rem;
  opacity: 0.5;
  position: relative;
  top: -6px;
}

.attend-sec .search-list .nav-item {
  background-color: rgb(115 39 107 / 13%);
  height: 45px;
}

.atnd i {
  position: relative;
  top: 3px;
}

.attend-sec .search-list .nav-item:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.attend-sec .search-list .nav-item:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.alphabets {
  color: #73276b;
  background-color: #ede3ec;
  margin: 2px 5px;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 4px;
  line-height: 23px;
  cursor: pointer;
}

.alphabets:hover {
  color: #73276b;
  background-color: #ede3ec;
  border: 1px solid #73276b;
}

.students-sort {
  border-bottom: 1px solid #73276b2b;
  text-align: end;
  margin-bottom: 24px;
}

.sort-alph {
  display: inline;
  position: relative;
  top: 14px;
  right: 8px;
  color: #73276b;
  background-color: #fff;
  padding: 3px;
  font-weight: 500;
  cursor: pointer;
}

.std-container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.responsive-table li {
  padding: 5px 30px;
  display: flex;
  justify-content: space-between;
}

.responsive-table .table-header {
  background-color: #95a5a6;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.responsive-table .table-row {
  background-color: #ffffff;
  border-bottom: 1px solid #00000008;
}

.responsive-table .col-1 {
  flex-basis: 10%;
}

.responsive-table .col-2 {
  flex-basis: 40%;
}

.responsive-table .col-3 {
  flex-basis: 25%;
}

.responsive-table .col-4 {
  flex-basis: 25%;
}

@media all and (max-width: 767px) {
  .responsive-table .table-header {
    display: none;
  }

  .responsive-table li {
    display: block;
  }

  .responsive-table .col {
    flex-basis: 100%;
  }

  .responsive-table .col {
    display: flex;
    padding: 10px 0;
  }

  .responsive-table .col:before {
    color: #6c7a89;
    padding-right: 10px;
    content: attr(data-label);
    flex-basis: 50%;
    text-align: right;
  }
}

.std-container .avatar img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.std-container .switch-toggle {
  width: 13em;
  max-height: 30px;
  position: relative;
  right: 23px;
}

.std-container .switch-toggle label:not(.disabled) {
  cursor: pointer;
}

.std-container .switch-toggle .disabled svg:first-child {
  position: relative;
  top: -2px;
  left: -5px;
  stroke: #ffffff;
}

.std-container .switch-toggle .disabled svg:last-child {
  position: relative;
  top: -2px;
  right: -8px;
  stroke: #ffffff;
}

.switch-candy:focus {
  outline: none !important;
}

.t-switch {
  text-align: end;
}

.std-container .std-name {
  margin-top: 9px;
}

.t-switch label.absent {
  color: #d50909;
}

.t-switch label.present {
  color: #63a034;
}

.responsive-table .table-row:last-child {
  background-color: #ffffff;
  border-bottom: none;
}

.atnd-scroll {
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.atnd-scroll::-webkit-scrollbar {
  display: none;
}

.switch-field {
  display: flex;
  overflow: hidden;
  left: 48px;
  position: relative;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #e4e4e4;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #73276b;
  box-shadow: none;
  color: #fff;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}

.mark-sec {
  text-align: center;
  position: relative;
  top: -30px;
}

.mark-sec img {
  width: 80px;
  height: auto;
  cursor: pointer;
}

.mark-sec svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.mark-all-sec {
}

.mark-all-sec .option-group {
  width: 90%;
  max-width: 400px;
  height: 150px;
  position: relative;
  overflow: hidden;
  border-radius: 0.25em;
  font-size: 4rem;
  margin: 0.2em auto;
  transform: translateZ(0);
  top: -29px;
}

.mark-all-sec .option-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 120%;
  height: 100%;
  margin: 0 -10%;
}

.mark-all-sec .option {
  overflow: hidden;
  flex: 1;
  display: block;
  padding: 0.5em;
  background: #e1e1e1;
  position: relative;
  margin: 0em;
  margin-right: 0.2em;
  border-radius: 0.25em;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
  opacity: 0.5;
  transition-duration: 0.8s, 0.6s;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.98, 0, 0.22, 0.98), linear;
  will-change: transform, opacity;
}

.mark-all-sec .option:last-child {
  margin-right: 0;
}

.mark-all-sec .option__indicator {
  display: block;
  transform-origin: left bottom;
  transition: inherit;
  will-change: transform;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  left: 0.5em;
}

.mark-all-sec .option__indicator:before,
.mark-all-sec .option__indicator:after {
  content: "";
  display: block;
  border: solid 2px #adc926;
  border-radius: 50%;
  width: 0.25em;
  height: 0.25em;
  position: absolute;
  top: 0;
  right: 0;
}

.mark-all-sec .option__indicator:after {
  background: #adc926;
  transform: scale(0);
  transition: inherit;
  will-change: transform;
}

.mark-all-sec .option-input {
  position: absolute;
  top: 0;
  z-index: -1;
  visibility: hidden;
}

.mark-all-sec .option__label {
  display: block;
  width: 100%;
  font-size: 32px;
  transform-origin: left bottom;
  transform: translateX(20%) scale(0.7);
  transition: inherit;
  will-change: transform;
}

.mark-all-sec .option__label sub {
  margin-left: 0.25em;
  font-size: 16px;
  display: inline-block;
  vertical-align: -0.7em;
}

.mark-all-sec .option__label:after {
  content: "";
  display: block;
  border: solid 2px #adc926;
  width: 100%;
  transform-origin: 0 0;
  transform: scaleX(0.2);
  transition: inherit;
  will-change: transform;
}

.mark-all-sec .option:last-child .option__label {
  transform: translateX(0%) scale(0.7);
}

.mark-all-sec .option:last-child .option__indicator {
  transform: translateX(-20%);
}

.mark-all-sec .option-input:checked ~ .option {
  transform: translateX(-20%) translateX(0.2em);
}

.mark-all-sec .option-input:checked ~ .option .option__indicator {
  transform: translateX(0%);
}

.mark-all-sec .option-input:checked ~ .option .option__label {
  transform: translateX(40%) scale(0.7);
}

.mark-all-sec .option-input:first-child:checked ~ .option {
  transform: translateX(20%) translateX(-0.2em);
}

.mark-all-sec .option-input:first-child:checked ~ .option .option__indicator {
  transform: translateX(-40%);
}

.mark-all-sec .option-input:first-child:checked ~ .option .option__label {
  transform: translateX(0%) scale(0.7);
}

.mark-all-sec .option-input:nth-child(1):checked ~ .option:nth-of-type(1),
.mark-all-sec .option-input:nth-child(2):checked ~ .option:nth-of-type(2) {
  opacity: 1;
}

.mark-all-sec
  .option-input:nth-child(1):checked
  ~ .option:nth-of-type(1)
  .option__indicator,
.mark-all-sec
  .option-input:nth-child(2):checked
  ~ .option:nth-of-type(2)
  .option__indicator {
  transform: translateX(0);
}

.mark-all-sec
  .option-input:nth-child(1):checked
  ~ .option:nth-of-type(1)
  .option__indicator::after,
.mark-all-sec
  .option-input:nth-child(2):checked
  ~ .option:nth-of-type(2)
  .option__indicator::after {
  transform: scale(1);
}

.mark-all-sec
  .option-input:nth-child(1):checked
  ~ .option:nth-of-type(1)
  .option__label,
.mark-all-sec
  .option-input:nth-child(2):checked
  ~ .option:nth-of-type(2)
  .option__label,
.mark-all-sec
  .option-input:nth-child(1):checked
  ~ .option:nth-of-type(1)
  .option__label::after,
.mark-all-sec
  .option-input:nth-child(2):checked
  ~ .option:nth-of-type(2)
  .option__label::after {
  transform: scale(1);
}

.to-do-scroll {
  max-height: 610.53px;
  overflow-y: scroll;
}

.to-do-scroll::-webkit-scrollbar {
  display: none;
}

.card-body.to-do-scroll {
  max-height: 519.53px;
  overflow-y: scroll;
}

.btn-edsys-primary {
  background-color: #9eb91c !important;
  border-color: #78266a00 !important;
  color: #ffffff;
  transition: background-color 1s ease;
  position: relative;
  top: -10px;
}

.btn-edsys-primary:hover {
  background-color: #fff !important;
  border-color: #9eb91c !important;
  color: #9eb91c;
}

.assignment-sec .btn-edsys-primary svg {
  top: 3px;
  position: relative;
}

.assignment-sec .btn-edsys-primary span {
  top: -4px;
  position: relative;
}

.assignment-sec .card.stack-card {
  border-radius: 10px;
  /* box-shadow:
    0 -1px 1px rgba(0,0,0,0.15),
    0 -10px 0 -5px #eee,
    0 -10px 1px -4px rgba(0,0,0,0.15); */
  padding: 15px;
}

.assignment-sec h6 {
  font-size: 14px;
  position: relative;
  top: 7px;
  left: 8px;
}

.assignment-sec .class {
  color: #fff;
  background-color: #73276b;
  border-radius: 7px;
  height: 23px;
  padding: 3px 10px;
  font-size: 12px;
}

.assignment-sec .card.stack-card .card-header {
  background-color: #fff0;
  cursor: pointer;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid #dfdfdf;
}

.assignment-sec .card.stack-card .card-body {
  background-color: #fff0;
  padding: 15px 0px 10px 0px;
  cursor: pointer;
}

.assignment-sec .card.stack-card .card-body h5 {
  color: #772569;
  font-size: 22px;
  font-weight: 500;
}

.assignment-sec .card.stack-card .card-body a {
  color: #772569;
  font-size: 22px;
  font-weight: 500;
}

.assignment-sec .card.stack-card .card-footer .action-list {
  border-radius: 50%;
  position: absolute;
  right: 20px;
  display: inline-block;
  float: right;
  z-index: 1;
}

.assignment-sec .card.stack-card .card-footer {
  background-color: transparent;
  border-top: 1px solid #e6edef00;
  padding: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.assignment-sec .card.stack-card .card-footer .action-list ul {
  display: inline-flex;
}

.assignment-sec .card.stack-card .card-footer .setting-secondary svg {
  width: 15px;
  stroke-width: 2.5px;
  -webkit-animation: none;
  animation: none;
  color: #73276b;
}

.assignment-sec .card.stack-card .card-footer .setting-secondary:hover {
  background-color: #73276b;
}

.assignment-sec .card.stack-card .card-footer .setting-secondary:hover svg {
  color: #fff;
}

.assignment-sec .card.stack-card .card-footer .setting-secondary {
  background-color: #fff;
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50px;
  margin-left: 10px;
  cursor: pointer;
}

.assignment-sec .card.stack-card .card-footer h5 {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.56px;
  padding: 6px 15px;
  border-radius: 5px;
}

.assignment-sec .card.sub-1 {
  background-color: #fdeeee;
  border-left: 5px solid #f1512a;
}

.assignment-sec .card.sub-1 .card-footer h5 {
  color: #f1512a;
  background-color: #fecfc4;
}

.assignment-sec .card.sub-2 {
  background-color: #d4deff;
  border-left: 5px solid #1235a7;
}

.assignment-sec .card.sub-2 .card-footer h5 {
  color: #1235a7;
  background-color: #a7beff;
}

.assignment-sec .card.sub-3 {
  background-color: #e9e5fe;
  border-left: 5px solid #411f87;
}

.assignment-sec .card.sub-3 .card-footer h5 {
  color: #411f87;
  background-color: #e2ccff;
}

.assignment-sec .card.sub-4 {
  background-color: #eafdf6;
  border-left: 5px solid #53c7a1;
}

.assignment-sec .card.sub-4 .card-footer h5 {
  color: #3aae88;
  background-color: #c3fae8;
}

.assignment-sec .card.sub-5 {
  background-color: #fffbe1;
  border-left: 5px solid #f6de42;
}

.assignment-sec .card.sub-5 .card-footer h5 {
  color: #cab31e;
  background-color: #fff5b2;
}

.assignment-sec .card.sub-6 {
  background-color: #fff9f1;
  border-left: 5px solid #bd6f0c;
}

.assignment-sec .card.sub-6 .card-footer h5 {
  color: #bd6f0c;
  background-color: #ffd8ce;
}

.assignment-sec .card {
  margin-bottom: 5px;
  border: 1px solid #e6edef;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 15px;
  background-color: #fff;
}

.assignment-sec .card .card-header {
  padding: 30px;
  border-bottom: none;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
  background-color: #fff;
}

.assignment-sec .card .card-body {
  padding: 30px 30px 0 30px;
  background-color: transparent;
}

.assignment-sec .select2-search--dropdown {
  display: none !important;
}

.assignment-sec .form-control {
  padding: 8.6px 11px;
}

.modal-scroll {
  max-height: 488px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.modal-scroll::-webkit-scrollbar {
  display: none;
}

.modal::-webkit-scrollbar {
  display: none;
}

.details-banner h3 {
  color: #051b26;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.72px;
  word-break: break-word;
  width: 100%;
}

.details-banner h5 {
  color: #766c8e;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.72px;
}

.details-banner h6 {
  color: #949fa6;
  font-size: 18px;
  letter-spacing: 0.48px;
  padding-bottom: 27px;
  border-bottom: 1px solid #7070702b;
  margin-bottom: 32px;
}

.details-banner h6 span {
  color: #772569;
  font-size: 18px;
  margin-left: 6px;
  letter-spacing: 0.48px;
}

.details-banner .sub-date {
  color: #305edc;
  background-color: #d5defe;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 15px;
  letter-spacing: 0.56px;
  border-radius: 5px;
  display: inline-block;
}

/* TEST CODE: DO NOT USE THESE IN PRODUCTION */
.edsys-slider .carousel-slide:nth-of-type(1) {
  background-color: #000;
}

.edsys-slider .carousel-slide:nth-of-type(2) {
  background-color: orange;
}

.edsys-slider .carousel-slide:nth-of-type(3) {
  background-color: yellow;
}

.edsys-slider .carousel-slide:nth-of-type(4) {
  background-color: lightgreen;
}

.edsys-slider .carousel-slide:nth-of-type(5) {
  background-color: cyan;
}

/* END TEST CODE; */

.edsys-slider .carousel {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  border-radius: 15px;
}

.edsys-slider .carousel-deck {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.edsys-slider .carousel-slide {
  position: absolute;
  left: 100%;
  top: 0;

  width: 100%;
  height: 100%;

  /*transition: left .5s;*/
}

.edsys-slider .carousel-slide.atLeft {
  left: -100%;
}

.edsys-slider .carousel-slide.atRight {
  left: 100%;
}

.edsys-slider .carousel-slide.atCenter {
  left: 0;
}

.edsys-slider .moving {
  transition: left 0.4s;
}

.edsys-slider .carousel-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.edsys-slider .carousel-move-left,
.edsys-slider .carousel-move-right {
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 2em;
  font-size: 1.3rem;
  font-family: monospace;
  border-radius: 50%;
  position: absolute;
  bottom: 10px;
  transition: all 0.3s;
  font-weight: 600;
  background-color: #ffffff5e;
  cursor: pointer;
}

.edsys-slider .carousel-move-left:hover,
.edsys-slider .carousel-move-right:hover {
  background-color: #ffffff;
}

.edsys-slider .carousel-paginate {
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 2em;
  font-family: monospace;

  border-radius: 50%;

  position: absolute;
  bottom: 10px;
  transition: all 0.3s;
}

.edsys-slider .carousel-move-left {
  left: -2em;
}

.edsys-slider .carousel:hover .carousel-move-left {
  left: 10px;
}

.edsys-slider .carousel-move-right {
  right: -2em;
}

.edsys-slider .carousel:hover .carousel-move-right {
  right: 10px;
}

.edsys-slider .carousel-pagination {
  display: inline-block;
  position: relative;
  bottom: -1.35em;
  transition: all 0.3s;
}

.edsys-slider .carousel:hover .carousel-pagination {
  bottom: 10px;
}

.edsys-slider .carousel-paginate {
  position: static;
  font-size: 1rem;
  border-width: 0;
  cursor: pointer;
  outline: 0;
  display: inline-block;
  color: rgb(255 255 255);
  background-color: transparent;
}

.edsys-slider .carousel-paginate.active {
  color: rgb(173 201 38);
  cursor: pointer;
}

.edsys-slider .carousel-paginate:hover {
  color: rgb(173 201 38);
  cursor: pointer;
}

.edsys-slider .carousel[data-controls="pagination"] .carousel-move-right,
.edsys-slider .carousel[data-controls="pagination"] .carousel-move-left {
  display: none;
}

.edsys-slider .carousel[data-controls="directional"] .carousel-pagination {
  display: none;
}

.edsys-slider .carousel[data-controls="none"] .carousel-controls {
  display: none;
}

.br-15 {
  border-radius: 15px !important;
}

.br-b-15 {
  border-radius: 0 0 15px 15px !important;
}

.assignment-status .student .user-image img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.assignment-status .student .full-name {
}

.assignment-status .student .status {
  color: #fff;
  font-size: 12px;
  padding: 6px 15px;
  border-radius: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 132px;
}

.assignment-status .student .status.submitted {
  background-color: #72a44b;
  cursor: pointer;
}

.assignment-status .student .status.pending {
  background-color: #ce5353;
}

.assignment-status .student .status.reviewed {
  background-color: #dbc814;
  cursor: pointer;
}

.assignment-status .student {
  padding: 12px 20px;
  border-bottom: 1px solid #00000017;
  border-radius: 18px;
}

.assignment-status .student:hover {
  background-color: #f2f6f7;
  -webkit-transform: translateY(-1px) scale(1.01);
  transform: translateY(-1px) scale(1.01);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  border-bottom: none;
}

.assignment-status {
  max-height: 412px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.assignment-status::-webkit-scrollbar {
  display: none;
}

.view-assignment h2 {
  font-size: 1.3rem;
}

.view-assignment .details-banner h5 {
  color: #949fa6;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.72px;
  margin-bottom: 15px;
}

.view-assignment .details-banner h5 svg {
  width: 13px;
  height: auto;
  margin-right: 5px;
  position: relative;
  top: 1px;
}

.view-assignment .student-header {
  top: -87px;
  left: 2%;
}

.view-assignment .details-banner .sub-date {
  color: #ef5934;
  background-color: #ffd8ce;
}

.view-assignment .student-image {
  position: absolute;
  top: 20px;
  bottom: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: 24px;
  text-align: end;
}

.view-assignment .student-image .student-avatar img {
  width: 100px;
  height: 100px;
  border-radius: 15px;
}

.view-assignment .student-image .student-avatar .class {
  position: relative;
  top: -22px;
  left: 40px;
  width: 60px;
  color: #fff;
  background-color: #adc927;
  border-radius: 7px 0 12px 7px;
  font-size: 12px;
  padding: 2px 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.view-assignment .student-image .student-name {
  color: #592160;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.88px;
  text-transform: uppercase;
  position: relative;
  top: -18px;
}

.card .card-body.header-card {
  padding: 30px 24px;
  background-color: transparent;
}

.review-card {
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.review-card .title-icon {
  width: 65px;
  height: auto;
}

.review-card .review-title {
  color: #051b26;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.rating-wrap {
  left: 30%;
  position: relative;
  margin-top: 15px;
}

.review-card .slidecontainer {
  margin-top: 15px;
}

.review-card .slidecontainer .range-slider {
  margin: 0;
}

.review-card .slidecontainer .range-slider {
  width: 100%;
}

.review-card .slidecontainer .range-slider__range {
  -webkit-appearance: none;
  width: calc(100% - (73px));
  height: 10px;
  border-radius: 5px;
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin: 0;
  /* Create by ME-Start */
  background: linear-gradient(
    90deg,
    rgb(147, 173, 24) 50%,
    rgb(215, 220, 223) 50.1%
  );
  /*  */
}

.review-card .slidecontainer .range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #7c930f;
  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

.review-card .slidecontainer .range-slider__range::-webkit-slider-thumb:hover {
  background: #93ad18;
}

.review-card .slidecontainer .range-slider__range:active::-webkit-slider-thumb {
  background: #93ad18;
}

.review-card .slidecontainer .range-slider__range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  border: 1px solid #7c930f;
  background-color: #fff;
  cursor: pointer;
  -moz-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

.review-card .slidecontainer .range-slider__range::-moz-range-thumb:hover {
  background: #fff;
}

.review-card .slidecontainer .range-slider__range:active::-moz-range-thumb {
  background: #93ad18;
}

.review-card .slidecontainer .range-slider__range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px #fff, 0 0 0 6px #93ad18;
}

.review-card .slidecontainer .range-slider__value {
  display: none;
  position: relative;
  width: 60px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #7c930f;
  padding: 5px 10px;
  margin-left: 8px;
}

.review-card .slidecontainer .range-slider__value:after {
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid #7c930f;
  border-bottom: 7px solid transparent;
  content: "";
}

.review-card .slidecontainer ::-moz-range-track {
  background: #d7dcdf;
  border: 0;
}

.review-card .slidecontainer input::-moz-focus-inner,
.review-card .slidecontainer input::-moz-focus-outer {
  border: 0;
}

.review-card .br-theme-fontawesome-stars .br-widget a,
.review-card .br-theme-fontawesome-stars .br-widget a.br-selected:after,
.review-card .br-theme-fontawesome-stars .br-widget a.br-active:after {
  color: #ffa800 !important;
  font: normal normal normal 18px/1 FontAwesome;
}

.review-card .rating-blk {
  position: relative;
  left: 24%;
  top: 10px;
}

.creativity-rating {
  position: relative;
  left: 13%;
  top: 15px;
}

.rating-card .card {
  min-height: 215px;
}

.rating-card .br-theme-bars-1to10 .br-widget .br-current-rating {
  display: none;
}

.dropzone.dz-clickable .dz-message svg {
  width: 50px;
  height: auto;
  stroke: #adc926;
}

.ass-form .ass-comment .form-control,
.ass-form .ass-comment .form-select {
  border: 2px dashed #73276b;
  border-radius: 15px;
}

.final-ass-fback .inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;
  border-radius: 7px;
}

.final-ass-fback .inputGroup label {
  padding: 16px 30px;
  width: 100%;
  display: block;
  text-align: left;
  color: #3c454c;
  background-color: #dfdfdf;
  border-radius: 7px;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
}

.final-ass-fback .inputGroup label:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  background-color: #96af16;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}

.final-ass-fback .inputGroup label:after {
  width: 32px;
  height: 32px;
  content: "";
  border: 2px solid #d1d7dc;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: 2px 3px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 200ms ease-in;
}

.final-ass-fback .inputGroup input:checked ~ label {
  color: #fff;
}

.final-ass-fback .inputGroup input:checked ~ label:before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}

.final-ass-fback .inputGroup input:checked ~ label:after {
  background-color: #badb13;
  border-color: #badb11;
}

.final-ass-fback .inputGroup input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}

.view-review .nav-pills .nav-link {
  border-radius: 15px 15px 0 0;
}

.view-review .nav-pills .nav-link.active,
.view-review .nav-pills .show > .nav-link {
  background-color: #ffffff;
  color: #73276b;
}

.view-review .nav-link {
  padding: 0.8rem 1.8rem;
}

.view-review .nav {
  position: relative;
  top: 13px;
  left: 1px;
  z-index: 0;
}

.view-review .t-review {
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.view-review .t-review .percent {
  color: #772569;
  font-size: 55px;
  font-weight: 600 !important;
  margin-bottom: 0;
  line-height: 54px;
}

.view-review .t-review .neatness {
  color: #772569;
  font-size: 55px;
  font-weight: 600 !important;
  margin-bottom: 0;
  line-height: 50px;
}

.view-review .t-review .neatness span {
  color: #772569;
  font-size: 20px;
  font-weight: 600 !important;
  line-height: 31px;
}

.view-review .t-review .review-title {
  color: #051b26;
  font-size: 16px;
  font-weight: 500;
}

.student-rating-star {
  color: #adacab;
  display: inline-flex;
  font-size: 20px;
}

.student-rating-star li.checked {
  color: #f5c304 !important;
}

.mh-145 {
  min-height: 145px;
}

.feedback-sec {
  border-top: 1px solid #dfdfdf;
  padding-top: 15px;
}

.feedback-sec .fb-sec {
  color: #051b26;
  font-size: 14px;
  letter-spacing: 0.56px;
  font-weight: 500;
}

.feedback-sec .fb-status {
  color: #fff;
  background-color: #adc926;
  padding: 3px 36px;
  border-radius: 20px;
  font-weight: 500;
}

/* Delete Button */
#deleteWarning .d-button {
  --background: #ce5353;
  --background-hover: #9c2e2e;
  --text: #fff;
  --shadow: rgba(0, 9, 61, 0.2);
  --paper: #000000;
  --paper-lines: #fff;
  --trash: #e1e6f9;
  --trash-lines: #fff;
  --check: #fff;
  --check-background: #000000;
  position: relative;
  border: none;
  outline: none;
  background: none;
  padding: 10px 24px;
  border-radius: 7px;
  min-width: 100px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  display: flex;
  color: var(--text);
  background: var(--btn, var(--background));
  box-shadow: 0 var(--shadow-y, 4px) var(--shadow-blur, 8px) var(--shadow);
  transform: scale(var(--scale, 1));
  transition: transform 0.3s, box-shadow 0.3s, background 0.3s;
}

#deleteWarning .d-button span {
  display: block;
  font-size: 14px;
  line-height: 25px;
  font-weight: 600;
  opacity: var(--span-opacity, 1);
  transform: translateX(var(--span-x, 0)) translateZ(0);
  transition: transform 0.4s ease var(--span-delay, 0.2s),
    opacity 0.3s ease var(--span-delay, 0.2s);
}

#deleteWarning .d-button .trash {
  display: block;
  position: relative;
  left: -8px;
  transform: translate(var(--trash-x, 0), var(--trash-y, 1px)) translateZ(0)
    scale(var(--trash-scale, 0.64));
  transition: transform 0.5s;
}

#deleteWarning .d-button .trash:before,
#deleteWarning .d-button .trash:after {
  content: "";
  position: absolute;
  height: 8px;
  width: 2px;
  border-radius: 1px;
  background: var(--icon, var(--trash));
  bottom: 100%;
  transform-origin: 50% 6px;
  transform: translate(var(--x, 3px), 2px) scaleY(var(--sy, 0.7))
    rotate(var(--r, 0deg));
  transition: transform 0.4s, background 0.3s;
}

#deleteWarning .d-button .trash:before {
  left: 1px;
}

#deleteWarning .d-button .trash:after {
  right: 1px;
  --x: -3px;
}

#deleteWarning .d-button .trash .top {
  position: absolute;
  overflow: hidden;
  left: -4px;
  right: -4px;
  bottom: 100%;
  height: 40px;
  z-index: 1;
  transform: translateY(2px);
}

#deleteWarning .d-button .trash .top:before,
#deleteWarning .d-button .trash .top:after {
  content: "";
  position: absolute;
  border-radius: 1px;
  background: var(--icon, var(--trash));
  width: var(--w, 12px);
  height: var(--h, 2px);
  left: var(--l, 8px);
  bottom: var(--b, 5px);
  transition: background 0.3s, transform 0.4s;
}

#deleteWarning .d-button .trash .top:after {
  --w: 28px;
  --h: 2px;
  --l: 0;
  --b: 0;
  transform: scaleX(var(--trash-line-scale, 1));
}

#deleteWarning .d-button .trash .top .paper {
  width: 14px;
  height: 18px;
  background: var(--paper);
  left: 7px;
  bottom: 0;
  border-radius: 1px;
  position: absolute;
  transform: translateY(-16px);
  opacity: 0;
}

#deleteWarning .d-button .trash .top .paper:before,
#deleteWarning .d-button .trash .top .paper:after {
  content: "";
  width: var(--w, 10px);
  height: 2px;
  border-radius: 1px;
  position: absolute;
  left: 2px;
  top: var(--t, 2px);
  background: var(--paper-lines);
  transform: scaleY(0.7);
  box-shadow: 0 9px 0 var(--paper-lines);
}

#deleteWarning .d-button .trash .top .paper:after {
  --t: 5px;
  --w: 7px;
}

#deleteWarning .d-button .trash .box {
  width: 20px;
  height: 25px;
  border: 2px solid var(--icon, var(--trash));
  border-radius: 1px 1px 4px 4px;
  position: relative;
  overflow: hidden;
  z-index: 2;
  transition: border-color 0.3s;
}

#deleteWarning .d-button .trash .box:before,
#deleteWarning .d-button .trash .box:after {
  content: "";
  position: absolute;
  width: 4px;
  height: var(--h, 20px);
  top: 0;
  left: var(--l, 50%);
  background: var(--b, var(--trash-lines));
}

#deleteWarning .d-button .trash .box:before {
  border-radius: 2px;
  margin-left: -2px;
  transform: translateX(-3px) scale(0.6);
  box-shadow: 10px 0 0 var(--trash-lines);
  opacity: var(--trash-lines-opacity, 1);
  transition: transform 0.4s, opacity 0.4s;
}

#deleteWarning .d-button .trash .box:after {
  --h: 16px;
  --b: var(--paper);
  --l: 1px;
  transform: translate(-0.5px, -16px) scaleX(0.5);
  box-shadow: 7px 0 0 var(--paper), 14px 0 0 var(--paper), 21px 0 0 var(--paper);
}

#deleteWarning .d-button .trash .check {
  padding: 4px 3px;
  border-radius: 50%;
  background: var(--check-background);
  position: absolute;
  left: 2px;
  top: 24px;
  opacity: var(--check-opacity, 0);
  transform: translateY(var(--check-y, 0)) scale(var(--check-scale, 0.2));
  transition: transform var(--check-duration, 0.2s) ease var(--check-delay, 0s),
    opacity var(--check-duration-opacity, 0.2s) ease var(--check-delay, 0s);
}

#deleteWarning .d-button .trash .check svg {
  width: 8px;
  height: 6px;
  display: block;
  fill: none;
  stroke-width: 1.5;
  stroke-dasharray: 9px;
  stroke-dashoffset: var(--check-offset, 9px);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--check);
  transition: stroke-dashoffset 0.4s ease var(--checkmark-delay, 0.4s);
}

#deleteWarning .d-button.delete {
  --span-opacity: 0;
  --span-x: 16px;
  --span-delay: 0s;
  --trash-x: 30px;
  --trash-y: 2px;
  --trash-scale: 1;
  --trash-lines-opacity: 0;
  --trash-line-scale: 0;
  --icon: #fff;
  --check-offset: 0;
  --check-opacity: 1;
  --check-scale: 1;
  --check-y: 16px;
  --check-delay: 1.7s;
  --checkmark-delay: 2.1s;
  --check-duration: 0.55s;
  --check-duration-opacity: 0.3s;
}

#deleteWarning .d-button.delete .trash:before,
#deleteWarning .d-button.delete .trash:after {
  --sy: 1;
  --x: 0;
}

#deleteWarning .d-button.delete .trash:before {
  --r: 40deg;
}

#deleteWarning .d-button.delete .trash:after {
  --r: -40deg;
}

#deleteWarning .d-button.delete .trash .top .paper {
  -webkit-animation: paper 1.5s linear forwards 0.5s;
  animation: paper 1.5s linear forwards 0.5s;
}

#deleteWarning .d-button.delete .trash .box:after {
  -webkit-animation: cut 1.5s linear forwards 0.5s;
  animation: cut 1.5s linear forwards 0.5s;
}

#deleteWarning .d-button.delete,
#deleteWarning .d-button:hover {
  --btn: var(--background-hover);
  --shadow-y: 5px;
  --shadow-blur: 9px;
}

#deleteWarning .d-button:active {
  --shadow-y: 2px;
  --shadow-blur: 5px;
  --scale: 0.94;
}

@-webkit-keyframes paper {
  10%,
  100% {
    opacity: 1;
  }

  20% {
    transform: translateY(-16px);
  }

  40% {
    transform: translateY(0);
  }

  70%,
  100% {
    transform: translateY(24px);
  }
}

@keyframes paper {
  10%,
  100% {
    opacity: 1;
  }

  20% {
    transform: translateY(-16px);
  }

  40% {
    transform: translateY(0);
  }

  70%,
  100% {
    transform: translateY(24px);
  }
}

@-webkit-keyframes cut {
  0%,
  40% {
    transform: translate(-0.5px, -16px) scaleX(0.5);
  }

  100% {
    transform: translate(-0.5px, 24px) scaleX(0.5);
  }
}

@keyframes cut {
  0%,
  40% {
    transform: translate(-0.5px, -16px) scaleX(0.5);
  }

  100% {
    transform: translate(-0.5px, 24px) scaleX(0.5);
  }
}

/* Delete Button */

.warning-message {
  padding: 30px 0;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.warning-message svg {
  width: 80px;
  height: auto;
  stroke: #e33131;
}

.warning-message h6 {
  margin-top: 15px;
}

.assessment-details .details-banner .sub-date,
.assessment-details .details-banner .sub-time {
  color: #ef5934;
  background-color: #ffd8ce;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 15px;
  letter-spacing: 0.56px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 25px;
}

.assessment-details .details-banner .sub-time i {
  margin-right: 5px;
}

.assessment-details .details-banner h5 {
  color: #766c8e;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.72px;
  margin-bottom: 20px;
}

.assessment-details .details-banner h6 {
  color: #949fa6;
  font-size: 16px;
  letter-spacing: 0.48px;
  padding-bottom: 27px;
  border-bottom: 1px solid #7070702b;
  margin-bottom: 32px;
  font-weight: 300;
}

.assessment-details .knowledgebase-search {
  position: absolute;
  top: 86px;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: inherit;
  left: 3%;
}

.assessment-details .desc h5 {
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 8px;
  z-index: 99;
}

.assessment-details .desc p {
  color: #939191;
  z-index: 99;
}

.assessment-details .banner-img {
  background-image: url(../images/assessments/bg_1.svg);
  background-size: cover;
  background-position: center center;
  display: block;
}

/* Rank List */
.rank-list .banner-img {
  background-image: url(../images/assessments/bg_2.svg);
  background-size: cover;
  background-position: center center;
  display: block;
  border-radius: 15px 15px 0 0;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 17px;
}

.rank-list-banner {
  height: 500px;
}

.rank-list-banner .photo {
  width: 75px;
  background: #fff;
  border-radius: 50%;
  border: 5px solid #adc926;
  margin: 5px 0 15px 0;
  cursor: pointer;
}

.rank-list-banner .main {
  width: 125px;
  box-shadow: 0 0 10px #adc926;
}

.rank-list-banner .profile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.rank-list-banner .profile .person {
  display: flex;
  margin: 1rem 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rank-list-banner .profile .person.second i,
.rank-list-banner .profile .person.third i {
  color: #adc926;
  font-size: 20px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
}

.rank-list-banner .profile .person.first {
  z-index: 8.8;
  transform: translateY(-10%);
}

.rank-list-banner .first i {
  color: #ebbd0e;
  font-size: 35px;
}

.rank-list-banner .num {
  color: white;
}

.rank-list-banner .fa-caret-up {
  color: cyan;
  font-size: 21px;
}

.rank-list-banner .link {
  margin: 0.2rem 0;
  color: #fff;
  margin-top: -0.3rem;
  font-size: 13px;
}

.rank-list-banner .points {
  color: #adc927;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.48px;
}

.rank-list-banner .points span {
  color: #fff;
  font-size: 12px;
  font-weight: normal;
}

.rank-list-banner .second {
  margin-right: -0.7rem !important;
}

.rank-list-banner .third {
  margin-left: -0.8rem !important;
}

.rank-list-banner .p_img {
  width: 50px;
  background: #fff;
  border-radius: 50%;
}

.rank-list-banner .flex {
  display: flex;
  align-items: center;
}

.rank-list-banner .others {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
}

.rank-list-banner .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  background: rgba(210, 255, 213, 0.3);
}

.rank-list-banner .info .points {
  margin-left: 0.2rem;
  margin-right: 1.2rem;
}

.rank-list-banner .info .link {
  margin: 0 1rem;
}

.rank-list-banner .rank {
  display: flex;
  align-items: center;
  margin: 0 1rem;
  flex-direction: column-reverse;
}

.rank-list-banner .rank i {
  margin-top: -5px !important;
}

.rank-list-banner .rank .num {
  margin: 0 !important;
}

.td_text_center {
  text-align: center;
  vertical-align: middle;
}

.student-rank-list .ranks {
  color: #ffffff;
  font-size: 16px;
  margin-right: 24px;
}

.student-rank-list:last-child .ranks {
  margin-right: 17px;
}

.student-rank-list img {
  width: 42px;
  height: 42px;
  border-radius: 25px;
  border: 3px solid #adc927;
  margin-right: 24px;
}

.student-rank-list {
  background-color: #77256900;
  padding: 9px 20px;
  border-radius: 15px;
  margin-bottom: 6px;
  border-bottom: 1px solid #ffffff24;
  cursor: pointer;
}

.student-rank-list-heading {
  background-color: #77256900;
  padding: 9px 20px;
  border-radius: 15px;
  margin-bottom: 6px;
  border-bottom: 1px solid #ffffff24;
  cursor: pointer;
  font-weight: 600;
}

.student-rank-list .student-name {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.56px;
}

.card::-webkit-scrollbar {
  display: none;
}

.approve-leave .student-rank-list {
  background-color: #ebebeb00;
  padding: 9px 20px;
  border-radius: 15px;
  margin-bottom: 6px;
  /* border-bottom: 1px solid #ebebeb; */
  cursor: pointer;
}

.approve-leave .student-rank-list .student-name {
  color: #1d1a4f;
  font-size: 14px;
  letter-spacing: 0.56px;
}

.approve-leave .student-rank-list .student-name td {
  color: #592160;
  border: none !important;
}

.approve-leave .student-rank-list .student-name tr {
  border: none !important;
}

.approve-leave .student-rank-list .ranks {
  color: #1d1a4e;
  font-size: 16px;
  margin-right: 24px;
}

.student-rank-list:last-child {
  background-color: #77256900;
  padding: 9px 20px;
  border-radius: 15px;
  margin-bottom: 0;
  border-bottom: none;
  cursor: pointer;
}

.student-rank-list:hover {
  background-color: #772569;
  -webkit-transform: translateY(-1px) scale(1.01);
  transform: translateY(-1px) scale(1.01);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  border-bottom: none;
}

.student-rank-list .scores {
  color: #adc927;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.48px;
}

.student-rank-list .scores span {
  color: #fff;
  font-size: 12px;
  font-weight: normal;
}

.rank-list .card {
  margin-bottom: 30px;
  border: 1px solid #1a1746;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 0 0 15px 15px;
  background-color: #1a1746;
}

.mark-sheet h2.back-btn {
  cursor: pointer;
}

.mark-sheet h2.back-btn span {
  font-size: 20px;
}

.mark-sheet h2.back-btn svg {
  position: relative;
  top: 8px;
  height: auto;
  width: 30px;
}

.mark-sheet .duration {
  color: #949fa6;
  font-size: 14px;
  letter-spacing: 0.56px;
  margin-top: 22px;
  padding-top: 5px;
  border-top: 1px solid #9999992e;
}

.mark-sheet .details-banner h5.subject {
  color: #9c2788;
  font-weight: 600;
  letter-spacing: 0.68px;
}

.mark-sheet .duration .time svg {
  width: 14px;
  height: auto;
  margin-right: 4px;
  position: relative;
  top: 2px;
}

.mark-sheet .duration span {
  color: #772569;
  font-size: 18px;
  letter-spacing: 0.76px;
}

.qa-header .question-type {
  color: #772569;
  background-color: #fff;
  font-size: 15px;
  letter-spacing: 0.6px;
  font-weight: 600;
  border: 1px solid #adc927;
  border-radius: 20px;
  padding: 8px 14px;
  z-index: 1;
  -webkit-box-align: center;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.qa-header .question-number {
  color: #766c8e;
  font-size: 14px;
  position: relative;
  top: 9px;
  /* background-color: #fff; */
  z-index: 1;
  padding: 0 0 0 10px;
}

.qa-header .question-number span {
  color: #99b127;
  font-size: 16px;
  letter-spacing: 0.64px;
}

.qa-header .question-number span.t-ques {
  color: #766c8e;
  font-size: 12px;
  letter-spacing: 0.64px;
}

.qa-header .h-line {
  border-top: 1px solid #b3b3b34d;
  position: relative;
  top: -19px;
  margin: 0 50px;
  width: 85%;
}

.question p {
  margin-top: 20px;
}

.question p span {
  color: #78266a;
  font-weight: 600;
  margin-right: 8px;
}

.multi-choice .answer .answer-list {
  background-color: #f5f6fa;
  padding: 18px;
  border-radius: 27px;
  margin-bottom: 15px;
  max-height: 57px;
}

.multi-choice .answer .answer-list.wrong-ans {
  background-color: #ffe4e4;
}

.multi-choice .answer .answer-list.correct-ans {
  background-color: #e4f9d4;
}

.multi-choice .answer .answer-list .ans-title {
  color: #051b26;
  font-size: 14px;
  letter-spacing: 0.56px;
}

.multi-choice .answer .answer-list.wrong-ans .ans-title {
  color: #ce3131;
}

.multi-choice .answer .answer-list.correct-ans .ans-title {
  color: #72a44b;
}

.radio-btn {
  position: relative;
  top: 3px;
  clear: both;
  background: transparent;
  border: 2px solid transparent;
  outline: 2px solid #949fa6;
  transition: 0.7s all ease-in;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 11px;
}

.multi-choice .answer .answer-list.wrong-ans .radio-btn {
  background: #ce3131;
  border: 2px solid #fff;
  outline: 2px solid #ce3131;
  transition: 0.7s all ease-in;
}

.multi-choice .answer .answer-list.correct-ans .radio-btn {
  background: #72a44b;
  border: 2px solid #fff;
  outline: 2px solid #72a44b;
  transition: 0.7s all ease-in;
}

.multi-choice .answer .answer-list img {
  display: none;
}

.multi-choice .answer .answer-list.ans-selected img {
  display: inline-flex;
  position: relative;
  top: -7px;
  right: -27px;
  width: 35px;
  height: 35px;
  border: 3px solid #fff;
  border-radius: 50%;
}

.fill-in-the-blanks .answer {
  font-size: 14px;
  font-weight: 500;
  padding: 4px 15px;
  letter-spacing: 0.56px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 0px;
}

.fill-in-the-blanks .correct .answer {
  color: #72a44b;
  background-color: #e4f9d4;
}

.fill-in-the-blanks .own-ans .i-m-g img {
  display: inline-flex;
  position: relative;
  top: -1px;
  right: -22px;
  width: 35px;
  height: 35px;
  border: 3px solid #fff;
  border-radius: 50%;
}

.fill-in-the-blanks .wrong {
  margin-left: 24px;
}

.fill-in-the-blanks .wrong .answer {
  color: #ce3131;
  background-color: #ffe4e4;
}

.fill-in-the-blanks .wrong .correct-answer {
  font-size: 12px;
}

.fill-in-the-blanks .wrong .correct-answer i {
  color: #74a54d;
}

.fill-in-the-blanks .wrong .correct-answer span {
  color: #72a44b;
  font-size: 16px;
}

.match-the-following .answer .answer-list img {
  display: inline-flex;
}

.multi-choice.match-the-following .answer .answer-list.ans-selected img {
  display: inline-flex;
  position: relative;
  top: -7px;
  right: -27px;
  width: 35px;
  height: 35px;
  border: 3px solid #fff;
  border-radius: 50%;
}

.single-choice-img .answer-choice .answer img {
  width: 80px;
  height: 80px;
  border-radius: 15px;
  border: 3px solid #bbbaba;
  margin-right: 20px;
}

.single-choice-img .answer-choice .answer.wrong img {
  border: 3px solid #eba8a8;
  box-shadow: rgb(206 49 49 / 32%) 0px 8px 24px;
}

.single-choice-img .answer-choice .answer.correct img {
  border: 3px solid #b3d797;
  box-shadow: rgb(114 164 74 / 49%) 0px 8px 24px;
}

.n-attend-list .card {
  margin-bottom: 30px;
  border: 1px solid #1a1746;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 15px;
  background-color: #1a1746;
}

.n-attend-list .n-attend-total .total-no {
  color: #fff;
  font-size: 30px;
  font-weight: 800;
}

.n-attend-list .n-attend-total {
  color: #fff;
  border: 1px solid #adc926;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 30px;
}

.n-attend-list .n-attend-total .msg {
  position: relative;
  top: -5px;
}

.n-attend-total .h-i-m-g img {
  width: 40px;
  height: auto;
}

.assignment-sec .input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.assessment-sec .inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;
}

.assessment-sec .inputGroup label {
  padding: 5px 30px;
  width: 100%;
  display: block;
  text-align: left;
  border-radius: 5px;
  color: #000000;
  background-color: #c1c1c100;
  cursor: pointer;
  border: 1px solid #e6edef;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
}

.assessment-sec .inputGroup label:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  background-color: #adc926;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}

.assessment-sec .inputGroup label:after {
  width: 32px;
  height: 32px;
  content: "";
  border: 2px solid #d1d7dc;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: 2px 3px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 200ms ease-in;
}

.assessment-sec .inputGroup input:checked ~ label {
  color: #fff;
}

.assessment-sec .inputGroup input:checked ~ label:before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}

.assessment-sec .inputGroup input:checked ~ label:after {
  background-color: #55660f;
  border-color: #55660f;
}

.assessment-sec .inputGroup input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}

.assessment-sec .radio-button {
  padding: 0 16px;
  max-width: 550px;
  margin: 8px 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}

.add-ques .card.sub-1 {
  background-color: #fff;
  border-left: 5px solid #73276b;
  border-radius: 15px;
}

.add-ques .stack-card h6 {
  font-size: 14px;
  position: relative;
  top: 13px;
  left: 8px;
}

#more {
  display: none;
}

.add-ques p.description span {
  font-weight: inherit;
}

.add-ques p.description span.r-more {
  color: #829914;
  font-size: 12px;
  cursor: pointer;
}

.add-ques .stack-card .lesson {
  color: #adc926;
  font-weight: 600;
  letter-spacing: 0.8px;
}

.add-ques .stack-card .class {
  color: #fff;
  background-color: #73276b;
  border-radius: 7px 7px 0 0;
  height: 23px;
  padding: 3px 10px;
  font-size: 12px;
  font-weight: 600;
}

.add-ques .stack-card .syllabus {
  background-color: #adc926;
  color: #5f700d;
  border-radius: 0 0 7px 7px;
  height: 23px;
  padding: 3px 10px;
  font-size: 12px;
  font-weight: 600;
}

.add-ques .stack-card .card-footer {
  background-color: #00000003;
  padding: 15px 30px;
}

.add-ques .stack-card .card-footer .br-1 {
  border-right: 1px solid #cad1d3;
}

.add-ques .stack-card .footer-blk-4 {
  text-align: center;
}

.add-ques .stack-card .footer-blk-4 .title {
  color: #949788;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.82px;
}

.add-ques .stack-card .footer-blk-4 .info {
  color: #1d1a4f;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.82px;
}

.add-ques .create-qns {
  background-color: #9ab322 !important;
  color: #fff;
  border: 1px solid #9ab322 !important;
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 7px;
}

.add-ques .create-qns:hover {
  background-color: #ffffff00 !important;
  color: #9ab322;
  border: 1px solid #9ab322 !important;
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  font-weight: 600;
  text-decoration: none;
}

.add-ques .avail-qns {
  background-color: #ffffff00 !important;
  color: #73276b;
  border: 1px solid #73276b !important;
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 7px;
  margin-right: 10px;
}

.add-ques .avail-qns:hover {
  background-color: #73276b !important;
  color: #fff;
  border: 1px solid #73276b !important;
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  font-weight: 600;
  text-decoration: none;
}

.add-ques .student .option {
  width: 35px;
  height: 35px;
  background-color: #72a44b;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  padding: 6px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.add-ques .ass-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.add-ques .ass-dropdown-content {
  display: none;
  text-align: start;
  position: absolute;
  right: 2px;
  top: 25px;
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99;
}

.add-ques .ass-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  font-weight: normal;
}

.add-ques .ass-dropdown-content a svg {
  margin-right: 8px;
}

.add-ques .ass-dropdown-content a span {
  font-size: 12px;
  font-weight: 400;
  position: relative;
  top: 3px;
}

.add-ques .ass-dropdown-content a:hover {
  background-color: #adc926;
  color: #4c5a09;
}

.add-ques .ass-dropdown:hover .ass-dropdown-content {
  display: block;
}

.add-ques .assessment-block .table {
  font-size: 14px;
}

.add-ques .assessment-block .table th {
  text-align: start;
  width: 34px;
}

.add-ques .assessment-block .table th,
.add-ques .assessment-block .table td {
  color: #242934;
  background-color: #eef2ff;
  border: none;
  border-bottom: 5px solid #fff;
  padding-top: 20px;
}

.add-ques .assessment-block .table td {
  font-weight: 600;
}

.add-ques .assessment-block .table td:last-child {
  text-align: end;
}

.add-ques .assessment-block .table tr th {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.add-ques .assessment-block .table tr:last-child {
  border: none;
}

.add-ques .assessment-block .table tr td:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.add-ques .assessment-block .table tr:last-child .ass-dropdown-content {
  right: 2px;
  top: -145px;
  box-shadow: 0px -8px 16px 0px rgb(0 0 0 / 20%);
}

.add-ques .assessment-block .table thead tr th {
  border-radius: 0;
  background-color: #0a0a0a1c;
  padding-top: 18px;
}

.add-ques .assessment-block .table thead tr th:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.add-ques .assessment-block .table thead tr th:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

/* .multi-field-wrapper .remove-field,
.multi-field-wrapper .remove-field:hover {
  padding: 8px 10px 5px 10px;
  text-align: center;
  background-color: #ef1e1e;
  border-radius: 0 5px 5px 0;
  text-decoration: none;
  cursor: pointer;
}

.multi-field-wrapper .remove-field svg {
  stroke: #fff;
} */

.multi-field-wrapper .form-control {
  border-radius: 0.25rem 0 0 0.25rem;
}

.multi-field-wrapper .multi-field {
  margin-top: 5px;
}

.multi-field-wrapper .add-field {
  color: #879f17;
  background-color: transparent;
  display: initial;
  border-radius: 5px;
  border: 1px solid #adc926;
  text-align: center;
  font-weight: 500;
  padding: 5px 15px;
  margin-top: 10px;
  cursor: pointer;
}

.multi-field-wrapper .add-field:hover {
  color: #fff;
  background-color: #879f17;
  border: 1px solid #879f17;
}

.multi-field-wrapper a,
.multi-field-wrapper a:hover {
  color: inherit;
  text-decoration: none;
}

.multi-field-wrapper .add-field svg {
  position: relative;
  top: 4px;
  margin-right: 8px;
}

.multi-field-wrapper .add-field span {
  position: relative;
  top: -3px;
}

.create-ques .form-bookmark .add-qn a {
  color: #78266a !important;
  background-color: transparent;
  border: 1px solid #78266a;
  padding: 12px 15px;
  border-radius: 0.25rem;
  display: block;
  text-align: center;
  cursor: pointer;
}

.create-ques .form-bookmark .add-qn a:hover {
  color: #fff !important;
  background-color: #78266a !important;
  border: 1px solid #78266a;
}

.create-ques .assessment-sec .inputGroup label:before {
  background-color: #78266a;
}

.create-ques .assessment-sec .inputGroup input:checked ~ label:after {
  background-color: #78266a;
  border-color: #ffffff;
}

.create-ques .card-header h5 svg {
  position: relative;
  top: 5px;
  width: 25px;
  height: auto;
  stroke: #742668;
}

.create-ques .card-footer .btn-primary {
  margin-left: 10px;
}

.create-ques .needs-validation label,
.add-ques .needs-validation label,
.assignment-sec .needs-validation label {
  opacity: 1 !important;
  margin-bottom: 10px;
}

.create-ques .selection .select2-selection .select2-search__field {
  margin-top: 0;
  padding: 3px 0px 4px 15px;
}

.create-ques
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 5px 5px 0px 5px;
  width: 100%;
}

.create-ques .select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #e6edef;
  border-radius: 4px;
  cursor: text;
}

.create-ques .card .card-footer {
  background-color: transparent;
  border-top: 1px solid #e6edef;
  padding: 15px 30px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.match-da-folo .multi-field-wrapper .form-control:first-child {
  border-radius: 0.25rem;
}

.match-da-folo .multi-field-wrapper .form-control {
  border-radius: 0.25rem 0 0 0.25rem;
  margin-right: 5px;
}

.create-ques
  .sort-the-folo
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  margin-bottom: 8px;
}

.e-learning-detail .details-banner h4 {
  color: #73276b;
  font-size: 22px;
  font-weight: 600;
}

.e-learning-detail .e-learning-item .card .card-header {
  padding: 15px;
  border-bottom: 1px solid #e6edef;
  border-radius: 15px 15px 0 0;
}

.e-learning-detail .e-learning-item .card .card-body {
  padding: 15px;
  background-color: transparent;
}

.e-learning-detail .e-learning-item .card .card-header h5.title {
  color: #772569;
  font-size: 14px;
  letter-spacing: 0.56px;
  padding-bottom: 6px;
}

.e-learning-detail .c-date {
  color: #ef5934;
  background-color: #ffd8ce;
  border-radius: 5px;
  padding: 4px 10px;
  font-size: 12px;
  line-height: 25px;
  font-weight: 500;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 30px;
  position: relative;
  top: 5px;
}

.e-learning-detail .type {
  color: #051b26;
  background-color: #eef2ff;
  font-size: 14px;
  position: relative;
  top: 7px;
  right: -10px;
  padding: 2px 14px 2px 8px;
  height: 28px;
  border-radius: 5px;
}

.e-learning-detail .type-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #eef2ff;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.e-learning-detail .type-icon svg {
  width: 20px;
  height: auto;
  position: relative;
  top: 11px;
  stroke: #73276b;
}

.e-learning-detail .footer-blk {
  color: #b0afaf;
  font-size: 14px;
  position: relative;
  top: 5px;
  margin-top: 10px;
}

.e-learning-detail .footer-blk-detail {
  font-size: 14px;
  color: #282739;
  font-weight: 500;
}

.e-learning-detail .header-icon {
  width: 35px;
  height: 35px;
  position: relative;
  top: -7px;
  margin-left: 6px;
  border-radius: 50%;
  background-color: #eef2ff;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.e-learning-detail .header-icon svg {
  width: 17px;
  height: auto;
  position: relative;
  top: 8px;
  stroke: #73276b;
}

.cursor-pointer-on {
  cursor: pointer;
}

.topic-ico img {
  width: 130px;
  height: auto;
}

.e-learning-gallery .gallery-head h4 {
  color: #772569;
  font-size: 18px;
  font-weight: 600;
}

.e-learning-gallery .gallery-head h6 {
  color: #766c8e;
  font-size: 13px;
  font-weight: 400;
}

.e-learning-gallery .gallery-head h6 span {
  color: #282739;
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
}

.e-learning-gallery .gallery-head h6:last-child span {
  margin-left: 12px;
}

.e-learning-gallery .gallery-head .desc {
  color: #766c8e;
  font-size: 13px;
  font-weight: 400;
}

.e-learning-gallery .gallery-head p {
  color: #282739;
  font-size: 12px;
  font-weight: 500;
}

/* E-Learning Gallery Section */
.gallery-sec {
  margin-top: 20px;
}

.notification-sec li {
  background-color: #efecef;
  margin-bottom: 20px;
  /* box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px; */
  border-radius: 15px 0px 0px 15px;
}

.notification-sec li .notify-date {
  font-size: 11px;
  letter-spacing: 0.36px;
  color: #424c14;
  position: relative;
  top: 40px;
  width: 234px;
}

.notification-sec li .message {
  font-size: 14px;
  letter-spacing: 0.42px;
  color: #222222;
}

.notification-sec li svg {
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.notification-sec .bellico {
  padding: 15px 6px 15px 15px;
  border-radius: 10px 0 0 10px;
}

.notification-sec li.red .bellico {
  background-color: #fed9d8;
}

.notification-sec li.byzantium .bellico {
  background-color: #e5d0e4;
}

.notification-sec li.red .bellico svg {
  stroke: #cd100c;
}

.notification-sec li.byzantium .bellico svg {
  stroke: #78266a;
}

.notification-sec li.yellow .bellico {
  background-color: #fefdd9;
}

.notification-sec li.yellow .bellico svg {
  stroke: #837e0b;
}

.notification-sec li.blue .bellico {
  background-color: #d9f5ff;
}

.notification-sec li.blue .bellico svg {
  stroke: #205b93;
}

.notification-sec li.green .bellico {
  background-color: #dffed9;
}

.notification-sec li.green .bellico svg {
  stroke: #248911;
}

.notification-sec li .message {
  padding: 10px 15px;
}

.notification-sec li.red {
  border-right: 5px solid #cd100c;
}

.notification-sec li.byzantium {
  border-right: 5px solid #78266a;
}

.notification-sec li.yellow {
  border-right: 5px solid #837e0b;
}

.notification-sec li.blue {
  border-right: 5px solid #205b93;
}

.notification-sec li.green {
  border-right: 5px solid #248911;
}

.notification-sec li.red .notify-date {
  color: #cd100c;
}

.notification-sec li.byzantium .notify-date {
  color: #78266a;
}

.notification-sec li.yellow .notify-date {
  color: #837e0b;
}

.notification-sec li.blue .notify-date {
  color: #205b93;
}

.notification-sec li.green .notify-date {
  color: #248911;
}

.bell {
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: #252934;
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;

  margin-right: 8px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.syllabus-sec .lessons .lession-title {
  color: #766c8e;
  font-size: 14px;
  letter-spacing: 0.56px;
}

.syllabus-sec .lessons .lession-title span {
  color: #772569;
  font-size: 16px;
  font-size: 500;
  letter-spacing: 0.56px;
  margin-left: 10px;
  border-left: 1px solid #c8cfd1;
  padding-left: 10px;
}

.syllabus-sec .lessons {
  background-color: #eef2ff;
  padding: 8px 15px;
  border-radius: 8px;
  margin: 5px 0px;
}

.syllabus-sec .lessons:hover {
  background-color: #e9e9e9;
}

.icon-tile {
  color: #78266a;
  width: 40px;
  height: 40px;
  font-size: 18px;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  text-align: center;
  line-height: 31px;
}

.syllabus-sec .lessons:hover .icon-tile {
  color: #fff;
  background-color: #78266a;
}

.syllabus-sec .btn-edsys-primary span {
  top: -4px;
  position: relative;
}

.syllabus-sec .btn-edsys-primary svg {
  top: 3px;
  position: relative;
}

/* TimeTable */
.tt-align i {
  font-size: 25px;
}

.tt-align .line-grid-1 {
  left: 12px;
}

.tt-align .line-grid-2 {
  left: 18px;
}

.tt-align .line-grid {
  width: 4px;
  height: 15px;
}

.tt-box .tt-row {
  width: 100%;
  display: flex;
}

.break-row {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e9e9e9;
  line-height: 1px;
  margin: 18px 0 18px;
}

.break-row span {
  background: #fff;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.86px;
}

.tt-box .tt-row .tt-col {
  width: 12.5%;
  text-align: center;
  align-items: center !important;
}

.tt-box .tt-row .tt-col.monday .tt-title {
  background: linear-gradient(to bottom right, #cb5310 0%, #feb68d 100%);
}

.tt-box .tt-row .tt-col.monday .tt-title .tt-title-inner .title {
  background: -webkit-linear-gradient(#cb5310, #feb68d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tt-box .tt-row .tt-col.tuesday .tt-title .tt-title-inner .title {
  background: -webkit-linear-gradient(#1438c3, #9cb0ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tt-box .tt-row .tt-col.wednesday .tt-title .tt-title-inner .title {
  background: -webkit-linear-gradient(#8458e7, #d5c2ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tt-box .tt-row .tt-col.thursday .tt-title .tt-title-inner .title {
  background: -webkit-linear-gradient(#2c9b71, #8fddc0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tt-box .tt-row .tt-col.friday .tt-title .tt-title-inner .title {
  background: -webkit-linear-gradient(#978723, #f2e592);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tt-box .tt-row .tt-col.sunday .tt-title .tt-title-inner .title {
  background: -webkit-linear-gradient(#2386a3, #b5edfd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tt-box .tt-row .tt-col.sunday .tt-title .tt-title-inner .title {
  background: -webkit-linear-gradient(#979797, #e3e3e3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tt-box .tt-row .tt-col.tuesday .tt-title {
  background: linear-gradient(to bottom right, #1438c3 0%, #9cb0ff 100%);
}

.tt-box .tt-row .tt-col.wednesday .tt-title {
  background: linear-gradient(to bottom right, #8458e7 0%, #d5c2ff 100%);
}

.tt-box .tt-row .tt-col.thursday .tt-title {
  background: linear-gradient(to bottom right, #2c9b71 0%, #8fddc0 100%);
}

.tt-box .tt-row .tt-col.friday .tt-title {
  background: linear-gradient(to bottom right, #978723 0%, #f2e592 100%);
}

.tt-box .tt-row .tt-col.sunday .tt-title {
  background: linear-gradient(to bottom right, #2386a3 0%, #b5edfd 100%);
}

.tt-box .tt-row .tt-col.sunday .tt-title {
  background: linear-gradient(to bottom right, #979797 0%, #e3e3e3 100%);
}

.tt-box .tt-row .tt-col .tt-title {
  background: linear-gradient(to bottom right, #aba9a9 0%, #dddddd 100%);
  padding: 4px;
  position: relative;
  /* z-index: 1; */
  cursor: default;
  transition: all 0.3s ease;
  margin: 3px 2px;
  border-radius: 11px;
}

.tt-box .tt-row .tt-col .tt-title .tt-title-inner {
  background: #fff;
  padding: 10px 0;
  border-radius: 8px;
  transition: inherit;
}

.tt-box .tt-row .tt-col .tt-title .tt-title-inner .title {
  background: -webkit-linear-gradient(#000000, #787877);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tt-box .tt-row .tt-col .tt-desc.tt-desc-inner {
  background-color: white;
  border: 4px solid #c7c7c7;
  border-radius: 11px;
  margin: 3px 2px;
}

.tt-box .tt-row .tt-col .tt-desc.tt-desc-inner .duration {
  background-color: #e7e7e7;
  border-radius: 8px;
  font-size: 11px;
  font-weight: 500;
  height: 44px;
  padding: 3px;
}

.tt-box .tt-row .tt-col .tt-desc.tt-desc-inner img {
  width: 30px;
  height: 30px;
  margin-top: 8px;
  border-radius: 50%;
}

.tt-box .tt-row .tt-col .tt-desc.tt-desc-inner .title {
  color: #732468;
  font-weight: 600;
  font-size: 12px;
}

.tt-box .tt-row .tt-col.tt-content .tt-desc.tt-desc-inner .title {
  color: #732468;
  font-weight: 600;
  font-size: 12px;
}

.tt-box .tt-row .tt-col.tt-content .tt-desc.tt-desc-inner .duration .status {
  font-weight: 600;
  font-size: 11px;
  line-height: 40px;
}

.tt-box .tt-row .tt-col.tt-content.monday .tt-desc.tt-desc-inner {
  background-color: #fff6f1;
  border: 4px solid #cb5310;
}

.tt-box .tt-row .tt-col.tt-content.monday .tt-desc.tt-desc-inner .duration {
  background-color: #ffb68d;
}

.tt-box
  .tt-row
  .tt-col.tt-content.monday
  .tt-desc.tt-desc-inner
  .duration
  .status {
  color: #cb5310;
}

.tt-box .tt-row .tt-col.tt-content.tuesday .tt-desc.tt-desc-inner {
  background-color: #e1e7ff;
  border: 4px solid #1538c3;
}

.tt-box .tt-row .tt-col.tt-content.tuesday .tt-desc.tt-desc-inner .duration {
  background-color: #9cb0ff;
}

.tt-box
  .tt-row
  .tt-col.tt-content.tuesday
  .tt-desc.tt-desc-inner
  .duration
  .status {
  color: #1538c3;
}

.tt-box .tt-row .tt-col.tt-content.wednesday .tt-desc.tt-desc-inner {
  background-color: #eae0ff;
  border: 4px solid #8458e7;
}

.tt-box .tt-row .tt-col.tt-content.wednesday .tt-desc.tt-desc-inner .duration {
  background-color: #d5c2ff;
}

.tt-box
  .tt-row
  .tt-col.tt-content.wednesday
  .tt-desc.tt-desc-inner
  .duration
  .status {
  color: #8458e7;
}

.tt-box .tt-row .tt-col.tt-content.thursday .tt-desc.tt-desc-inner {
  background-color: #eafdf6;
  border: 4px solid #2b9b71;
}

.tt-box .tt-row .tt-col.tt-content.thursday .tt-desc.tt-desc-inner .duration {
  background-color: #8fddc0;
}

.tt-box
  .tt-row
  .tt-col.tt-content.thursday
  .tt-desc.tt-desc-inner
  .duration
  .status {
  color: #2b9b71;
}

.tt-box .tt-row .tt-col.tt-content.friday .tt-desc.tt-desc-inner {
  background-color: #fffbe1;
  border: 4px solid #978724;
}

.tt-box .tt-row .tt-col.tt-content.friday .tt-desc.tt-desc-inner .duration {
  background-color: #f2e592;
}

.tt-box
  .tt-row
  .tt-col.tt-content.friday
  .tt-desc.tt-desc-inner
  .duration
  .status {
  color: #978724;
}

.tt-box .tt-row .tt-col.tt-content.sunday .tt-desc.tt-desc-inner {
  background-color: #f1fcff;
  border: 4px solid #2386a3;
}

.tt-box .tt-row .tt-col.tt-content.sunday .tt-desc.tt-desc-inner .duration {
  background-color: #b5edfd;
}

.tt-box
  .tt-row
  .tt-col.tt-content.sunday
  .tt-desc.tt-desc-inner
  .duration
  .status {
  color: #2386a3;
}

.tt-box .tt-row .tt-col.tt-content.weekend .tt-desc.tt-desc-inner {
  border: 4px solid #cbcbcb;
}

.tt-box .tt-row .tt-content.weekend .tt-desc.tt-desc-inner {
  min-height: 111.59px;
  padding-top: 18px;
}

.tt-title:hover {
  transform: scale(1.03);
  z-index: 10;
}

.tt-box .tt-row .tt-col.tt-content.monday .tt-desc.tt-desc-inner:hover,
.tt-box .tt-row .tt-col.tt-content.tuesday .tt-desc.tt-desc-inner:hover,
.tt-box .tt-row .tt-col.tt-content.wednesday .tt-desc.tt-desc-inner:hover,
.tt-box .tt-row .tt-col.tt-content.thursday .tt-desc.tt-desc-inner:hover,
.tt-box .tt-row .tt-col.tt-content.friday .tt-desc.tt-desc-inner:hover,
.tt-box .tt-row .tt-col.tt-content.sunday .tt-desc.tt-desc-inner:hover {
  transform: scale(1.03);
  z-index: 10;
}

.timetable .nav-pills .nav-link {
  font-weight: 600;
  background-color: white;
  border-radius: 0.25rem;
  margin: 0 2px;
}

.timetable .nav-pills .nav-link.active,
.timetable .nav-pills .show > .nav-link {
  background-color: #78266a;
}

.timetable .assignment-sec .card.stack-card .card-footer {
  margin-top: 10px;
}

.stack-card .card-footer img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.timetable .assignment-sec .card.stack-card .card-footer .name {
  color: #051b26;
  position: relative;
  top: 8px;
  left: 10px;
  font-weight: 600;
}

.timetable .assignment-sec .card.stack-card .card-header h5 {
  font-size: 22px;
  color: #772569;
  letter-spacing: 0.88px;
  position: relative;
  left: 10px;
  top: 4px;
}

.timetable .assignment-sec .card.stack-card .card-header .class {
  color: #fff;
  background-color: #73276b;
  border-radius: 7px;
  height: 23px;
  padding: 3px 10px;
  font-size: 12px;
  line-height: 17px;
}

.timetable .assignment-sec .card.stack-card .card-footer .time {
  color: #772569;
  font-weight: 500;
  position: relative;
  top: 9px;
}

.timetable .assignment-sec .card.stack-card .card-header {
  background-color: #fff0;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid #73276b1a;
}

.timetable .assignment-sec .card {
  margin-bottom: 15px;
}

.timetable .assignment-sec .break-row {
  margin: 0px 0 18px;
}

/* Attendance section */
.my-attendance .punch-tile {
  border-right: 1px solid #ffffff38;
  padding-right: 15px;
}

.my-attendance h4 {
  color: #fff;
}

.punch-button {
  width: 180px;
  height: 180px;
  background-color: transparent;
  border: 6px solid #fff;
  border-radius: 50%;
  padding: 3px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  margin: 50px auto;
}

.punch-button .punch-btn {
  position: relative;
  top: -4px;
  left: 3px;
  width: 157px;
  height: 76px;
  padding-top: 16px;
  text-align: center;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  background-color: #adc925;
  border-radius: 0 0 200px 200px;
  cursor: pointer;
}

.punch-btn:hover {
  color: #4c5b09;
  background: rgb(135, 157, 38);
  background: linear-gradient(
    322deg,
    rgba(135, 157, 38, 1) 0%,
    rgba(182, 214, 33, 1) 37%,
    rgba(191, 214, 87, 1) 100%
  );
}

.punch-button .total-hrs {
  color: #fff;
  font-size: 13px;
  margin-top: 22px;
  font-weight: 600;
}

.punch-button .start-time {
  color: #fff;
  font-size: 30px;
}

.work-duration img {
  width: 50px;
  height: auto;
  /* position: relative; */
}

.work-duration img.p-out-img {
  right: 30px;
}

.work-duration .p-in,
.work-duration .p-out {
  color: #fff;
  margin-top: 15px;
}

.work-duration .p-out {
  margin-right: 10px;
}

.work-duration .p-in-time,
.work-duration .p-out-time {
  color: #fff;
  margin-top: 15px;
  font-size: 18px;
}

.work-duration .p-out-time {
  margin-right: 15px;
}

.my-attendance .profile-greeting {
  min-height: 500px;
}

.my-attendance .select-month {
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  -webkit-overflow-scrolling: touch;
}

.my-attendance .select-month::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.my-attendance .select-month .months {
  padding: 4px 10px;
  color: 000;
  background-color: #fff;
  border-radius: 6px;
  margin: 0 3px;
  cursor: pointer;
}

.my-attendance .select-month .months:first-child {
  margin: 0 3px 0 0;
}

.my-attendance .select-month .months:hover,
.my-attendance .select-month .months.active {
  color: #4c5a08;
  background-color: #adc924;
}

.punch-status .date-tile {
  display: table;
  border: 1px solid #fff;
  background-color: transparent;
  padding: 4px 8px;
  border-radius: 8px;
  margin: 10px 0 0 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 58px;
}

.punch-status .date-tile .date {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.punch-status .date-tile .day {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.punch-review .nav {
  -ms-flex-wrap: inherit !important;
  flex-wrap: inherit !important;
  overflow-y: hidden;
  white-space: nowrap !important;
}

.react-calendar {
  width: auto !important;
}

.react-calendar button {
  height: 10vh !important;
}

.punch-status {
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  -webkit-overflow-scrolling: touch;
}

.punch-status::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.punch-progress {
  text-align: start;
  position: relative;
  top: 25px;
  left: 34px;
  width: 100%;
  max-width: 525px;
}

.punch-progress .attend {
  background-color: #99cd1f !important;
}

.punch-progress .working-hrs {
  color: #fff;
}

.punch-progress .holiday {
  background-color: #ffd42a !important;
  height: 1rem;
}

.punch-progress .leave {
  background-color: #fd3030 !important;
  height: 1rem;
}

.leave-status {
  background-color: transparent;
  border: 3px solid #adc926;
  padding: 8px;
  border-radius: 10px;
}

.leave-status img {
  margin-top: 8px;
  width: 73px;
  height: 73px;
}

.leave-status .leaves-count {
  font-size: 32px;
  font-weight: 600;
}

.leave-status .title {
  color: #5f9612;
  font-size: 18px;
  font-weight: 500;
}

.apply-leave .leve-form .radio-group {
  border: solid 3px #675f6b;
  display: inline-block;
  margin: 10px 0;
  border-radius: 10px;
  overflow: hidden;
}

.apply-leave .leve-form input[type="radio"] {
  position: absolute;
  visibility: hidden;
  display: none;
}

.leave-status img {
  width: 73px;
  height: 73px;
}

.leave-status .leaves-count {
  font-size: 32px;
  font-weight: 600;
}

.leave-status .title {
  color: #5f9612;
  font-size: 18px;
  font-weight: 500;
}

.leave-status {
  background-color: transparent;
  border: 3px solid #adc926;
  padding: 8px;
  border-radius: 10px;
}

/* Donut Chart */
.apply-leave .chart-area {
  position: relative;
  top: -40px;
  text-align: center;
  text-align: -webkit-center;
}

/* Leave Form */
.apply-leave .leve-form input[type="radio"] {
  position: absolute;
  visibility: hidden;
  display: none;
}

.apply-leave .leve-form label {
  color: #9a929e;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 20px;
  opacity: 1;
  margin-bottom: 0;
}

.apply-leave .leve-form input[type="radio"]:checked + label {
  color: #566609;
  background: #adc926;
}

.apply-leave .leve-form label + input[type="radio"] + label {
  border-left: solid 3px #675f6b;
  margin: 0px 1px;
}

.apply-leave .leve-form .radio-group {
  border: solid 3px #675f6b;
  display: inline-block;
  margin: 10px 0;
  border-radius: 10px;
  overflow: hidden;
}

/* .apply-leave .details-banner h3 {
  color: #051b26;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.72px;
} */

.approve-leave .n-attend-list .n-attend-total {
  color: #fff;
  border: 1px solid #1a1746;
}

.approve-leave .no-leave {
  color: #adc926;
}

.approve-leave .modal-header img {
  width: 42px;
  height: 42px;
  border-radius: 25px;
  border: 3px solid #adc927;
  margin-right: 15px;
}

.approve-leave .modal-header .no-leave {
  color: #adc926;
  position: absolute;
  top: 26px;
  right: 25px;
}

.approve-leave .modal-bookmark .modal-header {
  padding: 15px 25px;
  background-color: #78266a;
  color: #fff;
  border-radius: 15px;
  border-bottom: 0px;
}

.approve-leave .modal-content {
  border-radius: 15px;
}

.approve-leave .modal-body .fixed-form .al-label {
  color: #766c8e;
  font-size: 14px;
  letter-spacing: 0.56px;
  margin-bottom: 10px;
}

.approve-leave .modal-body .fixed-form .al-input {
  color: #282739;
  background-color: #f5f6fa;
  text-align: center;
  padding: 12px 0;
  font-size: 14px;
  letter-spacing: 0.56px;
  border-radius: 27px;
  margin-bottom: 25px;
}

.approve-leave .modal-body .fixed-form .al-text-area {
  color: #282739;
  background-color: #f5f6fa;
  text-align: start;
  padding: 12px 12px;
  font-size: 14px;
  letter-spacing: 0.56px;
  border-radius: 10px;
  min-height: 100px;
}

.approve-leave .student-rank-list {
  background-color: #ebebeb00;
  padding: 9px 20px;
  border-radius: 15px;
  margin-bottom: 6px;
  /* border: 1px solid #ebebeb; */
  cursor: pointer;
}

.approve-leave .student-rank-list .student-name {
  color: #1d1a4f;
  font-size: 14px;
  letter-spacing: 0.56px;
}

.approve-leave .student-rank-list .ranks {
  color: #592160;
  font-size: 16px;
  margin-right: 24px;
}

.approve-leave .nav-pills .nav-link {
  font-weight: 600;
  border: 2px solid #78266a;
  margin-left: 8px;
}

.approve-leave .modal-body .fixed-form .al-approved {
  color: #282739;
  background-color: #eafdf6;
  text-align: center;
  padding: 12px 0;
  font-size: 14px;
  letter-spacing: 0.56px;
  border-radius: 27px;
  margin-bottom: 25px;
}

.approve-leave .modal-body .fixed-form .al-pending {
  color: #282739;
  background-color: #f0e6e4;
  text-align: center;
  padding: 12px 0;
  font-size: 14px;
  letter-spacing: 0.56px;
  border-radius: 27px;
  margin-bottom: 25px;
}

.approve-leave .modal-scroll {
  max-height: 565px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.page-main-header .main-header-right .nav-right .language-box {
  position: relative;
}

.page-main-header .main-header-right .nav-right .language-dropdown li {
  padding: 8px 25px 8px 10px !important;
}

.page-main-header
  .main-header-right
  .nav-right
  .language-dropdown
  li.noti-primary:hover {
  background-color: rgba(36, 105, 92, 0.1);
}

.page-main-header
  .main-header-right
  .nav-right
  .language-dropdown
  li
  .media
  .language-bg.bg-light-primary {
  background-color: rgba(36, 105, 92, 0.1);
  color: #78266a;
}

.page-main-header
  .main-header-right
  .nav-right
  .language-dropdown
  li
  .media
  .language-bg {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.page-main-header
  .main-header-right
  .nav-right
  .language-dropdown.onhover-show-div {
  top: 40px;
  position: absolute;
  z-index: 8;
  background-color: #fff;
  border: 1px solid #f5f7fb;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.page-main-header
  .main-header-right
  .nav-right
  .language-dropdown
  .media
  .media-body
  p {
  position: relative;
  top: 8px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.user-profile .my-profile-header {
  background-image: linear-gradient(120deg, #7929692e 0%, #adc92836 100%);
  padding: 1.5rem;
  border-top: 2px solid #d5d5d59e;
  border-left: 2px solid #d5d5d59e;
  border-right: 2px solid #d5d5d59e;
  border-bottom: none;
}

.user-profile .my-profile-header .profile-image img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.user-profile .about-text {
  max-width: 500px;
}

.user-profile .my-profile-menu {
  background-color: #fff;
  border-bottom: 2px solid #d5d5d59e;
  border-left: 2px solid #d5d5d59e;
  border-right: 2px solid #d5d5d59e;
  border-top: none;
  padding: 0.9375rem 1.5rem;
}

.user-profile .table-responsive {
  overflow-x: auto;
  padding-top: 15px;
}

.user-profile .table tr td:first-child {
  font-weight: 400;
  color: #6f6f70;
}

.user-profile .table td {
  border: none;
  padding: 5px;
}

.user-profile .table tr td:last-child {
  font-weight: 600;
  color: #000;
}

.user-profile .update-pw {
  color: #782668;
  font-size: 18px;
  position: relative;
  top: 19px;
  left: 79px;
  background-color: #ffffff;
  display: inline;
  padding: 4px 10px 4px 10px;
  border-left: 0;
  border-top: 1px solid #e6edef;
  border-right: 1px solid #e6edef;
  border-bottom: 1px solid #e6edef;
}

.contact-form .theme-form {
  border: 1px solid #e6edef;
  padding: 30px;
  border-radius: 4px;
}

.contact-form .theme-form .form-icon {
  width: 53px;
  height: 53px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #e6edef;
  border-radius: 100%;
  font-size: 28px;
  background-color: #fff;
  color: #adc927;
  margin-top: -55px;
  margin-bottom: 15px;
}

.contact-form .theme-form label {
  font-weight: 600;
}

.profile-user-info .first-name {
  font-weight: 500;
  font-size: 1.3rem;
}

.profile-user-info .sur-name {
  font-weight: 600;
  font-size: 1.5rem;
}

.edit-profile {
  position: relative;
  top: 0px;
  background-color: transparent;
  text-align: center;
  padding: 8px 15px;
  border: 2px solid #78266a;
  color: #78266a;
  font-weight: 500;
  border-radius: 0.25rem;
}

.edit-profile:hover {
  background-color: #78266a;
  border: 2px solid #78266a;
  color: #fff;
}

/* Edit Profile */
.profile-pic-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pic-holder {
  text-align: center;
  position: relative;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  top: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.pic-holder .pic {
  height: 120px;
  width: 120px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.pic-holder .upload-file-block,
.pic-holder .upload-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(90, 92, 105, 0.7);
  color: #f8f9fc;
  font-size: 12px;
  font-weight: 600;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.pic-holder .upload-file-block {
  cursor: pointer;
}

.pic-holder:hover .upload-file-block {
  opacity: 1;
}

.pic-holder.uploadInProgress .upload-file-block {
  display: none;
}

.pic-holder.uploadInProgress .upload-loader {
  opacity: 1;
}

/* Snackbar css */
.snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 14px;
  transform: translateX(-50%);
}

.snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

.user-profile .text-uppercase {
  text-transform: uppercase !important;
  font-size: 11px;
}

.user-profile label {
  margin-bottom: 0.5rem;
  color: #59667a;
  font-weight: 500;
}

.user-profile .form-control,
.form-select {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
  min-height: 40px;
}

.user-profile
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #777f87;
  line-height: 28px;
  font-weight: 200;
}

.user-profile .edit-pro.my-profile-header {
  background-image: linear-gradient(120deg, #7929692e 0%, #adc92836 100%);
  border: 2px solid #ffffff21;
}

li .language-box span.active-lang .flag-icon {
  position: absolute;
  right: -12px;
  top: -11px;
  width: 15px !important;
  height: auto !important;
}

.apply-leave .leve-form label {
  color: #9a929e;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 20px;
  opacity: 1;
  margin-bottom: 0;
}

.apply-leave .leve-form input[type="radio"]:checked + label {
  color: #566609;
  background: #adc926;
}

.apply-leave .leve-form label + input[type="radio"] + label {
  border-left: solid 3px #675f6b;
  margin: 0px 0px;
}

.apply-leave .leve-form .radio-group {
  border: solid 3px #675f6b;
  display: inline-block;
  margin: 10px 0;
  border-radius: 10px;
  overflow: hidden;
}

.apply-leave .details-banner h3 {
  color: #051b26;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.72px;
  margin-top: 15px;
}

.approve-leave .n-attend-list .n-attend-total {
  color: #fff;
  border: 1px solid #1a1746;
}

.approve-leave .no-leave {
  color: #adc926;
}

.approve-leave .no-leave {
  color: #adc926;
}

.approve-leave .modal-header img {
  width: 42px;
  height: 42px;
  border-radius: 25px;
  border: 3px solid #adc927;
  margin-right: 15px;
}

.approve-leave .modal-header .no-leave {
  color: #adc926;
  position: absolute;
  top: 26px;
  right: 25px;
}

.approve-leave .modal-bookmark .modal-header {
  padding: 8px 25px;
  background-color: #78266a;
  color: #fff;
  border-radius: 15px;
}

.approve-leave .modal-content {
  border-radius: 15px;
}

.approve-leave .modal-body .fixed-form .al-label {
  color: #766c8e;
  font-size: 14px;
  letter-spacing: 0.56px;
  margin-bottom: 10px;
}

.approve-leave .modal-body .fixed-form .al-input {
  color: #282739;
  background-color: #f5f6fa;
  text-align: center;
  padding: 12px 0;
  font-size: 14px;
  letter-spacing: 0.56px;
  border-radius: 27px;
  margin-bottom: 25px;
}

.approve-leave .modal-body .fixed-form .al-text-area {
  color: #282739;
  background-color: #f5f6fa;
  text-align: start;
  padding: 12px 12px;
  font-size: 14px;
  letter-spacing: 0.56px;
  border-radius: 10px;
  min-height: 100px;
}

.my-attendance .btn-apply-leave {
  color: #15194c;
  background-color: #adc924;
  border: 2px solid #adc924;
  padding: 9px 15px;
  border-radius: 8px;
  display: inline-block;
  position: relative;
  top: 21px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
  text-decoration: none;
}

.my-attendance .btn-apply-leave:hover {
  color: #742568;
  background-color: #eef2ff;
  border: 2px solid #eef2ff;
}

.my-attendance .btn-apply-leave {
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  a
  img {
  width: 20px;
  height: auto;
  margin-right: 15px;
  vertical-align: bottom;
  float: none;
  stroke-width: 2.5px;
}

.announcement .ico-n {
  width: 45px;
  height: 45px;
  background-color: #73276b;
  padding: 4px 8px;
  border-radius: 8px;
  line-height: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.announcement .ico-n svg {
  stroke: #fff;
}

.announcement .date-footer {
  color: #949fa6;
  border-top: 1px solid #0000001c;
  padding-top: 8px;
  margin-top: 10px;
  font-size: 12px;
}

.announcement .card .card-body {
  padding: 20px;
  background-color: transparent;
}

.notif-setting .switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 26px;
}

.notif-setting .icon-state .switch-state:after {
  content: "\efb8";
  font-family: IcoFont;
  left: 5px;
  position: absolute;
  top: -2px;
  color: #2c323f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 0.3;
}

.notif-setting .switch-state:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;

  bottom: 3px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;
}

.notif-setting .icon-state input:checked + .switch-state:after {
  content: "\efad";
  left: 27px;
  top: -2px;
  opacity: 1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.notif-setting .switch input:checked + .switch-state:before {
  -webkit-transform: translateX(21px);
  transform: translateX(21px);
}

.notif-setting label {
  margin-bottom: 0;
  font-weight: 600;
}

.card.notif-setting {
  border-radius: 0px;
}

.notif-setting .card-header h5 svg {
  width: 35px;
  height: auto;
  position: relative;
  top: 10px;
  margin-right: 7px;
  stroke-width: 2.5 px;
  -webkit-animation: rotateInSetting 3.5s ease infinite;
  animation: rotateInSetting 3.5s ease infinite;
}

.notif-setting table th,
.table td {
  font-weight: 400;
}

.notif-setting .table thead th {
  color: #782669;
  background-color: #7826690d;
  font-size: 16px;
  padding: 22px 14px;
  font-weight: 600;
}

.notif-setting .table thead th:first-child {
  border-radius: 10px 0px 0px 10px;
}

.notif-setting .table thead th:last-child {
  border-radius: 0px 10px 10px 0px;
}

.channel-list .channel-video {
  position: relative;
  text-align: center;
  color: white;
}

.channel-list .channel-video img {
  width: 170px;
  height: 134px;
  border-radius: 7px;
  margin-top: 10px;
}

.channel-list .channel-video .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffffde;
  border-radius: 50%;
  padding: 10px 12px 10px 15px;
  width: 50px;
  height: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  line-height: 44px;
  cursor: pointer;
}

.channel-list .channel-video .play-button svg {
  stroke: #000;
}

.channel-list .channel-video .play-button:hover {
  background-color: #adc926;
}

.channel-list .channel-video .play-button:hover svg {
  stroke: #4c5a08;
}

.channel-list .channel-video .play-button svg {
  stroke: #000;
}

.channel-list .channel-class {
  color: #772569;
  font-size: 50px;
  font-weight: 600;
  margin-top: -20px;
}

.channel-list .video-title {
  color: #949fa6;
  font-size: 14px;
  letter-spacing: 0.56px;
  margin-top: -17px;
}

.channel-list .card .card-body {
  padding: 8px;
  background-color: transparent;
}

.channel-list .card .card-footer {
  background-color: transparent;
  border-top: 1px solid #e6edef;
  padding: 8px;
  text-align: center;
}

/* Channel Video Content */
.channel-video-content video {
  background-color: #000;
  width: 100%;
  height: auto;
  border-radius: 15px 15px 0 0;
}

.channel-video-content .speedControl {
  width: 2em;
  padding: 2px;
  border: 1px inset silver;
  box-sizing: border-box;
  background: #000;
  align-self: stretch;
  margin: 0 0 0 0.5em;
  border-radius: 4px;
  overflow: hidden;
}

.channel-video-content .indicator {
  user-select: none;
  color: white;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to left top, blue, red);
  font-size: 0.7em;
  font-family: sans-serif;
}

.channel-video-content .card .card-body {
  padding: 0;
  background-color: transparent;
}

.about-video {
  padding: 15px;
}

.upcoming-block {
  padding: 15px;
}

.upcoming-block h5 {
  border-bottom: 1px solid #0000002e;
  padding-bottom: 10px;
  margin-bottom: 18px;
  font-size: 16px;
}

.upcoming-block .channel-list .channel-class {
  color: #772569;
  font-size: 34px;
  font-weight: 600;
  margin-top: -12px;
}

.upcoming-block .channel-list .video-title {
  color: #949fa6;
  font-size: 14px;
  letter-spacing: 0.56px;
  margin-top: -8px;
}

.channel-list .channel-video .time {
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: #fff;
  background-color: #abc62900 #abc629;
  padding: 0 8px;
  border-radius: 5px;
}

.channel-list .channel-video:hover .time {
  color: #5c6c0e;
  background-color: #abc629;
}

.login-card img.shape-top {
  position: absolute;
  top: -181px;
  left: -112px;
  width: 500px;
  height: auto;
}

.login-card img.login-logo {
  position: absolute;
  top: 82px;
  left: 56px;
  width: 175px;
  height: auto;
}

.login-card img.shape-center {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 175px;
  height: auto;
  opacity: 0.16;
}

/* custom popup fade for assignment modal start */
.modal.testingll {
  box-shadow: inset 5000px 1000px 0px 20px rgb(0 0 0 / 50%);
}

.uploadCloud {
  width: 50px;
  height: auto;
  stroke: #adc926;
}

/* create assignment starts */

#heading {
  text-transform: uppercase;
  color: #78266a;
  font-weight: normal;
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;

  /*stacking fieldsets above each other*/
  position: relative;
}

.form-card {
  text-align: left;
}

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
  display: none;
}

#msform input,
#msform textarea {
  padding: 8px 15px 8px 15px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  background-color: #eceff1;
  font-size: 16px;
  letter-spacing: 1px;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #78266a;
  outline-width: 0;
}

/*Next Buttons*/
#msform .action-button {
  width: 100px;
  background: #78266a;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 0px 10px 5px;
  float: right;
}

#msform .action-button:hover,
#msform .action-button:focus {
  background-color: #311b92;
}

/*Previous Buttons*/
#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: right;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  background-color: #000000;
}

/*The background card*/
.progress_bar_card {
  z-index: 0;
  border: none;
  position: relative;
}

/*FieldSet headings*/
.fs-title {
  font-size: 25px;
  color: #78266a;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left;
}

.purple-text {
  color: #78266a;
  font-weight: normal;
}

/*Step Count*/
.steps {
  font-size: 25px;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right;
}

/*Field names*/
.fieldlabels {
  color: gray;
  text-align: left;
}

/*Icon progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: #78266a;
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
}

/*Icons in the ProgressBar*/
#progressbar #account:before {
  font-family: FontAwesome;
  /*content: "\f13e";*/
  content: "1";
}

#progressbar #personal:before {
  font-family: FontAwesome;
  /*content: "\f007";*/
  content: "2";
}

#progressbar #payment:before {
  font-family: FontAwesome;
  /*content: "\f030";*/
  content: "3";
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  /*content: "\f00c";*/
  content: "4";
}

/*Icon ProgressBar before any progress*/
#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

/*ProgressBar connectors*/
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

/*Color number of the step and the connector before it*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #78266a;
}

/*Animated Progress Bar*/
.progress {
  height: 5px;
}

.progress-bar {
  background-color: #78266a;
}

/*Fit image in bootstrap div*/
.fit-image {
  width: 100%;
  object-fit: cover;
}

.scrollable_table::-webkit-scrollbar {
  scrollbar-color: "#adc927 #ffffff";
  scrollbar-width: "thin";
}

.scrollable_table::-webkit-scrollbar {
  width: 8px;
}

.scrollable_table::-webkit-scrollbar-track {
  background: #ffffff;
}

.scrollable_table::-webkit-scrollbar-thumb {
  background-color: #adc927;
  border-radius: 6px;
  border: 3px solid #fff;
}

.assignment_table_icon {
  width: 17px;
  cursor: pointer;
}

.ck-content {
  height: 150px !important;
}

/* create assignment ends */

.communication_table_th_td {
  border: #e6edef 1px solid;
  padding: 1%;
}

.notification_searchbar {
  margin-top: 1%;
  margin-bottom: 3%;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 10px 10px 10px 10px;
  /*background-color: #af8ead;*/
  border: 1px solid #7e4a7f;
  padding-top: 2%;
  padding-bottom: 2%;
}

.notification_clear_all_filter_button {
  float: right;
  font-size: 50%;
  border: 1px solid #ff000c;
  border-radius: 5px 5px 5px 6px;
  padding: 3px;
  cursor: pointer;
}

.notification_mark_all_as_read_button {
  float: right;
  font-size: 50%;
  border: 1px solid #339321;
  border-radius: 5px 5px 5px 6px;
  padding: 3px;
  cursor: pointer;
}

.range-slderi.rangeSlider {
  /* background: linear-gradient( 
88deg
 , rgb(147, 173, 24) 48%, rgb(215, 220, 223) 30.1%); */
}

/* custom popup fade for assignment modal end */

/* 
- assignments
- view-assignment
- view-reviewed-assignment
- assessments
- assessment-detail
- rank-list
- score-details
- syllabus
- e-learning
- e-learning-detail
- img-video-gallery
- timetable
- timetable-2
- to-do




add-questions.html
assignment-detail.html
assignment-detail-2.html
attendance.html.html
create-question.html
create-question-2.html
create-question-3.html
create-question-4.html
not-attended-list.html
notification.html
review-assignment.html

*/

.accordion-button {
  border: none;
  background-color: transparent;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.lec_list_name {
  color: #051b26;
  position: relative;
  top: 8px;
  left: 10px;
  font-weight: 600;
}

.name {
  /* line-height: 1em;  */
  font-size: 20px;
  font-weight: 400;
  text-overflow: ellipsis;
  cursor: pointer;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
}

.name:hover {
  overflow: visible;
  white-space: normal;
  height: auto;
  transition: all 0.5s;
  transform: translateY(-10px);
}

.cancelBtn {
  padding: 10px;
  border-radius: 10px;
  color: grey;
  background-color: white;
  /* border-color: 1px solid red; */
  border: 1px solid grey;
  font-size: 18px;
}
.YesBtn {
  padding: 10px;
  border-radius: 10px;
  color: white;
  background-color: #f54747;

  border: 1px solid #f54747;
  font-size: 18px;
  margin-left: 20px;
}
.borderModal {
  border-bottom-color: 1px solid grey;
}
.newassignment {
  font-size: 15px;
}
.assignmentdiv {
  flex-direction: row;
}
.custom-select {
  color: blue; /* Change color to whatever you prefer */
}
.timetableinput {
  width: "20%" !important;
  /* background-color: #73276b !important; */
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.gradeinput {
  /* border: none; */
  font-weight: 600;
  background: #e9f0ee;
  color: #592160;
  padding: 8px 10px;
  width: 40%;
  border-radius: 4px;
  border-color: #592160;
  border-width: 1px;
  margin-left: 10px;
}
.gradeinput1 {
  /* border: none; */
  font-weight: 600;
  background: #e9f0ee;
  color: #592160;
  padding: 8px 10px;
  width: 40%;
  border-radius: 4px;
  border-color: #592160;
  border-width: 1px;
}

/* <----my work--> */
