.chat-container {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
  overflow: hidden;
}

/* Sidebar Styles */
.chat-sidebar {
  width: 25%;
  border-right: 1px solid #ccc;
  overflow-y: auto;
  padding: 10px;
  background-color: #f5f5f5;
}

.chat-sidebar h2 {
  margin: 10px 0;
  font-size: 1.5rem;
}

/* Chat Window */
.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.chat-header {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  background-color: #f9f9f9;
  font-size: 1.2rem;
}

.chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #e6e6e6;
}

.chat-input {
  border-top: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  /* Stack sidebar and chat window vertically */
  .chat-container {
    flex-direction: column;
    height: 100%;
  }

  .chat-sidebar {
    width: 100%;
    height: 20%;
    border-right: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;
  }

  .chat-sidebar h2 {
    font-size: 1.2rem;
    text-align: center;
  }

  .chat-window {
    flex: 1;
    height: 80%;
  }

  .chat-header {
    font-size: 1rem;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  /* Smaller screens (phones) */
  .chat-sidebar {
    height: 25%;
    padding: 5px;
  }

  .chat-sidebar h2 {
    font-size: 1rem;
    margin: 5px 0;
  }

  .chat-header {
    font-size: 0.9rem;
  }

  .chat-messages {
    padding: 5px;
  }

  .chat-input {
    padding: 5px;
  }

  .chat-input input {
    font-size: 0.9rem;
  }

  .chat-input button {
    font-size: 0.9rem;
  }
}
