.view {
  background-color: #9eb91c;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 4px 6px;
  border-radius: 5px;
}
.view1 {
  background-color: #78266a;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 4px 6px;
  border-radius: 5px;
}
.buttons {
  display: flex;
  gap: 5px;
  justify-content: right;
}
.image {
  width: 20px;
  height: 20px;
  font-weight: bold;
}
.profileimg {
  width: 30px;
  height: 30px;
}
.broder {
  border-bottom: 1px solid #e6edef;
  margin-top: 10px;
}
.padding {
  margin-left: 30px;
  margin-right: 30px;
}
.details {
  background-color: #73276b14;

  border-radius: 10px;
}
.textdetails {
  margin-left: 5px;
  align-self: center;
}
.firstdata {
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  color: #343a40;
  font-family: "Poppins";
  margin-left: 10px;
}
.seconddata {
  font-weight: 500;
  font-size: 14px;
  line-height: 35px;
  color: rgb(133, 126, 132);
  font-family: "Poppins";
}
